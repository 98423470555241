import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-twelve-comp',
  templateUrl: './ahu-twelve-comp.component.html',
  styleUrls: ['./ahu-twelve-comp.component.css']
})
export class AhuTwelveCompComponent {

}
