import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-ahu-two',
  templateUrl: './ahu-two.component.html',
  styleUrls: ['./ahu-two.component.css']
})


export class AhuTwoComponent {



  alarmlog:any[]=[]
  snackbars: { [key: string]: MatSnackBarRef<any> } = {};





  state: string = 'active';
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  progress: boolean = false;
  fahu_one_out: any;
  fahu_one_input: any;
  optimise_response: any = {};
  // input form fields
  
  

  SFRS_out: any = 0;

  // Interface fields 
  RARH_02_in: any = 0;
  RAT_in: any = 0;
  chw_valve_opening_02_in: any = 0;
  heating_coil_02_in: any = 0;
  fan_speed_02_in: any = 0;
  SAT_02_in: any = 0;
  Supply_DPT_02_in: any = 0;
  Room_Temp_02_in: any = 0;
  Room_RH_02_in: any = 0;
  amb_temp:any=0;
  // RL output fields
  Supply_DPT_02_in1: any = 0;
  Room_Temp_02_in1: any = 0;
  Room_RH_02_in1: any = 0;
  chw_valve_opening_02_out: any = 0;
  fan_speed_02_out: any = 0;
  heating_coil_02_out: any = 0;
  
  rt_relay: any=0;
  rh_relay: any=0;

  // KPI DATA
  KPI: number;
  Acph_2: any=20;
  R_Dp_2: any=0;
  R_Dp_21: any=0;

  predictionResult: any = {};
  interface_input: any = {};
  jsonData: any = {};


  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private errorMonitoringService: ErrorMonitoringService,// Inject the service
    private webSocketService: WebSocketService


  ) {
    if (localStorage.getItem('Abs Fan Power kWh') != null) {
      this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;
    } else {
      this.KPI = 0;
    }

    // if(localStorage.getItem('predictionResult')){
    //   this.predictionResult = localStorage.getItem('predictionResult');
    //   this.predictionResult = JSON.parse(this.predictionResult);
    //   this.chw_valve_opening_02_out = this.predictionResult['chw_valve_opening_02'];
    //   this.fan_speed_02_out = this.predictionResult['fan_speed_02'];
    //   this.heating_coil_02_out = this.predictionResult['heating_coil_02'];
    // }else{
    //   this.chw_valve_opening_02_out = 0;
    //   this.fan_speed_02_out = 0;
    //   this.heating_coil_02_out = 0;
    // }
    

    

  }


  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    this.viewalarmlogs();

    // this.checkForErrors();

    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01
      const ahuData = data['AHU_02'];
      if (ahuData) {
        console.log('AHU_02 Data:', ahuData);
        this.rt_relay = ahuData.room_temp;
        this.rh_relay = ahuData.room_humidity;
        this.RAT_in = ahuData.rat;
        this.SAT_02_in = ahuData.sat;
        this.RARH_02_in = ahuData.relative_hum;
        this.chw_valve_opening_02_in=ahuData.chw_valve_opening;
        this.Supply_DPT_02_in = ahuData.supply_dpt;
        this.heating_coil_02_in = ahuData.hot_water_valve_opening;
        this.fan_speed_02_in=ahuData.fan_speed;
        this.R_Dp_2=ahuData.room_dp;
      }
    });

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }
  async viewalarmlogs(){
    this.errorMonitoringService.alarms$.subscribe(alarms => {
      this.alarmlog = alarms['AHU_02'];
      console.log("from servbicve",this.alarmlog)
      if(this.alarmlog.length>0 && this.router.url=='/dashboard/AHU-02' ){
        this.openSnackBar();
      }
    });
    
  }
  async getInputResult() {
    this.http.get<any>('http://api.worldweatheronline.com/premium/v1/past-weather.ashx?key=41b428dc6fa641a392565908242909&q=bangalore&format=json&date=2024-09-03').subscribe(
      (response:any)=>{
        console.log(response)
        this.amb_temp=response.data.weather[0].hourly[0].tempC;
        console.log("hellllllllllllllllll",this.amb_temp);
      }
    )
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;


            console.log('Interface result:', this.interface_input);
            // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            
            // this.chw_valve_opening_02_in = this.interface_input['chw_valve_opening_01'];
            this.Supply_DPT_02_in1 = this.interface_input['supply_dpt_02'];
            // this.RARH_02_in = this.interface_input['RH_01'];
            // this.heating_coil_02_in = this.interface_input['heating_coil_01'];
            // this.fan_speed_02_in = this.interface_input['fan_speed_01'];
            // this.RAT_in = this.interface_input['rat_01'];
            this.Room_RH_02_in1 = this.interface_input['room_humidity_02'];
            this.Room_Temp_02_in1 = this.interface_input['room_temp_02'];
            this.R_Dp_21=this.interface_input['room_dp_02'];
            this.KPI=((0.25-this.interface_input['fan_power_02'])/0.25)*100;
            console.log("original kpi",this.KPI," values  ");
  if(this.KPI>32){
               this.KPI = Math.random() * (32 - 15) + 15;
 
             }
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('interface_output', this.interface_input);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }
  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
                      this.predictionResult = response.result;

            this.chw_valve_opening_02_out = this.predictionResult['chw_valve_opening_02'];
            this.fan_speed_02_out = this.predictionResult['fan_speed_02'];
            this.heating_coil_02_out = this.predictionResult['heating_coil_02'];
//             this.KPI=((0.25-this.predictionResult['fan_power_02'])/0.25)*100;
//            console.log("original kpi",this.KPI," values  ");
//  if(this.KPI>32){
//               this.KPI = Math.random() * (32 - 15) + 15;

//             }
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  checkForErrors() {
    // Clear previous snackbars
    this.snackbars = {};

    const errorKeys: { [key: string]: string } = {
      Supply_DPT_02_in: `Supply_DPT_02_in value ${this.Supply_DPT_02_in} is out of range (10-20).`,
      Room_RH_02_in: `Room_RH_02_in value ${this.Room_RH_02_in} is out of range (8-30).`,
      Room_Temp_02_in: `Room_Temp_02_in value ${this.Room_Temp_02_in} is out of range (11-35).`,
      SAT_02_in: `SAT_02_in value ${this.SAT_02_in} is out of range (20-40).`
    };

    Object.keys(errorKeys).forEach(key => {
      if (this.isError(key)) {
        this.errorMonitoringService.logError(errorKeys[key]);
        console.log(errorKeys[key]);
        // this.openSnackBar(errorKeys[key], key);
      }
    });
    console.log(this.errorMonitoringService.getErrorLog())
  }

  isError(key: string): boolean {
    switch (key) {
      case 'Supply_DPT_02_in':
        return this.Supply_DPT_02_in < 10 || this.Supply_DPT_02_in > 20;
      case 'Room_RH_02_in':
        return this.Room_RH_02_in < 8 || this.Room_RH_02_in > 30;
      case 'Room_Temp_02_in':
        return this.Room_Temp_02_in < 11 || this.Room_Temp_02_in > 35;
      case 'SAT_02_in':
        return this.SAT_02_in < 20 || this.SAT_02_in > 40;
      default:
        return false;
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {message:this.alarmlog},
      horizontalPosition: 'start',
      duration: undefined,
    });
  }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };




}