<div style="position: relative; height: 100%; width: 100%">
    <div style="
            width: 100%;
            height: 100%;
            min-width: 1100px;
            display: flex;
            flex-direction: column;
            position: absolute;">
        <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
            <!-- main body -->
            <div style="
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: start;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;
                margin-top:2%;
                padding-left: 200px;">
                <div style="
                    height: 550px;
                    width: 1100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;">
                    <!-- map -->
                    <div style="
                        height: 550px;
                        display: flex;
                        width: 1100px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        margin-top:10%;
                        margin-left:87%;">
                        <img src="/assets/strides-images/AHU7(1).svg" width="1000px" height="1000px" />
                        <img src="/assets/strides-images/AHU7.svg" width="1000px" height="1000px" style="margin-left: 21%;" />
                    </div>
                    <!-- componets -->
                    <div style="height: 350px; width: 1070px; position: absolute">
                        <!-- FAN -->


                       




               





                    </div>




                </div>





                                <!-- KPI FORM  -->
        











            </div>
        </div>
    </div>
</div>