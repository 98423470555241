import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-eleven-comp',
  templateUrl: './ahu-eleven-comp.component.html',
  styleUrls: ['./ahu-eleven-comp.component.css']
})
export class AhuElevenCompComponent {

}
