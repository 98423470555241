import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-seven-comp',
  templateUrl: './ahu-seven-comp.component.html',
  styleUrls: ['./ahu-seven-comp.component.css']
})
export class AhuSevenCompComponent {

}
