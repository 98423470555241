
<div class="scaffold">
  <div style="height: 100%; width: 100%; position: relative; font-family: Mulish">
    <div
      style="
        height: 80%;
        width: 100%;
        background: 
          url('/assets/predict_ahu/strides login1.svg');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 140px;
        "
        class="client-logo"
      >
     
   

        <div style="position: relative;">
          <img src="/assets/strides-images/strides-logo-login.svg" width="300px" style="border-radius: 5px;" />
          <div style="position: absolute; top: 67%; left: 65%; transform: translate(-50%, -50%);">
              <img src="/assets/strides-images/logo animation mulish 1.gif" width="240px" />
          </div>
      </div>
      
      <!-- <app-role-selection *ngIf="showRoleModal" (roleSelected)="onRoleSelected()"></app-role-selection> -->
      <form class="form" (ngSubmit)="handleSubmit()">
        
        <div
          style="
            width: 80%;
            display: flex;
            flex-direction: column;
            
            justify-content: center;
            align-items: center;
          "
        >
          <div  style="text-align: center; color:#AAAAAA">
            <div style=" font-size: larger; color: #32774E;font-weight: bolder; margin-bottom: 10px;"> Forgot Password</div>
            Enter email address associated with and we will send you link to reset your password
          </div>
          <mat-form-field style="width: 100%; margin-top: 11px;" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="text"
              matInput
              [formControl]="emailFormControl"
              [errorStateMatcher]="matcher"
              placeholder="Email"
              
              [(ngModel)]="email"
              
            />
      
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
      
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
      
          <button
            mat-raised-button class="btn"
            
            type="submit"
          >
            Send Reset Link
          </button>
      
          <a class="hover-text-dark" [routerLink]="['/login']">Go to Login</a>
        </div>
      </form>



      </div>
    </div>
  </div>
</div>


<!-- <form class="form" (ngSubmit)="handleSubmit()" style="height: 310px">
        
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="span-text-dark" style="text-align: center">
      Enter your registered email to reset your password
    </div>
    <mat-form-field style="width: 100%;border-radius:25px" appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="text"
        matInput
        [formControl]="emailFormControl"
        [errorStateMatcher]="matcher"
        placeholder="Email"
        style="font-size: 14px;border-radius:25px;"
        
      />

      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>

      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      [routerLink]="['/change-password']"
      style="
        width: 100%;
        background-color:#53A574;
        height: 45px;
        color: white;
        font-family: Mulish;
        border-radius:17px;
      "
      type="submit"
    >
      Send Reset Link
    </button>

    <a class="hover-text-dark" [routerLink]="['/login']">Go to Login</a>
  </div>
</form> -->