import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pid-tuning-command',
  templateUrl: './pid-tuning-command.component.html',
  styleUrls: ['./pid-tuning-command.component.css']
})
export class PidTuningCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  errorMessage: string = '';
  selectedfloor: string='';
  loopname: string='';
  selectedBlock: string = ''; // Default block
  blocks: string[]=['OLT']
  chiller_blocks: string[]=['SA_OLT']
  floors:string[]=['GF']
  chiller_floors: string[]=['CP','PUMP','CT'];
  loopnames: string[]=['RAT','DP','RAT_H'];
  selectedSection: string = 'AHU'; // Default section
  selectedEquipmentType: string = '';
  selectedEquipmentID: number | null = null;
  equipmentTypes: string[] = ['Chiller', 'PrimaryPump', 'SecondaryPump', 'CondenserPump'];
  equipmentIDOptions: number[] = [];

  selectedAHU: string = '';
  selectedchillerequip: string='';
  ahus : string[]=['AHU_01','AHU_02','AHU_03','AHU_04','AHU_05','AHU_06','AHU_07','AHU_08','AHU_10','AHU_11','AHU_12']
  set_point: number=0;
  chiller_labels: string []=[]
  min_limit: number=0;
  max_limit: number=0;
  responseMessage: string = '';
  isLoading: boolean = false;
  blockahumap:Map<string,number>= new Map<string,number>();
constructor(private http: HttpClient){}

  ngOnInit() : void{
     

    for(let i=0; i<this.blocks.length; i++){
      this.blockahumap.set(this.blocks[i],i);
    } 

    console.log(this.blockahumap);
    console.log(this.blockahumap.get("A"),"helllll");
    console.log(this.selectedBlock);
  }
  showCommand() {
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Wrong command';
      this.showDetails = false;
    }
  }
  selectSection(section: string) {
    this.selectedSection = section;
    this.resetChillerPlantFields();
  }
  resetChillerPlantFields() {
    this.selectedEquipmentType = '';
    this.selectedEquipmentID = null;
    this.equipmentIDOptions = [];
  }

  onEquipmentTypeChange() {
    switch (this.selectedEquipmentType) {
      case 'Chiller':
        this.equipmentIDOptions = Array.from({ length: 3 }, (_, i) => i + 1); // IDs 1 to 3
        break;
      case 'PrimaryPump':
        this.equipmentIDOptions = Array.from({ length: 6 }, (_, i) => i + 1); // IDs 1 to 6
        break;
      case 'SecondaryPump':
        this.equipmentIDOptions = Array.from({ length: 8 }, (_, i) => i + 1); // IDs 1 to 8
        break;
      case 'CondenserPump':
        this.equipmentIDOptions = Array.from({ length: 4 }, (_, i) => i + 1); // IDs 1 to 4
        break;
      default:
        this.equipmentIDOptions = [];
    }
    this.selectedEquipmentID = null; // Reset the selected ID when type changes
  }
  updateChillerLabels(selectedfloor: string): void {
    if (selectedfloor === 'CP') {
      this.chiller_labels = ['CH_01', 'CH_02', 'CH_03', 'CH_04'];
    } else if (selectedfloor === 'PUMP') {
      this.chiller_labels = [
        'PP_01', 'PP_02', 'PP_03', 'PP_04', 'PP_05', 'PP_06',
        'SP_01', 'SP_02', 'SP_03', 'SP_04', 'SP_05', 'SP_06', 'SP_07', 'SP_08'
      ];
    } else if (selectedfloor === 'CT') {
      this.chiller_labels = ['CP_01', 'CP_02', 'CP_03', 'CP_04','CTF_01','CTF_02'];
    }
  }

  sendCommand(){
    this.isLoading = true;
    console.log("fffffffffffff",this.selectedSection)
    if (this.selectedSection === 'AHU') {
      const data = {
        client: 'strides',
        site: 'krsg',
        action: 2,
        id1: this.selectedBlock,
        id2: this.selectedfloor,
        id3: this.selectedAHU, 
        body: {
          loop: this.loopname.toLowerCase(),
          set_point: this.set_point,
          min_limit: this.min_limit,
          max_limit: this.max_limit
        }
      };

      this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
        (response)=>{
          this.isLoading=false;
          this.responseMessage='Command sent successfully!!';
          console.log('post response',response);
          console.log(data);

        },
        (error)=>{
          this.isLoading=false;
          this.responseMessage='Failed to send command.please try again!';
          console.log('post response',error);
          console.log(data);

        }
      )
    } else if (this.selectedSection === 'ChillerPlant') {
      if(this.selectedchillerequip.substring(0,2)=='CH'){
        this.loopname='chiller';
      }
      else if(this.selectedchillerequip.substring(0,2)=='PP' ){
        this.loopname='pri_p';
      }
      else if(this.selectedchillerequip.substring(0,2)=='SP'){
        this.loopname='sec_p';
      }
      else if(this.selectedchillerequip.substring(0,2)=='CP'){
        this.loopname='cond_p';
      }
      else{
        this.loopname='ct_fan';
      }
      const data = {
        client: 'strides',
        site: 'krsg',
        action: 5,
        id1: this.selectedBlock,
        id2: this.selectedfloor,
        id3:this.selectedchillerequip,
        body: {
          loop: this.loopname,
          // equipment_type: this.selectedEquipmentType,
          // equipment_id: this.selectedEquipmentID,
          set_point: this.set_point,
          min_limit: this.min_limit,
          max_limit: this.max_limit
        }
       
        // Add other necessary fields
      };

      this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
        (response)=>{
          this.isLoading=false;
          this.responseMessage='Command sent successfully!!';
          console.log(data);
          console.log('post response',response);
        },
        (error)=>{
          this.isLoading=false;
          this.responseMessage='Failed to send command.please try again!';
          console.log(data);
          console.log('post response',error);
        }
      )
    }

   

   
  }
}
