
          

          
<div class="main-container">
    <div class="model-container">
        <img src="/assets/strides-images/strides_design.svg"   />
        <div class="button-olt">
            <button  routerLink="../olt-block">
                OLT Block
            </button>
        </div>
        
    </div>

    <div class="form">
        <span class="span-text-dark">Key Performance Indicator</span>

        <div style="height: 40px;"></div>
        <div  class="hvac-text">
        <span class="span-text-dark" style="font-size: 16px; font-weight: 500">Power Saving</span>
        <div style="width:70px">
            <input style="width:70px; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
            [ngModel]="KPI | number : '1.2-2'"
            (ngModelChange)="KPI = $event" />
        </div>
        <span style="margin-left:-35px; font-size: 16px;">%</span>
        </div>
    </div> 
</div>