import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-secondary-pumps',
  templateUrl: './secondary-pumps.component.html',
  styleUrls: ['./secondary-pumps.component.css']
})

export class SecondaryPumpsComponent {

    errorLog: { message: string, timestamp: Date }[] = [];
    snackbars: { [key: string]: MatSnackBarRef<any> } = {};
    Ch_w_temp: any=0;
    Chw_in_1: any=0;
    vfd_pump1: any=0;
    vfd_pump2: any=0;
    vfd_pump3: any=0;
    vfd_pump4: any=0;
    vfd_pump5: any=0;
    vfd_pump6: any=0;
    vfd_pump7: any=0;
    vfd_pump8: any=0;
    vfd_pump9: any=0;

    power_pump1: any=0;
    power_pump2: any=0;
    power_pump3: any=0;
    power_pump4: any=0;
    power_pump5: any=0;
    power_pump6: any=0;
    power_pump7: any=0;
    power_pump8: any=0;
    power_pump9: any=0;

    power_pump1_out: any=0;

    power_pump2_out: any=0;
  
    power_pump3_out: any=0;
    power_pump4_out: any=0;
  
    power_pump5_out: any=0;
  
    power_pump6_out: any=0;
    power_pump7_out: any=0;

    power_pump8_out: any=0;
    power_pump9_out: any=0;


    // supply_temp_pump1: any=0;
    // supply_temp_pump2: any=0;
    // supply_temp_pump3: any=0;
    // supply_temp_pump4: any=0;
    // supply_temp_pump5: any=0;
    // supply_temp_pump6: any=0;
    // supply_temp_pump7: any=0;
    // supply_temp_pump8: any=0;
    // diff_pressure1: any=0;
    // diff_pressure2: any=0;
    // diff_pressure3: any=0;
    // diff_pressure4: any=0;
    // diff_pressure7: any=0;
    // diff_pressure8: any=0;
    Chw_out_1: any=0;
    Condw_in_1: any=0;
    Condw_out_1: any=0;
    
    KPI: any=0;
    
  // vfd_for_condenser_pump_1_in: any=0;
  // vfd_for_condenser_pump_2_in: any=0;
  // vfd_for_condenser_pump_3_in: any=0;
  // vfd_for_condenser_pump_4_in: any=0;

  secondary_pump_2_vfd_feedback_out: any=0;
  secondary_pump_1_vfd_feedback_out: any=0;
  secondary_pump_3_vfd_feedback_out: any=0;
  secondary_pump_4_vfd_feedback_out: any=0;
  chilled_water_set_point_out: any=0;
  VFD_for_secondary_pump_5_out: any=0;
  VFD_for_secondary_pump_6_out: any=0;
  secondary_pump_7_vfd_feedback_out: any=0;
  secondary_pump_8_vfd_feedback_out: any=0;
  VFD_for_secondary_pump_9_out: any=0;

  Chilled_Water_Set_Point: any=0;

  predictionResult: any = {};
  interface_input: any = {};
  jsonData: any = {};

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private errorMonitoringService: ErrorMonitoringService,
    private router:Router,
    private webSocketService:WebSocketService// Inject the service
  ){
    if(localStorage.getItem('interface_input')){
      this.interface_input = localStorage.getItem('interface_input');
      this.interface_input = JSON.parse(this.interface_input);
      // this.supply_temp_pump1=this.interface_input['supply_temp_after_sec_pump_1'];
      // this.supply_temp_pump2=this.interface_input['supply_temp_after_sec_pump_2'];
      // this.supply_temp_pump3=this.interface_input['supply_temp_after_sec_pump_3'];
      // this.supply_temp_pump4=this.interface_input['supply_temp_after_sec_pump_4'];
      // this.supply_temp_pump7=this.interface_input['supply_temp_after_sec_pump_7'];
      // this.supply_temp_pump8=this.interface_input['supply_temp_after_sec_pump_8'];
      // this.diff_pressure1=this.interface_input['diff_pressure_sec_pump_1'];
      // this.diff_pressure2=this.interface_input['diff_pressure_sec_pump_2'];
      // this.diff_pressure3=this.interface_input['diff_pressure_sec_pump_3'];
      // this.diff_pressure4=this.interface_input['diff_pressure_sec_pump_4'];
      // this.diff_pressure7=this.interface_input['diff_pressure_sec_pump_7'];
      // this.diff_pressure8=this.interface_input['diff_pressure_sec_pump_8'];
      // this.power_pump1=this.interface_input['sec_pump_power_1'];
      // this.power_pump2=this.interface_input['sec_pump_power_2'];
      // this.power_pump3=this.interface_input['sec_pump_power_3'];
      // this.power_pump4=this.interface_input['sec_pump_power_4'];
      // this.power_pump5=this.interface_input['average_total_power_for_secondary_pump_5'];
      // this.power_pump6=this.interface_input['average_total_power_for_secondary_pump_6'];
      // this.power_pump7=this.interface_input['sec_pump_power_7'];
      // this.power_pump8=this.interface_input['sec_pump_power_8'];
      // this.vfd_pump1=this.interface_input['secondary_pump_1_vfd_feedback'];
      // this.vfd_pump2=this.interface_input['secondary_pump_2_vfd_feedback'];
      // this.vfd_pump3=this.interface_input['secondary_pump_3_vfd_feedback'];
      // this.vfd_pump4=this.interface_input['secondary_pump_4_vfd_feedback'];
      // this.vfd_pump5=this.interface_input['vfd_for_secondary_pump_5'];
      // this.vfd_pump6=this.interface_input['vfd_for_secondary_pump_6'];
      // this.vfd_pump7=this.interface_input['secondary_pump_7_vfd_feedback'];
      // this.vfd_pump8=this.interface_input['secondary_pump_8_vfd_feedback'];

    }else{
      // this.supply_temp_pump1=0;
      // this.supply_temp_pump2=0;
      // this.supply_temp_pump3=0;
      // this.supply_temp_pump4=0;
      // this.supply_temp_pump7=0;
      // this.supply_temp_pump8=0;
      // this.diff_pressure1=0;
      // this.diff_pressure2=0;
      // this.diff_pressure3=0;
      // this.diff_pressure4=0;
      // this.diff_pressure7=0;
      // this.diff_pressure8=0;
      this.power_pump1=0;
      this.power_pump2=0;
      this.power_pump3=0;
      this.power_pump4=0;
      this.power_pump5=0;
      this.power_pump6=0;
      this.power_pump7=0;
      this.power_pump8=0;
      this.vfd_pump1=0;
      this.vfd_pump2=0;
      this.vfd_pump3=0;
      this.vfd_pump4=0;
      this.vfd_pump5=0;
      this.vfd_pump6=0;
      this.vfd_pump7=0;
      this.vfd_pump8=0;
    }
    if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      this.secondary_pump_1_vfd_feedback_out=this.predictionResult['secondary_pump_1_vfd_feedback'];
      this.secondary_pump_2_vfd_feedback_out=this.predictionResult['secondary_pump_2_vfd_feedback'];
      this.secondary_pump_3_vfd_feedback_out=this.predictionResult['secondary_pump_3_vfd_feedback'];
      this.secondary_pump_4_vfd_feedback_out=this.predictionResult['secondary_pump_4_vfd_feedback'];
      this.VFD_for_secondary_pump_5_out=this.predictionResult['vfd_for_secondary_pump_5'];
      this.VFD_for_secondary_pump_6_out=this.predictionResult['vfd_for_secondary_pump_6'];
      this.secondary_pump_7_vfd_feedback_out=this.predictionResult['secondary_pump_7_vfd_feedback'];
      this.secondary_pump_8_vfd_feedback_out=this.predictionResult['secondary_pump_8_vfd_feedback'];
      this.power_pump1_out=this.predictionResult['sec_pump_power_1'];
      this.power_pump2_out=this.predictionResult['sec_pump_power_2'];
      this.power_pump3_out=this.predictionResult['sec_pump_power_3'];
      this.power_pump4_out=this.predictionResult['sec_pump_power_4'];
      this.power_pump5_out=this.predictionResult['average_total_power_for_secondary_pump_5'];
      this.power_pump6_out=this.predictionResult['average_total_power_for_secondary_pump_6'];
      this.power_pump7_out=this.predictionResult['sec_pump_power_7'];
      this.power_pump8_out=this.predictionResult['sec_pump_power_8'];
    }else{
      this.secondary_pump_1_vfd_feedback_out=0;
      this.secondary_pump_2_vfd_feedback_out=0;
      this.secondary_pump_3_vfd_feedback_out=0;
      this.secondary_pump_4_vfd_feedback_out=0;
      this.VFD_for_secondary_pump_5_out=0;
      this.VFD_for_secondary_pump_6_out=0;
      this.power_pump1_out=0;
      this.power_pump2_out=0;
      this.power_pump3_out=0;
      this.power_pump4_out=0;
      this.power_pump5_out=0;
      this.power_pump6_out=0;
      this.power_pump7_out=0;
      this.power_pump8_out=0;
    }


  }



  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    this.getInputResult();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01
      const ahuData = data['CH_0'];
      if (ahuData) {
        console.log('chiller 3 Data:', ahuData);
        this.power_pump1 = ahuData.sec_pump_power_1;
            this.power_pump2= ahuData.sec_pump_power_2;
            this.power_pump3 = ahuData.sec_pump_power_3;
            this.power_pump4 = ahuData.sec_pump_power_4;
            this.power_pump7 = ahuData.sec_pump_power_7;
            this.power_pump8 = ahuData.sec_pump_power_8;
            this.vfd_pump1 =  ahuData.secondary_pump_1_vfd_feedback;
            this.vfd_pump2 =  ahuData.secondary_pump_2_vfd_feedback;
            this.vfd_pump3 =  ahuData.secondary_pump_3_vfd_feedback;
            this.vfd_pump4 =  ahuData.secondary_pump_4_vfd_feedback;
            this.vfd_pump7 =  ahuData.secondary_pump_7_vfd_feedback;
            this.vfd_pump8 = ahuData.secondary_pump_8_vfd_feedback;
      }
    });

    // this.checkForErrors();

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }
  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;


            console.log('Interface result:', this.interface_input);
            
            // this.supply_temp_pump1=this.interface_input['supply_temp_after_sec_pump_1'];
            // this.supply_temp_pump2=this.interface_input['supply_temp_after_sec_pump_2'];
            // this.supply_temp_pump3=this.interface_input['supply_temp_after_sec_pump_3'];
            // this.supply_temp_pump4=this.interface_input['supply_temp_after_sec_pump_4'];
            // this.supply_temp_pump7=this.interface_input['supply_temp_after_sec_pump_7'];
            // this.supply_temp_pump8=this.interface_input['supply_temp_after_sec_pump_8'];
            // this.diff_pressure1=this.interface_input['diff_pressure_sec_pump_1'];
            // this.diff_pressure2=this.interface_input['diff_pressure_sec_pump_2'];
            // this.diff_pressure3=this.interface_input['diff_pressure_sec_pump_3'];
            // this.diff_pressure4=this.interface_input['diff_pressure_sec_pump_4'];
            // this.diff_pressure7=this.interface_input['diff_pressure_sec_pump_7'];
            // this.diff_pressure8=this.interface_input['diff_pressure_sec_pump_8'];
            // this.power_pump1=this.interface_input['sec_pump_power_1'];
            // this.power_pump2=this.interface_input['sec_pump_power_2'];
            // this.power_pump3=this.interface_input['sec_pump_power_3'];
            // this.power_pump4=this.interface_input['sec_pump_power_4'];
            // this.power_pump5=this.interface_input['average_total_power_for_secondary_pump_5'];
            // this.power_pump6=this.interface_input['average_total_power_for_secondary_pump_6'];
            // this.power_pump7=this.interface_input['sec_pump_power_7'];
            // this.power_pump8=this.interface_input['sec_pump_power_8'];
            // this.vfd_pump1=this.interface_input['secondary_pump_1_vfd_feedback'];
            // this.vfd_pump2=this.interface_input['secondary_pump_2_vfd_feedback'];
            // this.vfd_pump3=this.interface_input['secondary_pump_3_vfd_feedback'];
            // this.vfd_pump4=this.interface_input['secondary_pump_4_vfd_feedback'];
            // this.vfd_pump5=this.interface_input['vfd_for_secondary_pump_5'];
            // this.vfd_pump6=this.interface_input['vfd_for_secondary_pump_6'];
            // this.vfd_pump7=this.interface_input['secondary_pump_7_vfd_feedback'];
            // this.vfd_pump8=this.interface_input['secondary_pump_8_vfd_feedback'];
            // this.Ch_w_temp=this.interface_input['chilled_water_set_point_wc']
            this.power_pump1_out=this.interface_input['sec_pump_power_1'];
            this.power_pump2_out=this.interface_input['sec_pump_power_2'];
            this.power_pump3_out=this.interface_input['sec_pump_power_3'];
            this.power_pump4_out=this.interface_input['sec_pump_power_4'];
            this.power_pump5_out=this.interface_input['average_total_power_for_secondary_pump_5'];
            this.power_pump6_out=this.interface_input['average_total_power_for_secondary_pump_6'];
            this.power_pump7_out=this.interface_input['sec_pump_power_7'];
            this.power_pump8_out=this.interface_input['sec_pump_power_8'];
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('interface_output', this.interface_input);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }
  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
                      this.predictionResult = response.result;

            this.secondary_pump_1_vfd_feedback_out=this.predictionResult['secondary_pump_1_vfd_feedback'];
            this.secondary_pump_2_vfd_feedback_out=this.predictionResult['secondary_pump_2_vfd_feedback'];
            this.secondary_pump_3_vfd_feedback_out=this.predictionResult['secondary_pump_3_vfd_feedback'];
            this.secondary_pump_4_vfd_feedback_out=this.predictionResult['secondary_pump_4_vfd_feedback'];
            this.VFD_for_secondary_pump_5_out=this.predictionResult['vfd_for_secondary_pump_5'];
            this.VFD_for_secondary_pump_6_out=this.predictionResult['vfd_for_secondary_pump_6'];
            this.secondary_pump_7_vfd_feedback_out=this.predictionResult['secondary_pump_7_vfd_feedback'];
            this.secondary_pump_8_vfd_feedback_out=this.predictionResult['secondary_pump_8_vfd_feedback'];

            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  checkForErrors() {
    // Clear previous snackbars
    this.snackbars = {};

    const errorKeys: { [key: string]: string } = {
      Chw_in_1: `Chw_in_1 value ${this.Chw_in_1} is out of range (10-20).`,
      Chw_out_1: `Chw_out_1 value ${this.Chw_out_1} is out of range (8-30).`,
      Condw_in_1: `Condw_in_1 value ${this.Condw_in_1} is out of range (11-35).`,
      Condw_out_1: `Condw_out_1 value ${this.Condw_out_1} is out of range (20-40).`
    };

    Object.keys(errorKeys).forEach(key => {
      if (this.isError(key)) {
        this.errorMonitoringService.logError(errorKeys[key]);
        console.log(errorKeys[key]);
        this.openSnackBar(errorKeys[key], key);
      }
    });
    console.log(this.errorMonitoringService.getErrorLog())


  }

  isError(key: string): boolean {
    switch (key) {
      case 'Chw_in_1':
        return this.Chw_in_1 < 10 || this.Chw_in_1 > 20;
      case 'Chw_out_1':
        return this.Chw_out_1 < 8 || this.Chw_out_1 > 30;
      case 'Condw_in_1':
        return this.Condw_in_1 < 11 || this.Condw_in_1 > 35;
      case 'Condw_out_1':
        return this.Condw_out_1 < 20 || this.Condw_out_1 > 40;
      default:
        return false;
    }
  }

  openSnackBar(message: string, key: string) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message, key },
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: undefined,
    });
  }




}
