<div class="ahu-one">
    
    <svg id="ahu-one" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 57 97.607">
      <g id="Component_191_1" data-name="Component 191 – 1" transform="translate(0 1.506)">
        <path id="Path_11431" data-name="Path 11431" d="M12855.63-20969.152c2.823.023,44.894,0,44.894,0a3.238,3.238,0,0,1,3.563,3.488c-.024,3.777.034,81.084.034,81.084s-.1,3.7-3.277,3.662-44.9-.113-44.9-.113a3.048,3.048,0,0,1-3.29-3.01c-.111-3.182-.063-83.309-.063-83.309S12852.807-20969.172,12855.63-20969.152Z" transform="translate(-12851 20969.158)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
        <text id="AHU1" transform="translate(0 36.432)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="8.048" y="14">AHU1</tspan></text>
        <path id="Path_11432" data-name="Path 11432" d="M-2385.927-21038.947v-9.447h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-9.232" transform="translate(-21034.213 2481.529) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        <text id="Liquid_dispensing_" data-name="Liquid 
    dispensing 
    " transform="translate(2 3.432)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="10.725" y="10">Liquid </tspan><tspan x="1" y="23">dispensing </tspan><tspan x="0" y="36"></tspan></text>
      </g>
    </svg>
    
  </div>
  
  
  
  
  
  
  
  
  
  
  
  