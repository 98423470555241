import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MainSideBarComponent } from './components/main-side-bar/main-side-bar.component';
import { CardComponent } from './components/card/card.component';
import { AhuComponent } from './components/ahu/ahu.component';
import { BertComponentsModule } from '../../libs/bert-components/src/index';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; // Import the spinner module
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';


// angular material
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  
  MatDialogModule,
} from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { WaterMarkComponent } from './components/water-mark/water-mark.component';

import { AhuTemplateComponent } from './ahu-template/ahu-template.component';

import { ZoneFiveComponent } from './pages/zone-wise-pages/zone-five/zone-five.component';
import { ZoneSixComponent } from './pages/zone-wise-pages/zone-six/zone-six.component';
import { ZoneSevenComponent } from './pages/zone-wise-pages/zone-seven/zone-seven.component';
import { ZoneNineComponent } from './pages/zone-wise-pages/zone-nine/zone-nine.component';
import { MallTwoComponent } from './pages/zone-wise-pages/mall-two/mall-two.component';
import { MallOneComponent } from './pages/zone-wise-pages/mall-one/mall-one.component';
import { MainLobbyComponent } from './pages/zone-wise-pages/main-lobby/main-lobby.component';
import { MainEntranceComponent } from './pages/zone-wise-pages/main-entrance/main-entrance.component';
import { HyperLobbyComponent } from './pages/zone-wise-pages/hyper-lobby/hyper-lobby.component';
import { ZoneElevenComponent } from './pages/zone-wise-pages/zone-eleven/zone-eleven.component';
import { HeatExchanger1Component } from './pages/heat-exchanger-pumps/heat-exchanger1/heat-exchanger1.component';
import { HeatExchanger2Component } from './pages/heat-exchanger-pumps/heat-exchanger2/heat-exchanger2.component';
import { HeatExchanger3Component } from './pages/heat-exchanger-pumps/heat-exchanger3/heat-exchanger3.component';
import { AnimatedHomeScreenComponent } from './components/animated-home-screen/animated-home-screen.component';
import { ZoneOneScreenComponent } from './pages/zone-wise-pages/zone-one-screen/zone-one-screen.component';
import { ZoneTwoScreenComponent } from './pages/zone-wise-pages/zone-two-screen/zone-two-screen.component';
import { ZoneThreeScreenComponent } from './pages/zone-wise-pages/zone-three-screen/zone-three-screen.component';
import { ZoneFourScreenComponent } from './pages/zone-wise-pages/zone-four-screen/zone-four-screen.component';

import { FahuOneComponent } from './pages/fahu-one/fahu-one.component';
import { FahuTwoComponent } from './pages/fahu-two/fahu-two.component';
import { SummaryPageComponent } from './pages/summary-page/summary-page.component';
import { AhuOneComponent } from './pages/ahu-one/ahu-one.component';
import { AhuTwoComponent } from './pages/ahu-two/ahu-two.component';
import { AhuThreeComponent } from './pages/ahu-three/ahu-three.component';
import { AhuFourComponent } from './pages/ahu-four/ahu-four.component';
import { AhuFiveComponent } from './pages/ahu-five/ahu-five.component';
import { AhuSixComponent } from './pages/ahu-six/ahu-six.component';
import { AhuSevenComponent } from './pages/ahu-seven/ahu-seven.component';
import { AhuEightComponent } from './pages/ahu-eight/ahu-eight.component';
import { AhuNineComponent } from './pages/ahu-nine/ahu-nine.component';
import { AhuTenComponent } from './pages/ahu-ten/ahu-ten.component';
import { AhuElevenComponent } from './pages/ahu-eleven/ahu-eleven.component';
import { AhuSevenScreenTwoComponent } from './pages/ahu-seven-screen-two/ahu-seven-screen-two.component';
import { AhuTwelveComponent } from './pages/ahu-twelve/ahu-twelve.component';
import { AhuElevenCompComponent } from './components/home-components-strides/ahu-eleven-comp/ahu-eleven-comp.component';
import { AhuOneCompComponent } from './components/home-components-strides/ahu-one-comp/ahu-one-comp.component';
import { AhuTwoCompComponent } from './components/home-components-strides/ahu-two-comp/ahu-two-comp.component';
import { AhuThreeCompComponent } from './components/home-components-strides/ahu-three-comp/ahu-three-comp.component';
import { AhuFourCompComponent } from './components/home-components-strides/ahu-four-comp/ahu-four-comp.component';
import { AhuFiveCompComponent } from './components/home-components-strides/ahu-five-comp/ahu-five-comp.component';
import { AhuSixCompComponent } from './components/home-components-strides/ahu-six-comp/ahu-six-comp.component';
import { AhuSevenCompComponent } from './components/home-components-strides/ahu-seven-comp/ahu-seven-comp.component';
import { AhuEightCompComponent } from './components/home-components-strides/ahu-eight-comp/ahu-eight-comp.component';
import { AhuTenCompComponent } from './components/home-components-strides/ahu-ten-comp/ahu-ten-comp.component';
import { AhuTwelveCompComponent } from './components/home-components-strides/ahu-twelve-comp/ahu-twelve-comp.component';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import { ChillerStridesCompComponent } from './components/home-components-strides/chiller-strides-comp/chiller-strides-comp.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { OltBlockComponent } from './pages/olt-block/olt-block.component';
import { Chiller1Component } from './pages/chiller-plant/chiller1/chiller1.component';
import { Chiller2Component } from './pages/chiller-plant/chiller2/chiller2.component';
import { Chiller3Component } from './pages/chiller-plant/chiller3/chiller3.component';
import { SavingsFormForAhusComponent } from './pages/savings-form-for-ahus/savings-form-for-ahus.component';
import { ChillerRoomComponent } from './pages/chiller-plant/chiller-room/chiller-room.component';
import { Chiller4Component } from './pages/chiller-plant/chiller4/chiller4.component';
import { CoolingTower1Component } from './pages/chiller-plant/cooling-tower1/cooling-tower1.component';
import { CoolingTower2Component } from './pages/chiller-plant/cooling-tower2/cooling-tower2.component';
import { CoolingTowersComponent } from './pages/chiller-plant/cooling-towers/cooling-towers.component';
import { CondenserPumpsComponent } from './pages/chiller-plant/condenser-pumps/condenser-pumps.component';
import { PrimaryPumpsComponent } from './pages/chiller-plant/primary-pumps/primary-pumps.component';
import { SecondaryPumpsComponent } from './pages/chiller-plant/secondary-pumps/secondary-pumps.component';
import { ViewAllErrorsComponent } from './pages/view-all-errors/view-all-errors.component';
import { CustomSnackbarComponent } from './pages/custom-snackbar/custom-snackbar.component';
import { StridesAnalyticsComponent } from './pages/strides-analytics/strides-analytics.component';
import { CommandsComponent } from './commands/commands.component';
import { AhuCommandComponent } from './commands/ahu-command/ahu-command.component';
import { PidTuningCommandComponent } from './commands/pid-tuning-command/pid-tuning-command.component';
import { ChillerplantCommandComponent } from './commands/chillerplant-command/chillerplant-command.component';
import { AhuscheduleCommandComponent } from './commands/ahuschedule-command/ahuschedule-command.component';
import { RoleSelectionComponent } from './role-selection/role-selection.component';
import { AuthInterceptor } from './auth.interceptor';
import{MatMenuModule} from '@angular/material/menu';
import { ProfileComponent } from './components/users/profile/profile.component';
import { ViewusersComponent } from './components/users/viewusers/viewusers.component';
import { AdduserComponent } from './components/users/adduser/adduser.component'
import { TableDataComponent } from './table-data/table-data.component';

import { MatDatepickerModule } from '@angular/material/datepicker';  // Import Datepicker module
import { MatNativeDateModule } from '@angular/material/core';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AdminLogComponent } from './pages/admin-log/admin-log.component';
import { AlarmLogComponent } from './pages/alarm-log/alarm-log.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CommentDialogComponent } from './pages/view-all-errors/comment-dialog/comment-dialog.component';
import { PasswordchangepopupComponent } from './pages/passwordchangepopup/passwordchangepopup.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,    
    ForgotPasswordComponent,

    DashboardComponent,
    HomeComponent,
    MainSideBarComponent,
    CardComponent,
    AhuComponent,



    AhuTemplateComponent,
    
    ZoneFiveComponent,
    ZoneSixComponent,
    ZoneSevenComponent,
    ZoneNineComponent,
    MallTwoComponent,
    MallOneComponent,
    MainLobbyComponent,
    MainEntranceComponent,
    HyperLobbyComponent,
    ZoneElevenComponent,
    HeatExchanger1Component,
    HeatExchanger2Component,
    HeatExchanger3Component,
    AnimatedHomeScreenComponent,
    ZoneOneScreenComponent,
    ZoneTwoScreenComponent,
    ZoneThreeScreenComponent,
    ZoneFourScreenComponent,
    FahuOneComponent,
    FahuTwoComponent,
    SummaryPageComponent,
    AhuOneComponent,
    AhuTwoComponent,
    AhuThreeComponent,
    AhuFourComponent,
    AhuFiveComponent,
    AhuSixComponent,
    AhuSevenComponent,
    AhuEightComponent,
    AhuNineComponent,
    AhuTenComponent,
    AhuElevenComponent,
    AhuSevenScreenTwoComponent,
    AhuTwelveComponent,
    AhuElevenCompComponent,
    AhuOneCompComponent,
    AhuTwoCompComponent,
    AhuThreeCompComponent,
    AhuFourCompComponent,
    AhuFiveCompComponent,
    AhuSixCompComponent,
    AhuSevenCompComponent,
    AhuEightCompComponent,
    AhuTenCompComponent,
    AhuTwelveCompComponent,
    ChillerPlantComponent,
    ChillerStridesCompComponent,
    OltBlockComponent,
    Chiller1Component,
    Chiller2Component,
    Chiller3Component,
    Chiller4Component,
    SavingsFormForAhusComponent,
    ChillerRoomComponent,
    CoolingTower1Component,
    CoolingTower2Component,
    CoolingTowersComponent,
    CondenserPumpsComponent,
    PrimaryPumpsComponent,
    SecondaryPumpsComponent,
    ViewAllErrorsComponent,
    CustomSnackbarComponent,
    CommandsComponent,
    AhuCommandComponent,
    PidTuningCommandComponent,
    ChillerplantCommandComponent,
    AhuscheduleCommandComponent,
    RoleSelectionComponent,
    ProfileComponent,
    ViewusersComponent,
    AdduserComponent,
    TableDataComponent,
    ChangepasswordComponent,
    ManageUsersComponent,
    AdminLogComponent,
    
    AlarmLogComponent,
         CommentDialogComponent,
         PasswordchangepopupComponent,
    // StridesAnalyticsComponent,

  ],
  imports: [
    
    BrowserModule,
    LoadingSpinnerComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatSidenavModule,
    MatDatepickerModule ,
    MatNativeDateModule,
    MatListModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatInputModule,
    ReactiveFormsModule,
    NavBarComponent,
    LogoutDialogComponent,
    BertComponentsModule,
    HttpClientModule,
    MatProgressBarModule,
    NgChartsModule,
    MatTooltipModule,
    StridesAnalyticsComponent,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatBadgeModule
    

  ],
  exports: [
  ],

  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, direction: 'ltr' },
    },
    AuthService,AuthGuard,AdminGuard,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AuthInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
