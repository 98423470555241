<div class="ahu-ten">
   
   
    <svg id="ahu-ten" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 160.37 96.014">
      <g id="Component_186_1" data-name="Component 186 – 1" transform="translate(1.503 1.5)">
        <path id="Path_11418" data-name="Path 11418" d="M12439.38-21105.1c0,4.387-.194,78.758-.194,78.758s-.238,5.119,6.384,4.926,147.331-.437,147.331-.437a4.239,4.239,0,0,0,3.651-4.107c.048-3.609-.151-80.211-.151-80.211a3.844,3.844,0,0,0-4.134-3.887l-150.363.184S12439.377-21109.49,12439.38-21105.1Z" transform="translate(-12439.185 21110.063)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
        <text id="AHU10" transform="translate(4.688 33.787)" fill="#377c54" font-size="16" font-family="Mulish" font-weight="700"><tspan x="7.712" y="16">CHILLER PLANT</tspan></text>
      </g>
    </svg>
   
</div>
 