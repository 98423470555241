<div class="main-container">
    <h2>Alarms Log</h2>
<div
style="
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
"
>
<app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

<div style="display: flex; align-items: center">
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >From Date :</label
  >
  <input
    matInput
    [matDatepicker]="fromDatePicker"
    [(ngModel)]="fromDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid #32774E;
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="fromDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #fromDatePicker></mat-datepicker>
</div>
<div>
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="fromTime"
    style="background-color: white; border-color: #32774E"
  >
    <option *ngFor="let time of times" [value]="time.value">
      {{ time.name }}
    </option>
  </select>
</div>

<div style="display: flex; align-items: center">
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >To Date :</label
  >
  <input
    matInput
    [matDatepicker]="toDatePicker"
    [(ngModel)]="toDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid #32774E;
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="toDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #toDatePicker></mat-datepicker>
</div>

<div>
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="toTime"
    style="background-color: white; border-color: #32774E"
  >
    <option
      *ngFor="let time of times"
      [value]="time.value"
      style="max-height: 1000px; overflow-y: auto"
    >
      {{ time.name }}
    </option>
  </select>
</div>

</div>

<div class="button-group">
    <button class="save-btn" (click) =SendData()>Show Alarms</button>
    <button *ngIf="isresponse()"
    (click)="downloadPDF()"
    style="margin-left: 20px;"
    >
    Download
    </button>
  </div>

 


  
  
  

</div>



<div class="form-container">
    <div class="alarms-container">
      <!-- Iterate through each equipment -->
      <div class="alarms-container" *ngFor="let equipment of response1 | keyvalue">
        <h3>{{ equipment.key }}</h3> <!-- Display equipment name -->
        
        <!-- Iterate through alarms for each equipment -->
        <div *ngFor="let alarm of equipment.value" [ngClass]="{'alarm-card': true, 'active': !isalarmOff(alarm), 'inactive': isalarmOff(alarm)}">
          <div class="alarm-content">
            <div class="alarm-title">{{ alarm.title }}</div>
            <div class="alarm-time">
              <span style="font-weight: bold; color: #FF741D;">Start Time: </span>{{ alarm.time }}
            </div>
                        <div class="alarm-description">{{ alarm.description }}</div>
            <div *ngIf="isalarmOff(alarm)">
              <div class="alarm-time">
                <span style="font-weight: bold; color: #FF741D;">Comment: </span>{{ alarm.Comment }}
              </div>
              <div class="alarm-time">
                <span style="font-weight: bold; color: #FF741D;">Closing Time: </span>{{ alarm.Alarm_Closing_Time_from_frontend }}
              </div>
              
               </div>
          </div>
        </div>
      </div>
    </div>
  </div>