<div style="position: relative; height: 100%; width: 100%">
    <div style="
            width: 100%;
            height: 100%;
            min-width: 1200px;
            display: flex;
            flex-direction: column;
            position: absolute;">
        <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
            <!-- main body -->
            <div style="
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: start;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;
                margin-top:2%;
                padding-left: 150px;">
                <div style="
                    height: 620px;
                    width: 1200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;">
                    <!-- map -->
                    <div style="
                        height: 620px;
                        display: flex;
                        width: 1200px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        
                        margin-left:-15%;">
                        <img src="/assets/manar-zones/zone-nine-map-details.svg" width="1200px" height="620px" />
                    </div>
                    <!-- componets -->
                    <div style="height: 350px; width: 1000px; position: absolute">
                        <!-- FAN -->


                        <!-- 1. RAT -->




                        <div
                            style=" display: flex; flex-direction: row;  align-items: center; gap:10px; margin-top:10px; margin-left:120px;  ">
                            <div class="input-group" style="width: 90px;">
                                <img src="/assets/images/temperature-celcius.png" />
                                <input [ngModel]="RAT_out_AHU_11 | number : '1.1-1'"
                                (ngModelChange)="RAT_out_AHU_11 = $event" />
                            </div>
                            <div class="ahu-one-hover" routerLink="/dashboard/AHU-11">
                                <span style="font-size: 15px; color:#0B3876; ">EXP-AHU-RF-09</span>
                            </div>
                        </div>

                        <div
                            style=" display: flex; flex-direction: row;  align-items: center; gap:10px; margin-top:30px; margin-left:90px;  ">
                            <div class="input-group" style="width: 90px">
                                <img src="/assets/images/temperature-celcius.png" />
                                <input [ngModel]="RAT_out_AHU_23 | number : '1.1-1'"
                                (ngModelChange)="RAT_out_AHU_23 = $event" />
                            </div>
                            <div class="ahu-one-hover" routerLink="/dashboard/AHU-23">
                                <span style="font-size: 15px; color:#0B3876;">EXP-AHU-RF-03</span>
                            </div>
                        </div>


                        <div
                            style=" display: flex; flex-direction: row;  align-items: center; gap:10px; margin-top:30px; margin-left:64px;  ">
                            <div class="input-group" style="width: 90px">
                                <img src="/assets/images/temperature-celcius.png" />
                                <input [ngModel]="RAT_out_AHU_24 | number : '1.1-1'"
                                (ngModelChange)="RAT_out_AHU_24 = $event" />
                            </div>
                            <div class="ahu-one-hover" routerLink="/dashboard/AHU-24">
                                <span style="font-size: 15px; color:#0B3876;">EXP-AHU-RF-23</span>
                            </div>
                        </div>

                       






                    </div>




                </div>



                                <!-- KPI FORM  -->
        
      

      <div class="form" style="height: 200px; width: 380px; background:#F0F6FF; margin-top:20px; z-index:1; margin-right:50px; gap:10px;">
        <span
          class="span-text-dark"
          style="font-weight: bold; font-size: 20px; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div style="height: 30px;   "></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            width: 350px;
            justify-content: space-between;
            align-items: center;
            padding:15px;
            z-index:1; background:white;
            border-radius: 10px;
            gap:20px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 16px; font-weight: 500"
            >ZONE 9 Temperature</span
          >

          <div style="width:70px">
            
            <input style="width:70px; font-size: 20px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
            [ngModel]="Zone_temp | number : '1.1-1'"
            (ngModelChange)="Zone_temp = $event"
            />
            
          </div>
          <span style="margin-left:-55px; font-size: 20px;">ºC</span>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            width: 350px;
            justify-content: space-between;
            align-items: center;
            padding:15px;
            z-index:1; background:white;
            border-radius: 10px;
            gap:20px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 16px; font-weight: 500"
            >ZONE 9 Energy Saving</span
          >

          <div style="width:70px">
            
            <input style="width:70px; font-size: 20px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
            [ngModel]="KPI | number : '1.1-1'"
            (ngModelChange)="KPI = $event"
            />
            
          </div>
          <span style="margin-left:-35px; font-size: 20px;">%</span>
        </div>

      </div> 






            </div>
        </div>
    </div>
</div>