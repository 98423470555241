import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-olt-block',
  templateUrl: './olt-block.component.html',
  styleUrls: ['./olt-block.component.css']
})
export class OltBlockComponent {
  KPI: number = 0;
  constructor(
    private router: Router,
  ) {}
}
