<div class="ahu-four">
    
    <svg id="ahu-four" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 158.918 99.689">
      <g id="Component_188_1" data-name="Component 188 – 1" transform="translate(1.503 1.5)">
        <path id="Path_11425" data-name="Path 11425" d="M12439.379-21105.1c0,4.387-.192,78.758-.192,78.758s-.236,5.119,6.324,4.926,145.972-.437,145.972-.437a4.219,4.219,0,0,0,3.618-4.107c.046-3.609-.149-80.211-.149-80.211a3.832,3.832,0,0,0-4.1-3.887l-148.976.184S12439.376-21109.49,12439.379-21105.1Z" transform="translate(-12439.186 21110.063)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
        <text id="AHU4" transform="translate(40.288 38.52)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="20.048" y="14">AHU4</tspan></text>
        <path id="Path_11426" data-name="Path 11426" d="M-2385.927-21037v-11.393h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-12.373" transform="translate(-20984.41 2483.617) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        <text id="Liquid_filing_" data-name="Liquid filing
    " transform="translate(29.288 11.52)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="25.895" y="10">Liquid filing</tspan><tspan x="0" y="23"></tspan></text>
      </g>
    </svg>
    
  </div>
  
  
  
  
  
  
  
  
  
  
  
  