<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                <img src="/assets/strides-images/COOLING TOWERS.svg" class="fmap">
                
                <div class="component" style="width: 10%; height: 18%; top: 56%;left: 36%;  font-size: 120%;">
                    <button class="hbtn" routerLink="../cooling-tower1">
                      COOLING TOWER-1
                    </button>
                  </div>
                  <div class="component" style="width: 10%; height: 18%; top: 56%;left: 62%;  font-size: 120%;">
                    <button class="hbtn" routerLink="../cooling-tower2">
                      COOLING TOWER-2
                    </button>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 85%; left: 24.5%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #00A578; font-weight:200" >CT1 FAN SPEED</span>
                  </div>
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 12%; left: 36%; width: 3.5%; height: 3%;">
                    <input style="color:#00A578; font-weight:600" [ngModel]=" CT1_FAN_SPEED_GR | number: '1.2-2'" (ngModelChange)=" CT1_FAN_SPEED_GR = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 88%; left: 24.5%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:200" >CT1 FAN SPEED</span>
                  </div>
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 9%; left: 36%; width: 3.5%; height: 3%;">
                    <input style="color: #D90606; font-weight:600" [ngModel]=" CT1_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT1_FAN_SPEED_RED = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 85%; left: 51.2%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #00A578; font-weight:200" >CT2 FAN SPEED</span>
                  </div>
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 12%; left: 62.5%; width: 3.5%; height: 3%;">
                      <input style="color:#00A578; font-weight:600" [ngModel]=" CT2_FAN_SPEED_GR | number: '1.2-2'" (ngModelChange)=" CT2_FAN_SPEED_GR = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 88%; left: 51.2%; width: 13.5%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >CT2 FAN SPEED</span>
                    </div>
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 9%; left: 62.5%; width: 3.5%; height: 3%;">
                      <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT2_FAN_SPEED_RED = $event" />
                      <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                    </div>
                    
                    <!-- CT1 Water out -->

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom:12%; left: 24.8%; width: 13.5%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >CT1 Water out</span>
                    </div>
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 85%; left: 35.5%; width: 3.5%; height: 3%;">
                      <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT1_Water_out = $event" />
                      <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                    </div>

                    <!-- CT2 Water out -->
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom:12.5%; left: 64.8%; width: 13.5%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >CT2 Water out</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 84.3%; left: 75.5%; width: 3.5%; height: 3%;">
                      <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT2_Water_out = $event" />
                      <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                    </div>


                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top:-2%; left: 11%; width: 13.5%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >CT Water in.</span>
                    </div>
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -2%; left: 21%; width: 3.5%; height: 3%;">
                      <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT_Water_in = $event" />
                      <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                    </div>

                 
            
        </div>  
        <div class="form component">
          <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
  
          <div style="height: 40px;"></div>
          <div  class="hvac-text">
          <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
          <div style="width:25%; ">
              <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event" />
          </div>
          <span style="margin-left:-10%; font-size: 100%;">%</span>
          </div>
      </div>  
    </div>

</div>