import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-eight-comp',
  templateUrl: './ahu-eight-comp.component.html',
  styleUrls: ['./ahu-eight-comp.component.css']
})
export class AhuEightCompComponent {

}
