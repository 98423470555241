<div class="ahu-eight">
    

    <svg id="ahu-eight" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 82.513 137.115">
        <g id="Component_181_1" data-name="Component 181 – 1" transform="translate(1.513 0.5)">
          <path id="Path_11395" data-name="Path 11395" d="M12917.613-20963.9c.279,5.945-.046,116.629-.046,116.629s-.557,5.148,4.444,5.006,69.574-.117,69.574-.117a4.07,4.07,0,0,0,3.879-4.295c.014-4.15.075-119.094.075-119.094a3.6,3.6,0,0,0-3.844-3.717c-3.934.014-70.812.082-70.812.082S12917.334-20969.846,12917.613-20963.9Z" transform="translate(-12917.549 20977.377)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
          <text id="Liquid_Preparation1" data-name="Liquid 
      Preparation1" transform="translate(0 94.377)" fill="#606060" font-size="10" font-family="Mulish" font-weight="300"><tspan x="23.725" y="10">Liquid </tspan><tspan x="9.71" y="23">Preparation1</tspan></text>
          <text id="AHU-8" transform="translate(3 53.377)" fill="#377c54" font-size="14" font-family="Mulish" font-weight="700"><tspan x="13.114" y="14">AHU-8</tspan></text>
          <path id="Path_11398" data-name="Path 11398" d="M13.717-2.709V7.535H0s.562,5.316,3.757,8.441,9.024,4.082,9.024,4.082-5.829.809-9.024,4.172S0,33.5,0,33.5l6.547-.162,7.17-.176V44.094" transform="translate(28.905 13.717) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        </g>
      </svg>
      
    
  </div>
  
  
  
  
  
  
  
  
  
  
  
  