import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-heat-exchanger1',
  templateUrl: './heat-exchanger1.component.html',
  styleUrls: ['./heat-exchanger1.component.css']
})
export class HeatExchanger1Component {
 
  @Input() data!: string;
  jsonData: any = {};
  updateSelectStyle() {
    // Assuming, don't need to manually update styles on selection change
  }

  onOffStatus1: string = '1'; // Default value, can set it to '1' for ON or '2' for OFF
  onOffStatus2: string = '1';
  onOffStatus3: string = '1';
  Pump1_Status: number = 1;
  Pump2_Status: number = 1;
  Pump3_Status: number = 1;

  isToggled = false;
  progress: boolean = false;


  hep_out: any;
  hep_input: any;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  disableOptimise: boolean = false;

  VFD_Sec_Pump_1: number = 0;
  VFD_Sec_Pump_2: number = 0;
  VFD_Sec_Pump_3: number = 0;
  model_no: number = 31;

  TOTAL_POWER_out: number = 0;
  kpi: number = 0;




  Power_FAHU_01_out: number=0;
  Power_FAHU_02_out: number=0;
  Power_AHU_01_out: number=0;
  Power_AHU_02_out: number=0;
  Power_AHU_03_out: number=0;
  Power_AHU_04_out: number=0;
  Power_AHU_05_out: number=0;
  Power_AHU_06_out: number=0;
  Power_AHU_07_out: number=0;
  Power_AHU_08_out: number=0;
  Power_AHU_09_out: number=0;
  Power_AHU_10_out: number=0;
  Power_AHU_11_out: number=0;
  Power_AHU_12_out: number=0;
  Power_AHU_13_out: number=0;
  Power_AHU_14_out: number=0;
  Power_AHU_15_out: number=0;
  Power_AHU_16_out: number=0;
  Power_AHU_17_out: number=0;
  Power_AHU_18_out: number=0;
  Power_AHU_19_out: number=0;
  Power_AHU_20_out: number=0;
  Power_AHU_21_out: number=0;
  Power_AHU_22_out: number=0;
  Power_AHU_23_out: number=0;
  Power_AHU_24_out: number=0;
  Power_AHU_25_out: number=0;
  Power_AHU_26_out: number=0;
  Power_AHU_27_out: number=0;
  Power_AHU_28_out: number=0;
  

  fahu_one_out: any;
  fahu_two_out: any;
  //predict output fields initialisation
  VFD_Sec_Pump_1_out: number = 0;
  VFD_Sec_Pump_2_out: number = 0;
  VFD_Sec_Pump_3_out: number = 0;
  Diff_Pressure_Sec_Pump_out: number = 0;
  Sec_Pump_Power_out_1: number = 0;
  Sec_Pump_Power_out_2: number = 0;
  Sec_Pump_Power_out_3: number = 0;
  id: string = '';
  tag: string = '';







  updatePump1StatusOut() {
    localStorage.setItem('onOffStatus1', this.onOffStatus1);
    if (this.onOffStatus1 === '1') {
      this.Pump1_Status = 1;
    } else if (this.onOffStatus1 === '2') {
      this.Pump1_Status = 0;
    }
    localStorage.setItem('Pump1_Status', String(this.Pump1_Status));
  }
  updatePump2StatusOut() {
    localStorage.setItem('onOffStatus2', this.onOffStatus2);
    if (this.onOffStatus2 === '1') {
      this.Pump2_Status = 1;
    } else if (this.onOffStatus2 === '2') {
      this.Pump2_Status = 0;
    }
    localStorage.setItem('Pump2_Status', String(this.Pump2_Status)); // Store pump status
  }
  updatePump3StatusOut() {
    localStorage.setItem('onOffStatus3', this.onOffStatus3);
    if (this.onOffStatus3 === '1') {
      this.Pump3_Status = 1;

    } else if (this.onOffStatus3 === '2') {
      this.Pump3_Status = 0;
    }
    localStorage.setItem('Pump3_Status', String(this.Pump3_Status)); // Store pump status
  }

  onOnOffStatus1Change() {
    this.updatePump1StatusOut(); // Call the function to update Pump1 Output Status
  }
  onOnOffStatus2Change() {
    this.updatePump2StatusOut(); // Call the function to update Pump2 Output Status
  }
  onOnOffStatus3Change() {
    this.updatePump3StatusOut(); // Call the function to update Pump3 Output Status
  }

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.onOffStatus1 = localStorage.getItem('onOffStatus1') || '1';
    this.onOffStatus2 = localStorage.getItem('onOffStatus2') || '1';
    this.onOffStatus3 = localStorage.getItem('onOffStatus3') || '1';
    this.Pump1_Status = Number(localStorage.getItem('Pump1_Status')) || 1;
    this.Pump2_Status = Number(localStorage.getItem('Pump2_Status')) || 1;
    this.Pump3_Status = Number(localStorage.getItem('Pump3_Status')) || 1;

    this.route.params.subscribe((params) => {
      const myVariable = params['id'];
      this.id = myVariable;
      console.log("My Variable", myVariable);

      console.log('id: ', this.id);
      try {
        this.data = String(localStorage.getItem(this.id));

        this.jsonData = JSON.parse(this.data);
        this.tag = this.jsonData['tag'];



        // console.log("data: ", this.data);
        console.log('jsonData: ', this.jsonData);

      } catch (error) {
        console.error('Error parsing JSON:', error);
      }


    })




    this.http.get('assets/mall-ahus-fcus.json', { responseType: 'text' })
      .subscribe(data => {
        const ahusData = JSON.parse(data);

        for (const key in ahusData) {
          if (ahusData.hasOwnProperty(key)) {
            const ahu = JSON.parse(localStorage.getItem(key) || '{}');
            if (!ahu['isDisabled']) {
              if (ahu.hasOwnProperty('predict_response')) {
                const predict_response = ahu['predict_response'];
                this.predict_input[`CHWVP_${ahu.tag}`] = predict_response['CHWVP'];
                // console.log("predict response of some AHU", this.predict_input[`CHWVP_${ahu.tag}`]);

                console.log(predict_response);
              } else {
                this.predict_input[`CHWVP_${ahu.tag}`] = parseInt('100', 10);
                // console.log(key);
                console.log("predict_input-for all AHUs", this.predict_input)
                this.openSnackBar(`Values Not Predicted For: ${ahu.tag}`)
                // this.disableOptimise = true;
              }
            }
          }

        }
        if (localStorage.getItem('fahu_one_out')) {
          this.fahu_one_out = localStorage.getItem('fahu_one_out');
          this.fahu_one_out = JSON.parse(this.fahu_one_out);
          // console.log(this.fahu_one_out);  
          this.predict_input['CHWVP_FAHU_01'] = this.fahu_one_out['CHWVP_out'];

          this.optimise_input['Ambient temp'] = this.fahu_one_out['Ambient temp'];
          this.optimise_input['CHWVP_FAHU_01'] = this.fahu_one_out['CHWVP_out'];
          this.optimise_input['SFRS_FAHU_01'] = this.fahu_one_out['SFRS_out'];
          this.optimise_input['sat_FAHU_01'] = this.fahu_one_out['sat_FAHU_01'];
          this.optimise_input['VFD_freq_FAHU_01'] = this.fahu_one_out['VFD_freq_out'];
          this.optimise_input['fan_power_FAHU_01'] = this.fahu_one_out['fan_power_FAHU_01'];
        } else{
          this.optimise_input['Ambient temp'] = 0;
          this.optimise_input['CHWVP_FAHU_01'] = 0;
          this.optimise_input['SFRS_FAHU_01'] = 0;
          this.optimise_input['sat_FAHU_01'] = 0;
          this.optimise_input['VFD_freq_FAHU_01'] = 0;
          this.optimise_input['fan_power_FAHU_01'] = 0;
          this.openSnackBar('Values Not Predicted For: EXP-KFAHU-01')
                this.disableOptimise = true;
        }
        if (localStorage.getItem('fahu_two_out')) {
          this.fahu_two_out = localStorage.getItem('fahu_two_out');
          this.fahu_two_out = JSON.parse(this.fahu_two_out);
          // console.log(this.fahu_two_out);  
          this.predict_input['CHWVP_FAHU_02'] = this.fahu_two_out['CHWVP_out'];

          this.optimise_input['CHWVP_FAHU_02'] = this.fahu_two_out['CHWVP_out'];
          this.optimise_input['SFRS_FAHU_02'] = this.fahu_two_out['SFRS_out'];
          this.optimise_input['sat_FAHU_02'] = this.fahu_two_out['sat_FAHU_02'];
          this.optimise_input['VFD_freq_FAHU_02'] = this.fahu_two_out['VFD_freq_out'];
          this.optimise_input['fan_power_FAHU_02'] = this.fahu_two_out['fan_power_FAHU_02'];
        }else{
          this.optimise_input['CHWVP_FAHU_02'] = 0;
          this.optimise_input['SFRS_FAHU_02'] = 0;
          this.optimise_input['sat_FAHU_02'] = 0;
          this.optimise_input['VFD_freq_FAHU_02'] = 0;
          this.optimise_input['fan_power_FAHU_02'] = 0;
          this.openSnackBar('Values Not Predicted For: EXP-KFAHU-02')
          this.disableOptimise = true;
        }
        this.optimise_input['Chilled water return temp'] = Number(localStorage.getItem('CHWRT')) || 0; 
        this.optimise_input['Chilled water supply temp'] = Number(localStorage.getItem('CHWST')) || 0;
        this.optimise_input['Ambient temp'] = Number(localStorage.getItem('Ambient_temp')) || 0;
        
        for (const key in ahusData) {
          if (ahusData.hasOwnProperty(key)) {
            const ahu = JSON.parse(localStorage.getItem(key) || '{}');
            if (!ahu['isDisabled']) {
              if (ahu.hasOwnProperty('predict_response')){
                const predict_response = ahu['predict_response'];
                this.optimise_input[`rat_${ahu.tag}`] = predict_response[`rat_${ahu.tag}`];
                this.optimise_input[`CHWVP_${ahu.tag}`] = predict_response['CHWVP'];
                this.optimise_input[`SFRS_${ahu.tag}`] = predict_response['SFRS'];
                this.optimise_input[`sat_${ahu.tag}`] = predict_response[`sat_${ahu.tag}`] || 20;
                this.optimise_input[`VFD_freq_${ahu.tag}`] = predict_response['VFD_freq'];
                this.optimise_input[`fan_power_${ahu.tag}`] = predict_response[`fan_power_${ahu.tag}`];
      
                console.log(predict_response);
              } else {
                this.optimise_input[`rat_${ahu.tag}`] = parseInt('24',10);
                this.optimise_input[`CHWVP_${ahu.tag}`] = parseInt('100',10);
                this.optimise_input[`SFRS_${ahu.tag}`] = parseInt('1',10);
                this.optimise_input[`sat_${ahu.tag}`] = parseInt('20',10);
                this.optimise_input[`VFD_freq_${ahu.tag}`] = parseInt('100',10);
                this.optimise_input[`fan_power_${ahu.tag}`] = parseInt('100',10);
                console.log(key);
                this.openSnackBar(`Values Not Predicted For: ${ahu.tag}`)
                this.disableOptimise = true;
              }
            }
          }
          
        }
        
        if (localStorage.getItem('hep_out')) {
          this.hep_out = localStorage.getItem('hep_out');
          this.hep_out = JSON.parse(this.hep_out);
          // console.log(this.hep_out);
          this.optimise_input['SecPumpVFD1'] = this.hep_out['VFD_Sec_Pump_1_out'];
          this.optimise_input['SecPumpPower1'] = this.hep_out['SecPumpPower1'];
          this.optimise_input['SecPumpVFD2'] = this.hep_out['VFD_Sec_Pump_2_out'];
          this.optimise_input['SecPumpPower2'] = this.hep_out['SecPumpPower2'];
          this.optimise_input['SecPumpVFD3'] = this.hep_out['VFD_Sec_Pump_3_out'];
          this.optimise_input['SecPumpPower3'] = this.hep_out['SecPumpPower3'];
          this.optimise_input['Diff Pressure Sec Pump'] = this.hep_out['Diff Pressure Sec Pump'];
          // console.log("hep-output", this.hep_out);
        }else{
          this.optimise_input['SecPumpVFD1'] = 0;
          this.optimise_input['SecPumpPower1'] = 0;
          this.optimise_input['SecPumpVFD2'] = 0;
          this.optimise_input['SecPumpPower2'] = 0;
          this.optimise_input['SecPumpVFD3'] = 0;
          this.optimise_input['SecPumpPower3'] = 0;
          this.optimise_input['Diff Pressure Sec Pump'] = 0;
          // this.openSnackBar('Values Not Predicted For: HEP')
                this.disableOptimise = true;
        }
        
       











      });



    if (localStorage.getItem('hep_input')) {
      this.hep_input = localStorage.getItem('hep_input');
      this.hep_input = JSON.parse(this.hep_input);
      this.VFD_Sec_Pump_1 = Number(this.hep_input['SecPumpVFD1']);
      this.VFD_Sec_Pump_2 = Number(this.hep_input['SecPumpVFD2']);
      this.VFD_Sec_Pump_3 = Number(this.hep_input['SecPumpVFD3']);
      this.model_no = 31;
    } else {
      this.VFD_Sec_Pump_1 = 77;
      this.VFD_Sec_Pump_2 = 68;
      this.VFD_Sec_Pump_3 = 70;
    }

    // output field initialisation
    if (localStorage.getItem('optimise-response')) {
      this.optimise_response = localStorage.getItem('optimise-response');
      this.optimise_response = JSON.parse(this.optimise_response);
      this.hep_out = localStorage.getItem('hep_out');
      this.hep_out = JSON.parse(this.hep_out);
      console.log(this.hep_out);
      this.Diff_Pressure_Sec_Pump_out = this.hep_out['Diff Pressure Sec Pump']
      this.Sec_Pump_Power_out_1 = this.hep_out['SecPumpPower1'];
      this.Sec_Pump_Power_out_2 = this.hep_out['SecPumpPower2'];
      this.Sec_Pump_Power_out_3 = this.hep_out['SecPumpPower3'];
      this.VFD_Sec_Pump_1_out = this.optimise_response['SecPumpVFD1'];
      this.VFD_Sec_Pump_2_out = this.optimise_response['SecPumpVFD2'];
      this.VFD_Sec_Pump_3_out = this.optimise_response['SecPumpVFD3'];
    }else{
      if (localStorage.getItem('hep_out')) {
        this.hep_out = localStorage.getItem('hep_out');
        this.hep_out = JSON.parse(this.hep_out);
        console.log(this.hep_out);
        this.Diff_Pressure_Sec_Pump_out = this.hep_out['Diff Pressure Sec Pump']
        this.Sec_Pump_Power_out_1 = this.hep_out['SecPumpPower1'];
        this.Sec_Pump_Power_out_2 = this.hep_out['SecPumpPower2'];
        this.Sec_Pump_Power_out_3 = this.hep_out['SecPumpPower3'];
        this.VFD_Sec_Pump_1_out = this.hep_out['VFD_Sec_Pump_1_out'];
        this.VFD_Sec_Pump_2_out = this.hep_out['VFD_Sec_Pump_2_out'];
        this.VFD_Sec_Pump_3_out = this.hep_out['VFD_Sec_Pump_3_out'];
        console.log("hep-output", this.hep_out);
      } else {
        this.Diff_Pressure_Sec_Pump_out = 0;
        this.Sec_Pump_Power_out_1 = 0;
        this.Sec_Pump_Power_out_2 = 0;
        this.Sec_Pump_Power_out_3 = 0;
        this.VFD_Sec_Pump_1_out = 0;
        this.VFD_Sec_Pump_2_out = 0;
        this.VFD_Sec_Pump_3_out = 0;
      }
    }
    
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.VFD_Sec_Pump_1 < 30 || this.VFD_Sec_Pump_1 > 100) {
      this.openSnackBar('VFD_Sec_Pump_1 should be in range [30,100]');
      return false;
    } else if (this.VFD_Sec_Pump_2 < 30 || this.VFD_Sec_Pump_2 > 100) {
      this.openSnackBar('VFD_Sec_Pump_2 should be in range [30,100]');
      return false;
    } else if (this.VFD_Sec_Pump_3 < 30 || this.VFD_Sec_Pump_3 > 100) {
      this.openSnackBar('VFD_Sec_Pump_3 should be in range [30,100]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    if (this.validate()) {
      // this.progress = true;

      this.predict_input['SecPumpVFD1'] = Number(this.VFD_Sec_Pump_1);
      this.predict_input['SecPumpVFD2'] = Number(this.VFD_Sec_Pump_2);
      this.predict_input['SecPumpVFD3'] = Number(this.VFD_Sec_Pump_3);
      this.predict_input['model_no.'] = 31;
      console.log('predict input', this.predict_input);

      console.log('Optimise-input', this.optimise_input);
      try {
        const response = await this.http
          .post<any>(
            'http://52.140.60.102:5031/sec_31',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        this.predict_response['VFD_Sec_Pump_1'] = Number(this.VFD_Sec_Pump_1);
        this.predict_response['VFD_Sec_Pump_2'] = Number(this.VFD_Sec_Pump_2);
        this.predict_response['VFD_Sec_Pump_3'] = Number(this.VFD_Sec_Pump_3);

        console.log('predict input', this.predict_input);

        console.log('predict output', this.predict_response);


        this.openSnackBar('Predicted Succesfully');
        this.Diff_Pressure_Sec_Pump_out = this.predict_response["Diff Pressure Sec Pump"];
        this.Sec_Pump_Power_out_1 = this.predict_response["SecPumpPower1"];
        this.Sec_Pump_Power_out_2 = this.predict_response["SecPumpPower2"];
        this.Sec_Pump_Power_out_3 = this.predict_response["SecPumpPower3"];
        this.VFD_Sec_Pump_1_out = this.predict_response['VFD_Sec_Pump_1'];
        this.VFD_Sec_Pump_2_out = this.predict_response['VFD_Sec_Pump_2'];
        this.VFD_Sec_Pump_3_out = this.predict_response['VFD_Sec_Pump_3'];
        localStorage.setItem('predict_response', JSON.stringify(response));
        localStorage.setItem('hep_input', JSON.stringify(this.predict_input));
        // console.log('resp', this.CT1_VFD_Feedback_F1_out);
        this.hep_out = {
          'Diff Pressure Sec Pump': this.Diff_Pressure_Sec_Pump_out,
          'SecPumpPower1': this.Sec_Pump_Power_out_1,
          'SecPumpPower2': this.Sec_Pump_Power_out_2,
          'SecPumpPower3': this.Sec_Pump_Power_out_3,
          'VFD_Sec_Pump_1_out': this.VFD_Sec_Pump_1_out,
          'VFD_Sec_Pump_2_out': this.VFD_Sec_Pump_2_out,
          'VFD_Sec_Pump_3_out': this.VFD_Sec_Pump_3_out,
        }
        localStorage.setItem('hep_out', JSON.stringify(this.hep_out));
        console.log("hep-output", this.hep_out);
        this.progress = false;
        this.disableOptimise = false;


      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }


  async optimiseData(){
    this.progress = true;
    console.log('optimise input: ', this.optimise_input);
    console.log('optimise api call');
    try {
      const response = await this.http
        .post<any>(
          'http://52.140.60.102:5032/optimise',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      // console.log('optimise input: ', this.optimise_input);

      console.log('optimise output', this.optimise_response);
      
      
      localStorage.setItem('SecPumpVFD1', String(response['SecPumpVFD1']));
      localStorage.setItem('SecPumpVFD2', String(response['SecPumpVFD2']));
      localStorage.setItem('SecPumpVFD3', String(response['SecPumpVFD3']));
     
      // Power-AHU-01 calculation by using VFD-AHU_01 value which is being received after optimisation
      this.Power_AHU_01_out=((7.5)*(this.optimise_response['VFD_freq_AHU_01']/100)*(this.optimise_response['VFD_freq_AHU_01']/100)*(this.optimise_response['VFD_freq_AHU_01']/100))
      localStorage.setItem('Power_AHU_01_out', String(this.Power_AHU_01_out));
      console.log("Power-AHU-01", this.Power_AHU_01_out);

      // Power-AHU-02 calculation by using VFD-AHU_02 value which is being received after optimisation
      this.Power_AHU_02_out=((11)*(this.optimise_response['VFD_freq_AHU_02']/100)*(this.optimise_response['VFD_freq_AHU_02']/100)*(this.optimise_response['VFD_freq_AHU_02']/100))
      localStorage.setItem('Power_AHU_02_out', String(this.Power_AHU_02_out));
      console.log("Power-AHU-02", this.Power_AHU_02_out);

      // Power-AHU-03 calculation by using VFD-AHU_03 value which is being received after optimisation
      this.Power_AHU_03_out=((20.5)*(this.optimise_response['VFD_freq_AHU_03']/100)*(this.optimise_response['VFD_freq_AHU_03']/100)*(this.optimise_response['VFD_freq_AHU_03']/100))
      localStorage.setItem('Power_AHU_03_out', String(this.Power_AHU_03_out));
      console.log("Power-AHU-03", this.Power_AHU_03_out);

      // Power-AHU-04 calculation by using VFD-AHU_04 value which is being received after optimisation
      this.Power_AHU_04_out=((20.5)*(this.optimise_response['VFD_freq_AHU_04']/100)*(this.optimise_response['VFD_freq_AHU_04']/100)*(this.optimise_response['VFD_freq_AHU_04']/100))
      localStorage.setItem('Power_AHU_04_out', String(this.Power_AHU_04_out));
      console.log("Power-AHU-04", this.Power_AHU_04_out);

      // Power-AHU-05 calculation by using VFD-AHU_05 value which is being received after optimisation
      this.Power_AHU_05_out=((20.5)*(this.optimise_response['VFD_freq_AHU_05']/100)*(this.optimise_response['VFD_freq_AHU_05']/100)*(this.optimise_response['VFD_freq_AHU_05']/100))
      localStorage.setItem('Power_AHU_05_out', String(this.Power_AHU_05_out));
      console.log("Power-AHU-05", this.Power_AHU_05_out);

      // Power-AHU-06 calculation by using VFD-AHU_06 value which is being received after optimisation
      this.Power_AHU_06_out=((21)*(this.optimise_response['VFD_freq_AHU_06']/100)*(this.optimise_response['VFD_freq_AHU_06']/100)*(this.optimise_response['VFD_freq_AHU_06']/100))
      localStorage.setItem('Power_AHU_06_out', String(this.Power_AHU_06_out));
      console.log("Power-AHU-06", this.Power_AHU_06_out);

      // Power-AHU-07 calculation by using VFD-AHU_07 value which is being received after optimisation
      this.Power_AHU_07_out=((5.5)*(this.optimise_response['VFD_freq_AHU_07']/100)*(this.optimise_response['VFD_freq_AHU_07']/100)*(this.optimise_response['VFD_freq_AHU_07']/100))
      localStorage.setItem('Power_AHU_07_out', String(this.Power_AHU_07_out));
      console.log("Power-AHU-07", this.Power_AHU_07_out);

      // Power-AHU-08 calculation by using VFD-AHU_08 value which is being received after optimisation
      this.Power_AHU_08_out=((7.5)*(this.optimise_response['VFD_freq_AHU_08']/100)*(this.optimise_response['VFD_freq_AHU_08']/100)*(this.optimise_response['VFD_freq_AHU_08']/100))
      localStorage.setItem('Power_AHU_08_out', String(this.Power_AHU_08_out));
      console.log("Power-AHU-08", this.Power_AHU_08_out);

      // Power-AHU-09 calculation by using VFD-AHU_09 value which is being received after optimisation
      this.Power_AHU_09_out=((5.5)*(this.optimise_response['VFD_freq_AHU_09']/100)*(this.optimise_response['VFD_freq_AHU_09']/100)*(this.optimise_response['VFD_freq_AHU_09']/100))
      localStorage.setItem('Power_AHU_09_out', String(this.Power_AHU_09_out));
      console.log("Power-AHU-09", this.Power_AHU_09_out);

      // Power-AHU-10 calculation by using VFD-AHU_10 value which is being received after optimisation
      this.Power_AHU_10_out=((5.5)*(this.optimise_response['VFD_freq_AHU_10']/100)*(this.optimise_response['VFD_freq_AHU_10']/100)*(this.optimise_response['VFD_freq_AHU_10']/100))
      localStorage.setItem('Power_AHU_10_out', String(this.Power_AHU_10_out));
      console.log("Power-AHU-10", this.Power_AHU_10_out);

      // Power-AHU-11 calculation by using VFD-AHU_11 value which is being received after optimisation
      this.Power_AHU_11_out=((21)*(this.optimise_response['VFD_freq_AHU_11']/100)*(this.optimise_response['VFD_freq_AHU_11']/100)*(this.optimise_response['VFD_freq_AHU_11']/100))
      localStorage.setItem('Power_AHU_11_out', String(this.Power_AHU_11_out));
      console.log("Power-AHU-11", this.Power_AHU_11_out);

      // Power-AHU-12 calculation by using VFD-AHU_12 value which is being received after optimisation
      this.Power_AHU_12_out=((5.5)*(this.optimise_response['VFD_freq_AHU_12']/100)*(this.optimise_response['VFD_freq_AHU_12']/100)*(this.optimise_response['VFD_freq_AHU_12']/100))
      localStorage.setItem('Power_AHU_12_out', String(this.Power_AHU_12_out));
      console.log("Power-AHU-12", this.Power_AHU_12_out);

      // Power-AHU-13 calculation by using VFD-AHU_13 value which is being received after optimisation
      this.Power_AHU_13_out=((9.3)*(this.optimise_response['VFD_freq_AHU_13']/100)*(this.optimise_response['VFD_freq_AHU_13']/100)*(this.optimise_response['VFD_freq_AHU_13']/100))
      localStorage.setItem('Power_AHU_13_out', String(this.Power_AHU_13_out));
      console.log("Power-AHU-13", this.Power_AHU_13_out);

      // Power-AHU-14 calculation by using VFD-AHU_14 value which is being received after optimisation
      this.Power_AHU_14_out=((9.3)*(this.optimise_response['VFD_freq_AHU_14']/100)*(this.optimise_response['VFD_freq_AHU_14']/100)*(this.optimise_response['VFD_freq_AHU_14']/100))
      localStorage.setItem('Power_AHU_14_out', String(this.Power_AHU_14_out));
      console.log("Power-AHU-14", this.Power_AHU_14_out);

      // Power-AHU-15 calculation by using VFD-AHU_15 value which is being received after optimisation
      this.Power_AHU_15_out=((11)*(this.optimise_response['VFD_freq_AHU_15']/100)*(this.optimise_response['VFD_freq_AHU_15']/100)*(this.optimise_response['VFD_freq_AHU_15']/100))
      localStorage.setItem('Power_AHU_15_out', String(this.Power_AHU_15_out));
      console.log("Power-AHU-15", this.Power_AHU_15_out);

      // Power-AHU-16 calculation by using VFD-AHU_16 value which is being received after optimisation
      this.Power_AHU_16_out=((5.5)*(this.optimise_response['VFD_freq_AHU_16']/100)*(this.optimise_response['VFD_freq_AHU_16']/100)*(this.optimise_response['VFD_freq_AHU_16']/100))
      localStorage.setItem('Power_AHU_16_out', String(this.Power_AHU_16_out));
      console.log("Power-AHU-16", this.Power_AHU_16_out);

      // Power-AHU-17 calculation by using VFD-AHU_17 value which is being received after optimisation
      this.Power_AHU_17_out=((11)*(this.optimise_response['VFD_freq_AHU_17']/100)*(this.optimise_response['VFD_freq_AHU_17']/100)*(this.optimise_response['VFD_freq_AHU_17']/100))
      localStorage.setItem('Power_AHU_17_out', String(this.Power_AHU_17_out));
      console.log("Power-AHU-17", this.Power_AHU_17_out);

      // Power-AHU-18 calculation by using VFD-AHU_18 value which is being received after optimisation
      this.Power_AHU_18_out=((11)*(this.optimise_response['VFD_freq_AHU_18']/100)*(this.optimise_response['VFD_freq_AHU_18']/100)*(this.optimise_response['VFD_freq_AHU_18']/100))
      localStorage.setItem('Power_AHU_18_out', String(this.Power_AHU_18_out));
      console.log("Power-AHU-18", this.Power_AHU_18_out);

      // Power-AHU-19 calculation by using VFD-AHU_19 value which is being received after optimisation
      this.Power_AHU_19_out=((9.3)*(this.optimise_response['VFD_freq_AHU_19']/100)*(this.optimise_response['VFD_freq_AHU_19']/100)*(this.optimise_response['VFD_freq_AHU_19']/100))
      localStorage.setItem('Power_AHU_19_out', String(this.Power_AHU_19_out));
      console.log("Power-AHU-19", this.Power_AHU_19_out);

      // Power-AHU-20 calculation by using VFD-AHU_20 value which is being received after optimisation
      this.Power_AHU_20_out=((9.3)*(this.optimise_response['VFD_freq_AHU_20']/100)*(this.optimise_response['VFD_freq_AHU_20']/100)*(this.optimise_response['VFD_freq_AHU_20']/100))
      localStorage.setItem('Power_AHU_20_out', String(this.Power_AHU_20_out));
      console.log("Power-AHU-20", this.Power_AHU_20_out);

      // Power-AHU-21 calculation by using VFD-AHU_21 value which is being received after optimisation
      this.Power_AHU_21_out=((5.5)*(this.optimise_response['VFD_freq_AHU_21']/100)*(this.optimise_response['VFD_freq_AHU_21']/100)*(this.optimise_response['VFD_freq_AHU_21']/100))
      localStorage.setItem('Power_AHU_21_out', String(this.Power_AHU_21_out));
      console.log("Power-AHU-21", this.Power_AHU_21_out);

      // Power-AHU-22 calculation by using VFD-AHU_22 value which is being received after optimisation
      this.Power_AHU_22_out=((5.5)*(this.optimise_response['VFD_freq_AHU_22']/100)*(this.optimise_response['VFD_freq_AHU_22']/100)*(this.optimise_response['VFD_freq_AHU_22']/100))
      localStorage.setItem('Power_AHU_22_out', String(this.Power_AHU_22_out));
      console.log("Power-AHU-22", this.Power_AHU_22_out);

      // Power-AHU-23 calculation by using VFD-AHU_23 value which is being received after optimisation
      this.Power_AHU_23_out=((21)*(this.optimise_response['VFD_freq_AHU_23']/100)*(this.optimise_response['VFD_freq_AHU_23']/100)*(this.optimise_response['VFD_freq_AHU_23']/100))
      localStorage.setItem('Power_AHU_23_out', String(this.Power_AHU_23_out));
      console.log("Power-AHU-23", this.Power_AHU_23_out);

      // Power-AHU-24 calculation by using VFD-AHU_24 value which is being received after optimisation
      this.Power_AHU_24_out=((32.5)*(this.optimise_response['VFD_freq_AHU_24']/100)*(this.optimise_response['VFD_freq_AHU_24']/100)*(this.optimise_response['VFD_freq_AHU_24']/100))
      localStorage.setItem('Power_AHU_24_out', String(this.Power_AHU_24_out));
      console.log("Power-AHU-24", this.Power_AHU_24_out);

      // Power-AHU-25 calculation by using VFD-AHU_25 value which is being received after optimisation
      this.Power_AHU_25_out=((15)*(this.optimise_response['VFD_freq_AHU_25']/100)*(this.optimise_response['VFD_freq_AHU_25']/100)*(this.optimise_response['VFD_freq_AHU_25']/100))
      localStorage.setItem('Power_AHU_25_out', String(this.Power_AHU_25_out));
      console.log("Power-AHU-25", this.Power_AHU_25_out);

      // Power-AHU-26 calculation by using VFD-AHU_26 value which is being received after optimisation
      this.Power_AHU_26_out=((21)*(this.optimise_response['VFD_freq_AHU_26']/100)*(this.optimise_response['VFD_freq_AHU_26']/100)*(this.optimise_response['VFD_freq_AHU_26']/100))
      localStorage.setItem('Power_AHU_26_out', String(this.Power_AHU_26_out));
      console.log("Power-AHU-26", this.Power_AHU_26_out);

      // Power-AHU-27 calculation by using VFD-AHU_27 value which is being received after optimisation
      this.Power_AHU_27_out=((21)*(this.optimise_response['VFD_freq_AHU_27']/100)*(this.optimise_response['VFD_freq_AHU_27']/100)*(this.optimise_response['VFD_freq_AHU_27']/100))
      localStorage.setItem('Power_AHU_27_out', String(this.Power_AHU_27_out));
      console.log("Power-AHU-27", this.Power_AHU_27_out);

      // Power-AHU-28 calculation by using VFD-AHU_28 value which is being received after optimisation
      this.Power_AHU_28_out=((5.5)*(this.optimise_response['VFD_freq_AHU_28']/100)*(this.optimise_response['VFD_freq_AHU_28']/100)*(this.optimise_response['VFD_freq_AHU_28']/100))
      localStorage.setItem('Power_AHU_28_out', String(this.Power_AHU_28_out));
      console.log("Power-AHU-28", this.Power_AHU_28_out);

      // Power-FAHU-01 calculation by using VFD-FAHU_01 value which is being received after optimisation
      this.Power_FAHU_01_out=((12.5)*(this.optimise_response['VFD_freq_FAHU_01']/100)*(this.optimise_response['VFD_freq_FAHU_01']/100)*(this.optimise_response['VFD_freq_FAHU_01']/100))
      localStorage.setItem('Power_FAHU_01_out', String(this.Power_FAHU_01_out));
      console.log("Power-AHU-24", this.Power_FAHU_01_out);

      // Power-FAHU-02 calculation by using VFD-FAHU_02 value which is being received after optimisation
      this.Power_FAHU_02_out=((6.42)*(this.optimise_response['VFD_freq_FAHU_02']/100)*(this.optimise_response['VFD_freq_FAHU_02']/100)*(this.optimise_response['VFD_freq_FAHU_02']/100))
      localStorage.setItem('Power_FAHU_02_out', String(this.Power_FAHU_02_out));
      console.log("Power-FAHU-02", this.Power_FAHU_02_out);
      

     
      this.openSnackBar('Optimised Succesfully');
      localStorage.setItem('optimise-response', JSON.stringify(response));

      this.http.get('assets/mall-ahus-fcus.json', { responseType: 'text' })
      .subscribe(data => {
        const ahusData = JSON.parse(data);
        for (const tag in ahusData) {
          if (ahusData.hasOwnProperty(tag)) {
            localStorage.setItem('AMBIENT_TEMP_optimise_out', String(response['Ambient temp']));
          }
        }
        
    })
      
      
      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }







  }


}