<div class="main-container">
  <div class="sidebar">
    <div class="menu-items">
      <div class="menu-item" routerLink="../ahucommand">AHU Command</div>
      <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
      <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Sequencing</div>
      <div class="menu-item" routerLink="../ahuscedule-command">AHU Schedule Command</div>
    </div>
  </div>

  <!-- <div class="content">
    <div class="command-section">
      <h2>AHU CONTROL COMMAND</h2>
      <div class="command-input-container">
        <input [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
        <button class="show-command-btn" (click)="showCommand()">Show Command</button>
      </div>

      <div *ngIf="showDetails">
        <p>You selected: {{selectedBlock}} - {{ selectedAHU }}</p>
        <div class="form-row">
          <label>BLOCK</label>
          <select [(ngModel)]="selectedBlock">
            <option *ngFor="let block of blocks">{{block}}</option>
          </select>
        </div>

        <div class="form-row">
          <label>FLOOR</label>
          <select [(ngModel)]="selectedfloor">
            <option *ngFor="let floor of floors">{{floor}}</option>
          </select>
        </div>

        <div class="form-row">
          <label>Label</label>
          <select [(ngModel)]="selectedAHU">
            <option *ngFor="let ahu of ahus">{{ahu}}</option>
          </select>
        </div>

        <div class="form-row">
          <label>BODY:-</label>
        
        </div>

        <div class="radio-group">
          <label>ON/OFF</label>
          <label>
            <input type="radio" name="selectedAHUStatus" [(ngModel)]="selectedAHUStatus" [value]="1" /> ON
          </label>
          <label>
            <input type="radio" name="selectedAHUStatus" [(ngModel)]="selectedAHUStatus" [value]="0" /> OFF
          </label>
        </div>
        

        <div class="form-row">
          <label>CHW Valve</label>
          <input type="number" [(ngModel)]="chw_valve" />
        </div>

        <div class="form-row">
          <label>Fan Speed</label>
          <input type="number" [(ngModel)]="fan_speed" />
        </div>
        <div class="form-row">
          <label>Fa Damper</label>
          <input type="number" [(ngModel)]="fa_damper" />
        </div>

        <button (click)="sendCommand()">Send</button>
        <p class="waiting-text" *ngIf="isLoading">Sending Command!</p>
        <p *ngIf="responseMessage"  [ngClass]="{ 'response-message': responseMessage[0] == 'C', 'error-message': responseMessage[0] == 'F' }" >{{responseMessage}}</p>      </div>
      <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div> -->
</div>