<div class="ahu-two">
      
      <svg id="ahu-two" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 355.097 270.664">
        <g id="Component_180_1" data-name="Component 180 – 1" transform="translate(1.499 1.506)">
          <path id="Path_11391" data-name="Path 11391" d="M12856.08-20969.148c3.24.031,51.525,0,51.525,0s4.118-.416,4.09,5.018.038,116.627.038,116.627-.118,5.322-3.761,5.27-51.531-.166-51.531-.166-3.652.25-3.778-4.328-.07-119.828-.07-119.828S12852.84-20969.18,12856.08-20969.148Z" transform="translate(-12852.58 21109.158)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
          <path id="Path_11392" data-name="Path 11392" d="M12855.426-20969.152c2.635.023,41.895,0,41.895,0s3.35-.289,3.326,3.488.031,81.084.031,81.084-.1,3.7-3.059,3.662-41.9-.113-41.9-.113a2.893,2.893,0,0,1-3.07-3.01c-.1-3.182-.059-83.309-.059-83.309S12852.791-20969.172,12855.426-20969.152Z" transform="translate(-12548.58 20969.158)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
          <text id="AHU-2" transform="translate(7.42 189.158)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="-0.114" y="14">AHU-2</tspan></text>
          <text id="AHU2" transform="translate(307.42 37.158)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="-0.048" y="14">AHU2</tspan></text>
          <text id="SFG_quranmtine_" data-name="SFG 
      quranmtine  
      " transform="translate(2.42 230.158)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="15.4" y="10">SFG </tspan><tspan x="1.14" y="23" xml:space="preserve">quranmtine  </tspan><tspan x="0" y="36"></tspan></text>
          <text id="Day_store_" data-name="Day store 
      " transform="translate(305.42 10.158)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="-0.875" y="10">Day store </tspan><tspan x="0" y="23"></tspan></text>
          <path id="Path_11393" data-name="Path 11393" d="M-2385.927-21040.859v-7.535h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-9.238" transform="translate(-20731.166 2481.256) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11394" data-name="Path 11394" d="M13.717-2.709V7.535H0s.562,5.316,3.757,8.441,9.024,4.082,9.024,4.082-5.829.809-9.024,4.172S0,33.5,0,33.5l6.547-.162,7.17-.176V44.094" transform="translate(8.138 148.498) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        </g>
      </svg>
      


  </div>
  



  
  
  
  
  
  
  
  
  
  
  