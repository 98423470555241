<div class="main-container">
    <div class="sidebar">
      <div class="menu-items">
        <div class="menu-item" routerLink="../ahucommand">AHU Command</div>
        <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command</div>
        <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Sequencing</div>
        <div class="menu-item" routerLink="../ahuscedule-command">AHU Schedule Command</div>
      </div>
    </div>
  
    <div class="content">
      <div class="command-section">
        <h2>PID TUNING COMMAND</h2>
        <div class="command-input-container">
          <input [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
          <button class="show-command-btn" (click)="showCommand()">Show Command</button>
        </div>
  
        <div *ngIf="showDetails">
          <div class="button-group">
            <button [ngClass]="{'active-btn': selectedSection === 'AHU','inactive-btn': selectedSection !== 'AHU'
}" (click)="selectSection('AHU')">AHU</button>
            <button [ngClass]="{'active-btn': selectedSection === 'ChillerPlant','inactive-btn': selectedSection !== 'ChillerPlant'
}" (click)="selectSection('ChillerPlant')">Chiller Plant</button>
          </div>
  
          <div *ngIf="selectedSection === 'AHU'">
            <!-- AHU-specific inputs -->
            <div class="form-row">
              <label>BLOCK</label>
              <select [(ngModel)]="selectedBlock">
                <option *ngFor="let block of blocks">{{block}}</option>
              </select>
            </div>
            <div class="form-row">
              <label>FLOOR</label>
              <select [(ngModel)]="selectedfloor">
                <option *ngFor="let floor of floors">{{floor}}</option>
              </select>
            </div>
            <div class="form-row">
              <label>Label</label>
              <select [(ngModel)]="selectedAHU">
                <option *ngFor="let ahu of ahus">{{ ahu }}</option>
              </select>
            </div>
            <div class="form-row">
              <label>PID Loop</label>
              <select [(ngModel)]="loopname">
                <option *ngFor="let name of loopnames">{{name}}</option>
              </select>
            </div>
            <div class="form-row">
                <label>BODY:-</label>
                <!-- <select [(ngModel)]="selectedAHU">
                  <option *ngFor="let ahu of ahus">{{ ahu }}</option>
                </select> -->
              </div>
            <div class="form-row">    
              <label>Set Point</label>
              <input type="number" [(ngModel)]="set_point" />
            </div>
            <div class="form-row">
              <label>Min Limit</label>
              <input type="number" [(ngModel)]="min_limit" />
            </div>
            <div class="form-row">
              <label>Max Limit</label>
              <input type="number" [(ngModel)]="max_limit" />
            </div>
          </div>
  
          <div *ngIf="selectedSection === 'ChillerPlant'">
            <!-- Chiller Plant-specific inputs -->
            <div class="form-row">
                <label>BLOCK</label>
                <select [(ngModel)]="selectedBlock">
                  <option *ngFor="let block of chiller_blocks">{{block}}</option>
                </select>
              </div>
              <div class="form-row">
                <label>FLOOR</label>
                <select [(ngModel)]="selectedfloor" (ngModelChange)="updateChillerLabels($event)">
                  <option *ngFor="let floor of chiller_floors">{{floor}}</option>
                </select>
              </div>
              <div class="form-row">
                <label>Label</label>
                <select [(ngModel)]="selectedchillerequip">
                  <option *ngFor="let label of chiller_labels">{{label}}</option>
                </select>
              </div>
              <!-- <div class="form-row">
                <label>PID Loop</label>
                <select [(ngModel)]="loopname">
                  <option *ngFor="let name of loopnames">{{name}}</option>
                </select>
              </div> -->
              <div class="form-row">
                  <label>BODY:-</label>
                  <!-- <select [(ngModel)]="selectedAHU">
                    <option *ngFor="let ahu of ahus">{{ ahu }}</option>
                  </select> -->
                </div>
<!--                 
            <div class="form-row">
              <label>Equipment Type</label>
              <select [(ngModel)]="selectedEquipmentType" (change)="onEquipmentTypeChange()">
                <option *ngFor="let type of equipmentTypes">{{type}}</option>
              </select>
            </div>
            <div class="form-row">
              <label>Equipment ID</label>
              <select [(ngModel)]="selectedEquipmentID" [disabled]="!equipmentIDOptions.length">
                <option *ngFor="let id of equipmentIDOptions">{{id}}</option>
              </select>
            </div> -->
            <div class="form-row">
                <label>Set Point</label>
                <input type="number" [(ngModel)]="set_point" />
              </div>
              <div class="form-row">
                <label>Min Limit</label>
                <input type="number" [(ngModel)]="min_limit" />
              </div>
              <div class="form-row">
                <label>Max Limit</label>
                <input type="number" [(ngModel)]="max_limit" />
              </div>
          </div>
  
          <button (click)="sendCommand()">Send</button>
          <p class="waiting-text" *ngIf="isLoading">Sending Command!</p>
          <p *ngIf="responseMessage"  [ngClass]="{ 'response-message': responseMessage[0] == 'C', 'error-message': responseMessage[0] == 'F' }" >{{responseMessage}}</p>
        </div>
        <p *ngIf="errorMessage" class="error-message">{{errorMessage}}</p>
      </div>
    </div>
  </div>
  