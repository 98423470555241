import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-nine',
  templateUrl: './zone-nine.component.html',
  styleUrls: ['./zone-nine.component.css']
})
export class ZoneNineComponent {
  type!: number;
  RAT: number;
RAT_out: any;
KPI: number=0;
RAT_out_AHU_11: any;
RAT_out_AHU_23: any;
RAT_out_AHU_24: any;
Zone_temp:number=0;
power_ahu_11:number=0;
power_ahu_23:number=0;
power_ahu_24:number=0;
BASELINE_ZONE_09:number=0;
POWER_ZONE_09: number=0;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_11 = Number(localStorage.getItem('RAT_out_AHU_11')) || 0;
  localStorage.setItem('RAT_out_AHU_11', JSON.stringify(this.RAT_out_AHU_11));
  console.log("RAT_AHU_11_output_zone_one", this.RAT_out_AHU_11 );

  this.RAT_out_AHU_23 = Number(localStorage.getItem('RAT_out_AHU_23')) || 0;
  localStorage.setItem('RAT_out_AHU_23', JSON.stringify(this.RAT_out_AHU_23));
  console.log("RAT_AHU_23_output_zone_one", this.RAT_out_AHU_23 );

  this.RAT_out_AHU_24 = Number(localStorage.getItem('RAT_out_AHU_24')) || 0;
  localStorage.setItem('RAT_out_AHU_24', JSON.stringify(this.RAT_out_AHU_24));
  console.log("RAT_AHU_24_output_zone_one", this.RAT_out_AHU_24 );


  this.Zone_temp = ((this.RAT_out_AHU_11+this.RAT_out_AHU_23+this.RAT_out_AHU_24)/3);


  this.power_ahu_11 = Number(localStorage.getItem('Power_AHU_11_out')) || 0;
  localStorage.setItem('power_ahu_11', JSON.stringify('power_ahu_11'));
  console.log("Power_AHU_11_out", this.power_ahu_11);

  this.power_ahu_23 = Number(localStorage.getItem('Power_AHU_23_out')) || 0;
  localStorage.setItem('power_ahu_23', JSON.stringify('power_ahu_23'));
  console.log("Power_AHU_23_out", this.power_ahu_23);

  this.power_ahu_24 = Number(localStorage.getItem('Power_AHU_24_out')) || 0;
  localStorage.setItem('power_ahu_24', JSON.stringify('power_ahu_24'));
  console.log("Power_AHU_24_out", this.power_ahu_24);

  this.BASELINE_ZONE_09 = 17.25;
  this.POWER_ZONE_09=(this.power_ahu_11+this.power_ahu_23+this.power_ahu_24);

  

  if(this.POWER_ZONE_09){
    this.KPI = ((this.BASELINE_ZONE_09-this.POWER_ZONE_09)/this.BASELINE_ZONE_09)*100;
  localStorage.setItem('kpi_ZONE_09', JSON.stringify('KPI'));
  }
  else{
    this.KPI = 0 ;
  }



  

 }
}
