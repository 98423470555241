<div class="ahu-three">
    <svg id="ahu-three" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 176.176 97.605">
      <g id="Component_187_1" data-name="Component 187 – 1" transform="translate(1.504 1.5)">
        <path id="Path_11423" data-name="Path 11423" d="M12439.4-21105.1c0,4.387-.214,78.758-.214,78.758s-.263,5.119,7.024,4.926,162.128-.437,162.128-.437,3.966-.5,4.019-4.107-.166-80.211-.166-80.211.057-3.893-4.55-3.887l-165.465.184S12439.4-21109.49,12439.4-21105.1Z" transform="translate(-12439.186 21110.063)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
        <text id="AHU3" transform="translate(47.288 36.605)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="20.048" y="14">AHU3</tspan></text>
        <path id="Path_11424" data-name="Path 11424" d="M-2385.927-21037v-11.393h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-12.373" transform="translate(-20976.109 2481.533) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        <text id="Supposistories" transform="translate(32.288 9.605)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="9.87" y="12">Supposistories</tspan></text>
      </g>
    </svg>
    
  </div>
  
  
  
  
  
  
  
  
  
  
  
  