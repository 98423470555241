import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-passwordchangepopup',
  templateUrl: './passwordchangepopup.component.html',
  styleUrls: ['./passwordchangepopup.component.css']
})
export class PasswordchangepopupComponent {
  new_password: string = '';
  old_password: string = '';
  confirm_password: string = '';
isloading:boolean=false;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(private http: HttpClient, private router:Router,private snackBar: MatSnackBar, private authservice: AuthService) {}
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  onSubmit() {
    const data = {
      username: sessionStorage.getItem('username'),
      emailId: sessionStorage.getItem('emailID'),
      oldPassword: this.old_password,
      newPassword: this.new_password
    };

    this.isloading=true;

    console.log(data);
    this.http.post<any>(`${environment.apiBaseUrl}/auth/changePassword`, data).subscribe(
      (response) => {
        this.isloading=false;

        console.log(response);
        this.openSnackBar('Successfully changed your password')
      },
       (error) => {
        this.isloading=false;

          if (error.status === 401) {
            // Redirect to the login page
            this.openSnackBar('Error occured please try again')

            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
        console.log(error);
      }
    );
    this.authservice.setuserfirstime(false);
  }
}
