<div class="main-container">
    <h2>ADMIN LOG</h2>
<div
style="
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
"
>
<app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

<div style="display: flex; align-items: center">
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >From Date :</label
  >
  <input
    matInput
    [matDatepicker]="fromDatePicker"
    [(ngModel)]="fromDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid #32774E;
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="fromDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #fromDatePicker></mat-datepicker>
</div>
<div>
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="fromTime"
    style="background-color: white; border-color: #32774E"
  >
    <option *ngFor="let time of times" [value]="time.value">
      {{ time.name }}
    </option>
  </select>
</div>
<div style="display: flex; align-items: center">
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >To Date :</label
  >
  <input
    matInput
    [matDatepicker]="toDatePicker"
    [(ngModel)]="toDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid #32774E;
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="toDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #toDatePicker></mat-datepicker>
</div>
<div>
  <label
    style="
      color: #32774E;
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="toTime"
    style="background-color: white; border-color: #32774E"
  >
    <option
      *ngFor="let time of times"
      [value]="time.value"
      style="max-height: 1000px; overflow-y: auto"
    >
      {{ time.name }}
    </option>
  </select>
</div>
</div>

<div class="button-group">
    <button class="save-btn" (click) = SendData()>Show Activities</button>
    <button class="save-btn" *ngIf="showDownloadButton" (click)="downloadLogsAsPDF()">Download Logs as PDF</button>
   
  </div>

  <div  *ngIf="showDownloadButton" class="search-container">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>search</mat-icon> <!-- Search icon -->
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search logs" class="search-input">
    </mat-form-field>
  </div>
  
  
  <mat-paginator  style="margin-bottom: 20px;" [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons class="custom-paginator"></mat-paginator>
  
  <table *ngIf="showDownloadButton" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 custom-table">
  
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let log">{{ log.date }}</td>
    </ng-container>
  
    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
      <td mat-cell *matCellDef="let log">{{ log.time }}</td>
    </ng-container>
  
    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
      <td mat-cell *matCellDef="let log" class="username-cell">{{ log.username }}</td>
    </ng-container>
  
    <!-- Detail Column -->
    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Detail</th>
      <td mat-cell *matCellDef="let log">{{ log.detail }}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

