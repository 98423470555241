import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-four-screen',
  templateUrl: './zone-four-screen.component.html',
  styleUrls: ['./zone-four-screen.component.css']
})
export class ZoneFourScreenComponent {
  type!: number;
  RAT: number;
RAT_out: any;
KPI: number=0;
RAT_out_AHU_27: any;
Zone_temp:number=0;
power_ahu_27:number=0;
BASELINE_ZONE_04:number=0;
POWER_ZONE_04:number=0;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_27 = Number(localStorage.getItem('RAT_out_AHU_27')) || 0;
  localStorage.setItem('RAT_out_AHU_27', JSON.stringify(this.RAT_out_AHU_27));
  console.log("RAT_AHU_27_output_zone_one", this.RAT_out_AHU_27 );


  this.Zone_temp = this.RAT_out_AHU_27;


    this.power_ahu_27 = Number(localStorage.getItem('Power_AHU_27_out')) || 0;
    localStorage.setItem('power_ahu_27', JSON.stringify('power_ahu_27'));
    console.log("Power_AHU_27_out", this.power_ahu_27);

    this.BASELINE_ZONE_04 = 8.55;
    this.POWER_ZONE_04=this.power_ahu_27;

    
    if(this.POWER_ZONE_04){
      this.KPI = ((this.BASELINE_ZONE_04-this.POWER_ZONE_04)/this.BASELINE_ZONE_04)*100;
    localStorage.setItem('kpi_zone_04', JSON.stringify('KPI'));
    }
    else{
      this.KPI = 0 ;
    }




  

 }
}
