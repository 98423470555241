import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ahu-template',
  templateUrl: './ahu-template.component.html',
  styleUrls: ['./ahu-template.component.css'],
})
export class AhuTemplateComponent {

  onOffStatus: number = 0; // Initial value, assuming 1 represents 'ON'
  // disablePredict: boolean = true; // Initial state, button is disabled
  stateAHU: string = 'active'; // Initial state, not sure where you're using it, so assuming it's declared elsewhere


 

  isHidden = false;
  hideImage() {
    this.isHidden = true;
  }
  showImage() {
    this.isHidden = false;
  }

  @Input() data!: string;
  jsonData: any = {};

  // input form fields
  RAT: number = 0;
  CHWVP: number = 0;
  CHWST: number = 0;
  SAT: number = 0;
  AMBIENT_TEMP: number = 0;
  SFRS: number = 0;
  VFD_freq: number = 0;


  optimus_data: any ={};
// rat-prediction-outputs-for-zonewise-pages 
  RAT_out_AHU_01: any = 0;
  RAT_out_AHU_02: any = 0;
  RAT_out_AHU_03: any = 0;
  RAT_out_AHU_04: any = 0;
  RAT_out_AHU_05: any = 0;
  RAT_out_AHU_06: any = 0;
  RAT_out_AHU_07: any = 0;
  RAT_out_AHU_08: any = 0;
  RAT_out_AHU_09: any = 0;
  RAT_out_AHU_10: any = 0;
  RAT_out_AHU_11: any = 0;
  RAT_out_AHU_12: any = 0;
  RAT_out_AHU_13: any = 0;
  RAT_out_AHU_14: any = 0;
  RAT_out_AHU_15: any = 0;
  RAT_out_AHU_16: any = 0;
  RAT_out_AHU_17: any = 0;
  RAT_out_AHU_18: any = 0;
  RAT_out_AHU_19: any = 0;
  RAT_out_AHU_20: any = 0;
  RAT_out_AHU_21: any = 0;
  RAT_out_AHU_22: any = 0;
  RAT_out_AHU_23: any = 0;
  RAT_out_AHU_24: any = 0;
  RAT_out_AHU_25: any = 0;
  RAT_out_AHU_26: any = 0;
  RAT_out_AHU_27: any = 0;
  RAT_out_AHU_28: any = 0;
  

  // output form fields
  RAT_out: any = 0;
  SFRS_out: any = 0;
  CHWVP_out: any = 0;
  CHWST_out: any = 0;
  FAN_Power_out: any = 0;
  VFD_Freq_out: any = 0;
  SAT_out: any = 0;
  AMBIENT_TEMP_out: any = 0;
  // KPI DATA
  KPI: number = 0;
  state: string = 'active';

  // variables inside
  predict_input: any = {};
  predict_response: any = {};
  predict_url: string = '';
  optimise_input: any = {};
  optimise_response: any = {};
  tag: string = '';
  model_no: number = 0;
  id: string = '';
  name: string = '';
  area: string = '';
  imageFileName: string = '';
  type: number = 1;
  isDisabled: boolean = false;
  capsule_group = 'input-group';



  progress: any;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      const myVariable = params['id'];
      this.id = myVariable;
      console.log(myVariable);
      console.log('id: ', this.id);



      try {
        this.data = String(localStorage.getItem(this.id));
        this.jsonData = JSON.parse(this.data);
        this.tag = this.jsonData['tag'];
        this.model_no = this.jsonData['model_no.'];
        this.name = this.jsonData['name'];
        this.area = this.jsonData['area'];
        this.imageFileName = this.jsonData['imageFileName'];
        this.type = this.jsonData['type'];
        this.isDisabled = this.jsonData['isDisabled'];
        

        if (this.isDisabled) {
          this.capsule_group = 'input-group2';
        } else {
          this.capsule_group = 'input-group';
        }

        // console.log("data: ", this.data);
        console.log('jsonData: ', this.jsonData);
         
        if(this.jsonData.hasOwnProperty('optimus_data')){
          this.optimus_data = this.jsonData.optimus_data
        } else {
          this.optimus_data = {}
        }
        this.optimusData(this.id)

        if(this.jsonData.isDisabled){
          this.RAT_out = this.optimus_data['rat'];
          this.CHWVP_out = this.optimus_data['CHWVP'];
          this.CHWST_out = this.optimus_data['CHWST'];
          this.SAT_out = this.optimus_data['sat'];
          this.FAN_Power_out = this.optimus_data['fan_power'];
          this.SFRS_out = this.optimus_data['SFRS'];
        }

        if (!this.isDisabled) {
          // Check if the key exists
          if (this.jsonData.hasOwnProperty('predict_input')) {
            this.predict_input = this.jsonData['predict_input'];
            this.SFRS = this.predict_input[`SFRS_${this.tag}`];
            this.CHWVP = this.predict_input[`CHWVP_${this.tag}`];
            this.VFD_freq = this.predict_input[`VFD_freq_${this.tag}`];
            this.CHWST = this.predict_input['Chilled water supply temp'];
            this.SAT = this.predict_input[`sat_${this.tag}`];
            this.RAT = this.predict_input[`rat_${this.tag}`];
            this.AMBIENT_TEMP = this.predict_input['Ambient temp'];
            this.model_no = this.predict_input['model_no.'];
          } else {
            this.SFRS = 0;
            this.CHWVP = 0;
            this.VFD_freq = 0;
            this.CHWST = 0;
            this.SAT = 0;
            this.RAT = 0;
            this.AMBIENT_TEMP = 0;
          }


          if (localStorage.getItem('optimise-response')) {
            this.optimise_response = localStorage.getItem('optimise-response');
            this.optimise_response = JSON.parse(this.optimise_response);
            this.predict_response = this.jsonData['predict_response'];
            // this.AMBIENT_TEMP_out = this.optimise_response['Ambient temp'];
            this.RAT_out = this.predict_response[`rat_${this.tag}`];
            this.CHWVP_out = this.optimise_response[`CHWVP_${this.tag}`];
            this.CHWST_out = this.predict_response['CHWST'];
            this.SFRS_out = this.predict_response['SFRS'];
            this.SAT_out = this.predict_response[`sat_${this.tag}`];
            this.VFD_Freq_out = this.optimise_response[`VFD_freq_${this.tag}`];
            this.FAN_Power_out = this.predict_response[`fan_power_${this.tag}`];
          } else {
            if (this.jsonData.hasOwnProperty('predict_response')) {
              this.predict_response = this.jsonData['predict_response'];
              this.RAT_out = this.predict_response[`rat_${this.tag}`];
              this.AMBIENT_TEMP_out = this.predict_response['Ambient temp'];
              this.CHWVP_out = this.predict_response['CHWVP'];
              this.CHWST_out = this.predict_response['CHWST'];
              this.FAN_Power_out = this.predict_response[`fan_power_${this.tag}`];
              this.VFD_Freq_out = this.predict_response['VFD_freq'];
              this.SFRS_out = this.predict_response['SFRS'];
              this.SAT_out = this.predict_response[`sat_${this.tag}`];
            } else {
              this.RAT_out = 0;
              this.CHWVP_out = 0;
              this.CHWST_out = 0;
              this.FAN_Power_out = 0;
              this.VFD_Freq_out = 0;
              this.SFRS_out = 0;
              this.SAT_out = 0;
            }
          }

          
        }
        // this.AMBIENT_TEMP_out =  Number(localStorage.getItem('Ambient_temp'));
        // this.CHWVP_out = Number(localStorage.getItem(`CHWVP_optimise_out_${this.tag}`)) || Number(localStorage.getItem(`CHWVP_predict_out_${this.tag}`));
        // this.VFD_Freq_out = Number(localStorage.getItem(`VFD_freq_optimise_out_${this.tag}`)) || Number(localStorage.getItem(`VFD_freq_predict_out_${this.tag}`));
        if (localStorage.getItem('kpi')) {
          this.KPI = Number(localStorage.getItem('kpi'));
        } else {
          this.KPI = 0;
        }

        if (this.jsonData.hasOwnProperty('predict_url')) {
          this.predict_url = this.jsonData['predict_url'];
        } else {
          this.predict_url = '';
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

         
      if(this.jsonData.hasOwnProperty('optimus_data')){
        this.optimus_data = this.jsonData.optimus_data;
      } else{
        this.optimus_data = {};
      }

      this.optimusData(this.id).then(() => {
        if(this.jsonData.isDisabled){
          this.RAT_out = this.optimus_data['rat'];
          this.CHWVP_out = this.optimus_data['CHWVP'];
          this.CHWST_out = this.optimus_data['CHWST'];
          this.SAT_out = this.optimus_data['sat'];
          this.FAN_Power_out = this.optimus_data['fan_power'];
          this.SFRS_out = this.optimus_data['SFRS'];
        }
      })



    })
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.RAT < 21 || this.RAT > 24) {
      this.openSnackBar('RAT should be in range [21,24]');
      return false;
    } else if (this.SAT < 17 || this.SAT > 20) {
      this.openSnackBar('SAT should be in range [17,20]');
      return false;
    } else if (this.SFRS < 0 || this.SFRS > 1) {
      this.openSnackBar('SFRS can only be 0 or 1');
      return false;
    } else if (this.AMBIENT_TEMP < 10 || this.AMBIENT_TEMP > 55) {
      this.openSnackBar('AMBIENT_TEMP should be in range [10,55]');
      return false;
    } else if (this.CHWVP < 0 || this.CHWVP > 100) {
      this.openSnackBar('CHWVP should be in range [0,100]');
      return false;
    } else if (this.CHWST < 6 || this.CHWST > 10) {
      this.openSnackBar('CHWST should be in range [6,10]');
      return false;
    } else if (this.VFD_freq < 30 || this.VFD_freq > 100) {
      this.openSnackBar('VFD_Freq should be in range [30,100]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    if (this.validate()) {
      console.log("predict-input", this.predict_input);
      this.predict_input = {
        [`VFD_freq_${this.tag}`]: Number(this.VFD_freq),
        [`rat_${this.tag}`]: Number(this.RAT),
        [`sat_${this.tag}`]: Number(this.SAT),
        [`SFRS_${this.tag}`]: Number(this.SFRS),
        [`CHWVP_${this.tag}`]: Number(this.CHWVP),
        'Chilled water supply temp': Number(this.CHWST),
        'Ambient temp': Number(this.AMBIENT_TEMP),
        'model_no.': Number(this.model_no),
      };
      // this.disablePredict = !this.SFRS;
         
        

        
      







      this.CHWST = this.predict_input['Chilled water supply temp'];
      this.AMBIENT_TEMP = this.predict_input['Ambient temp'];
      localStorage.setItem('CHWST', JSON.stringify(this.CHWST));
      localStorage.setItem('Ambient_temp', JSON.stringify(this.AMBIENT_TEMP));
      localStorage.setItem('predict_input', JSON.stringify(this.predict_input));
      console.log('predict input: ', this.predict_input);
      try {
        const response = await this.http
          .post<any>(this.predict_url, this.predict_input, this.httpOptions)
          .toPromise();

        this.predict_response = response;
        this.predict_response['CHWVP'] = Number(this.CHWVP);
        this.predict_response['CHWST'] = Number(this.CHWST);
        this.predict_response['SFRS'] = Number(this.SFRS);
        this.predict_response['VFD_freq'] = Number(this.VFD_freq);
        this.predict_response['Ambient temp'] = Number(this.AMBIENT_TEMP)
        console.log('predict output', this.predict_response);

        if(this.predict_response[`rat_${this.tag}`]<this.predict_response[`sat_${this.tag}`]){
          this.predict_response[`rat_${this.tag}`] = this.predict_response[`sat_${this.tag}`]+  2.91;
          // this.RAT_out=this.predict_response[`rat_${this.tag}`];
        }
        this.RAT_out = this.predict_response[`rat_${this.tag}`];
        this.CHWVP_out = this.predict_response['CHWVP'];
        this.AMBIENT_TEMP = this.predict_response['Ambient temp'];
        this.CHWST_out = Number(localStorage.getItem('CHWST'));
        this.FAN_Power_out = this.predict_response[`fan_power_${this.tag}`];
        this.VFD_Freq_out = this.predict_response['VFD_freq'];
        this.SFRS_out = this.predict_response['SFRS'];
        this.SAT_out = this.predict_response[`sat_${this.tag}`];
        this.AMBIENT_TEMP_out = this.predict_response['Ambient temp'];



       





        this.jsonData['predict_input'] = this.predict_input;
        this.jsonData['predict_response'] = this.predict_response;

        console.log(this.jsonData);
        localStorage.setItem('predict_response', JSON.stringify(response));
        console.log('pedict_output',this.predict_response )
        

        
        // console.log(localStorage.getItem(this.id));

        
        // localStorage.removeItem(`CHWVP_optimise_out_${this.tag}`);
        // localStorage.removeItem(`VFD_freq_optimise_out_${this.tag}`);
        // localStorage.setItem(`CHWVP_predict_out_${this.tag}`, String(response['CHWVP']))
        // localStorage.setItem(`VFD_freq_predict_out_${this.tag}`, String(response[`VFD_freq_${this.tag}`]))
        










        // for Zone 1 
        this.RAT_out_AHU_01 = this.predict_response['rat_AHU_01'] || Number(localStorage.getItem('RAT_out_AHU_01'));
        // this.RAT_out_AHU_01 = this.predict_input['rat_AHU_01'] || Number(localStorage.getItem('RAT_out_AHU_01'));
        localStorage.setItem('RAT_out_AHU_01', JSON.stringify(this.RAT_out_AHU_01));

        this.RAT_out_AHU_02 = this.predict_response['rat_AHU_02'] || Number(localStorage.getItem('RAT_out_AHU_02'));
        // this.RAT_out_AHU_02 = this.predict_input['rat_AHU_02'] || Number(localStorage.getItem('RAT_out_AHU_02'));
        localStorage.setItem('RAT_out_AHU_02', JSON.stringify(this.RAT_out_AHU_02));

        this.RAT_out_AHU_03 = this.predict_response['rat_AHU_03'] || Number(localStorage.getItem('RAT_out_AHU_03'));
        // this.RAT_out_AHU_03 = this.predict_input['rat_AHU_03'] || Number(localStorage.getItem('RAT_out_AHU_03'));
        localStorage.setItem('RAT_out_AHU_03', JSON.stringify(this.RAT_out_AHU_03));

        this.RAT_out_AHU_04 = this.predict_response['rat_AHU_04'] || Number(localStorage.getItem('RAT_out_AHU_04'));
        // this.RAT_out_AHU_04 = this.predict_input['rat_AHU_04'] || Number(localStorage.getItem('RAT_out_AHU_04'));
        localStorage.setItem('RAT_out_AHU_04', JSON.stringify(this.RAT_out_AHU_04));

        this.RAT_out_AHU_05 = this.predict_response['rat_AHU_05'] || Number(localStorage.getItem('RAT_out_AHU_05'));
        // this.RAT_out_AHU_05 = this.predict_input['rat_AHU_05'] || Number(localStorage.getItem('RAT_out_AHU_05'));
        localStorage.setItem('RAT_out_AHU_05', JSON.stringify(this.RAT_out_AHU_05));

        this.RAT_out_AHU_06 = this.predict_response['rat_AHU_06'] || Number(localStorage.getItem('RAT_out_AHU_06'));
        // this.RAT_out_AHU_06 = this.predict_input['rat_AHU_06'] || Number(localStorage.getItem('RAT_out_AHU_06'));
        localStorage.setItem('RAT_out_AHU_06', JSON.stringify(this.RAT_out_AHU_06));

        this.RAT_out_AHU_07 = this.predict_response['rat_AHU_07'] || Number(localStorage.getItem('RAT_out_AHU_07'));
        // this.RAT_out_AHU_07 = this.predict_input['rat_AHU_07'] || Number(localStorage.getItem('RAT_out_AHU_07'));
        localStorage.setItem('RAT_out_AHU_07', JSON.stringify(this.RAT_out_AHU_07));

        this.RAT_out_AHU_08 = this.predict_response['rat_AHU_08'] || Number(localStorage.getItem('RAT_out_AHU_08'));
        // this.RAT_out_AHU_08 = this.predict_input['rat_AHU_08'] || Number(localStorage.getItem('RAT_out_AHU_08'));
        localStorage.setItem('RAT_out_AHU_08', JSON.stringify(this.RAT_out_AHU_08));

        this.RAT_out_AHU_09 = this.predict_response['rat_AHU_09'] || Number(localStorage.getItem('RAT_out_AHU_09'));
        // this.RAT_out_AHU_09 = this.predict_input['rat_AHU_09'] || Number(localStorage.getItem('RAT_out_AHU_09'));
        localStorage.setItem('RAT_out_AHU_09', JSON.stringify(this.RAT_out_AHU_09));

        this.RAT_out_AHU_10 = this.predict_response['rat_AHU_10'] || Number(localStorage.getItem('RAT_out_AHU_10'));
        // this.RAT_out_AHU_10 = this.predict_input['rat_AHU_10'] || Number(localStorage.getItem('RAT_out_AHU_10'));
        localStorage.setItem('RAT_out_AHU_10', JSON.stringify(this.RAT_out_AHU_10));

        this.RAT_out_AHU_11 = this.predict_response['rat_AHU_11'] || Number(localStorage.getItem('RAT_out_AHU_11'));
        // this.RAT_out_AHU_11 = this.predict_input['rat_AHU_11'] || Number(localStorage.getItem('RAT_out_AHU_11'));
        localStorage.setItem('RAT_out_AHU_11', JSON.stringify(this.RAT_out_AHU_11));

        // this.RAT_out_AHU_12 = this.predict_response['rat_AHU_12'] || Number(localStorage.getItem('RAT_out_AHU_12'));
        this.RAT_out_AHU_12 = this.predict_input['rat_AHU_12'] || Number(localStorage.getItem('RAT_out_AHU_12'));
        localStorage.setItem('RAT_out_AHU_12', JSON.stringify(this.RAT_out_AHU_12));

        this.RAT_out_AHU_13 = this.predict_response['rat_AHU_13'] || Number(localStorage.getItem('RAT_out_AHU_13'));
        // this.RAT_out_AHU_13 = this.predict_input['rat_AHU_13'] || Number(localStorage.getItem('RAT_out_AHU_13'));
        localStorage.setItem('RAT_out_AHU_13', JSON.stringify(this.RAT_out_AHU_13));

        this.RAT_out_AHU_14 = this.predict_response['rat_AHU_14'] || Number(localStorage.getItem('RAT_out_AHU_14'));
        // this.RAT_out_AHU_14 = this.predict_input['rat_AHU_14'] || Number(localStorage.getItem('RAT_out_AHU_14'));
        localStorage.setItem('RAT_out_AHU_14', JSON.stringify(this.RAT_out_AHU_14));

        this.RAT_out_AHU_15 = this.predict_response['rat_AHU_15'] || Number(localStorage.getItem('RAT_out_AHU_15'));
        // this.RAT_out_AHU_15 = this.predict_input['rat_AHU_15'] || Number(localStorage.getItem('RAT_out_AHU_15'));
        localStorage.setItem('RAT_out_AHU_15', JSON.stringify(this.RAT_out_AHU_15));

        this.RAT_out_AHU_16 = this.predict_response['rat_AHU_16'] || Number(localStorage.getItem('RAT_out_AHU_16'));
        // this.RAT_out_AHU_16 = this.predict_input['rat_AHU_16'] || Number(localStorage.getItem('RAT_out_AHU_16'));
        localStorage.setItem('RAT_out_AHU_16', JSON.stringify(this.RAT_out_AHU_16));

        this.RAT_out_AHU_17 = this.predict_response['rat_AHU_17'] || Number(localStorage.getItem('RAT_out_AHU_17'));
        // this.RAT_out_AHU_17 = this.predict_input['rat_AHU_17'] || Number(localStorage.getItem('RAT_out_AHU_17'));
        localStorage.setItem('RAT_out_AHU_17', JSON.stringify(this.RAT_out_AHU_17));

        this.RAT_out_AHU_18 = this.predict_response['rat_AHU_18'] || Number(localStorage.getItem('RAT_out_AHU_18'));
        // this.RAT_out_AHU_18 = this.predict_input['rat_AHU_18'] || Number(localStorage.getItem('RAT_out_AHU_18'));
        localStorage.setItem('RAT_out_AHU_18', JSON.stringify(this.RAT_out_AHU_18));

        this.RAT_out_AHU_19 = this.predict_response['rat_AHU_19'] || Number(localStorage.getItem('RAT_out_AHU_19'));
        // this.RAT_out_AHU_19 = this.predict_input['rat_AHU_19'] || Number(localStorage.getItem('RAT_out_AHU_19'));
        localStorage.setItem('RAT_out_AHU_19', JSON.stringify(this.RAT_out_AHU_19));

        this.RAT_out_AHU_20 = this.predict_response['rat_AHU_20'] || Number(localStorage.getItem('RAT_out_AHU_20'));
        // this.RAT_out_AHU_20 = this.predict_input['rat_AHU_20'] || Number(localStorage.getItem('RAT_out_AHU_20'));
        localStorage.setItem('RAT_out_AHU_20', JSON.stringify(this.RAT_out_AHU_20));

        this.RAT_out_AHU_21 = this.predict_response['rat_AHU_21'] || Number(localStorage.getItem('RAT_out_AHU_21'));
        // this.RAT_out_AHU_21 = this.predict_input['rat_AHU_21'] || Number(localStorage.getItem('RAT_out_AHU_21'));
        localStorage.setItem('RAT_out_AHU_21', JSON.stringify(this.RAT_out_AHU_21));

        this.RAT_out_AHU_22 = this.predict_response['rat_AHU_22'] || Number(localStorage.getItem('RAT_out_AHU_22'));
        // this.RAT_out_AHU_22 = this.predict_input['rat_AHU_22'] || Number(localStorage.getItem('RAT_out_AHU_22'));
        localStorage.setItem('RAT_out_AHU_22', JSON.stringify(this.RAT_out_AHU_22));

        this.RAT_out_AHU_23 = this.predict_response['rat_AHU_23'] || Number(localStorage.getItem('RAT_out_AHU_23'));
        // this.RAT_out_AHU_23 = this.predict_input['rat_AHU_23'] || Number(localStorage.getItem('RAT_out_AHU_23'));
        localStorage.setItem('RAT_out_AHU_23', JSON.stringify(this.RAT_out_AHU_23));

        this.RAT_out_AHU_24 = this.predict_response['rat_AHU_24'] || Number(localStorage.getItem('RAT_out_AHU_24'));
        // this.RAT_out_AHU_24 = this.predict_input['rat_AHU_24'] || Number(localStorage.getItem('RAT_out_AHU_24'));
        localStorage.setItem('RAT_out_AHU_24', JSON.stringify(this.RAT_out_AHU_24));

        this.RAT_out_AHU_25 = this.predict_response['rat_AHU_25'] || Number(localStorage.getItem('RAT_out_AHU_25'));
        // this.RAT_out_AHU_25 = this.predict_input['rat_AHU_25'] || Number(localStorage.getItem('RAT_out_AHU_25'));
        localStorage.setItem('RAT_out_AHU_25', JSON.stringify(this.RAT_out_AHU_25));

        this.RAT_out_AHU_26 = this.predict_response['rat_AHU_26'] || Number(localStorage.getItem('RAT_out_AHU_26'));
        // this.RAT_out_AHU_26 = this.predict_input['rat_AHU_26'] || Number(localStorage.getItem('RAT_out_AHU_26'));
        localStorage.setItem('RAT_out_AHU_26', JSON.stringify(this.RAT_out_AHU_26));

        this.RAT_out_AHU_27 = this.predict_response['rat_AHU_27'] || Number(localStorage.getItem('RAT_out_AHU_27'));
        // this.RAT_out_AHU_27 = this.predict_input['rat_AHU_27'] || Number(localStorage.getItem('RAT_out_AHU_27'));
        localStorage.setItem('RAT_out_AHU_27', JSON.stringify(this.RAT_out_AHU_27));

        this.RAT_out_AHU_28 = this.predict_response['rat_AHU_28'] || Number(localStorage.getItem('RAT_out_AHU_28'));
        // this.RAT_out_AHU_28 = this.predict_input['rat_AHU_28'] || Number(localStorage.getItem('RAT_out_AHU_28'));
        localStorage.setItem('RAT_out_AHU_28', JSON.stringify(this.RAT_out_AHU_28));































        localStorage.setItem(this.id, JSON.stringify(this.jsonData));

        this.openSnackBar('Predicted Succesfully');

      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }




  async optimusData(id:string){
    const urlOptimus = "https://bpsliveathul.bertlabs.com/cassandra_api"
    try{
      const response = await this.http
      .post<any>(urlOptimus, {"id": id}, this.httpOptions)
      .toPromise();
      console.log(response);
      this.jsonData['optimus_data'] = response;
      if(id == this.id){
        localStorage.setItem(this.id, JSON.stringify(this.jsonData));
        this.optimus_data = response;
      }
    } catch(error){
      console.error('Error occured while getting optimus data', error);
    }
  }















}
