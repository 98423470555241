import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-six-comp',
  templateUrl: './ahu-six-comp.component.html',
  styleUrls: ['./ahu-six-comp.component.css']
})
export class AhuSixCompComponent {

}
