import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WaterMarkComponent } from '../water-mark/water-mark.component';


@Component({
  selector: 'Nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  imports: [MatButtonModule, MatDialogModule,WaterMarkComponent],
})
export class NavBarComponent {
  title: any;

  constructor(public dialog: MatDialog, private router: Router) {
    

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var endpoint=this.cropUrl(event.url);
        this.title = this.url_dictionary.get(endpoint);
      }
    });
  }

  showLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  cropUrl(url: string): string {
    var splitted = url.split('/');
    console.log(splitted);
    return splitted[splitted.length - 1];
  }


  url_dictionary = new Map<string, string>([
    ['home', 'Home'],
    ['olt-block', 'OLT Block'],
    ["chiller-plant","Chiller Plant"],

    // Strides AHUs and Chiller Plant
    ["AHU-01", "LMB-AHU-01-1000 CFM"],
    ["AHU-02", "LMB-AHU-02-1425 CFM"],
    ["AHU-03", "LMB-AHU-03-4675 CFM"],
    ["AHU-04", "LMB-AHU-04-4000 CFM"],
    ["AHU-05", "LMB-AHU-05-3675 CFM"],
    ["AHU-06", "LMB-AHU-06-4000 CFM"],
    ["AHU-07", "LMB-AHU-07-5800 CFM"],
    ["AHU-07-02", "LMB-AHU-07-5800 CFM"],
    ["AHU-08", "LMB-AHU-08-3675 CFM"],
    ["AHU-10", "LMB-AHU-10-4000 CFM"],  
    ["AHU-11", "LMB-AHU-11-8300 CFM"],
    ["AHU-12", "LMB-AHU-12 & eu-12-600 CFM"],
    ["chiller-plant", "Chiller Plant"],  
    ["chiller-plant2", "Chiller Plant 2"], 
    ["chiller1","Chiller-1"],
    ["chiller2","Chiller-2"],
    ["chiller3","Chiller-3"],
    ["chiller4","Chiller-4"],
    ["chiller-room","Chiller Room"],
    ["cooling-towers","Cooling Towers"],
    ["cooling-tower1","Cooling Tower1"],
    ["cooling-tower2","Cooling Tower2"],
    ["condenser_pumps","Condenser Pump"],
    ["primary_pumps","Primary Pump"],
    ["secondary_pumps","Secondary Pump"],
    ["view-all-errors","Fault Alarms"],
    ["table-data","Real time Data"]
    
    

  ]);
}
