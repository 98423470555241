import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-chillerplant-command',
  templateUrl: './chillerplant-command.component.html',
  styleUrls: ['./chillerplant-command.component.css']
})
export class ChillerplantCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  selectedComponent: string = 'Chillers';
  Chiller1_OnOff:number|undefined;
  componentStates: { [key: string]: any } = {};
  components: any[] = [];
  equipstatus: { [key: string]: string } = {};  
  mapinout: {[key:string]:string}={};
    constructor(
        private http:HttpClient,
        private webSocketService:WebSocketService){

    }

    ngOnInit():void{
        this.mapinout['Evap Valve Open FB CH 1']='Chiller1_EvapValve';
        this.mapinout['Evap Valve Open FB CH 2']='Chiller2_EvapValve';
        this.mapinout['Evap Valve Open FB CH 3']='Chiller3_EvapValve';
        this.mapinout['Evap Valve Open FB CH 4']='Chiller4_EvapValve';
        this.mapinout['Cond Valve Open FB CH 1']='Chiller1_CondValve';
        this.mapinout['Cond Valve Open FB CH 2']='Chiller2_CondValve';
        this.mapinout['Cond Valve Open FB CH 3']='Chiller3_CondValve';
        this.mapinout['Run Status CH 1']='Chiller1_OnOff';
        this.mapinout['Run Status CH 2']='Chiller2_OnOff'
        this.mapinout['Run Status CH 3']='Chiller3_OnOff'
        this.mapinout['Run Status CH 4']='Chiller4_OnOff'
        this.mapinout['Vfd1 run Status CT1']='Tower1_OnOff'
        this.mapinout['Vfd1 run Status CT2']='Tower2_OnOff'
        this.mapinout['VFD Run Status Cond Pump 1']='Condenser 1_OnOff'
        this.mapinout['VFD Run Status Cond Pump 2']='Condenser 2_OnOff'
        this.mapinout['VFD Run Status Cond Pump 3']='Condenser 3_OnOff'
        this.mapinout['VFD Run Status Cond Pump 4']='Condenser 4_OnOff'
        this.mapinout['VFD Run Status Pr Pump 1']='Primary 1_OnOff'
        this.mapinout['VFD Run Status Pr Pump 2']='Primary 2_OnOff'
        this.mapinout['VFD Run Status Pr Pump 3']='Primary 3_OnOff'
        this.mapinout['VFD Run Status Pr Pump 4']='Primary 4_OnOff'
        this.mapinout['VFD Run Status Sec P1']='Pump1_OnOff'
        this.mapinout['VFD Run Status Sec P2']='Pump2_OnOff'
        this.mapinout['VFD Run Status Sec P3']='Pump3_OnOff'
        this.mapinout['VFD Run Status Sec P4']='Pump4_OnOff'
        this.mapinout['VFD Run Status Sec P5']='Pump5_OnOff'
        this.mapinout['VFD Run Status Sec P6']='Pump6_OnOff'
        this.mapinout['VFD Run Status Sec P7']='Pump7_OnOff'
        this.mapinout['VFD Run Status Sec P8']='Pump8_OnOff'



        this.webSocketService.onMessage('onMessage').subscribe(message => {
            console.log('Received message:', message);
            console.log("hello show message",message.data.data.fan_speed);
            for (let key in message.data.data) {
                // Use the key to find the corresponding mapped value in mapinout
                let mappedKey = this.mapinout[key];
                
                // Check if the mapped value exists and is not null or undefined
                if (mappedKey !== undefined && mappedKey !== null && message.data.data[key] !== null && message.data.data[key] !== undefined) {
                  console.log(`Processing key: ${key}, value: ${message.data.data[key]}`);
                  if(message.data.data[key]==1){
                    this.equipstatus[mappedKey]='ON'
                  }
                  else{
                    this.equipstatus[mappedKey]='OFF'
                  }
                  // Proceed with processing the value
                } else {
                  console.log(`Skipping key: ${key} as it has no value or is not mapped.`);
                }
              }
          });
      
          this.webSocketService.sendMessage('eventName', { hello: 'world' });
    }

  showCommand() {
      if (this.command === 'bertlabs') {
          this.showDetails = true;
          this.onComponentChange();
      } else {
          alert('Wrong Command');
      }
  }
  getComponentState(componentName: string, key: string): any {
    return this.componentStates[`${componentName}_${key}`] || '';
  }

  // Setter for component state (used in ngModel binding)
  setComponentState(componentName: string, key: string, value: any): void {
    if(value=='OFF' || value=='CLOSE'){
        this.componentStates[`${componentName}_${key}`] = 0;

    }
    else if(value=='ON' || value=='OPEN'){
        this.componentStates[`${componentName}_${key}`] = 1;

    }
    else{
        this.componentStates[`${componentName}_${key}`]=value;
    }
  }

  onComponentChange() {
      switch (this.selectedComponent) {
          case 'Chillers':
              this.components = [
                  { name: 'Chiller1', hasValves: true, hasCondValves: true },
                  { name: 'Chiller2', hasValves: true, hasCondValves: true },
                  { name: 'Chiller3', hasValves: true, hasCondValves: true },
                  { name: 'Chiller4', hasValves: true, hasCondValves: false },
              ];
              this.components.forEach(component => {
                this.componentStates[`${component.name}_OnOff`] = undefined;
                this.componentStates[`${component.name}_EvapValve`] = undefined;
                this.componentStates[`${component.name}_CondValve`] = undefined;
                this.componentStates[`${component.name}_cwsp`] = undefined;

            });
            this.components.forEach(component => {
                // Initialize equipstatus for each component
                this.equipstatus[`${component.name}_OnOff`] = 'Not Known';
                this.equipstatus[`${component.name}_EvapValve`] = 'Not Known';
                this.equipstatus[`${component.name}_CondValve`] = 'Not Known';
                this.equipstatus[`${component.name}_cwsp`] = 'Not Known';
              });

              break;
          case 'Cooling Towers':
              this.components = [
                  { name: 'Tower1'},
                  { name: 'Tower2'},
              ];
              this.components.forEach(component => {
                this.componentStates[`${component.name}_FanOnOff`] = undefined;
                this.componentStates[`${component.name}_Fanvfd`] = undefined;
            });
            this.components.forEach(component => {
                // Initialize equipstatus for each component
                this.equipstatus[`${component.name}_FanOnOff`] = 'Not Known';
                this.equipstatus[`${component.name}_Fanvfd`] = 'Not Known';
              });
              break;
          case 'Condenser Pumps':
              this.components = [
                  { name: 'Condenser 1', hasValves: false },
                  { name: 'Condenser 2', hasValves: false },
                  { name: 'Condenser 3', hasValves: false },
                  { name: 'Condenser 4', hasValves: false },
              ];
              this.components.forEach(component => {
                this.componentStates[`${component.name}_OnOff`] = undefined;
                this.componentStates[`${component.name}_vfd`] = undefined;
            });

            this.components.forEach(component => {
                // Initialize equipstatus for each component
                this.equipstatus[`${component.name}_OnOff`] = 'Not Known';
                this.equipstatus[`${component.name}_vfd`] = 'Not Known';
              });
              break;
          case 'Primary Pumps':
              this.components = [
                  { name: 'Primary 1', hasValves: false },
                  { name: 'Primary 2', hasValves: false },
                  { name: 'Primary 3', hasValves: false },
                  { name: 'Primary 4', hasValves: false },
              ];
              this.components.forEach(component => {
                this.componentStates[`${component.name}_OnOff`] = undefined;
                this.componentStates[`${component.name}_vfd`] = undefined;
            });
            this.components.forEach(component => {
                // Initialize equipstatus for each component
                this.equipstatus[`${component.name}_OnOff`] = 'Not Known';
                this.equipstatus[`${component.name}_vfd`] = 'Not Known';
              });
              break;
          case 'Secondary Pumps':
              this.components = [
                  { name: 'Pump1', hasValves: false },
                  { name: 'Pump2', hasValves: false },
                  { name: 'Pump3', hasValves: false },
                  { name: 'Pump4', hasValves: false },
                  { name: 'Pump5', hasValves: false },
                  { name: 'Pump6', hasValves: false },
                  { name: 'Pump7', hasValves: false },
                  { name: 'Pump8', hasValves: false },
              ];
              this.components.forEach(component => {
                this.componentStates[`${component.name}_OnOff`] = undefined;
                this.componentStates[`${component.name}_vfd`] = undefined;
            });

            this.components.forEach(component => {
                // Initialize equipstatus for each component
                this.equipstatus[`${component.name}_OnOff`] = 'Not Known';
                this.equipstatus[`${component.name}_vfd`] = 'Not Known';
              });

              break;
          default:
              this.components = [];
              break;
      }
  }

  clearSelection() {
      this.components.forEach((component) => {
          component.selectedStatus = null;
          component.selectedEvapValve = null;
          component.selectedCondValve = null;
      });
  }
  getStatusColor(status: string): string {
    return status === 'ON' || status === 'OPEN' ? 'green' : status === 'OFF' || status === 'CLOSED' ? 'red' : 'black';
  }
  getid(name:any){
    return name[name.length-1];
  }

  getname(name:any){
    let newString = name.slice(0,-1);
    return newString;
    
  }

  sendCommand(id :any,name:any,status:any,totname:any,bodystatus:any){
   console.log(name);
    if(name=="Chiller"){
        const formattedId = id.toString().padStart(2, '0');  // Adds a leading zero if needed
        const id3=`CH_${formattedId}`
        // Construct the body string
        const bodyname = `${name.toLowerCase()}_${formattedId}_${status}`;
        const data = {
            client: 'strides',
            site: 'krsg',
            action: 4,
            id1:"SA_OLT",
            id2:"CP",
            id3:id3,
            
            body: {
                [bodyname]:this.componentStates[`${totname}_${bodystatus}`]
            }
          };
          console.log(data)
    
          this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
            (response)=>{
             
              console.log('post response',response);
              console.log(data);
    
            },
            (error)=>{
              
              console.log('post response',error);
              console.log(data);
    
            }
          )
    }

    else if(name=="Tower"){
        const formattedId = id.toString().padStart(2, '0');  // Adds a leading zero if needed
        const id3=`CTF_${formattedId}`
        // Construct the body string
        const bodyname = `cooling_${name.toLowerCase()}_${formattedId}_${status}`;
        const data = {
            client: 'strides',
            site: 'krsg',
            action: 4,
            id1:"SA_OLT",
            id2:"CT",
            id3:id3,
            
            body: {
                [bodyname]:this.componentStates[`${totname}_${bodystatus}`]
            }
          };
          console.log(data)
    
          this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
            (response)=>{
             
              console.log('post response',response);
              console.log(data);
    
            },
            (error)=>{
              
              console.log('post response',error);
              console.log(data);
    
            }
          )
    }
    else if(name=="Pump"){
        const formattedId = id.toString().padStart(2, '0');  // Adds a leading zero if needed
        const id3=`SP_${formattedId}`
        // Construct the body string
        const bodyname = `secondary_${name.toLowerCase()}_${formattedId}_${status}`;
        const data = {
            client: 'strides',
            site: 'krsg',
            action: 4,
            id1:"SA_OLT",
            id2:"PUMP",
            id3:id3,
            
            body: {
                [bodyname]:this.componentStates[`${totname}_${bodystatus}`]
            }
          };
          console.log(data)
    
          this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
            (response)=>{
             
              console.log('post response',response);
              console.log(data);
    
            },
            (error)=>{
              
              console.log('post response',error);
              console.log(data);
    
            }
          )

    }

    else if(name=="Primary "){
        name = name.slice(0,-1);
        const formattedId = id.toString().padStart(2, '0');  // Adds a leading zero if needed
        const id3=`PP_${formattedId}`
        // Construct the body string
        const bodyname = `${name.toLowerCase()}_pump_${formattedId}_${status}`;
        const data = {
            client: 'strides',
            site: 'krsg',
            action: 4,
            id1:"SA_OLT",
            id2:"PUMP",
            id3:id3,
            
            body: {
                [bodyname]:this.componentStates[`${totname}_${bodystatus}`]
            }
          };
          console.log(data)
    
          this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
            (response)=>{
             
              console.log('post response',response);
              console.log(data);
    
            },
            (error)=>{
              
              console.log('post response',error);
              console.log(data);
    
            }
          )
    }
    //for primary and condenser wantedly there is gap while comapring with name ,which i am removing here
    else if(name=="Condenser "){
        name = name.slice(0,-1);
        const formattedId = id.toString().padStart(2, '0');  // Adds a leading zero if needed
        const id3=`CP_${formattedId}`
        // Construct the body string
        const bodyname = `${name.toLowerCase()}_pump_${formattedId}_${status}`;
        const data = {
            client: 'strides',
            site: 'krsg',
            action: 4,
            id1:"SA_OLT",
            id2:"CT",
            id3:id3,
            
            body: {
                [bodyname]:this.componentStates[`${totname}_${bodystatus}`]
            }
          };
          console.log(data)
    
          this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
            (response)=>{
             
              console.log('post response',response);
              console.log(data);
    
            },
            (error)=>{
              
              console.log('post response',error);
              console.log(data);
    
            }
          )
    }
    
      
    
      
    

   

   
  }


}
