<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img src="/assets/strides-images/cond pumps.svg" class="fmap">



                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 48.8%; left: 9%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_for_condenser_pump_4_in | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_4_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 6.2%; left: 67.7%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_for_condenser_pump_2_in | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_2_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                      </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 6%; left: 41.3%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_for_condenser_pump_3_in | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_3_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 47.2%; left: 92.2%; width: 3%; height: 3%;">
                        <input readonly   style="color: #D90606; font-weight:600" [ngModel]=" vfd_for_condenser_pump_1_in | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_1_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                      </div>

                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 48.8%; left: 15.6%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_4_in | number: '1.2-2'" (ngModelChange)=" power_4_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 6.2%; left: 74.3%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_2_in | number: '1.2-2'" (ngModelChange)=" power_2_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                      </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 5.7%; left: 47.9%; width: 3%; height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_3_in | number: '1.2-2'" (ngModelChange)=" power_3_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 47%; left: 98.6%; width: 3%; height: 3%;">
                        <input readonly   style="color: #D90606; font-weight:600" [ngModel]=" power_1_in | number: '1.2-2'" (ngModelChange)=" power_1_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                      </div>



                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 44.8%; left: 9%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_for_condenser_pump_4_out | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_4_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>


                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 2%; left: 67.7%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_for_condenser_pump_2_out | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_2_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 1.8%; left: 41.3%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_for_condenser_pump_3_out | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_3_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 43%; left: 92.2%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_for_condenser_pump_1_out | number: '1.2-2'" (ngModelChange)=" vfd_for_condenser_pump_1_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 44.6%; left: 15.6%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" power_4_out | number: '1.2-2'" (ngModelChange)=" power_4_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>


                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 1.8%; left: 74.3%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" power_2_out | number: '1.2-2'" (ngModelChange)=" power_2_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 1.6%; left: 47.8%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" power_3_out | number: '1.2-2'" (ngModelChange)=" power_3_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>

                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 42.8%; left: 98.6%; width: 3%; height: 3%;">
                      <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" power_1_out | number: '1.2-2'" (ngModelChange)=" power_1_out = $event" />
                      <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
