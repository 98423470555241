import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hyper-lobby',
  templateUrl: './hyper-lobby.component.html',
  styleUrls: ['./hyper-lobby.component.css']
})
export class HyperLobbyComponent {
  type!: number;
  RAT: number;
  KPI:number=0;
RAT_out: any;
RAT_out_AHU_08: any;
RAT_out_AHU_25: any;
Zone_temp:number=0;
power_ahu_08:number=0;
power_ahu_25:number=0;
BASELINE_HYPER_LOBBY:number=0;
POWER_HYPER_LOBBY: number=0;


constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_08 = Number(localStorage.getItem('RAT_out_AHU_08')) || 0;
  localStorage.setItem('RAT_out_AHU_08', JSON.stringify(this.RAT_out_AHU_08));
  console.log("RAT_AHU_08_output_zone_one", this.RAT_out_AHU_08 );

  this.RAT_out_AHU_25 = Number(localStorage.getItem('RAT_out_AHU_25')) || 0;
  localStorage.setItem('RAT_out_AHU_25', JSON.stringify(this.RAT_out_AHU_25));
  console.log("RAT_AHU_25_output_zone_one", this.RAT_out_AHU_25 );



  this.Zone_temp = ((this.RAT_out_AHU_08+this.RAT_out_AHU_25)/2);


  this.power_ahu_08 = Number(localStorage.getItem('Power_AHU_08_out')) || 0;
  localStorage.setItem('power_ahu_08', JSON.stringify('power_ahu_08'));
  console.log("Power_AHU_08_out", this.power_ahu_08);

  this.power_ahu_25 = Number(localStorage.getItem('Power_AHU_25_out')) || 0;
  localStorage.setItem('power_ahu_25', JSON.stringify('power_ahu_25'));
  console.log("Power_AHU_25_out", this.power_ahu_25);

  this.BASELINE_HYPER_LOBBY = 12.258;
  this.POWER_HYPER_LOBBY=(this.power_ahu_08+this.power_ahu_25);

  

  if(this.POWER_HYPER_LOBBY){
    this.KPI = ((this.BASELINE_HYPER_LOBBY-this.POWER_HYPER_LOBBY)/this.BASELINE_HYPER_LOBBY)*100;
  localStorage.setItem('kpi_hyper_lobby', JSON.stringify('KPI'));
  }
  else{
    this.KPI = 0 ;
  }





 }
}
