import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-eleven',
  templateUrl: './zone-eleven.component.html',
  styleUrls: ['./zone-eleven.component.css']
})
export class ZoneElevenComponent {
  type!: number;
  RAT: number;
  KPI: number=0;
RAT_out: any;
Zone_temp:number=0;


  constructor(private router: Router) {
    this.RAT = 0;
   }
}
