<div class="snackbar-container" [ngClass]="{ minimized: isMinimized }">
  
  <div *ngIf="!isMinimized" class="snackbar-header">
    <span class="icon material-icons" (click)="toggleMinimize()">{{ isMinimized ? 'expand_less' : 'expand_more' }}</span>
    <span class="icon material-icons" (click)="dismissAll()">close</span>
  </div>

  <div *ngIf="!isMinimized" class="snackbar-content">
    <div *ngFor="let error of data.message" class="message-box" [ngClass]="{ closed: !error.isActive }">
      <div class="message-content">
        <p class="message-title">{{ error.title }}</p>
        <p class="message-time">{{ error.time }}</p>
        <p class="message-description">{{ error.description }}</p>
      </div>
      <button class="close-btn" [disabled]="!error.isActive" (click)="closeMessage(error)">
        {{ !error.isActive ? 'Closed' : 'Close' }}
      </button>
    </div>
  </div>

  <div *ngIf="isMinimized" class="minimized-text">
    Alarms raised
    <span class="icon material-icons" (click)="toggleMinimize()">expand_less</span>
    <span class="icon material-icons" (click)="dismissAll()">close</span>
  </div>
</div>
