<div style="position: relative; height: 100%; width: 100%;">
  <div  class="main_container">
      <div class="content-container">
          <div class="upcontainer">
              <div class="map-container">
                <app-role-selection *ngIf="isfirstimeuser()"></app-role-selection>
                <!-- <app-role-selection ></app-role-selection> -->
                <app-role-selection *ngIf="islessthan1day"></app-role-selection>

              <img src="/assets/strides-images/Home.svg" class="fmap">

              <div class="component" style="width: 9%; height: 12%; top: 37.5%;left: 62%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../chiller-room">
                  Chillers(WC)
                </button>
              </div>

              <div class="component" style="width: 9%; height: 12%; top: 1.5%;left: 42%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../AHU-01">
                  AHU Room
                </button>
              </div>


              <div class="component" style="width: 9%; height: 12%; top: 26%;left: 57%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../condenser_pumps">
                  Cond.Pumps
                </button>
              </div>

              <div class="component" style="width: 9%; height: 12%; top: 19%;left: 32%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../cooling-towers">
                  Cooling Towers
                </button>
              </div>

              <div class="component" style="width: 9%; height: 12%; top: 15.5%;left: 7%;  font-size: 120%;">
                <button class="hbtn"
                    routerLink="../chiller4">
                  Chiller(AC)
                </button>
              </div>

              <div class="component" style="width: 9%; height: 12%; top: 47.5%;left: 15%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../secondary_pumps">
                  Sec.Pumps
                </button>
              </div>

              <div class="component" style="width: 9%; height: 12%; top: 52.5%;left: 38%;  font-size: 120%;">
                <button class="hbtn"  routerLink="../primary_pumps">
                  Primary Pumps
                </button>
              </div>






          </div>


          <div class="form component">
            <span class="span-text-dark" style="font-family: 'Mulish'; margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>

            <div style="height: 40px;"></div>
            <div  class="hvac-text">
            <span class="span-text-dark" style="font-family: 'Mulish';font-size: 100%; font-weight: 500">Overall HVAC Saving</span>
            <div style="width:25%; ">
                <input style="font-family: 'Mulish';width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                [ngModel]="KPI | number : '1.2-2'"
                (ngModelChange)="KPI = $event"
                readonly />
            </div>
            <span style="margin-left:-10%; font-size: 100%;">%</span>
            </div>
        </div>


          </div>
      </div>
  </div>

</div>
