<div
  class="side-bar-container"
  style="display: flex; flex-direction: row;  height: 100%;  font-family:Mulish;
"
>
  <div class="main-side-bar">
    <div style="color: white; display: flex; flex-direction: column">
      <div class="icon-container" (click)="drawer.toggle();" matTooltip="Menu" matTooltipPosition="right" matTooltipClass="custom-tooltip"> 
        <mat-icon>menu</mat-icon>
      </div>
      <div class="icon-container" routerLink="home" (click)="homeTab(drawer)" matTooltip="Home" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <mat-icon>home</mat-icon>
      </div>

      <div class="icon-container" (click)="goback()" matTooltip="To back" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <mat-icon>reply</mat-icon>
      </div>

      <div class="icon-container" routerLink="analytics" matTooltip="Analytics" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <img src="assets/updated icons/Historic data.svg" alt="Analytics Icon" class="custom-icon">
      </div>

      <div class="icon-container" routerLink="commands" matTooltip="Commands" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <img src="assets/updated icons/control command.svg" alt="Command Icon" class="custom-icon">
      </div>

      <!-- Add routerlinks -->
      <div class="icon-container" routerLink="table-data" matTooltip="Real Time Data" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <img src="assets/updated icons/Realtime data.svg" alt="realtime Icon" class="custom-icon">
      </div>

      <!--  -->
      <div *ngIf="isAdmin()" class="icon-container" routerLink="admin-log" matTooltip="Admin-log" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <img src="assets/updated icons/admin log.svg" alt="admin log" class="custom-icon">
      </div>
      <!-- <div class="icon-container">
        <mat-icon>code</mat-icon>
      </div>

      <div class="icon-container">
        <mat-icon>notifications</mat-icon>
      </div> -->
    </div>

    <div
      style="
        border-top: 1px solid white;
        color: white;
        display: flex;
        flex-direction: column;
      "
    >
      <div class="icon-container">
        <mat-icon    [matMenuTriggerFor]="settingsMenu"  matTooltipPosition="right"  matTooltip="Settings">settings_outline</mat-icon>
      </div>

      <div 
      [matMenuTriggerFor]="alarmMenu" 
      class="icon-container" 
      matTooltip="Fault Alarms" 
      matTooltipPosition="right" 
      matTooltipClass="custom-tooltip">
    
      <!-- Notification Icon with mat-badge and dynamic color -->
      <mat-icon 
        [matBadge]="getnoalarms() > 0 ? getnoalarms() : ''"
        matBadgePosition="above after"
        matBadgeColor="warn"
        [style.color]="getnoalarms() > 0 ? 'yellow' : 'inherit'">
        notifications
      </mat-icon>
    </div>
    
    
      <div class="icon-container" (click)="showLogoutDialog()" matTooltip="Logout" matTooltipPosition="right" matTooltipClass="custom-tooltip">
        <mat-icon>logout</mat-icon>
      </div>
    </div>
  </div>

  <mat-menu #settingsMenu="matMenu">
    <button *ngIf="isAdmin()" mat-menu-item routerLink="manageusers"> <mat-icon>person</mat-icon> <span class="menu-text">Manage Roles</span></button>
    <button *ngIf="isAdmin()" mat-menu-item routerLink="viewusers" >  <mat-icon>group</mat-icon> <!-- Icon for "View Users" -->
      <span class="menu-text">Manage Users</span></button>
  </mat-menu>

  <mat-menu #alarmMenu="matMenu">
    <button mat-menu-item routerLink="view-all-errors">  <span class="menu-text">fault alarms</span></button>
    <button  mat-menu-item routerLink="alarm-log" >   <span class="menu-text">alarm logs</span></button>
  </mat-menu>

  <mat-drawer-container class="drawer-container">
    <mat-drawer #drawer class="drawer" mode="side" [opened]="showNavbar">
      <div style="font-family: Mulish; font-style:bold;" class=" drawer-content">
        <div style="font-weight: bold;  font-family: Mulish;" class="drawer-header">Dashboard</div>
        <mat-divider></mat-divider>

        <mat-action-list >
          <ng-container *ngFor="let item of items">

            <button style="font-family: Mulish" mat-list-item
            (click)="handleclick(item) ? drawer.toggle() : null"
            [routerLink]="item.route"
            [ngStyle]="{'font-family': 'Mulish'}"
            [ngClass]="{'selected-item': selectedItem === item}">
              <span style="font-weight: bold;" class="span-text-dark">{{ item.title }}</span>
              <!-- <mat-icon class="arrow-icon" >
                {{ selectedItem === item && 'keyboard_arrow_right'  }}
              </mat-icon> -->
              <mat-icon  class="arrow-icon" *ngIf="item.isExpandable && item.subComponents && item.subComponents.length" style="margin-right: 10%;">
                {{ item.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>

            </button>


            <mat-action-list *ngIf="item.expanded && item.subComponents && item.subComponents.length">
              <ng-container *ngFor="let subComponent of item.subComponents">
                <button
                  mat-list-item
                  [disabled]="subComponent.disabled"
                  (click)="toggleExpanded(subComponent); selectedItem = subComponent"
                  [routerLink]="subComponent.route"
                  [ngClass]="{'selected-item': selectedItem === subComponent}"
                  style="margin-left: 30px; width:285px; "
                >
                <div style="font-family: Mulish;display: flex; flex-direction: row; justify-content: space-between;">
                  <span style="font-weight: bold;" class="span-text-dark">{{ subComponent.title }}</span>
                  <mat-icon style="margin-right: 25px;" *ngIf="subComponent.isExpandable && subComponent.subSubComponents && subComponent.subSubComponents.length">
                    {{ subComponent.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </mat-icon>
                </div>
                </button>
                <mat-action-list *ngIf="subComponent.isExpandable && subComponent.expanded">
                  <ng-container *ngFor="let subSubComponent of subComponent.subSubComponents ">
                    <button
                    mat-list-item
                    [disabled]="subSubComponent.disabled"
                    (click)="toggleExpanded(subSubComponent); selectedItem = subSubComponent"
                    [routerLink]="subSubComponent.route"
                    style="margin-left: 60px;"
                  >
                      <span class="span-text-dark">{{ subSubComponent.title }}</span>
                    </button>
                  </ng-container>
                </mat-action-list>
              </ng-container>
            </mat-action-list>

        </ng-container>
        </mat-action-list>
      </div>
    </mat-drawer>
  </mat-drawer-container>

</div>
