<div class="main-container">

  <div class="add-roles-section">
    <h2> {{ismodifying ?'Modify Selected Roles':'Add New Roles'}}</h2>
    <div class="form-group">
      <label for="roleName">Role Name</label>
      <input type="text" id="roleName" [(ngModel)]="roleName" placeholder="Type new Role" [readOnly]="ismodifying" >
    </div>

    <div class="form-group">
      <label for="description">Role Description</label>
      <input type="text" id="description" [(ngModel)]="description" placeholder="Enter Role Description" >
    </div>

    <div class="form-group">
      <label for="RoleStatus">Role Status</label>
      <select id="RoleStatus" class="RoleStatus" [(ngModel)]="roleStatus" [ngClass]="{'placeholder': roleStatus === ''}">
        <option value="" disabled hidden>Select Role Status</option> <!-- Placeholder option -->
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
        <option value="pending">Pending</option>
      </select>
    </div>
    
    
    <h2>Role Permissions</h2>
    <p class="subtext">Add Permission for the roles</p>
    
    <div class="permissions-list">
      <!-- <div class="permission-item">
        <label>AHUs</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUsReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUsReadOnly']" 
            [disabled]="isReadOnlyDisabled('AHUs')" 
            [class.disabled]="isDisabledClass('AHUs')" />
            Read
          </label>
        </div>
      </div> -->

      <div class="permission-item">
        <label>AHUs</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahu'] === 1"
                   (change)="onPermissionChange('ahu', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahu'] === 0"
                   [disabled]="rolePermissions['ahu'] === 1"
                   (change)="onPermissionChange('ahu', 0)" />

            Read
          </label>
        </div>
      </div>
      <div class="permission-item">
        <label>Chiller Plant</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['chiller'] === 1"
                   
                   (change)="onPermissionChange('chiller', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['chiller'] === 0"
                   [disabled]="rolePermissions['chiller'] === 1"
                   (change)="onPermissionChange('chiller', 0)" />
            Read
          </label>
        </div>
      </div>

      <!-- <div class="permission-item">
        <label>Chillers</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['ChillersReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['ChillersReadOnly']" 
            [disabled]="isReadOnlyDisabled('Chillers')" 
            [class.disabled]="isDisabledClass('Chillers')" />
            Read
          </label>
        </div>
      </div> -->
      <!-- <div class="permission-item">
        <label>Cooling Towers</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['coolingtower'] === 1"
                   (change)="onPermissionChange('coolingtower', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['coolingtower'] === 0"
                   (change)="onPermissionChange('coolingtower', 0)" />
            Read
          </label>
        </div>
      </div> -->
      <!-- <div class="permission-item">
        <label>Cooling Towers</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['CoolingTowersReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['CoolingTowersReadOnly']" 
            [disabled]="isReadOnlyDisabled('CoolingTowers')" 
            [class.disabled]="isDisabledClass('CoolingTowers')"/>
            Read
          </label>
        </div>
      </div> -->

      <!-- <div class="permission-item">
        <label>Pumps</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['pumps'] === 1"
                   (change)="onPermissionChange('pumps', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['pumps'] === 0"
                   (change)="onPermissionChange('pumps', 0)" />
            Read
          </label>
        </div>
      </div> -->
<!-- 
      <div class="permission-item">
        <label>Pumps</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['PumpsReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['PumpsReadOnly']" 
            [disabled]="isReadOnlyDisabled('Pumps')" 
            [class.disabled]="isDisabledClass('Pumps')"/>
            Read
          </label>
        </div>
      </div> -->

      <!-- Additional Permissions -->
      <div class="permission-item">
        <label>AHU Control</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahucontrol'] === 1"
                   (change)="onPermissionChange('ahucontrol', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahucontrol'] === 0"
                   [disabled]="rolePermissions['ahucontrol'] === 1"
                   (change)="onPermissionChange('ahucontrol', 0)" />
            Read
          </label>
        </div>
      </div>
      <!-- <div class="permission-item">
        <label>AHU Control</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUControlReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUControlReadOnly']" 
            [disabled]="isReadOnlyDisabled('AHUControl')" 
            [class.disabled]="isDisabledClass('AHUControl')"/>
            Read
          </label>
        </div>
      </div> -->
      <div class="permission-item">
        <label>PID Tuning</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['pidtuning'] === 1"
                   
                   (change)="onPermissionChange('pidtuning', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['pidtuning'] === 0"
                   [disabled]="rolePermissions['pidtuning'] === 1"
                   (change)="onPermissionChange('pidtuning', 0)" />
            Read
          </label>
        </div>
      </div>
      <!-- <div class="permission-item">
        <label>PID Tuning</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['PIDTuningReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['PIDTuningReadOnly']" 
            [disabled]="isReadOnlyDisabled('PIDTuning')" 
            [class.disabled]="isDisabledClass('PIDTuning')"/>
            Read
          </label>
        </div>
      </div> -->
      <div class="permission-item">
        <label>AHU Schedule</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahuschedule'] === 1"
                   (change)="onPermissionChange('ahuschedule', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['ahuschedule'] === 0"
                   [disabled]="rolePermissions['ahuschedule'] === 1"

                   (change)="onPermissionChange('ahuschedule', 0)" />
            Read
          </label>
        </div>
      </div>
      <!-- <div class="permission-item">
        <label>AHU Schedule</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUScheduleReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['AHUScheduleReadOnly']" 
            [disabled]="isReadOnlyDisabled('AHUSchedule')" 
            [class.disabled]="isDisabledClass('AHUSchedule')"/>
            Read
          </label>
        </div>
      </div> -->

      <div class="permission-item">
        <label>Chiller Sequencing</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['chillersequencing'] === 1"
                   (change)="onPermissionChange('chillersequencing', 1)" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" 
                   [checked]="rolePermissions['chillersequencing'] === 0"
                   [disabled]="rolePermissions['chillersequencing'] === 1"
                   (change)="onPermissionChange('chillersequencing', 0)" />
            Read
          </label>
        </div>
      </div>

      <!-- <div class="permission-item">
        <label>Chiller Sequencing</label>
        <div class="permission-options">
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['ChillerSequencingReadWrite']" />
            Read & Write
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="rolePermissions['ChillerSequencingReadOnly']" 
            [disabled]="isReadOnlyDisabled('ChillerSequencing')" 
            [class.disabled]="isDisabledClass('ChillerSequencing')"/>
            Read
          </label>
        </div>
      </div> -->
    </div>

    <div class="button-group">
      <button (click)="submitRole()" class="save-btn">{{ismodifying?'Modify':'Save'}}</button>
      <button (click)="cancelRole()" class="cancel-btn">Cancel</button>
    </div>
  </div>

  <div class="roles-details-section">
    <h2>Roles and Details</h2>
    <p class="subtext">List of Roles and their permissions</p>
    
    <div class="role-list">
      <div class="role-item" *ngFor="let role of roleDataPacket" style="margin-bottom: 20px;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <!-- Role name on the left -->
          <h4 [ngClass]="{ 'admin-role': role.roleName === 'admin' }">
            {{ role.roleName.charAt(0).toUpperCase() + role.roleName.slice(1) }}
          </h4>
    
          <!-- Menu (three dots) on the right -->
          <div *ngIf="!isroleadmin(role)" class="mat-menu-container">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_horiz</mat-icon>
            </button>
    
            <!-- Menu reference -->
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editRole(role)">Modify</button>
              <button mat-menu-item (click)="deleteRole(role)">Delete</button>
            </mat-menu>
          </div>
        </div>
    
        <!-- Permissions below the role name -->
        <p style="margin: 5px 0 0 0;">
          Permission for {{ getPermissionsDisplay(role.permissions) }}
        </p>
      </div>
    </div>
    
    
        
        
        
      </div>
    </div>


