<div class="container">
  <div class="title">Add New User</div>
  <form  [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <!-- First Name and Last Name -->
    <div class="form-group nameclass">
      <div>   
        <label>First Name</label>
        <input formControlName="firstName" placeholder="Enter First Name" /></div>
      <div> 
        <label>Last Name</label>
        <input formControlName="lastName" placeholder="Enter Last Name" />
      </div>
    </div>
   
     

    <!-- Job Role -->
    <div class="form-group">
      <label>Job Role</label>
      <select formControlName="jobRole">
        <option value="engineer">Engineer</option>
        <option value="monitor">Monitor</option>
      </select>
    </div>

    <!-- Email Address -->
    <div class="form-group">
      <label>Email Address</label>
      <input formControlName="email" placeholder="Enter Work Email Address" />
    </div>

    <!-- Mobile Number -->
    <div class="form-group">
      <label>Mobile Number</label>
      <input formControlName="mobile" placeholder="Enter Mobile Number" />
    </div>

    <!-- Username -->
    <div class="form-group">
      <label>User Name</label>
      <input formControlName="username" placeholder="Type Username" />
    </div>

    <!-- Password -->
    <div class="form-group">
      <label>User Password</label>
      <input
        type="password"
        formControlName="password"
        placeholder="Min 8 Characters"
      />
    </div>

    <!-- Error Messages for Password Validation -->
    <div *ngIf="showvalid()" class="validation-messages">
      <div [ngClass]="{'valid': !hasMinLengthError(), 'invalid': hasMinLengthError()}">
        * Minimum 8 characters
      </div>
      <div [ngClass]="{'valid': !hasUppercaseError(), 'invalid': hasUppercaseError()}">
        * At least 1 uppercase letter
      </div>
      <div [ngClass]="{'valid': !hasLowercaseError(), 'invalid': hasLowercaseError()}">
        * At least 1 lowercase letter
      </div>
      <div [ngClass]="{'valid': !hasNumberError(), 'invalid': hasNumberError()}">
        * At least 1 number
      </div>
      <div [ngClass]="{'valid': !hasSpecialCharError(), 'invalid': hasSpecialCharError()}">
        * At least 1 special character
      </div>
    </div>

    <!-- Buttons -->
    <div class="form-buttons">
      <button type="submit" [disabled]="userForm.invalid">Submit</button>
      <button type="button" class="cancelbutton" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
