import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'moonshot-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, NgIf],
  styleUrls: ['./logout-dialog.component.css'],
})
export class LogoutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private router: Router,
    private authservice:AuthService,
    private http:HttpClient
  ) {}

  logout(): void {
   
    this.http.post<any>(`${environment.apiBaseUrl}/auth/logout`,{}).subscribe(
      (response)=>{
        console.log(response);
      },
      (error)=>{

      }
    )
    sessionStorage.clear();
    // localStorage.clear();
    this.authservice.logout();
    this.router.navigate(['/login']);
  }
}
