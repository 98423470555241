<div class="ahu-seven">

    
   
      <svg id="ahu-seven" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 243.97 335.24">
        <g id="Component_179_1" data-name="Component 179 – 1" transform="translate(1.5 1.508)">
          <g id="Group_4648" data-name="Group 4648" transform="translate(-13074.344 20033.514)">
            <path id="Path_11365" data-name="Path 11365" d="M13297.105-21109.42c-3.452-.209-34.85,0-34.85,0s-2.945-.268-2.912,4.207.091,79.66.091,79.66.113,4.4,4.341,4.422,13.223,0,13.223,0l-.271,45.176a4.149,4.149,0,0,0-3.379,4.277c-.085,3.811.186,122.68.186,122.68a3.134,3.134,0,0,0,3.221,3.381c3.434.1,20.837,0,20.837,0s2.943.09,2.949-3.6-.012-256.795-.012-256.795A3.42,3.42,0,0,0,13297.105-21109.42Z" transform="translate(-185 1076)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
            <path id="Path_11366" data-name="Path 11366" d="M13346.233-20928.174v-36.881s.2-3.375-3.842-3.4-34.02,0-34.02,0a3,3,0,0,0-2.972,3.115c-.086,3.117.059,116.281.059,116.281a3.433,3.433,0,0,0,3.486,3.7c3.7.1,38.863,0,38.863,0l-.024,64.2s0,3.939,3.732,3.879,41.371-.035,41.371-.035a3.389,3.389,0,0,0,3.025-3.627c.084-3.445,0-20.645,0-20.645l12.963.258a2.394,2.394,0,0,0,1.925.814c1.26-.051,83.574-.373,83.574-.373s3.108.1,3.148-3.5-.073-35.689-.073-35.689a3.439,3.439,0,0,0-3.667-3.354c-3.645-.062-53.854-.139-53.854-.139l.419-42.244,53.633.131s3.521-.154,3.589-3.156-.121-36.342-.121-36.342-.07-3.057-3.716-3.129S13346.233-20928.174,13346.233-20928.174Z" transform="translate(-185 1076)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
            <text id="AHU7_" data-name="AHU7
      " transform="translate(13155 -19783)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="20.048" y="14">AHU7</tspan><tspan x="0" y="32"></tspan></text>
            <text id="Air_Lock_2" data-name="Air Lock 2" transform="translate(13119.001 -19791.703)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="4" y="8">Air Lock 2</tspan></text>
            <text id="Air_Lock_1" data-name="Air Lock 1" transform="translate(13118.998 -19842.186)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="4" y="8">Air Lock 1</tspan></text>
            <text id="Prod._Office" data-name="Prod. 
      Office" transform="translate(13117.998 -19884.377)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="12.396" y="8">Prod. </tspan><tspan x="10" y="18">Office</tspan></text>
            <text id="Visitor_change_room" data-name="Visitor change room" transform="translate(13175.998 -19841)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="6.044" y="8">Visitor change </tspan><tspan x="19.344" y="18">room</tspan></text>
            <text id="Change_room_2" data-name="Change 
      room 2" transform="translate(13175.314 -19754.99)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="14.276" y="8">Change </tspan><tspan x="15.764" y="18">room 2</tspan></text>
            <text id="Linen" transform="translate(13213.912 -19800.377)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="9.86" y="8">Linen</tspan></text>
            <text id="Change_room_1" data-name="Change 
      room 1" transform="translate(13252.813 -19754.99)" fill="#606060" font-size="8" font-family="Mulish, sans-serif" font-weight="300"><tspan x="14.276" y="8">Change </tspan><tspan x="15.764" y="18">room 1</tspan></text>
            <path id="Path_11367" data-name="Path 11367" d="M0,27.7H0V21.25l12.066.3s-.494-5.2-3.3-8.159S0,9.692,0,9.692V3.325" transform="translate(13114.998 -19878.889)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11368" data-name="Path 11368" d="M0,27.7H0V21.25l12.066.3s-.494-5.2-3.3-8.159S0,9.692,0,9.692V3.325" transform="translate(13114.998 -19816.889)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11369" data-name="Path 11369" d="M0,3.325H0V9.771l12.066-.3s-.494,5.2-3.3,8.159S0,21.33,0,21.33V27.7" transform="translate(13114.998 -19834.512)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11370" data-name="Path 11370" d="M12.066,27.7h0V21.25L0,21.548s.494-5.2,3.3-8.159,8.761-3.7,8.761-3.7V3.325" transform="translate(13150.998 -19816.889)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11371" data-name="Path 11371" d="M12.066,3.325h0V9.771L0,9.474s.494,5.2,3.3,8.159,8.761,3.7,8.761,3.7V27.7" transform="translate(13150.998 -19834.512)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11372" data-name="Path 11372" d="M1093.065-21229.191h-40.347" transform="translate(12067.106 1378)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11373" data-name="Path 11373" d="M1194.834-21229.191H1052.718" transform="translate(12067 1419)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11374" data-name="Path 11374" d="M12.066,5.676v4.1L0,9.474s.494,5.2,3.3,8.159,8.761,3.7,8.761,3.7V27.7" transform="translate(13241.998 -19856.512)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11375" data-name="Path 11375" d="M1072.477-21229.2l-19.759,0" transform="translate(-7974.432 -20818.91) rotate(90)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11376" data-name="Path 11376" d="M1071.719-21229.191h-19" transform="translate(-7975.127 -20881.91) rotate(90)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11377" data-name="Path 11377" d="M0,25.82H0V23.6l12.066.3s-.494-5.2-3.3-8.16S0,12.043,0,12.043V5.676" transform="translate(13254.76 -19752.176)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11378" data-name="Path 11378" d="M12.066,5.676v4.1L0,9.474s.494,5.2,3.3,8.159,8.761,3.7,8.761,3.7V27.7" transform="translate(13250.14 -19755.158) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11379" data-name="Path 11379" d="M12.066,22.021v-4.1L0,18.223s.494-5.2,3.3-8.159,8.762-3.7,8.762-3.7V0" transform="translate(13277.836 -19821.188) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11381" data-name="Path 11381" d="M1089.022-21229.191h-36.3" transform="translate(12165.043 1450)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11382" data-name="Path 11382" d="M1083.719-21229.191h-31" transform="translate(-8011.432 -20862.91) rotate(90)" fill="none" stroke="#429163" stroke-width="1"/>
            <text id="Corridor" transform="translate(13095 -19816.66) rotate(-90)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="-18.195" y="10">Corridor</tspan></text>
            <text id="IPQA" transform="translate(13089 -19968) rotate(-90)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="-11.82" y="10">IPQA</tspan></text>
            <path id="Path_11383" data-name="Path 11383" d="M0,24.135H0v-5.5l10.3.254s-.422-4.443-2.822-6.967S0,8.762,0,8.762V3.325" transform="translate(13089.964 -19945.689) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11385" data-name="Path 11385" d="M1073.1-21229.191h-20.382" transform="translate(-8066.127 -20903.908) rotate(90)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11386" data-name="Path 11386" d="M1072.421-21229.191h-19.7" transform="translate(-8066.127 -20842.422) rotate(90)" fill="none" stroke="#429163" stroke-width="1"/>
            <path id="Path_11387" data-name="Path 11387" d="M-2399.645-21050.6v-7.258h10.3s-.422-3.994-2.822-6.34-6.778-3.066-6.778-3.066,4.378-.607,6.778-3.133,2.822-6.967,2.822-6.967l-10.3.254v-7.5" transform="translate(15485.782 1145.223)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <path id="Path_11388" data-name="Path 11388" d="M12.066,22.021v-4.1L0,18.223s.494-5.2,3.3-8.159,8.762-3.7,8.762-3.7V0" transform="translate(13241.78 -19801.213)" fill="none" stroke="#acc1af" stroke-width="1"/>
          </g>
        </g>
      </svg>
      
      
  </div>
  
  
  
  
  
  
  
  
  
  
  
  