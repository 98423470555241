<div style="position: relative; height: 100%; width: 100%">
    <div style="
            width: 100%;
            height: 100%;
            min-width: 1200px;
            display: flex;
            flex-direction: column;
            position: absolute;">
        <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
            <!-- main body -->
            <div style="
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: start;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;
                margin-top:2%;
                padding-left: 200px;">
                <div style="
                    height: 620px;
                    width: 1200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;">
                    <!-- map -->
                    <div style="
                        height: 620px;
                        display: flex;
                        width: 1200px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        
                        margin-left:-15%;">
                        <img src="/assets/strides-images/AHU8.svg" width="1000px" height="1000px" />
                    </div>
                    <!-- componets -->
                    <div style="height: 350px; width: 1000px; position: absolute">
                        <!-- FAN -->







                    






                    </div>




                </div>








                                <!-- KPI FORM  -->
        
      
     







            </div>
        </div>
    </div>
</div>