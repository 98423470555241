import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css']
})
export class CommandsComponent implements OnInit {
  ahuControlForm!: FormGroup;

  command: string = '';
showDetails: boolean = false;
errorMessage: string = '';
selectedfloor:string='';
selectedBlock: string = 'OLT'; 
blocks: string[]=['OLT']
selectedAHU: string = '';
selectedAHUStatus:number=0;
floors:string[]=['GF']
chw_valve:number=0;
fan_speed:number=0;
fa_damper:number=0;
responseMessage: string = '';

isLoading: boolean = false;


ahus : string[]=['AHU_01','AHU_02','AHU_03','AHU_04','AHU_05','AHU_06','AHU_07','AHU_08','AHU_10','AHU_11','AHU_12']

  blockahumap:Map<string,number>= new Map<string,number>();
constructor(private http:HttpClient,private fb:FormBuilder){

}

ngOnInit(): void {
  this.ahuControlForm = this.fb.group({
    selectedBlock: ['', Validators.required],
    selectedfloor: ['', Validators.required],
    selectedAHU: ['', Validators.required],
    selectedAHUStatus: ['', Validators.required],
    chw_valve: ['', Validators.required],
    fan_speed: ['', Validators.required],
    fa_damper: ['', Validators.required]
  });
}
onAhuChange(selectedAhu: string): void {
  console.log('Selected AHU:', this.selectedBlock);
}

showCommand() {
  console.log(this.ahus);
  if (this.command === 'bertlabs') {
    this.showDetails = true;
    this.errorMessage = '';
  } else {
    this.errorMessage = 'Wrong command';
    this.showDetails = false;
  }
}

sendCommand(){
 
  this.isLoading = true;

    const data = {
      client: 'strides',
        site: 'krsg',
        action:1,
        id1: this.selectedBlock,
        id2: this.selectedfloor,
        id3: this.selectedAHU,
        user: "bert",
        body:{
          on_off_val:this.selectedAHUStatus,
          chw_val:this.chw_valve,
          fan_val: this.fan_speed,
          heating_coil: this.fa_damper
        }
    };
    console.log(data);

    this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
      (response)=>{
        this.isLoading=false;
          this.responseMessage='Command sent successfully!!';
          console.log(data)
          console.log('post response',response);
      },
      (error)=>{
        
        this.isLoading=false;
        this.responseMessage='Failed to send command.please try again!';
        console.log('post response',error);
      }
    )
  
  }

 

 
}





