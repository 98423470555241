import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import jsPDF from 'jspdf';
@Component({
  selector: 'app-alarm-log',
  templateUrl: './alarm-log.component.html',
  styleUrls: ['./alarm-log.component.css']
})
export class AlarmLogComponent {
  fromDate: Date | null = null;
  fromTime: string | null = '09:30';
  toDate: Date | null = null;
  toTime: string | null = '09:30';
  anyerror: boolean = true;
  showData: boolean = true;
  isloading:boolean=false;
  aftermessage: string = '';

  numberoflogs: boolean = true;
  response1: { [equipment: string]: any[] } = {};

  times = [
    { value: '00:00', name: '00:00' },
    { value: '01:00', name: '01:00' },
    { value: '02:00', name: '02:00' },
    { value: '03:00', name: '03:00' },
    { value: '04:00', name: '04:00' },
    { value: '05:00', name: '05:00' },
    { value: '06:00', name: '06:00' },
    { value: '07:00', name: '07:00' },
    { value: '08:00', name: '08:00' },
    { value: '09:00', name: '09:00' },
    { value: '10:00', name: '10:00' },
    { value: '11:00', name: '11:00' },
    { value: '12:00', name: '12:00' },
    { value: '13:00', name: '13:00' },
    { value: '14:00', name: '14:00' },
    { value: '15:00', name: '15:00' },
    { value: '16:00', name: '16:00' },
    { value: '17:00', name: '17:00' },
    { value: '18:00', name: '18:00' },
    { value: '19:00', name: '19:00' },
    { value: '20:00', name: '20:00' },
    { value: '21:00', name: '21:00' },
    { value: '22:00', name: '22:00' },
    { value: '23:00', name: '23:00' },
  ];
  public mappingid:{[key:string]:string}={'AHU_01':'AHU_01','AHU_02':'AHU_02','AHU_03':'AHU_03','AHU_04':'AHU_04','AHU_05':'AHU_05','AHU_06':'AHU_06','AHU_07':'AHU_07','AHU_08':'AHU_08','AHU_10':'AHU_10','AHU_11':'AHU_11','AHU_12':'AHU_12','CH_01':'CHILLER_01','CH_02':'CHILLER_02','CH_03':'CHILLER_03','CH_04':'CHILLER_04'};

  constructor(private http:HttpClient, private router:Router  , private snackBar: MatSnackBar,
  ){

  }

  convertTo24HourFormat(time: string | null): string | null {
    if (!time) return null;
 
    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':');
    return `${hours}:${minutes}:00`;
  }

  async SendData() {
    this.anyerror = false;
 
    if (!this.fromDate) {
      this.anyerror = true;
      alert('From Date is required.');
      return;
    }
 
    if (!this.fromTime) {
      this.anyerror = true;
      alert('From Time is required.');
      return;
    }
 
    if (!this.toDate) {
      this.anyerror = true;
      alert('To Date is required.');
      return;
    }
 
    if (!this.toTime) {
      this.anyerror = true;
      alert('To Time is required.');
      return;
    }
 
    if (this.fromDate > this.toDate) {
      this.anyerror = true;
      alert('From date should be less than To date');
      return;
    }

    console.log(this.fromDate, 'check2');
    const fromDatetime =
    this.fromDate && this.fromTime
      ? (() => {
          // Create a Date object from the user input
          const date = new Date(this.fromDate);

          // Increase the date by 1 day
          date.setDate(date.getDate() + 1);
          const newDateString = date.toISOString().split('T')[0];
          const formattedTime = this.convertTo24HourFormat(this.fromTime);
          return `${newDateString} ${formattedTime}`;
        })()
      : '';
 
      const toDatetime =
      this.toDate && this.toTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.toDate);
 
            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
 
            // Format the adjusted date as "YYYY-MM-DD"
            const newDateString = date.toISOString().split('T')[0];
 
            // Convert the time to 24-hour format
            const formattedTime = this.convertTo24HourFormat(this.toTime);
 
            // Combine the adjusted date with the formatted time
            return `${newDateString} ${formattedTime}`;
          })()
        : '';


 
    console.log('check from', fromDatetime);
    console.log('check to', toDatetime);
 
    const data = {
      start_date: fromDatetime,
      end_date: toDatetime,
    };
// Function to subtract 5:30 hours from a given date


// Convert fromDate and toDate to Date objects and subtract 5:30 hours
let adjustedFromDatetime = this.subtractTime(new Date(fromDatetime), 5, 30);
let adjustedToDatetime = this.subtractTime(new Date(toDatetime), 5, 30);

// Format the dates back to strings in the format you need (e.g., YYYY-MM-DD)
const fromDateString = adjustedFromDatetime.toISOString().slice(0, 10); // Get YYYY-MM-DD part
const fromTimeString = adjustedFromDatetime.toTimeString().slice(0, 5); // Get HH:MM part

const toDateString = adjustedToDatetime.toISOString().slice(0, 10); // Get YYYY-MM-DD part
const toTimeString = adjustedToDatetime.toTimeString().slice(0, 5); // Get HH:MM part

// Now create the URL with the adjusted times
const url = `${environment.apiBaseUrl}/download_faults?start_date=${fromDateString}&start_time=${fromTimeString}:00&end_date=${toDateString}&end_time=${toTimeString}:00`;

    this.showData = true;
    this.isloading=true;
    this.http
      .get<any[]>(url)
      .subscribe(
        (response: any[]) => {
          this.isloading=false;
          console.log('received response', response);
          console.log(data);
          if(response.length==0){
            this.openSnackBar("There were no alarms in the given date range.")

          }
          this.response1=this.convertResponse(response)
         
          console.log(this.response1);


          
        },
 
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          // this.response1=this.convertResponse(this.response2)
          this.isloading=false;

          // console.log(this.response1,"gelo response1");
          this.numberoflogs = false;
          console.log(this.numberoflogs);
          console.log(data);
          console.error('Error:', error);
          this.openSnackBar(error.message)
        }
      );
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }

  isalarmOff(alarm:any){
    if(alarm.Comment=="undefined"){
      return false;
    }
    else{
      return true;
    }
  }
  subtractTime(date: Date, hours: number, minutes: number): Date {
    const newDate = new Date(date); // Clone the date object
    newDate.setHours(date.getHours() - hours);
    newDate.setMinutes(date.getMinutes() - minutes);
    return newDate;
  }
  convertResponse(response: any[]): { [equipment: string]: any[] } {
    let result: { [equipment: string]: any[] } = {};
    let ind = 0;
    response.forEach((res) => {
      let equipid = res.identifier;
      let ahuname = this.getequipmentname(equipid);
  
      const data = {
        id: ind+1,
        title: res.fault,
        time: this.formatTimestamp(res['Start Time']),
        description: ahuname,
        Comment:res.Comment,
        Alarm_Closing_Time_from_frontend:this.formatTimestamp(res.Alarm_Closing_Time_from_frontend),
        isActive: true
      };
  
      // If the equipment does not exist in the result, initialize it as an array
      if (!result[ahuname]) {
        result[ahuname] = [];
      }
  
      // Push the new data into the appropriate equipment's array
      result[ahuname].push(data);
      ind=ind+1;
    });
  
    return result;
  }
  getequipmentname(s:any){
    const parts=s.split('/');
    const equipname=parts[parts.length-1];
    console.log("getequipfun",equipname,this.mappingid[equipname])
    return this.mappingid[equipname];

  }
  private formatTimestamp(timestamp: string): string {
    console.log(timestamp);
    const date = new Date(timestamp);
    console.log(date,"arrived on formattimestamp")
    console.log(`${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  isresponse(): boolean {
    // Check if response1 has any keys (i.e., if it contains any equipment data)
    return Object.keys(this.response1).length > 0;
  }
  
  downloadPDF() {
  // Initialize jsPDF object
  const doc = new jsPDF('p', 'mm', 'a4');

  // Set up "Powered by Bert Labs" header
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text('Powered by', 10, 20);
  doc.setTextColor(255, 0, 0);
  doc.setFont('helvetica', 'bold');
  doc.text('Bert Labs', 35, 20);

  // Space to start writing the equipment and alarm data
  let yOffset = 40;

  // Set the image width and height
  const imgWidth = 200;
  const imgHeight = 200;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const xOffset = (pageWidth - imgWidth) / 2;

  // Loop through response1 (equipment-wise alarms)
  for (let equipmentKey in this.response1) {
    if (this.response1.hasOwnProperty(equipmentKey)) {
      // Add the equipment name as a heading
      doc.setFontSize(14);
      doc.setTextColor(0, 0, 255); // Blue color for equipment name
      doc.text(equipmentKey, 10, yOffset);

      yOffset += 10; // Add space after the equipment name

      // Loop through alarms for the current equipment
      this.response1[equipmentKey].forEach((alarm: any) => {
        // Add the alarm title, time, and description
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0); // Reset color to black
        doc.text(`Title: ${alarm.title}`, 10, yOffset);
        yOffset += 7; // Move down for next line

        doc.text(`Time: ${alarm.time}`, 10, yOffset);
        yOffset += 7;

        doc.text(`Description: ${alarm.description}`, 10, yOffset);
        yOffset += 10; // Add extra space after each alarm

        // Check if we've run out of space on the page
        if (yOffset > pageHeight - 20) {
          doc.addPage();
          yOffset = 20; // Reset yOffset for new page
        }
      });

      // Add extra space after each equipment
      yOffset += 10;

      // Check if we need to add a new page before proceeding to the next equipment
      if (yOffset > pageHeight - 20) {
        doc.addPage();
        yOffset = 20; // Reset yOffset for new page
      }
    }
  }

  // Save the generated PDF
  doc.save('equipment_alarms_report.pdf');
}

}
