import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AdminGuard } from './admin.guard';
import { engineerGuard } from './engineer.guard';
import { AuthService } from '../auth/auth.service';
import { RoleserviceService } from '../services/roleservice.service';
export const roleGuard: CanActivateFn = (route, state) => {
  const adminguard=inject(AdminGuard);
  const authservice=inject(AuthService);
  const roleservice=inject(RoleserviceService)
  // console.log(adminguard.canActivate()," heheheh")
  const commandName=route.data?.['commandName'];
  if(commandName){
    console.log(roleservice.isRoleCommand(commandName),"this is in role guard for admin")
    if(roleservice.isRoleCommand(commandName)){
      return true;
    }
    else{
      authservice.showMessage('Access denied: Only authorized personnel can access this page');
      return false;
    }
  }
  else {
    console.log("no command in this is in role guard for admin")

    authservice.showMessage('Access denied: Only authorized personnel can access this page');
    return false;
  }
};
