<div class="main_container"
 
>

  <!-- logos -->
  <div style="display: flex;flex-direction: row;align-items: center;padding: 5px;gap: 5px;margin-top: 10px; margin-left:-10px;">
    <!-- <img src="/assets/logos/client-logo-bg.png" height="50px">
    <div style="height: 100%; width: 1px;background-color: #000;"></div> -->
    <img src="/assets/strides-images/strides-logo.png" height="55px">
  </div>

  <!-- title -->
  <span class="title-dark" style="font-size: 1.5vw; text-transform: uppercase; margin-left: 8%;">{{title}}</span>

  <!-- account -->
  <div style="display: flex;flex-direction: row;align-items: center;padding: 5px;gap: 5px; margin-right: 20px;">
    <!-- <img src="/assets/logos/client-logo-bg.png" height="50px">
    <div style="height: 100%; width: 1px;background-color: #000;"></div> -->
    <div  style="display:flex; height:30px; font-size: 15px;font-weight: 300; justify-content:center; align-items:center; ">Powered by</div>

    <img src="/assets/logos/bertlabs-logo.png" height="30px">
  </div>


</div>
