import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-five',
  templateUrl: './zone-five.component.html',
  styleUrls: ['./zone-five.component.css']
})
export class ZoneFiveComponent {
  type!: number;
  RAT: number;
  KPI:number=0;
  Zone_temp:number=0;
  power_ahu_26:number=0;
  BASELINE_ZONE_05:number=0;
  POWER_ZONE_05:number=0;

RAT_out: any;
RAT_out_AHU_26: any;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_26 = Number(localStorage.getItem('RAT_out_AHU_26')) || 0;
  localStorage.setItem('RAT_out_AHU_26', JSON.stringify(this.RAT_out_AHU_26));
  console.log("RAT_AHU_26_output_zone_one", this.RAT_out_AHU_26 );



    this.Zone_temp = this.RAT_out_AHU_26;


    this.power_ahu_26 = Number(localStorage.getItem('Power_AHU_26_out')) || 0;
    localStorage.setItem('power_ahu_26', JSON.stringify('power_ahu_26'));
    console.log("Power_AHU_26_out", this.power_ahu_26);

    this.BASELINE_ZONE_05 = 9.02;
    this.POWER_ZONE_05=this.power_ahu_26;

    
    if(this.POWER_ZONE_05){
      this.KPI = ((this.BASELINE_ZONE_05-this.POWER_ZONE_05)/this.BASELINE_ZONE_05)*100;
    localStorage.setItem('kpi_zone_05', JSON.stringify('KPI'));
    }
    else{
      this.KPI = 0 ;
    }














 }
}
