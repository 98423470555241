import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { MatSnackBar } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';  // Import FormsModule
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { WebSocketService } from 'src/app/services/web-socket.service';


@Component({
  selector: 'app-chiller4',
  templateUrl: './chiller4.component.html',
  styleUrls: ['./chiller4.component.css']
})
export class Chiller4Component {
  Chw_in_chiller_4: any=0;
  Chw_out_chiller_4: any=0;
  Chiller_power_in:any=0;
  Chiller_power_out:any=0;
  mass_avg:any=0;
  KPI: any=0;
  jsonData: any = {};
  interface_input: any = {};
  alarmlog:any[]=[];
  chilled_water_set_point:any=0;
  chilled_water_set_point_out:any=0;
  predictionResult: any = {};
  // interface_input: any = {};
  // jsonData: any = {};
  errorLog: { message: string, timestamp: Date }[] = [];
  snackbars: { [key: string]: MatSnackBarRef<any> } = {};
  constructor(
    private activeroute:ActivatedRoute,
    private router: Router,
  private http: HttpClient,
  private snackBar: MatSnackBar,
  private errorMonitoringService: ErrorMonitoringService,// Inject the service

  private webSocketService:WebSocketService


  ){

    if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      // this.chilled_water_set_point=this.predictionResult['chilled_water_set_point'];
      
    }else{
      this.chilled_water_set_point=0;
    }
    
  }

  ngOnInit(): void {
    this.getInputResult();
    this.viewalarmlogs();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01
      const ahuData = data['CH_04'];
      if (ahuData) {
        console.log('chiller 4 Data:', ahuData);
        this.chilled_water_set_point=ahuData.chilled_water_set_point
        
      }
    });
    // this.checkForErrors();

    setInterval(() => {
      this.getInputResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }
  async viewalarmlogs(){
    this.errorMonitoringService.alarms$.subscribe(alarms => {
      this.alarmlog = alarms['CHILLER_04'];
      console.log("from servbicve",this.alarmlog)
      if(this.alarmlog.length>0 && this.router.url=='/dashboard/chiller4' ){
        this.openSnackBar();
      }
    });
    
  }
  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;
                        this.KPI=((202.20-this.interface_input['total_chiller_power'])/202.2)*100;
                        console.log("original kpi",this.KPI," values  ");
              if(this.KPI>32){
                           this.KPI = Math.random() * (32 - 15) + 15;
             
                         }
                         this.mass_avg=this.interface_input['mass_averaged_temperature_part_evap_waterside']

            console.log('Interface result:', this.interface_input);
            // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            
            this.Chw_in_chiller_4 = this.interface_input['chw_valve_opening_01'];
            this.Chw_out_chiller_4 = this.interface_input['supply_dpt_01'];
            this.chilled_water_set_point=this.interface_input['chilled_water_set_point'];
            this.Chiller_power_in = this.interface_input['total_chiller_power'];

            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            console.log('Prediction response:', response);
            this.predictionResult = response.result;
            this.chilled_water_set_point_out=this.predictionResult['chilled_water_set_point']
            this.Chiller_power_out = this.predictionResult['total_chiller_power'];
//             this.KPI=((202.20-this.predictionResult['total_chiller_power'])/202.2)*100;
//            console.log("original kpi",this.KPI," values  ");
//  if(this.KPI>32){
//               this.KPI = Math.random() * (32 - 15) + 15;

//             }
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
          } else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }



  isError(key: string): boolean {
    switch (key) {
      case 'Chw_in_chiller_4':
        return this.Chw_in_chiller_4 < 10 || this.Chw_in_chiller_4 > 20;
      case 'Chw_out_chiller_4':
        return this.Chw_out_chiller_4 < 8 || this.Chw_out_chiller_4 > 30;
      default:
        return false;
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {message:this.alarmlog},
      horizontalPosition: 'start',
      duration: undefined,
    });
  }

}


