export const environment = {
    production: true,//wheen deploying please change this into true
    apiBaseUrl: 'https://strideskrsg.bertlabs.com' // Replace this with your base URL
  };
 //when deploying please change this into true//wheen deploying please change this into true
  //when deploying please change production into true
   //whewhenen deploying please change production into true
    //when deploying please change production into true
     //when deploying please change production into true
      //whwheneen deploying please change production into true
       //when deploying please change production into true
