import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorMonitoringService {
  private errorLog: { message: string, timestamp: Date }[] = [];
  private alarmsSubject: BehaviorSubject<{ [equipment: string]: any[] }> = new BehaviorSubject<{ [equipment: string]: any[] }>({});
  public alarms$ = this.alarmsSubject.asObservable();
  public mappingid:{[key:string]:string}={'AHU_01':'AHU_01','AHU_02':'AHU_02','AHU_03':'AHU_03','AHU_04':'AHU_04','AHU_05':'AHU_05','AHU_06':'AHU_06','AHU_07':'AHU_07','AHU_08':'AHU_08','AHU_10':'AHU_10','AHU_11':'AHU_11','AHU_12':'AHU_12','CH_01':'CHILLER_01','CH_02':'CHILLER_02','CH_03':'CHILLER_03','CH_04':'CHILLER_04'};
  private alarmsByEquipment: { [equipment: string]: any[] } = {}; // Store alarms for each equipment




  logError(error: string) {
    // Check if the error is already logged
    
    const isErrorLogged = this.errorLog.some(e => e.message === error);
    if (!isErrorLogged) {
      this.errorLog.push({ message: error, timestamp: new Date() });
    }
  }
  getErrorLog() {
    return this.errorLog;
  }

  clearErrorLog() {
    this.errorLog = [];
  }

  getNumberOfAlarms(){
    let count=0;
    for (const equipment in this.alarmsByEquipment) {
      count+=this.alarmsByEquipment[equipment].length;
      }
      return count;
  }

    // Get the current alarms
    getAlarms(equipment: string): any[] {
      return this.alarmsByEquipment[equipment] || [];
    }
  
    // Set alarms and notify subscribers
    setAlarms(equipment: string, newAlarms: any[]): void {
      // Merge new alarms with existing alarms for other equipment
      this.alarmsByEquipment = {
        ...this.alarmsByEquipment, // Keep existing alarms
        [equipment]: newAlarms     // Add or replace alarms for the specified equipment
      };
      console.log("from error",this.alarmsByEquipment);
  
      // Notify subscribers of the updated alarms
      this.alarmsSubject.next(this.alarmsByEquipment);
    }
  
  
    updateAlarm(equipment: string, updatedAlarm: any): void {
      if (this.alarmsByEquipment[equipment]) {
        const alarms = this.alarmsByEquipment[equipment].map(alarm =>
          alarm.id === updatedAlarm.id ? { ...alarm, ...updatedAlarm } : alarm
        );
        this.alarmsByEquipment[equipment] = alarms;
        this.alarmsSubject.next(this.alarmsByEquipment); // Notify subscribers of changes
      }
    }
    clearAlarms(equipment: string) {
      if (this.alarmsByEquipment[equipment]) {
        this.alarmsByEquipment[equipment] = [];
        this.alarmsSubject.next(this.alarmsByEquipment); // Notify subscribers of changes
      }
    }
  async convertResponse(response: any[]): Promise<any[]> {
    let ans: any[] = [];
    let ind = 0;
    response.forEach((res) => {
      let equipid=res.identifier;
      let ahuname=this.getequipmentname(equipid);
      const data = {
        id: ind + 1,
        title: res.fault,
        time: this.formatTimestamp(res.timestamp),
        description: ahuname,
        isActive: true
      };

      ans.push(data);
      ind = ind + 1;
    });
    return ans;
  }

  getequipmentname(s:any){
    const parts=s.split('/');
    const equipname=parts[parts.length-1];
    console.log("getequipfun",equipname,this.mappingid[equipname])
    return this.mappingid[equipname];

  }

  private formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }



}
