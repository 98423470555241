<div style="position: relative; height: 100%; width: 100%">
    <!-- <Water-mark></Water-mark> -->
  
    <div
      style="
        width: 100%;
        height: 100%;
        min-width: 1200px;
        display: flex;
        flex-direction: column;
        position: absolute;
      "
    >
      <mat-progress-bar
        mode="indeterminate"
        [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
      ></mat-progress-bar>
      <div
        style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          align-items: center;
          gap: 10px;
        "
      >
        <div
          style="
            height: 400px;
            width: 1100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <div
            style="
              height: 400px;
              width: 1100px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            "
          >
            <img src="/assets/images/heat-exchanger-map.svg" width="1200px" height="400px" />
            <!-- <div style="position: absolute; top: 325px; left: 365px; height: 50px; width: 270px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                <p style="font-family: 'Inter', sans-serif; font-size: 24px; font-weight:520; color:var(--blue-one); text-align: center; line-height:25px; ">AHU ROOM NAME</p>
              </div>
            </div>
            <div style="position: absolute; top: 110px; left: 1010px; height: 100px; width: 150px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                <p style="font-family: 'Inter', sans-serif; font-size: 24px; font-weight:520; color:var(--blue-one); text-align: center; line-height:25px; ">AREA/ FLOOR/ SECTION</p>
              </div>
            </div> -->
          </div>
  
          <!-- componets -->

          <!-- FAN1 -->
          <div
            style="
                height: 85px;
                width: 85px;
                margin-top: -160px;
                margin-left: 424px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            "
          >
          <Primary-pump [state]="'active'"></Primary-pump>
          
            <!-- <Fan [state]="'active'"></Fan> -->
          </div>
          <div
            style="
                height: 85px;
                width: 85px;
                margin-top: 43px;
                margin-left: 424px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            "
          >
          <Primary-pump [state]="'active'"></Primary-pump>
          
            <!-- <Fan [state]="'active'"></Fan> -->
          </div>
          <div
            style="
                height: 85px;
                width: 85px;
                margin-top: 256px;
                margin-left: 424px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            "
          >
          <Primary-pump [state]="'active'"></Primary-pump>
          
            <!-- <Fan [state]="'active'"></Fan> -->
          </div>

          
        </div>
      </div>
  
      <div
        style="
          height: auto;
          width: 100%;
          min-width: 1100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 7px;
          align-items: center;
          gap: 10px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: auto;
            justify-content: center;
          "
        >
          <div style="width: 600px; height: 100%; margin: 0px 20px 0px 50px">
            <div
              class="form"
              style="
                padding: 20px;
                flex-direction: row;
                gap: 30px;
                align-items: start;
              "
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <!-- RAT -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >RAT</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="RAT" />
                  </div>
                </div>
  
                <!-- off coil temp -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >OFF COIL TEMP.</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="OCT" />
                  </div>
                </div>
  
                <!-- CHWR TEMP. -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHWR TEMP.</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input [(ngModel)]="CHWRT" />
                  </div>
                </div>
  
                <!-- FAN SPEED -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >FAN SPEED</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/hertz.png" />
                    <input [(ngModel)]="VSD" />
                  </div>
                </div>
              </div>
  
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: column; gap: 10px">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >SAT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input [(ngModel)]="SAT" />
                    </div>
                  </div>
  
                  <!-- chws temp. -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >CHWS TEMP.</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input [(ngModel)]="CHWST" />
                    </div>
                  </div>
  
                  <!-- CHW VALVE -->
  
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >CHW VALVE</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/percentage.png" />
                      <input [(ngModel)]="CHWVF" />
                    </div>
                  </div>
                </div>
  
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 10px;
                    justify-content: end;
                    margin-top: 10px;
                  "
                >
                  <button
                    style="
                      width: 50%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    "
                    (click)="predictData()"
                  >
                    Predict
                  </button>
                  <button
                    style="
                      width: 50%;
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    "
                    (click)="optimiseData()"
                    [ngClass]="{
                      'button-disabled': disableOptimise,
                      'button-enabled': !disableOptimise
                    }"
                    [disabled]="disableOptimise"
                  >
                    Optimise
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <!-- KPI -->
  
          <div class="form" style="height: 200px; width: 400px">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500; margin-right:10px;"
                >FAN POWER SAVING</span
              >
  
              <div class="input-group" style="width: 120px; ">
                <img src="/assets/images/power.png" />
                <input
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event"
                />
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  