<div class="main-container">
    <div class="sidebar">
      <div class="menu-items">
        <div class="menu-item" routerLink="../ahucommand">AHU Command</div>
        <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
        <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Sequencing</div>
        <div class="menu-item" routerLink="../ahuschedule-command">AHU Schedule Command</div>
      </div>
    </div>
  
    <div class="content">
      <div class="command-section">
        <h2>AHU SCHEDULER COMMAND</h2>
        <div class="command-input-container">
          <input [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
          <button class="show-command-btn" (click)="showCommand()">Show Command</button>
        </div>
  
        <div *ngIf="showDetails">
          <p>You selected: OLT - {{ selectedAHU }}</p>
          <div class="form-row">
            <label>BLOCK</label>
            <select>
              <option>OLT</option>
            </select>
          </div>
  
          <div class="form-row">
            <label>FLOOR</label>
            <select [(ngModel)]="selectedfloor">
              <option *ngFor="let floor of floors">{{floor}}</option>
            </select>
          </div>
  
          <div class="form-row">
            <label>BODY</label>
            <select [(ngModel)]="selectedAHU">
              <option *ngFor="let ahu of ahus">{{ ahu }}</option>
            </select>
          </div>
  
          <div class="form-row">
            <label>Schedule ID</label>
            <input type="number" [(ngModel)]="schedule_id" />
          </div>
  
          <div class="form-row">
            <label>Start Time</label>
            <div class="time-inputs">
              <input type="number" min="0" max="23" placeholder="0"  [(ngModel)]="starthour" /> <span>hr</span>
              <input type="number" min="0" max="59" placeholder="0" [(ngModel)]="startmin" /> <span>min</span>
            </div>
          </div>
          
          <div class="form-row">
            <label>Stop Time</label>
            <div class="time-inputs">
              <input type="number" min="0" max="23" placeholder="0" [(ngModel)]="endhour"/> <span>hr</span>
              <input type="number" min="0" max="59" placeholder="0" [(ngModel)]="endmin" /> <span>min</span>
            </div>
          </div>

          <!-- <div class="radio-group">
            <label>
              <input type="radio" name="selectedAHUStatus" [(ngModel)]="schedulecontrol" [value]="1" /> Add
            </label>
            <label>
              <input type="radio" name="selectedAHUStatus" [(ngModel)]="schedulecontrol" [value]="0" /> Remove
            </label>
          </div> -->
          
          <button (click)="sendCommand()">Send</button>
          <p class="waiting-text" *ngIf="isLoading">Sending Command!</p>
          <p *ngIf="responseMessage"  [ngClass]="{ 'response-message': responseMessage[0] == 'C', 'error-message': responseMessage[0] == 'F' }" >{{responseMessage}}</p>      </div>
             
        <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </div>