import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';4
import { AuthService } from 'src/app/auth/auth.service';
import { RoleserviceService } from 'src/app/services/roleservice.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.css']
})
export class ViewusersComponent {
  userForm: FormGroup;
  isediting:boolean=false;
  currentUser: any = null;
  isloading:boolean=false;
  allroles:any=[];
  users:any = [];
  constructor(private fb: FormBuilder, private router: Router,private authservice:AuthService,private roleservice:RoleserviceService,private http:HttpClient,private snackBar:MatSnackBar) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobRole: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      username: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$')  // Allow only numeric input for username
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?#^&])[A-Za-z\\d@$!%*?&].{7,}$'),
       
      ]]
    });
  }

  passwordForm!: FormGroup; // Ensure that it's initialized
 
  isPasswordVisible: boolean = false;
  hideShowPass() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
 
  // Getter to toggle between 'text' and 'password'
  get passwordFieldType(): string {
    return this.isPasswordVisible ? 'text' : 'password';
  }
 
  // Getter for toggling the icon class
  get eyeIcon(): string {
    return this.isPasswordVisible ? 'fa-eye' : 'fa-eye-slash';
  }

  ngOnInit():void{
    this.roleservice.getallroles();
    this.getallusers();
    this.allroles=this.roleservice.getallrolenames();
  
  }

 
  toggleUserStatus(user: any, isActive: boolean) {
    user.isUserDeactivated = !isActive;  // Toggle based on the 'checked' value
    // Optionally, call an API to update the user status on the backend
    // this.userService.updateUserStatus(user.id, !isActive).subscribe();
    const data={
      username:user.username,
      active:!user.isUserDeactivated
    }
    this.isloading=true;
    console.log(data,"toggle button");
    this.http.post<any>(`${environment.apiBaseUrl}/auth/userAccountActivateOrDeactivate`,data).subscribe(
      (response)=>{
        setTimeout(() => {
          this.isloading = false;
          this.openSnackBar(`Successfully changed the status of ${user.employeeName}`);
          this.getallusers(); // Call the function to fetch users after the delay
        }, 2000); 
      },
      (error)=>{
        this.isloading=false;
        this.openSnackBar(`Failed to change the status of ${user.employeeName} `)
      }
    )

    // this.getallusers();
  }
    isAdmin(user:any){

      if(user.role=='Admin' || user.role=='admin'){
        return true;
      }
      else{
        return false;
      }
    }
  editUser(user: any) {
    console.log('Edit user', user);
    this.isediting=true
    this.currentUser = user;
    const names=user.employeeName.split(' ');
    const firstname=names[0];
    const secondname=names[1];
    // Populate the form with the user's data
    this.userForm.patchValue({
      firstName: firstname,
      lastName: secondname,
      jobRole: user.role,
      email: user.emailId,
      mobile: user.mobileNo,
      username: user.username,
      password: '', // Keep password empty, assume user updates if needed
    });
   

    // Logic to edit the user
  }
  
  deleteUser(user: any) {
    this.isloading=true;

    console.log('Delete user', user);
    this.http.delete<any>(`${environment.apiBaseUrl}/users/${user.username}`).subscribe(
      (response)=>{
        this.isloading=false;

        console.log(response);
        this.getallusers();
        this.openSnackBar(`Successfully deleted  ${user.username}`)



      },
      (error)=>{
        this.isloading=false;
                this.openSnackBar('Unable to delete use.Please try again!!')


      }
    )
    // Logic to delete the user
  }
  
  changeAdmin(user: any) {
    // Logic for changing the admin
  }


  showvalid():boolean{

    if(this.userForm.get('password')?.dirty){
      if(this.userForm.get('password')?.value==''){
        return false;
      }
      else{
        return true;
      }
    }
    return false;
  }

  hasMinLengthError(): boolean {
    const control = this.userForm.get('password');
    // Check if the control is dirty and has an error, and if the password is not empty
    // console.log(control);
    // console.log(control?.dirty ,"hehe",control?.hasError('minlength'),"kj",control?.value !== "")
    // console.log(!!(control?.dirty && control?.hasError('minlength') && control?.value !== ""))
    return !!(control?.dirty && control?.hasError('minlength') );
  }
  
  hasUppercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[A-Z])/.test(control?.value ?? ''));
  }
  
  hasLowercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[a-z])/.test(control?.value ?? ''));
  }
  
  hasNumberError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*\d)/.test(control?.value ?? ''));
  }
  
  hasSpecialCharError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[@$#^!%*?&])/.test(control?.value ?? ''));
  }
  hasUsernameError(): boolean {
    return this.userForm.controls['username'].errors?.['pattern'];
  }
  

  onSubmit() {
    
    console.log(this.userForm.invalid,"submitpage")
    Object.keys(this.userForm.controls).forEach(field => {
      const control = this.userForm.get(field);
      if (control?.invalid) {
        console.log(`${field} is invalid.`);
        console.log('Errors:', control.errors); // Log specific errors
      }
    });
    console.log(this.userForm);
   
      if(this.isediting){
        this.updateUser();
        this.isediting=false;
      }
      else{
        this.addUser();
      }
    
  }

  addUser(){
    this.isloading=true;
    console.log(this.currentUser,"adduserpage");
    console.log(this.userForm.value);
    const name=`${this.userForm.value.firstName} ${this.userForm.value.lastName}`
    const data={
      username:this.userForm.value.username,
      password:this.userForm.value.password,
      employeeName:name,
      emailId:this.userForm.value.email,
      mobileNo:this.userForm.value.mobile,
      role:this.userForm.value.jobRole


    }
    this.http.post<any>(`${environment.apiBaseUrl}/users`,data).subscribe(
      (response)=>{
        this.isloading=false;
        console.log(response)
        this.openSnackBar(`Successfully added new user with name ${data.employeeName}`)

        this.getallusers();
      },
    (error)=>{
      this.isloading=false;
      console.log(error);
      this.openSnackBar(error.error.message)
      console.log(error.message)
    }
    )
    console.log(data);

    this.onCancel();
  }

  getallusers(){
    this.http.get<any>(`${environment.apiBaseUrl}/users`).subscribe(
      (response)=>{
        this.users=response
        console.log(response,"getallusers");
      },
      (error)=>{
        console.log(error);
      }
    )
  }
  updateUser(){
    this.isloading=true;
    const name=`${this.userForm.value.firstName} ${this.userForm.value.lastName}`
    const username=this.userForm.value.username
    const data={
      password:this.userForm.value.password,
      employeeName:name,
      emailId:this.userForm.value.email,
      mobileNo:this.userForm.value.mobile,
      role:this.userForm.value.jobRole


    }
    this.http.put<any>(`${environment.apiBaseUrl}/users/${username}`,data).subscribe(
      (response)=>{
        this.isloading=false;
        console.log(response);
        this.openSnackBar(response.message)
        this.getallusers();
      },
    (error)=>{
      this.isloading=false;
      this.openSnackBar('Unable to update the details of user.Please try again!!')
      console.log(error);
    }
    )
    console.log(this.currentUser);
    this.onCancel();
  }

  onSaveAndAddAnother() {
    if (this.userForm.valid) {
      console.log('User data:', this.userForm.value);
      alert('User added successfully! Add another user.');
      this.userForm.reset();
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }

  onCancel() {
    this.isediting=false;

    this.userForm.reset();

    // this.router.navigate(['dashboard/viewusers']);
  }

  get password() {
    return this.userForm.get('password');
  }

}
