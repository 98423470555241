<div id="roleModal" class="role-modal-overlay">
    <div class="role-modal-content">
        <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

        <h2 style="color: #32774E;">This is the first time you are logging in, please reset your password</h2>
        <div class="role-options">

            <!-- Old Password -->
            <label for="oldPassword">Old Password</label>
            <div class="password-container">
                <input [type]="showOldPassword ? 'text' : 'password'" id="oldPassword" placeholder="Old Password" [(ngModel)]="old_password">
                <button type="button" (click)="showOldPassword = !showOldPassword">
                    <i class="fa" [ngClass]="showOldPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </button>
            </div>

            <!-- New Password -->
            <label for="newPassword">New Password</label>
            <div class="password-container">
                <input [type]="showNewPassword ? 'text' : 'password'" id="newPassword" placeholder="New Password" [(ngModel)]="new_password">
                <button type="button" (click)="showNewPassword = !showNewPassword">
                    <i class="fa" [ngClass]="showNewPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </button>
            </div>

            <!-- Confirm Password -->
            <label for="confirmPassword">Confirm Password</label>
            <div class="password-container">
                <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" placeholder="Confirm Password" [(ngModel)]="confirm_password">
                <button type="button" (click)="showConfirmPassword = !showConfirmPassword">
                    <i class="fa" [ngClass]="showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </button>
            </div>

            <!-- Submit button -->
            <button class="submitbtn" (click)="onSubmit()">Submit</button>

        </div>
    </div>
</div>
