<div style="height: 100vh; width: 100vw; overflow-y: auto; display: flex; flex-direction: column;">
  <div style="height: 100%; width: 100%; position: relative;">
    <div
      style="
        height: 80%;
        width: 100%;
        background: url('/assets/predict_ahu/strides login1.svg');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 20px;
        "
      >
        <div style="position: relative; margin-bottom: 200px;">
          <img src="/assets/strides-images/strides-logo.png" width="350px" style="border-radius: 5px;" />
          <div style="position: absolute; top: 77%; left: 50%; transform: translate(-50%, -50%);">
            <img src="/assets/strides-images/logo animation mulish 1.gif" width="200px" />
          </div>
        </div>
        <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

        <form class="form" [formGroup]="userForm" (ngSubmit)="onSubmit()" style="height: 280px;">
          <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div  style="text-align: center; color:#AAAAAA">
              <div style=" font-size: larger; color: #32774E;font-weight: bolder; margin-bottom: 10px;"> New Credentials</div>
              Your Email has been verified.
               Set your new Password
            </div>
            <!-- Password Field with Lock Icon -->
           <!-- Input Wrapper for Password Field with Lock Icon -->
<div class="form-group" [ngClass]="{'input-invalid': !isPasswordValid()}">
  <div class="input-wrapper" style="position: relative;  max-width: 500px;">
    <!-- Lock Icon -->
    <i class="fa fa-lock" style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%); color: #999;"></i>
    
    <!-- Password Input Field -->
    <input
      type="password"
      formControlName="password"
      placeholder="New Password"
      class="password-input"
      [ngClass]="{'input-error': !isPasswordValid()}"
      style="padding-left: 40px;" 
    />
    
    <!-- Validation Icon with Tooltip -->
    <i class="validation-icon" [ngClass]="{'icon-error': !isPasswordValid()}"
       (mouseover)="showTooltip = true" 
       (mouseleave)="showTooltip = false"
       style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%);">
       &#x26A0; <!-- Warning icon -->
    </i>
  

    
    <!-- Tooltip for password validation -->
    <div class="tooltip" *ngIf="showTooltip" style="position: absolute; top: 100%; right: 0; background: #f9f9f9; padding: 10px; border: 1px solid #ccc; border-radius: 5px;">
      <div [ngClass]="{'valid': !hasMinLengthError(), 'invalid': hasMinLengthError()}">* Minimum 8 characters</div>
      <div [ngClass]="{'valid': !hasUppercaseError(), 'invalid': hasUppercaseError()}">* At least 1 uppercase letter</div>
      <div [ngClass]="{'valid': !hasLowercaseError(), 'invalid': hasLowercaseError()}">* At least 1 lowercase letter</div>
      <div [ngClass]="{'valid': !hasNumberError(), 'invalid': hasNumberError()}">* At least 1 number</div>
      <div [ngClass]="{'valid': !hasSpecialCharError(), 'invalid': hasSpecialCharError()}">* At least 1 special character</div>
    </div>
  </div>
</div>


            <!-- Confirm Password Field -->
            <div class="form-group">
              <div class="input-wrapper" style="position: relative;  max-width: 500px;">
                <!-- Lock Icon for Confirm Password -->
                <i class="fa fa-lock" style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%); color: #999;"></i>
                
                <!-- Confirm Password Input -->
                <input
                  type="password"
                  formControlName="confirmPassword"
                  placeholder="Confirm Password"
                  class="password-input"
                  style="padding-left: 40px;"
                />
              </div>
            </div>

            <div *ngIf="passwordsDoNotMatch()" class="invalid">* Passwords do not match</div>

            <!-- Submit Button -->
            <button mat-raised-button class="btn" [disabled]="!isFormValid()" type="submit">Submit</button>

            <!-- Go to Login -->
            <a class="hover-text-dark" [routerLink]="['/login']">Go to Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
