<div class="ahu-six">
    
    
      <svg id="ahu-six" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 156.771 97.506">
        <g id="Component_189_1" data-name="Component 189 – 1" transform="translate(1.503 1.5)">
          <g id="Group_4654" data-name="Group 4654" transform="translate(-13016.454 20421.605)">
            <path id="Path_11427" data-name="Path 11427" d="M12439.377-21105.1c0,4.387-.19,78.758-.19,78.758s-.232,5.119,6.237,4.926,143.962-.437,143.962-.437a4.188,4.188,0,0,0,3.568-4.107c.046-3.609-.147-80.211-.147-80.211a3.812,3.812,0,0,0-4.04-3.887l-146.925.184S12439.374-21109.49,12439.377-21105.1Z" transform="translate(577.269 688.457)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
            <text id="AHU6" transform="translate(13056 -20382)" fill="#377c54" font-size="14" font-family="Mulish, sans-serif" font-weight="700"><tspan x="20.048" y="14">AHU6</tspan></text>
            <path id="Path_11428" data-name="Path 11428" d="M-2385.927-21037v-11.393h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-12.373" transform="translate(-7968.7 -17940.172) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
            <text id="Ointment_fill_seal_" data-name="Ointment fill &amp; seal " transform="translate(13039 -20411)" fill="#606060" font-size="10" font-family="Mulish, sans-serif" font-weight="300"><tspan x="15" y="10">Ointment fill &amp; seal </tspan></text>
          </g>
        </g>
      </svg>
      
    
  </div>
  
  
  
  
  
  
  
  
  
  
  
  