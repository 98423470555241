<div style="position: relative; height: 100%; width: 100%; ">
  <div class="main_container">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <button class="button-a7" routerLink="/dashboard/AHU-07">
            <img src="assets/logos/left-arrow.png" alt="" style="width: 50%">
          </button>
          <img class="fmap" src="/assets/predict_ahu/AHU7-2.svg" />
          <!-- <button class="button-a7" routerLink="/dashboard/AHU-07-02">
            <img src="assets/logos/right-arrow.png" alt="" style="width: 50%">
          </button> -->


          <!-- Components -->
          <!-- <div class="component fan" style="height: 15%; top: 68%; left: 51.6%;">
            <Fan [state]="state" ></Fan>
          </div> -->


               <!--1 ROom Temp -->
               <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 30.4%; left: 32.2%; width: 40px; height: 3%;">
                <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
                <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
              </div>
                <!--1 Room RH  -->
          <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 30.4%; left: 37.5%; width: 40px; height: 3%;">
            <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
            <span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
          </div>

          <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 22.8%; left: 33%; width: 40px; height: 3%;">
            <input readonly  class="no-background-quartz" style="color: #183772;"
              [ngModel]="rt_relay1 | number : '1.2-2'"
              (ngModelChange)="rt_relay1 = $event"
            />
          </div>

          <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 24.9%; left: 33%; width: 40px; height: 3%;">
            <input readonly  class="no-background-quartz" style="color: #183772;"
              [ngModel]="rh_relay1 | number : '1.2-2'"
              (ngModelChange)="rh_relay1 = $event"
            />
          </div>

          <!--2 ROom Temp -->
          <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 45.6%; width: 39px; height: 3%;">
            <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
            <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
          </div>
            <!--2 Room RH  -->
      <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 31.5%; left: 50.6%; width: 40px; height: 3%;">
        <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
        <span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
      </div>

      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 22.6%; left: 46%; width: 40px; height: 3%;">
        <input readonly  class="no-background-quartz" style="color: #183772;"
          [ngModel]="rt_relay2 | number : '1.2-2'"
          (ngModelChange)="rt_relay2 = $event"
        />
      </div>

      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 24.7%; left: 46%; width: 40px; height: 3%;">
        <input readonly  class="no-background-quartz" style="color: #183772;"
          [ngModel]="rh_relay2 | number : '1.2-2'"
          (ngModelChange)="rh_relay2 = $event"
        />
      </div>

      <!--3 ROom Temp -->
      <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 58.7%; width: 40px; height: 3%;">
        <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
        <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
      </div>
        <!--3 Room RH  -->
  <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 31.5%; left: 63.9%; width: 40px; height: 3%;">
    <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
    <span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
  </div>

  <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 22.6%; left: 59.3%; width: 40px; height: 3%;">
    <input readonly  class="no-background-quartz" style="color: #183772;"
      [ngModel]="rt_relay3 | number : '1.2-2'"
      (ngModelChange)="rt_relay3 = $event"
    />
  </div>

  <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 24.7%; left: 59.3%; width: 40px; height: 3%;">
    <input readonly  class="no-background-quartz" style="color: #183772;"
      [ngModel]="rh_relay3 | number : '1.2-2'"
      (ngModelChange)="rh_relay3 = $event"
    />
  </div>

  <!--4 ROom Temp -->
  <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 31.3%; left: 71.7%; width: 40px; height: 3%;">
    <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
    <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
  </div>
    <!--4 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 31.3%; left: 76.9%; width: 40px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 22.6%; left: 72.3%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay4 | number : '1.2-2'"
    (ngModelChange)="rt_relay4 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 24.7%; left: 72.3%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay4 | number : '1.2-2'"
    (ngModelChange)="rh_relay4 = $event"
  />
</div>

<!--5 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 85.3%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--5 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 90.6%; width: 36px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 22.6%; left: 85.6%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay5 | number : '1.2-2'"
    (ngModelChange)="rt_relay5 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 24.7%; left: 85.6%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay5 | number : '1.2-2'"
    (ngModelChange)="rh_relay5 = $event"
  />
</div>

<!--6 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.7%; left: 38.8%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--6 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 79.7%; left: 44.3%; width: 40px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>

</div>



<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 72.7%; left: 39.7%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay6 | number : '1.2-2'"
    (ngModelChange)="rt_relay6 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 74.9%; left: 39.7%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay6 | number : '1.2-2'"
    (ngModelChange)="rh_relay6 = $event"
  />
</div>


<!--7 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.8%; left: 52%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--7 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 79.8%; left: 57.5%; width: 34px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 72.7%; left: 52.8%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay7 | number : '1.2-2'"
    (ngModelChange)="rt_relay7 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 74.9%; left: 52.8%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay7 | number : '1.2-2'"
    (ngModelChange)="rh_relay7 = $event"
  />
</div>

<!--8 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.9%; left: 65.3%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--8 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 79.9%; left: 70.5%; width: 37px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 72.6%; left: 66%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay8 | number : '1.2-2'"
    (ngModelChange)="rt_relay8 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 74.9%; left: 66%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay8 | number : '1.2-2'"
    (ngModelChange)="rh_relay8 = $event"
  />
</div>

<!--9 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.8%; left: 78.6%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--9 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 79.8%; left: 83.7%; width: 36px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 72.7%; left: 79.1%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay9 | number : '1.2-2'"
    (ngModelChange)="rt_relay9 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 74.9%; left: 79.1%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay9 | number : '1.2-2'"
    (ngModelChange)="rh_relay9 = $event"
  />
</div>

<!--10 ROom Temp -->
<div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 79.7%; left: 91.8%; width: 40px; height: 3%;">
  <input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SAT_out | number: '1.2-2'" (ngModelChange)="SAT_out = $event" />
  <span style="font-size: 80%;  color: #293AC6; font-weight:600" >°C</span>
</div>
  <!--10 Room RH  -->
<div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 79.7%; left: 97%; width: 36px; height: 3%;">
<input readonly  style=" color: #293AC6; font-weight:600" [ngModel]="SFRS_out | number: '1.2-2'" (ngModelChange)="SFRS_out = $event" />
<span style="font-size: 80%;  color: #293AC6; font-weight:600" >%</span>
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 72.7%; left: 92.4%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rt_relay10 | number : '1.2-2'"
    (ngModelChange)="rt_relay10 = $event"
  />
</div>

<div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 75%; left: 92.4%; width: 40px; height: 3%;">
  <input readonly  class="no-background-quartz" style="color: #183772;"
    [ngModel]="rh_relay10 | number : '1.2-2'"
    (ngModelChange)="rh_relay10 = $event"
  />
</div>

<!-- <div class="form component">
  <span class="span-text-dark">Key Performance Indicator</span>

  <div style="height: 40px;"></div>
  <div  class="hvac-text">
  <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
  <div style="width:25%; ">
      <input readonly  style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
      [ngModel]="KPI | number : '1.2-2'"
      (ngModelChange)="KPI = $event" />
  </div>
  <span style="margin-left:-10%; font-size: 100%;">%</span>
  </div>
</div>  -->

        </div>

      </div>
    </div>
  </div>
</div>








































