import { ChangeDetectorRef, Component } from '@angular/core';
import { GraphComponent } from 'src/app/components/graph/graph.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxPaginationModule } from 'ngx-pagination';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';

import { LoadingSpinnerComponent } from 'src/app/loading-spinner/loading-spinner.component';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-strides-analytics',
  standalone: true,
  imports: [
    GraphComponent,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    LoadingSpinnerComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ],
  templateUrl: './strides-analytics.component.html',
  styleUrls: ['./strides-analytics.component.css'],
})
export class StridesAnalyticsComponent {
  selectedGraph: string = 'AHU';
  selectedAhu: string = 'VAV-AHU-C4-L2-02';
  fromDate: Date | null = null;
  convfromDate: string | null = null;

  convtoDate: string | null = null;

  fromTime: string | null = '09:30';
  toDate: Date | null = null;
  toTime: string | null = '09:30';
  downloadtype: string = 'PDF';
  anyerror: boolean = true;
  showData: boolean = false;
  tableData: any[] = [];
  selectedAHUs: string[] = [];
  itemsPerPage: number = 50;
  currentPage: number = 1;
  itemsPerPageOptions: number[] = [10, 20, 50, 144];
  numberoflogs: boolean = true;
  aftermessage: string = '';
  selectedRoomOptions: { label: string; value: string }[] = [];
  ahus = new FormControl('');
  isloading: boolean = false;

  ahuList: string[] = [
    'AHU_01',
    'AHU_02',
    'AHU_03',
    'AHU_04',
    'AHU_05',
    'AHU_06',
    'AHU_07',
    'AHU_08',
    'AHU_10',
    'AHU_11',
    'AHU_12',
  ];
  downloadTypes = [
    // { value: 'XLSX', name: 'XLSX' },
    { value: 'PDF', name: 'PDF' },
  ];
  ahuRoomOptions: { [key: string]: { label: string; value: string }[] } = {
    AHU_01: [
      {
        label: 'Liquid Dispensing Staging',
        value: 'Liquid Dispensing Staging',
      },
    ],
    AHU_02: [
      { label: 'Day Store', value: 'Day Store' },
      { label: 'SFG Quranmtime', value: 'SFG Quranmtime' },
    ],
    AHU_03: [{ label: 'Suposistories', value: 'Suposistories' }],
    AHU_04: [{ label: 'Liquid Filing', value: 'Liquid Filing' }],
    AHU_05: [{ label: 'Ointment Preparation', value: 'Ointment Preparation' }],
    AHU_06: [
      { label: 'Ointment Fill and Seal', value: 'Ointment Fil and Seal' },
    ],
    AHU_07: [
      { label: 'Change Room Gents', value: 'Change Room Gents' },
      { label: 'Change Room Ladies', value: 'Change Room Ladies' },
      { label: 'Prod. Office', value: 'Prod. Office' },
      { label: 'IPQA', value: 'IPQA' },
      { label: 'Entrance Corridor', value: 'Entrance Corridor' },
      { label: 'Gents Air Lock', value: 'Gents Air Lock' },
      { label: 'Ladies Air Lock', value: 'Ladies Air Lock' },
      { label: 'Gents Hand and Foot Wash', value: 'Gents Hand and Foot Wash' },
      {
        label: 'Ladies Hand and Foot Wash',
        value: 'Ladies Hand and Foot Wash',
      },
      { label: 'Linen Room', value: 'Linen Room' },
    ],
    AHU_08: [{ label: 'Liquid Preparation', value: 'Liquid Preparation' }],
    AHU_10: [{ label: 'Sachet Filling', value: 'Sachet Filling' }],
    AHU_11: [{ label: 'Equipment Drying', value: 'Equipment Drying' }],
    AHU_12: [
      {
        label: 'Liquid Dispensing Staging',
        value: 'Liquid Dispensing Staging',
      },
    ],
  };
  times = [
    { value: '00:00', name: '00:00' },
    { value: '01:00', name: '01:00' },
    { value: '02:00', name: '02:00' },
    { value: '03:00', name: '03:00' },
    { value: '04:00', name: '04:00' },
    { value: '05:00', name: '05:00' },
    { value: '06:00', name: '06:00' },
    { value: '07:00', name: '07:00' },
    { value: '08:00', name: '08:00' },
    { value: '09:00', name: '09:00' },
    { value: '10:00', name: '10:00' },
    { value: '11:00', name: '11:00' },
    { value: '12:00', name: '12:00' },
    { value: '13:00', name: '13:00' },
    { value: '14:00', name: '14:00' },
    { value: '15:00', name: '15:00' },
    { value: '16:00', name: '16:00' },
    { value: '17:00', name: '17:00' },
    { value: '18:00', name: '18:00' },
    { value: '19:00', name: '19:00' },
    { value: '20:00', name: '20:00' },
    { value: '21:00', name: '21:00' },
    { value: '22:00', name: '22:00' },
    { value: '23:00', name: '23:00' },
  ];
  
  response1: any;
  selectedAhuRooms: { ahu: string; rooms: string[] }[] = [];
  chillerRoomOptions: { [key: string]: { label: string; value: string }[] } = {
    // 'Chiller-1 (WC)': [
    //   { label: 'Room1', value: 'Room1' },
    //   { label: 'Room2', value: 'Room2' },
    // ],
    // 'Chiller-2 (WC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
    // 'Chiller-3 (WC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
    // 'Chiller-4 (AC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
  };

  constructor(private cdr: ChangeDetectorRef, private snackbar:MatSnackBar, private router:Router, private http: HttpClient) {}

  async SendData() {
    this.anyerror = false;

    // Validation for required fields
    if (!this.selectedAHUs) {
      this.anyerror = true;
      alert('AHU selection is required.');
      return;
    }

    if (!this.fromDate) {
      this.anyerror = true;
      alert('From Date is required.');
      return;
    }

    if (!this.fromTime) {
      this.anyerror = true;
      alert('From Time is required.');
      return;
    }

    if (!this.toDate) {
      this.anyerror = true;
      alert('To Date is required.');
      return;
    }

    if (!this.toTime) {
      this.anyerror = true;
      alert('To Time is required.');
      return;
    }

    if (this.fromDate > this.toDate) {
      this.anyerror = true;
      alert('From date should be less than To date');
      return;
    }
    console.log(this.fromDate, 'check2');
    const fromDatetime =
      this.fromDate && this.fromTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.fromDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
            const newDateString = date.toISOString().split('T')[0];
            const formattedTime = this.convertTo24HourFormat(this.fromTime);
            return `${newDateString} ${formattedTime}`;
          })()
        : '';
this.convfromDate=    this.fromDate
? (() => {
    // Create a Date object from the user input
    const date = new Date(this.fromDate);

    // Increase the date by 1 day
    date.setDate(date.getDate() + 1);
    const newDateString = date.toISOString().split('T')[0];
    return `${newDateString}`;
  })()
: '';
    const toDatetime =
      this.toDate && this.toTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.toDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);

            // Format the adjusted date as "YYYY-MM-DD"
            const newDateString = date.toISOString().split('T')[0];

            // Convert the time to 24-hour format
            const formattedTime = this.convertTo24HourFormat(this.toTime);

            // Combine the adjusted date with the formatted time
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

        this.convtoDate =
        this.toDate
          ? (() => {
              // Create a Date object from the user input
              const date = new Date(this.toDate);
  
              // Increase the date by 1 day
              date.setDate(date.getDate() + 1);
  
              // Format the adjusted date as "YYYY-MM-DD"
              const newDateString = date.toISOString().split('T')[0];
  
              // Convert the time to 24-hour format
  
              // Combine the adjusted date with the formatted time
              return `${newDateString}`;
            })()
          : '';

    console.log('check from', fromDatetime);
    console.log('check to', toDatetime);

    const data = {
      from_datetime: fromDatetime,
      to_datetime: toDatetime,
      deviceid: this.selectedAHUs,
    };

    this.showData = true;
    this.isloading=true;

    this.http
      .post<any[]>(`${environment.apiBaseUrl}/auth/fetchlogs`, data)
      .subscribe(
        (response: any[]) => {
          this.isloading=false;
          console.log('received response', response);
          console.log(data);

          this.response1 = {
            timestamps: response.map((item) => item['time']),
            ahuData: response.map((item) => item['deviceid']),
            acphData: response.map((item) => item['acph']),
            dpData: response.map((item) => item['room_dp']),
            humidityData: response.map((item) => item['room_humidity']),
            tempData: response.map((item) => item['room_temp']),
            ratData: response.map((item) =>
              item['rat'] !== null ? item['rat'] : 'NA'
            ),
            rarhData: response.map((item) =>
              item['rh'] !== null ? item['rh'] : 'NA'
            ),
          };

          this.tableData = this.showtable(
            this.response1.timestamps,
            this.response1.acphData,
            this.response1.ahuData,
            this.response1.dpData,
            this.response1.humidityData,
            this.response1.tempData,
            this.response1.ratData,
            this.response1.rarhData
          );

          if (this.tableData.length == 0) {
            this.aftermessage = 'No data in given Range';
            this.openSnackBar('No data  in given Range');
            
          }
        },

         (error) => {
          this.isloading=false;
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          this.numberoflogs = false;
          console.log(this.numberoflogs);
          console.log(data);
          console.error('Error:', error);
        }
      );
  }

  convertTo24HourFormat(time: string | null): string | null {
    if (!time) return null;

    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':');
    return `${hours}:${minutes}:00`;
  }

  showtable(
    timedata: string[],
    acphData: number[],
    ahuData: string[],
    dpData: number[],
    humidityData: any[],
    tempData: any[],
    ratData: any[],
    rarhData: any[]
  ) {
    return timedata.map((time, index) => {
      const date = new Date(time);
      return {
        serialNo: index + 1,
        date: date.toLocaleDateString(), // Date in user's locale format
        time: date.toLocaleTimeString('en-IN', { hour12: false }), // Time in 24-hour format
        ahu: ahuData[index],
        acph: acphData[index],
        dp: dpData[index],
        humidity: humidityData[index],
        temp: tempData[index],
        rat: ratData[index],
        rarh: rarhData[index],
      };
    });
  }
  openSnackBar(message: string) {
    this.snackbar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  downloadXLSX() {
    const headers = [
      'S No.',
      'Date',
      'Time',
      'AHU',
      'Catering Rooms',
      'RAT (°C)',
      'RT (°C)',
      'RARH (%)',
      'RH (%)',
      'RDP (Pa)',
      'ACPH',
    ];

    // Get current time in IST
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30
    const istTime = new Date(now.getTime());
    const istDate = istTime.toLocaleDateString('en-IN', {
      day: '2-digit',    // Two-digit day
      month: '2-digit',  // Two-digit month
      year: 'numeric'    // Full year
    });
    const istTimeString = istTime.toLocaleTimeString('en-IN', { hour12: false }); // Format time as per IST

    // User information
    const username = sessionStorage.getItem('username'); // Replace with actual username variable
    const firstRow = [
      `Date: ${istDate}`,
      `Time: ${istTimeString}`,
      `Generated by: ${username}`,
    ];

    const wsData = [];
    wsData.push(firstRow); // Add first row to XLSX
    wsData.push(headers); // Add headers

    this.tableData.forEach((row) => {
      const values = [
        row.serialNo,
        row.date,
        row.time,
        row.ahu,
        'NA',
        row.rat,
        row.temp,
        row.rarh,
        row.humidity,
        row.dp,
        row.acph,
      ];
      wsData.push(values);
    });

    // Create XLSX file
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Custom column widths
    ws['!cols'] = [
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 80 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
    ];

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    // Convert to Blob
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const xlsxBlob = new Blob([wbout], { type: 'application/octet-stream' });

    // Send the XLSX file to the backend
    const formData = new FormData();
    formData.append('file', xlsxBlob, 'data.xlsx');

    // Make the HTTP POST request
    this.http
      .post(`${environment.apiBaseUrl}/encrypt/xlsx`, formData, {
        responseType: 'blob',
      })
      .subscribe(
        (response: Blob) => {
          // Create a download link for the received encrypted XLSX file
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `Ahu_history_log_${this.convfromDate}_${this.convtoDate}.xlsx`; // Encrypted file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error:', error);
        }
      );
  }

  downloadPDF() {
    const doc = new jsPDF('p', 'mm', 'a4');

    const imgWidth = 200;
    const imgHeight = 200;

    // Calculate the center of the page for the image
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const xOffset = (pageWidth - imgWidth) / 2;
    const yOffset = (pageHeight - imgHeight) / 2;

    // Set font size and color for "Powered by"
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Powered by', 10, 20);

    // Set font, color, and bold style for "Bert Labs"
    doc.setTextColor(255, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text('Bert Labs', 35, 20);

    // Set some space and write the "Strides" text with similar styling
    doc.setTextColor(0, 128, 0); // Reset color to black
    doc.setFont('helvetica', 'bold'); // Use bold font style
    doc.text('Strides', 75, 20); // Adjust position as needed

    // Get current time in IST
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30
    const istTime = new Date(now.getTime());
    const istDate = istTime.toLocaleDateString('en-IN', {
      day: '2-digit',    // Two-digit day
      month: '2-digit',  // Two-digit month
      year: 'numeric'    // Full year
    });
    const istTimeString = istTime.toLocaleTimeString('en-IN', { hour12: false });

    // User information (assume username is available)
    const username = sessionStorage.getItem('username'); // Replace with actual username variable

    // Add user information and current date/time
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Generated by: ${username}`, 10, 30); // Display username
    doc.text(`Date: ${istDate}`, 10, 35); // Display date
    doc.text(`Time: ${istTimeString}`, 10, 40); // Display time

    // Headers for the table
    const headers = [
      'S No. ',
      'Date',
      'Time',
      'AHU',
      'RAT (°C)',
      'RT (°C)',
      'RARH (%)',
      'RH (%)',
      'RDP (Pa)',
      'ACPH',
    ];
    const columnWidths = [12, 20, 20, 20, 20, 25, 20, 25, 25, 20];
    let yPos = 60; // Start table at a new position after user info
    let xPos = 10;
    const lineHeight = 7;

    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);

    // Draw table headers
    headers.forEach((header, index) => {
      doc.text(header, xPos, yPos);
      xPos += columnWidths[index];
    });

    yPos += lineHeight; // Increment yPos to the next line

    // Add table data
    this.tableData.forEach((row) => {
      const values = [
        row.serialNo,
        row.date,
        row.time,
        row.ahu,
        row.rat,
        row.temp,
        row.rarh,
        row.humidity,
        row.dp,
        row.acph,
      ];

      xPos = 10; // Reset xPos for each new row
      values.forEach((value, index) => {
        doc.text(String(value), xPos, yPos);
        xPos += columnWidths[index]; // Adjust xPos by the width of each column
      });

      yPos += lineHeight; // Increment yPos to the next line

      // Check if the yPos exceeds the page height and create a new page if needed
      if (yPos > pageHeight - 20) {
        // 20 mm margin from the bottom
        doc.addPage(); // Add a new page
        yPos = 20; // Reset yPos to the top of the new page after a margin

        // Reset xPos and add headers to the new page
        xPos = 10;
        headers.forEach((header, index) => {
          doc.text(header, xPos, yPos);
          xPos += columnWidths[index];
        });
        yPos += lineHeight; // Increment yPos after headers
      }
    });

    // Convert the PDF to a Blob
    const pdfBlob = doc.output('blob');
    this.isloading=true;
    // Create FormData to send the PDF to the backend
    const formData = new FormData();
    formData.append('file', pdfBlob, 'data.pdf');

    // Make the HTTP POST request to send the PDF to the backend
    this.http
      .post(`${environment.apiBaseUrl}/encrypt/pdf`, formData, {
        responseType: 'blob',
      })
      .subscribe(
        (response: Blob) => {
          this.isloading=false;

          // Create a download link for the received encrypted PDF file
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `Ahu_history_log_${this.convfromDate}_${this.convtoDate}.pdf`; // Name for the encrypted file
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
         (error) => {
          this.isloading=false;
            this.openSnackBar("Unable to download pdf please try again")
       
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error:', error);
        }
      );
  }

  onPageChange(page: number) {
    this.currentPage = page;
  }

  onItemsPerPageChange() {
    this.currentPage = 1;
  }
}
