<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img src="/assets/strides-images/sec pumps.svg" class="fmap">


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 79.3%; left: 60.3%; width: 40px; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump1 | number: '1.2-2'" (ngModelChange)=" vfd_pump1 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                      </div>

                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 72.4%; left: 73.4%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump2 | number: '1.2-2'" (ngModelChange)=" vfd_pump2 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 65.5%; left: 83%;width: 40px;height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump3 | number: '1.2-2'" (ngModelChange)=" vfd_pump3 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 41.3%; left: 80.6%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump4 | number: '1.2-2'" (ngModelChange)=" vfd_pump4 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>
                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 29.8%; left: 71.3%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump5 | number: '1.2-2'" (ngModelChange)=" vfd_pump5 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>
                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 19%; left: 55.7%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump6 | number: '1.2-2'" (ngModelChange)=" vfd_pump6 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>

                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 41.4%; left: 12.7%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump7 | number: '1.2-2'" (ngModelChange)=" vfd_pump7 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>



                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 71.7%; left: 26%; width: 40px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump8 | number: '1.2-2'" (ngModelChange)=" vfd_pump8 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 29.9%; left: 21.8%; width: 40px; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump9 | number: '1.2-2'" (ngModelChange)=" vfd_pump9 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:0" >%</span>
                      </div>




                                <!-- sec_pump_power -->


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 79.3%; left: 66.7%; width: 45px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump1 | number: '1.2-2'" (ngModelChange)=" power_pump1 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 72.4%; left: 79.4%;width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump2 | number: '1.2-2'" (ngModelChange)=" power_pump2 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>

                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 65.5%; left: 89%; width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump3 | number: '1.2-2'" (ngModelChange)=" power_pump3 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 41.3%; left: 86.6%; width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump4 | number: '1.2-2'" (ngModelChange)=" power_pump4 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 29.8%; left: 77.4%; width: 45px;  height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump5 | number: '1.2-2'" (ngModelChange)=" power_pump4 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                      </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 18.8%; left: 61.9%; width: 45px;  height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump6 | number: '1.2-2'" (ngModelChange)=" power_pump4 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                      </div>


                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 41.4%; left: 18.7%;width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump7 | number: '1.2-2'" (ngModelChange)=" power_pump7 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>



                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 71.7%; left: 32%;width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump8 | number: '1.2-2'" (ngModelChange)=" power_pump8 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 29.7%; left: 28.8%; width: 45px;  height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" power_pump9 | number: '1.2-2'" (ngModelChange)=" power_pump8 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:0" >kw</span>
                        </div>



                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 76.7%; left: 60.3%; width: 41px;  height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_1_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_1_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 70%; left: 73.4%;  width: 41px;height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_2_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_2_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 63%; left: 83%;  width: 41px;height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_3_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_3_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 38.7%; left: 80.6%;  width: 41px; height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_4_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_4_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 27.2%; left: 71.3%; width: 41px; height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" VFD_for_secondary_pump_5_out | number: '1.2-2'" (ngModelChange)=" VFD_for_secondary_pump_5_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 16.3%; left: 55.8%;  width: 41px;height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" VFD_for_secondary_pump_6_out | number: '1.2-2'" (ngModelChange)=" VFD_for_secondary_pump_6_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 38.8%; left: 12.7%;  width: 41px; height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_7_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_7_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 69%; left: 26%;  width: 41px; height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" secondary_pump_8_vfd_feedback_out | number: '1.2-2'" (ngModelChange)=" secondary_pump_8_vfd_feedback_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 27.2%; left: 21.8%;  width: 41px; height: 3%;">
                          <input readonly style="color:#00A578; font-weight:600" [ngModel]=" VFD_for_secondary_pump_9_out | number: '1.2-2'" (ngModelChange)=" VFD_for_secondary_pump_9_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:0" >%</span>
                        </div>


                        <!-- rl_power -->

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 76.7%; left: 66.8%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump1_out | number: '1.2-2'" (ngModelChange)=" power_pump1_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 70%; left: 79.4%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump2_out | number: '1.2-2'" (ngModelChange)=" power_pump2_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 63%; left: 89%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump3_out | number: '1.2-2'" (ngModelChange)=" power_pump3_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>


                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 38.7%; left: 86.6%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump4_out | number: '1.2-2'" (ngModelChange)=" power_pump4_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 27.2%; left: 77.5%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump5_out | number: '1.2-2'" (ngModelChange)=" power_pump5_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 16.3%; left: 62%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump6_out | number: '1.2-2'" (ngModelChange)=" power_pump6_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 38.8%; left: 18.8%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump7_out | number: '1.2-2'" (ngModelChange)=" power_pump7_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 69%; left: 32%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump8_out | number: '1.2-2'" (ngModelChange)=" power_pump8_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 27.2%; left: 28.8%; width: 45px; height: 3%;">
                          <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" power_pump9_out | number: '1.2-2'" (ngModelChange)=" power_pump9_out = $event" />
                          <span style="font-size: 80%; color: #293AC6; font-weight:0" >kw</span>
                        </div>

                           <!-- ch.w.temp -->
                           <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 83.6%; left: 46%; width: 3.5%; height: 3%;">
                            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" Ch_w_temp | number: '1.2-2'" (ngModelChange)=" Ch_w_temp = $event" />
                          </div>
                          <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 83.3%; left: 48.2%; ">
                            °C
                          </div>















                </div>
            </div>
        </div>
    </div>
</div>






                    <!-- <div class="component" style="width: 5%; height: 15%; top: 54%;left: 34.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11kw
                        </button>
                      </div>

                      <div class="component" style="width: 5%; height: 15%; top: 54%;left: 47.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11kw
                        </button>
                      </div>
                      <div class="component" style="width: 5%; height: 15%; top: 54%;left: 60.5%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11kw
                        </button>
                      </div>

                      <div class="component" style="width: 6%; height: 15%; top: 46.5%;left: 13%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            37.5kw
                        </button>
                      </div>

                      <div class="component" style="width: 5%; height: 15%; top: 46.5%;left: 80.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            18.5kw
                        </button>
                      </div>


                      <div class="component" style="width: 5%; height: 10%; top: 35.5%;left: 71.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 80%;
                            color: white;

                            z-index: 999;
                            " >
                            18.5kw
                        </button>
                      </div>

                      <div class="component" style="width: 4%; height: 8%; top: 30%;left: 66.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 70%;
                            color: white;

                            z-index: 999;
                            " >
                            18.5kw
                        </button>
                      </div>

                      <div class="component" style="width: 3.7%;height: 7%; top: 26.5%;left: 61.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 60%;
                            color: white;

                            z-index: 999;
                            " >
                            18.5kw
                        </button>
                      </div>


                      <div class="component" style="width: 5%; height: 10%; top: 35.5%;left: 25.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 80%;
                            color: white;

                            z-index: 999;
                            " >
                            37.5kw
                        </button>
                      </div> -->
