<div class="savings" >
    <div style="
       display: flex;
        position: relative;
        top: 40px;
        left: 0px;
        width: 355px;
        height: 40px;
        background: radial-gradient(circle, #5fb2b2, #429494);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color:white;
        justify-content:center;
        align-items:center;
        font-size:large;
        z-index:5;">
        SAVINGS %
       </div>
    <div
      class="form"
      style="
       padding-top:40px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        flex-direction: column;
        gap: 0px;
        align-items: start;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
        border: 1.5px solid var(--blue-one);
      "
    >
       
      <div
        style="
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-evenly;
        "
      >
        <!-- Chilled Water Inlet Temperature -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Chiller (TR)</span
          >

          <div class="input-group" style="width: 100px">
            
            <input [(ngModel)]="CHWRT" />
            <img src="/assets/images/percentage.png" />
          </div>
        </div>

        <!-- CHW Valve Chilled Water Valve Openiong Percentage-->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Chiller (kWh)</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="CHWVP" />
            <img src="/assets/images/percentage.png" />
          </div>
        </div>

        <!-- FAN SPEED -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Chiller Pump (kWh)</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <img src="/assets/images/percentage.png" />
            </div>
        </div>

       
      </div>
      <div
        style="
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        
        "
      >
        <!-- Chilled Water Inlet Temperature -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Hot Water Pump</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="CHWRT" />
          <img src="/assets/images/temp.svg" />
            </div>
        </div>

        <!-- CHW Valve Chilled Water Valve Openiong Percentage-->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Cool Reduction (T)</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="CHWVP" />
            <img src="/assets/images/percentage.png" />
           </div>
        </div>

        

       
      </div>
      
    </div>
    <!-- <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div> -->
  </div>









  <!-- Input-form -->
  <div
  style="
    height: auto;
    width: 100%;
    min-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    align-items: center;
    gap: 10px;
  "
>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: row;
      height: auto;
      justify-content: center;
    "
  >
  <div style="width: auto; height: 100%; margin: -60px 20px 0px 0px;  margin-top: 0vh;">
    <div style="
       display: flex;
        position: relative;
        top: 30px;
        left: 0px;
        width: auto;
        height: 30px;
        background: radial-gradient(circle, #5fb2b2, #429494);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color:white;
        justify-content:center;
        align-items:center;
        font-size:large;
        z-index:5;">
        INPUTS 
       </div>
    <div
      class="form"
      style="
       padding-top:40px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        flex-direction: row;
        gap: 10px;
        align-items: start;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
        border: 1.5px solid var(--blue-one);
      "
    >
       
      <div
        style="
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-evenly;
        "
      >
        <!-- RAT -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >RAT</span
          >

          <div class="input-group" style="width: 100px">
            
            <input [(ngModel)]="CHWRT" />
            <img src="/assets/images/temp.svg" />
          </div>
        </div>

        <!-- RA-RH-->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >RA-RH</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="CHWVP" />
            <img src="/assets/images/percentage.png" />
          </div>
        </div>

        <!-- CHW Valve -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >CHW Valve</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <img src="/assets/images/percentage.png" />
            </div>
        </div>



        <!-- HW Valve -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >HW Valve</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <img src="/assets/images/percentage.png" />
            </div>
        </div>



        <!-- Supply Air -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Supply Air</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <!-- <img src="/assets/images/percentage.png" /> -->
          <span class="input-groupX-span" style="width: 40px; " >CMH</span>    
        </div>
        </div>


        <!-- CHW inlet Temp. -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >CHW Inlet Temp.</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <img src="/assets/images/temp.svg" />
            </div>
        </div>

        <!-- AHU Heat Load -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >AHU Heat Load</span
          >

          <div class="input-group" style="width: 100px">
            <input [(ngModel)]="VFD_freq" />
          <!-- <img src="/assets/images/percentage.png" /> -->
          <span class="input-groupX-span"  >TR</span>    
        </div>
        </div>



       
      </div>
      <div
        style="
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0px;
        
        "
      >
      <div
      style="
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 0px;
        justify-content: end;
        margin-top: 0px;
      "
    >
      <button
        style="
          width: 120px;
          background-color: var(--blue-one);
          border-radius: 10px;
          border: none;
          height: auto;
          padding: 7px;
          color: white;
          cursor: pointer;
        "
        (click)="predictData()"
      >
        Predict
      </button>
    </div>

    <div
    style="
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 0px;
      justify-content: end;
      margin-top: 5px;
    "
  >
    <button
      style="
        width: 120px;
        background-color: var(--blue-one);
        border-radius: 10px;
        border: none;
        height: auto;
        padding: 7px;
        color: white;
        cursor: pointer;
      "
      (click)="predictData()"
    >
      Predict
    </button>
  </div>

        

       
      </div>
      
    </div>
  </div>

  </div>
</div>