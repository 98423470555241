import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cooling-tower2',
  templateUrl: './cooling-tower2.component.html',
  styleUrls: ['./cooling-tower2.component.css']
})
export class CoolingTower2Component {
  CT_water_in: any=0;
  CT2_water_out: any=0;
  CT2_FAN_SPEED_RED: any=0;
  CT2_FAN_SPEED_GR: any=0;
  KPI: any=0;
  predictionResult: any = {};
  predict_response: any = {};


  jsonData: any = {};
  interface_input: any = {};
  errorLog: { message: string, timestamp: Date }[] = [];
  snackbars: { [key: string]: MatSnackBarRef<any> } = {};

  constructor(
    private activeroute:ActivatedRoute,
    private router: Router,
  private http: HttpClient,
  private snackBar: MatSnackBar,
  private errorMonitoringService: ErrorMonitoringService// Inject the service



  ){
    
  }


  ngOnInit(): void {
    this.getInputResult();
    // this.checkForErrors();
    this.getPredictionResult();

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;


            console.log('Interface result:', this.interface_input);
            // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            
            this.CT_water_in = this.interface_input['chw_valve_opening_01'];
            this.CT2_water_out = this.interface_input['supply_dpt_01'];
           
            
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
                      this.predictionResult = response.result;

            this.CT_water_in = this.predictionResult['chw_valve_opening_09'];
            this.CT2_water_out = this.predictionResult['fan_speed_09'];
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }


  checkForErrors() {
    // Clear previous snackbars
    this.snackbars = {};

    const errorKeys: { [key: string]: string } = {
      CT1_Water_out: `CT1_Water_out value ${this.CT2_water_out} is out of range (10-20).`,
      CT_Water_in: `CT_Water_in value ${this.CT_water_in} is out of range (11-35).`,
    };

    Object.keys(errorKeys).forEach(key => {
      if (this.isError(key)) {
        this.errorMonitoringService.logError(errorKeys[key]);
        console.log(errorKeys[key]);
        this.openSnackBar(errorKeys[key], key);
      }
    });
    console.log(this.errorMonitoringService.getErrorLog())


  }

  isError(key: string): boolean {
    switch (key) {
      case 'CT_Water_in':
        return this.CT_water_in < 10 || this.CT_water_in > 20;
      case 'CT1_Water_out':
        return this.CT2_water_out < 8 || this.CT2_water_out > 30;
          default:
        return false;
    }
  }

  openSnackBar(message: string, key: string) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message, key },
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: undefined,
    });
  }



}
