<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                <img src="/assets/predict_ahu/AC chiller.svg" class="fmap">
                  <!-- Ch.w.in -->
               

                  <!-- Needs to be changed -->
                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 51.5%; left: 19%; width: 3.5%; height: 3%;">
                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" chilled_water_set_point | number: '1.2-2'" (ngModelChange)=" chilled_water_set_point = $event" />
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 51%; left: 19.8%; width: 4%; height: 3%;">
                    <span style="font-size: 80%;  color: #D90606;  font-weight:600" >°C</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 14.75%; left: 20.9%; width: 4%; height: 3%;">
                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" chilled_water_set_point | number: '1.2-2'" (ngModelChange)=" chilled_water_set_point = $event"  />
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 14.75%; left: 21.5%; width: 4%; height: 3%;">
                    <span style="font-size: 80%;  color: #D90606;  font-weight:600" >°C</span>
                  </div> -->

                  <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 67%; left: 4.5%; width: 10%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:500" >AC.Ch.w set point</span>
                  </div> -->
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 22%; left: 17.7%; width:45px; height: 3%;">
                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" chilled_water_set_point | number: '1.2-2'" (ngModelChange)=" chilled_water_set_point = $event" />
                    
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 22%; left: 18.7%; width:45px; height: 3%;">
                    <span style="font-size: 80%;  color: #D90606;  font-weight:600" >°C</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 27%; left: 17.7%; width:45px; height: 3%;">
                    <input readonly  style=" color: #00A578;  font-weight:600" [ngModel]=" chilled_water_set_point_out | number: '1.2-2'" (ngModelChange)=" chilled_water_set_point_out = $event" />
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 27%; left: 18.7%; width:45px; height: 3%;">
                    <span style="font-size: 80%;  color: #00A578;  font-weight:600" >°C</span>
                  </div>

                  <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 86%; left: 4.5%; width: 10%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:500" >AC.Chiller Power</span>
                  </div> -->
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 6.8%; left: 17.7%; width: 45px; height: 3%;">
                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" Chiller_power_in | number: '1.2-2'" (ngModelChange)=" Chiller_power_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                  </div>

                  <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 79%; left: 4.5%; width: 10%; height: 3%;">
                    <span style="font-size: 80%; color: #00A578; font-weight:500" >AC.Chiller Power</span>
                  </div> -->
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 85%; left: 17.7%; width: 45px; height: 3%;">
                    <input readonly  style="color: #00A578; font-weight:600" [ngModel]=" Chiller_power_out | number: '1.2-2'" (ngModelChange)=" Chiller_power_out = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600" >kw</span>
                    <div></div>
                  </div>
                    <!-- Ch.w.out -->
                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 29%; left: -3.5%; width: 3.5%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:500" >Mass Avg Temp</span>
                  </div> -->
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 63%; left: 7.2%; width: 45px; height: 3%;">
                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" mass_avg | number: '1.2-2'" (ngModelChange)=" mass_avg = $event" />
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 63.1%; left: 8%; width:45px; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 69.6%; left: 7.25%; width: 40px; height: 3%;">
                    <input readonly  style="color: #293AC6;  font-weight:600" [ngModel]=" mass_avg | number: '1.2-2'" (ngModelChange)=" mass_avg = $event" />
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 69.7%; left: 8%; width:45px; height: 3%;">
                    <span style="font-size: 80%; color: #293AC6; font-weight:600" >°C</span>
                  </div>
                  

                

            </div>
            <div class="form component">
              <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
      
              <div style="height: 40px;"></div>
              <div  class="hvac-text">
              <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
              <div style="width:25%; ">
                  <input readonly style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event" />
              </div>
              <span style="margin-left:-10%; font-size: 100%;">%</span>
              </div>
          </div> 
            </div>
        </div>
    </div>

</div>
