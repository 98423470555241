import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent {
  isMinimized = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    private router: Router
  ) {}

  dismiss() {
    this.snackBarRef.dismiss();
    console.log("snack bar broooooooo",this.data)
  }
  closeMessage(error: any) {
    console.log("oracleeeeeeeeeee",error);
    error.isActive = false; // Set isClosed to true when the close button is clicked
  }
  dismissAll() {
    this.snackBarRef.dismiss();
  }
  // closeAlarm(alarmId: number): void {
  //   const alarm = this.data.message.find(a => a.id === alarmId);
  //   if (alarm) {
  //     alarm.isActive = false;
  //   }
  // }
  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
  }


}
