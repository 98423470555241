<mat-progress-bar
  mode="indeterminate"
  [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  style="width: 100%"
></mat-progress-bar>

<div style="display: flex; flex-direction: row; height: 100%; width: 100%; position: relative; background-color: #FFFDF2; ">
  <!-- <Water-mark></Water-mark> -->
  <div
    #widgetsContent
    style="
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      overflow-y: scroll;
      overflow-x: scroll;
      scroll-behavior: smooth;
      background-color: #FFFDF2;
      display: flex;
      overflow-x: 100px;
      scroll-snap-points-x: 200px;
      flex-direction: column;
    "
  >
    <!-- chiller plant connections slab-->

    <div class="map-container">
      <img class="fmap" src="../../../assets/images/strides-chiller.svg"/>

      <!--primary pump 1-->
      <div class="component"  style="height:5%; top: 55.9%; left: 24%;  transform: rotate(-90deg); ">
          <Primary-pump></Primary-pump>
      </div>
      
      <!--primary pump 2-->
      <div class="component"  style="height:5%; top: 55.9%; left: 30%;  transform: rotate(-90deg); ">
        <Primary-pump></Primary-pump>
      </div>
      <!--primary pump 3-->
      <div class="component"  style="height:5%; top: 55.9%; left: 36%;  transform: rotate(-90deg); ">
        <Primary-pump></Primary-pump>
      </div>
      <!--primary pump 4-->
      <div class="component"  style="height:5%; top: 55.9%; left: 42.5%;  transform: rotate(-90deg); ">
        <Primary-pump></Primary-pump>
      </div>
      <!--condensor pump 1-->
      <div class="component"  style="height:5%; top: 77%; left: 68.5%;  transform: rotate(-90deg); ">
        <Condensor-pump></Condensor-pump>
      </div>
      <!--condensor pump 2-->
      <div class="component"  style="height:5%; top: 77%; left: 75%;  transform: rotate(-90deg); ">
        <Condensor-pump></Condensor-pump>
      </div>
      <!--condensor pump 3-->
      <div class="component"  style="height:5%; top: 77%; left: 81%;  transform: rotate(-90deg); ">
        <Condensor-pump></Condensor-pump>
      </div>
      <!--condensor pump 4-->
      <div class="component"  style="height:5%; top: 77%; left: 87%;  transform: rotate(-90deg); ">
        <Condensor-pump></Condensor-pump>
      </div>

      <div class="component"  style="height:4%; top: 19%; left: 2.5%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 9.5%; left: 9%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 9.5%; left: 15%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 9.5%; left: 21.5%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 9.5%; left: 28%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 21.5%; left: 9%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 21.5%; left: 15%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 21.5%; left: 21.5%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>
      <div class="component"  style="height:4%; top: 21.5%; left: 28%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>

      <div class="component"  style="height:4%; top: 8.6%; left: 81.5%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>

      <div class="component"  style="height:4%; top: 8.6%; left: 87.7%;  transform: rotate(-90deg); ">
        <Secondary-pump></Secondary-pump>
      </div>

      <div class="component" style="width: 10%; height: 40%; top: 79%;left: 2%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../chiller-room">
          CHILLER-ROOM
        </button>
      </div>

      <div class="component" style="width: 10%; height: 40%; top: 49%;left: 95%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../cooling-towers">
          Cooling towers
        </button>
      </div>

      <div class="component" style="width: 10%; height: 10%; top: 89%;left: 47%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../chiller3">
          CHILLER-3 (WC)
        </button>
      </div>
      <div class="component" style="width: 10%; height: 10%; top: 89%;left: 32%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../chiller1">
          CHILLER-1 (WC)
        </button>
      </div>
      <div class="component" style="width: 10%; height: 10%; top: 89%;left: 17%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../chiller2">
          CHILLER-2 (WC)
        </button>
      </div>

      <div class="component" style="width: 10%; height: 10%; top: 37.5%;left: 82%;  font-size: 120%;">
        <button style="
            border-radius: 5px;
            background-color: var(--blue-one);
            border: none;
            height: 40%;
            width: 100%;
            font-size: 70%;       
            color: white;
            cursor: pointer;
          
            z-index: 999;
            " routerLink="../chiller4">
          CHILLER-4(AC)
        </button>
      </div>


          <!-- chiller-plant-component-slab -->        
        <!-- 1.  -->
      
        <!-- 2.  -->
        
        <!-- 3.  -->
       
        <!-- 4.  -->
        
        <!-- 5.  -->
        
        <!-- 6.  -->
        
        <!-- 7.  -->
        
        <!-- 8.  -->
        
        <!-- 3.  -->

  
      
    </div> 
  </div>

  <div style="position: relative;">
    <button [ngClass]="{'formbutton':isFormCollapsed, 'closebutton':!isFormCollapsed}" (click)="toggleFormCollapse()"> {{isFormCollapsed?'Form':'X'}} </button>

  </div>
  <div class="form-container"
  [ngClass]="{'collapsed': isFormCollapsed}"
    style="
      width: 760px;

      height: 93%;
      box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.06),
        0px 0px 2px rgba(25, 25, 25, 0.12);
       overflow-y:scroll;
    "
    [ngClass]="{'collapsed':isFormCollapsed}"
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        gap: 24px;
       
       
      "
    >
   
          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-top:30px; ">
            <!-- CHiller 1 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Chiller-1(WC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                CHILLER-1(WC)
              </div>

              <!-- CHiller-1(WC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled1 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled1" (click)="toggleFormEnabled1()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled1 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
            <!-- CHiller 2 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Chiller-2(WC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                CHILLER-2(WC)
              </div>
              <!-- CHiller-2(WC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled1 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled2" (click)="toggleFormEnabled2()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled2 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
           

          </div>

          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-bottom:20px;">
            <!-- CHiller 3 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Chiller-3(WC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                CHILLER-3(WC)
              </div>
              <!-- CHiller-3(WC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled1 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled3" (click)="toggleFormEnabled3()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled3 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
            <!-- CHiller 4 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Chiller-4(AC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                CHILLER-4(AC)
              </div>
              <!-- CHiller-4(AC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled1 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled4" (click)="toggleFormEnabled4()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled4 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Primary Pump-01(AC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-01(AC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-01(AC)
              </div>

              <!-- Primary Pump-01(AC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled1 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled5" (click)="toggleFormEnabled5()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled5 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
              <!-- 1. VFD Operation (0-50) input capsule -->
              <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
              <div [ngClass]="{ 'input-groupD': !formEnabled5, 'input-groupR': formEnabled5 }" style="width: 100px; margin-right:10px; height: 30px;">
                <div [ngClass]="{'input-group-formulaD': !formEnabled5, 'input-group-formula': formEnabled5}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                <input
                  [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled5"
                  (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                  style="background-color: white; color: {{ formEnabled5 ? 'black' : 'white' }};"
                />  
              </div>
            </div>

            </div>
            <!-- Primary Pump-02(AC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-02(AC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-02(AC)
              </div>

              <!-- Primary Pump-02(AC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled6 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled6" (click)="toggleFormEnabled6()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled6 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled6, 'input-groupR': formEnabled6 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled6, 'input-group-formula': formEnabled6}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled6"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled6 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Primary Pump-03(AC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-03(AC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-03(AC)
              </div>

              <!-- Primary Pump-03(AC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled7 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled7" (click)="toggleFormEnabled7()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled7 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled7, 'input-groupR': formEnabled7 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled7, 'input-group-formula': formEnabled7}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled7"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled7 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Primary Pump-04(AC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-04(AC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-04(AC)
              </div>

              <!-- Primary Pump-04(AC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled8 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled8" (click)="toggleFormEnabled8()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled8 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled8, 'input-groupR': formEnabled8 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled8, 'input-group-formula': formEnabled8}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled8"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled8 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-bottom:20px;">
            <!-- Primary Pump-05(WC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-05(WC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-05(WC)
              </div>

              <!-- Primary Pump-05(WC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled9 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled9" (click)="toggleFormEnabled9()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled9 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled9, 'input-groupR': formEnabled9 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled9, 'input-group-formula': formEnabled9}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled9"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled9 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Primary Pump-06(WC) -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Primary Pump-06(WC) -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Primary Pump-06(WC)
              </div>

              <!-- Primary Pump-06(WC) On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled10 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled10" (click)="toggleFormEnabled10()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled10 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled10, 'input-groupR': formEnabled10 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled10, 'input-group-formula': formEnabled10}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled10"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled10 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Secondary Pump-1 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-1 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-1
              </div>

              <!-- Secondary Pump-1 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled11 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled11" (click)="toggleFormEnabled11()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled11 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled11, 'input-groupR': formEnabled11 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled11, 'input-group-formula': formEnabled11}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled11"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled11 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Secondary Pump-2 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-2 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-2
              </div>

              <!-- Secondary Pump-2 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled12 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled12" (click)="toggleFormEnabled12()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled12 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled12, 'input-groupR': formEnabled12 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled12, 'input-group-formula': formEnabled12}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled12"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled12 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Secondary Pump-3 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-3 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-3
              </div>

              <!-- Secondary Pump-3 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled13 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled13" (click)="toggleFormEnabled13()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled13 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled13, 'input-groupR': formEnabled13 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled13, 'input-group-formula': formEnabled13}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled13"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled13 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Secondary Pump-4 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-4 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-4
              </div>

              <!-- Secondary Pump-4 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled14 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled14" (click)="toggleFormEnabled14()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled14 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled14, 'input-groupR': formEnabled14 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled14, 'input-group-formula': formEnabled14}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled14"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled14 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Secondary Pump-5 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-5 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-5
              </div>

              <!-- Secondary Pump-5 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled15 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled15" (click)="toggleFormEnabled15()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled15 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled15, 'input-groupR': formEnabled15 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled15, 'input-group-formula': formEnabled15}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled15"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled15 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Secondary Pump-6 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-6 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-6
              </div>

              <!-- Secondary Pump-6 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled16 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled16" (click)="toggleFormEnabled16()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled16 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled16, 'input-groupR': formEnabled16 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled16, 'input-group-formula': formEnabled16}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled16"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled16 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Secondary Pump-7 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-7 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-7
              </div>

              <!-- Secondary Pump-7 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled17 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled17" (click)="toggleFormEnabled17()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled17 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled17, 'input-groupR': formEnabled17 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled17, 'input-group-formula': formEnabled17}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled17"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    [ngStyle]="{ 'background-color': 'white', 'color': formEnabled17 ? 'black' : 'white' }"
                  />  
                </div>
              </div>

            </div>
            <!-- Secondary Pump-8 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-8 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-8
              </div>

              <!-- Secondary Pump-8 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled18 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled18" (click)="toggleFormEnabled18()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled18 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled18, 'input-groupR': formEnabled18 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled18, 'input-group-formula': formEnabled18}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled18"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled18 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-bottom:20px;">
            <!-- Secondary Pump-9 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Secondary Pump-9 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Secondary Pump-9
              </div>

              <!-- Secondary Pump-9 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled19 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled19" (click)="toggleFormEnabled19()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled19 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled19, 'input-groupR': formEnabled19 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled19, 'input-group-formula': formEnabled19}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled19"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled19 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px;">
            <!-- Condenser Pump-01 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Condenser Pump-01 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Condenser Pump-01
              </div>

              <!-- Condenser Pump-01 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled20 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled20" (click)="toggleFormEnabled20()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled20 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled20, 'input-groupR': formEnabled20 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled20, 'input-group-formula': formEnabled20}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled20"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled20 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
            <!-- Condenser Pump-02 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Condenser Pump-02 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Condenser Pump-02
              </div>

              <!-- Condenser Pump-02 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled21 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled21" (click)="toggleFormEnabled21()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled21 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled21, 'input-groupR': formEnabled21 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled21, 'input-group-formula': formEnabled21}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled21"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled21 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-bottom:20px;">
            <!-- Condenser Pump-03 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Condenser Pump-03 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Condenser Pump-03
              </div>

              <!-- Condenser Pump-03 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled22 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled22" (click)="toggleFormEnabled22()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled22 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled22, 'input-groupR': formEnabled22 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled22, 'input-group-formula': formEnabled22}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled22"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled22 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>


            </div>
            <!-- Condenser Pump-04 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- Condenser Pump-04 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                Condenser Pump-04
              </div>

              <!-- Condenser Pump-04 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled23 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled23" (click)="toggleFormEnabled23()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled23 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>

              <!-- VFD Operation (0-50) -->
              <div style="display: flex;justify-content: space-between; ">
                <!-- 1. VFD Operation (0-50) input capsule -->
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">VFD Operation (0-50)</div>
                <div [ngClass]="{ 'input-groupD': !formEnabled23, 'input-groupR': formEnabled23 }" style="width: 100px; margin-right:10px; height: 30px;">
                  <div [ngClass]="{'input-group-formulaD': !formEnabled23, 'input-group-formula': formEnabled23}" style="font-size: 14px; line-height: 10px; font-weight:600">Hz</div>
                  <input
                    [ngModel]="Supply_temp_After_Sec_Pump_Zone_A " [disabled]="!formEnabled23"
                    (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A = $event"
                    style="background-color: white; color: {{ formEnabled23 ? 'black' : 'white' }};"
                  />  
                </div>
              </div>

            </div>
           

          </div>
          <div style="display: flex; flex-direction:row; width:100%; gap:10px; margin-bottom:20px;">
            <!-- COOLING TOWER-1 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- COOLING TOWER-1 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                COOLING TOWER-1
              </div>
              <!-- COOLING TOWER-1 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled24 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled24" (click)="toggleFormEnabled24()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled24 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
            <!-- CHiller 2 -->
            <!-- COOLING TOWER-2 -->
            <div style="display: flex; flex-direction:column; width: 50%; gap:5px;">
              <!-- COOLING TOWER-2 -->
              <div style="font-size: 16px; font-weight:500; color:#0B3876; width:100% ">
                COOLING TOWER-2
              </div>
              <!-- COOLING TOWER-2 On/Off Status switch -->
              <div style="display: flex; justify-content:space-between; ">
                <div style="display:flex; font-size: 12px; font-weight:normal; color:#0B3876; align-items:center;">ON/OFF STATUS</div>
                <div style="display: flex; flex-direction: column;  gap: 5px; justify-content: end; ">
                  <!-- <span class="blue-text" style="font-size: 15px; color: var(--blue-one); width:120px; display:flex; justify-content:center; align-items:center;">
                      {{ formEnabled25 ? 'Enabled' : 'Disabled' }}
                  </span> -->
                  <label class="switch">
                      <input type="checkbox" [(ngModel)]="formEnabled25" (click)="toggleFormEnabled25()">
                      <span class="slider round">
                          <span class="slider-text">{{ formEnabled25 ? 'ON' : 'OFF' }}</span>
                      </span>
                  </label>
                </div>
              </div>
            </div>
           

          </div>

          



      

      <div
        style="
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 10px;
          margin-top: 5px;
          padding: 5px 5px 5px 5px;
          gap: 10px;
          margin-bottom: 10px;
        "
      >
        <button
          style="
            width: 50%;
            background-color: var(--blue-one);
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
          "
          (click)="predictData()"
        >
          Predict
        </button>
        <button
          style="
            width: 50%;
            border-radius: 20px;
            border: none;
            height: auto;
            padding: 7px;
            color: white;
            cursor: pointer;
          "
          [ngClass]="{
            'button-disabled': disableOptimise,
            'button-enabled': !disableOptimise
          }"
          [disabled]="disableOptimise"
          (click)="optimiseData()"
        >
          Optimise
        </button>
      </div>
    </div>
  </div>
 
</div>
