// import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-primary-pumps',
  templateUrl: './primary-pumps.component.html',
  styleUrls: ['./primary-pumps.component.css']
})

export class PrimaryPumpsComponent {
  vfd_pump1: any=0;
  vfd_pump2: any=0;
  vfd_pump3: any=0;
  vfd_pump4: any=0;
  vfd_pump5: any=0;
  vfd_pump6: any=0;

  vfd_pump1_rl: any=0;
  vfd_pump2_rl: any=0;
  vfd_pump3_rl: any=0;
  vfd_pump4_rl: any=0;
  vfd_pump5_rl: any=0;
  vfd_pump6_rl: any=0;

  power_pump1: any=0;

  power_pump2: any=0;

  power_pump3: any=0;
  power_pump4: any=0;

  power_pump5: any=0;

  power_pump6: any=0;

  power_pump1_out: any=0;

  power_pump2_out: any=0;

  power_pump3_out: any=0;
  power_pump4_out: any=0;

  power_pump5_out: any=0;

  power_pump6_out: any=0;
  Ch_w_temp: any=0;

  errorLog: { message: string, timestamp: Date }[] = [];
  snackbars: { [key: string]: MatSnackBarRef<any> } = {};
  jsonData: any = {};
  predict_response: any = {};
  predict_input: any = {};
  
  KPI: any=0;
  predictionResult: any = {};
  interface_input: any = {};
  constructor(
    private activeroute:ActivatedRoute,
    private router: Router,
  private http: HttpClient,
  private snackBar: MatSnackBar,
  private errorMonitoringService: ErrorMonitoringService,
  private webSocketService:WebSocketService// Inject the service



  ){
    
  }

  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01
      const ahuData = data['CH_0'];
      if (ahuData) {
        console.log('chiller 3 Data:', ahuData);
        this.power_pump1 = ahuData.primary_pump_power_1;
            this.power_pump2= ahuData.primary_pump_power_2;
            this.power_pump3 = ahuData.primary_pump_power_3;
            this.power_pump4 = ahuData.primary_pump_power_4;
            this.power_pump5 = ahuData.primary_pump_power_5;
            this.power_pump6 = ahuData.primary_pump_power_6;
            this.vfd_pump1 =  ahuData.vfd_for_primary_pump_1;
            this.vfd_pump2 =  ahuData.vfd_for_primary_pump_2;
            this.vfd_pump3 =  ahuData.vfd_for_primary_pump_3;
            this.vfd_pump4 =  ahuData.vfd_for_primary_pump_4;
            this.vfd_pump5 =  ahuData.primary_pump_5_vfd_feedback;
            this.vfd_pump6 = ahuData.primary_pump_6_vfd_feedback;
      }
    });
    // this.checkForErrors();

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;


            console.log('Interface result:', this.interface_input);
            // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            
            // this.vfd_pump1 = this.interface_input['vfd_for_primary_pump_1'];
            // this.vfd_pump2 = this.interface_input['vfd_for_primary_pump_2'];
            // this.vfd_pump3 = this.interface_input['vfd_for_primary_pump_3'];
            // this.vfd_pump4 = this.interface_input['vfd_for_primary_pump_4'];
            // this.vfd_pump5 = this.interface_input['primary_pump_5_vfd_feedback'];
            // this.vfd_pump6 = this.interface_input['primary_pump_6_vfd_feedback'];
            // this.power_pump1 = this.interface_input['primary_pump_power_1'];
            // this.power_pump2= this.interface_input['primary_pump_power_2'];
            // this.power_pump3 = this.interface_input['primary_pump_power_3'];
            // this.power_pump4 = this.interface_input['primary_pump_power_4'];
            // this.power_pump5 = this.interface_input['primary_pump_power_5'];
            // this.power_pump6 = this.interface_input['primary_pump_power_6'];

            this.Ch_w_temp=this.interface_input['chilled_water_set_point_wc']

            this.power_pump1_out = this.interface_input['primary_pump_power_1'];
            this.power_pump2_out= this.interface_input['primary_pump_power_2'];
            this.power_pump3_out = this.interface_input['primary_pump_power_3'];
            this.power_pump4_out = this.interface_input['primary_pump_power_4'];
            this.power_pump5_out = this.interface_input['primary_pump_power_5'];
            this.power_pump6_out = this.interface_input['primary_pump_power_6'];




   

           
            
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
              this.predictionResult = response.result;

            this.vfd_pump1_rl = this.predictionResult['vfd_for_primary_pump_1'];
            this.vfd_pump2_rl = this.predictionResult['vfd_for_primary_pump_2'];
            this.vfd_pump3_rl = this.predictionResult['vfd_for_primary_pump_3'];
            this.vfd_pump4_rl = this.predictionResult['vfd_for_primary_pump_4'];
            this.vfd_pump5_rl = this.predictionResult['primary_pump_5_vfd_feedback'];
            this.vfd_pump6_rl = this.predictionResult['primary_pump_6_vfd_feedback'];
         
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predict_response);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }


  // checkForErrors() {
  //   this.snackbars = {};

  //   const errorKeys: { [key: string]: string } = {
  //     Chw_in_1: `Chw_in_1 value ${this.Chw_in_1} is out of range (10-20).`,
  //     Chw_out_1: `Chw_out_1 value ${this.Chw_out_1} is out of range (8-30).`,
  //     Condw_in_1: `Condw_in_1 value ${this.Condw_in_1} is out of range (11-35).`,
  //     Condw_out_1: `Condw_out_1 value ${this.Condw_out_1} is out of range (20-40).`
  //   };

  //   Object.keys(errorKeys).forEach(key => {
  //     if (this.isError(key)) {
  //       this.errorMonitoringService.logError(errorKeys[key]);
  //       console.log(errorKeys[key]);
  //       this.openSnackBar(errorKeys[key], key);
  //     }
  //   });
  //   console.log(this.errorMonitoringService.getErrorLog())


  // }

  // isError(key: string): boolean {
  //   switch (key) {
  //     case 'Chw_in_1':
  //       return this.Chw_in_1 < 10 || this.Chw_in_1 > 20;
  //     case 'Chw_out_1':
  //       return this.Chw_out_1 < 8 || this.Chw_out_1 > 30;
  //     case 'Condw_in_1':
  //       return this.Condw_in_1 < 11 || this.Condw_in_1 > 35;
  //     case 'Condw_out_1':
  //       return this.Condw_out_1 < 20 || this.Condw_out_1 > 40;
  //     default:
  //       return false;
  //   }
  // }

  openSnackBar(message: string, key: string) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message, key },
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: undefined,
    });
  }




}
