<div style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="/assets/strides-ahu/9.STRIDES3D.svg" />

          <!-- Components -->
          <div class="component fan" style="height: 15%; top: 68%; left: 51.6%;">
            <Fan [state]="state" ></Fan>
          </div>
          <!-- RARH  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 14.3%; left: 43.5%; width: 43px; height: 3%;">
            <input [ngModel]="RARH_09_in | number: '1.2-2'" (ngModelChange)="RARH_09_in = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>
          <!-- RAT  -->
          <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 16.6%; left: 43.5%; width: 43px; height: 3%;">
            <input [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" />
            <span style="font-size: 80%;" >°C</span>
          </div>
          <!-- Cooling Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 93.3%; left: 34.5%; width: 43px; height: 3%;">
            <input [ngModel]="chw_valve_opening_09_in | number: '1.2-2'" (ngModelChange)="chw_valve_opening_09_in = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>
           <!-- Cooling Coil Valve  -->
           <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 96.3%; left: 34.5%; width: 43px; height: 3%;">
            <input [ngModel]="chw_valve_opening_09_out | number: '1.2-2'" (ngModelChange)="chw_valve_opening_09_out = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>
            <!-- Heating COil valve  -->
            <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 93.3%; left: 49.5%; width: 43px; height: 3%;">
              <input [ngModel]="heating_coil_09_in | number: '1.2-2'" (ngModelChange)="heating_coil_09_in = $event" />
              <span style="font-size: 80%;" >%</span>
            </div>
          <!-- Heating Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 96.3%; left: 49.5%; width: 43px; height: 3%;">
            <input [ngModel]="heating_coil_09_out | number: '1.2-2'" (ngModelChange)="heating_coil_09_out = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>
           <!-- Fan Speed  -->
           <div class="input-groupR fanspeedc1 component" style="display:flex; justify-content:center; align-items:center; top: 86%; left: 59%; width: 43px; height: 3%;">
            <input [ngModel]="fan_speed_09_in | number: '1.2-2'" (ngModelChange)="fan_speed_09_in = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>  
            <!-- Fan Speed  -->
            <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 88.5%; left: 59%; width: 43px; height: 3%;">
              <input [ngModel]="fan_speed_09_out | number: '1.2-2'" (ngModelChange)="fan_speed_09_out = $event" />
              <span style="font-size: 80%;" >%</span>
            </div>
             <!-- SAT  -->
             <!-- <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 51%; left: 78%; width: 3.5%; height: 3%;">
              <input [ngModel]="SAT_09_in | number: '1.2-2'" (ngModelChange)="SAT_09_in = $event" />
              <span style="font-size: 80%;" >°C</span>
            </div> -->
              <!-- Supply DPT  -->
              <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 82%; left: 77.5%; width: 43px; height: 3%;">
                <input [ngModel]="Supply_DPT_09_in | number: '1.2-2'" (ngModelChange)="Supply_DPT_09_in = $event" />
                <span style="font-size: 80%;" >Pa</span>
              </div>
              <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 84%; left: 72.5%; width: 57px; height: 3%;">
                SupplyDPT
              </div>
              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 84%; left: 78%; width: 60px; height: 3%;">
                <input style=" font-weight:600; padding-right: 2px; " [ngModel]="Supply_DPT_09_in | number: '1.2-2'" (ngModelChange)="Supply_DPT_09_in = $event" />
                <div style="font-size: 80%; font-weight:600" >Pa</div>
              </div>
               <!-- ROom Temp -->
               <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 70.5%; left: 91%; width: 43px; height: 3%;">
                <input [ngModel]="Room_Temp_09_in | number: '1.2-2'" (ngModelChange)="Room_Temp_09_in = $event" />
                <span style="font-size: 80%;" >°C</span>
              </div>
              <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 66%; left: 83.5%; width: 43px; height: 3%;">
                RT
              </div>
              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 66%; left: 86.5%; width: 43px; height: 3%;">
                <input style=" font-weight:600" [ngModel]="Room_Temp_09_in | number: '1.2-2'" (ngModelChange)="Room_Temp_09_in = $event" />
                <span style="font-size: 80%;  font-weight:600" >°C</span>
              </div>
                <!-- Room RH  -->
          <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 73.5%; left: 91%; width: 43px; height: 3%;">
            <input [ngModel]="Room_RH_09_in | number: '1.2-2'" (ngModelChange)="Room_RH_09_in = $event" />
            <span style="font-size: 80%;" >%</span>
          </div>
          <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 66%; left: 89.5%; width: 43px; height: 3%;">
            RH
          </div>
          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 66%; left: 92.5%; width: 43px; height: 3%;">
            <input style="font-weight:600" [ngModel]="Room_RH_09_in | number: '1.2-2'" (ngModelChange)="Room_RH_09_in = $event" />
            <span style="font-size: 80%; font-weight:600" >%</span>
          </div>

          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 70.6%; left: 88.5%; width: 43px; height: 3%;">
            ACPH
          </div>
          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 70.6%; left: 92.5%; width: 43px; height: 3%;">
            <input style=" font-weight:600" [(ngModel)]="Acph_9" />
          </div>
          <div class="input-groupR  component" style="font-size: 80%;  font-weight:600; display:flex; justify-content:center; align-items:center; top: 70.6%; left: 92.5%; width: 43px; height: 3%;">
            No.
          </div>

          <div class="input-groupR  component" style="color: #D90606; display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 78%; left: 88.5%; width: 43px; height: 3%;">
            RDP
          </div>
          <div class="input-groupR  component" style="color: #D90606; display:flex; justify-content:center; align-items:center; top: 78%; left: 92.5%; width: 30px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="R_Dp_9 | number:'1.2-2'"  (ngModelChange)="R_Dp_9" />
          </div>
          <div class="input-groupR  component" style="color: #D90606; font-size: calc(0.5vw + 0.6vh);    font-weight:600; display:flex; justify-content:center; align-items:center; top: 78.1%; left: 94%; width: 43px; height: 3%; margin-left: 5px;">
            Pa
          </div>
          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 75%; left: 88.5%; width: 43px; height: 3%;">
            R DP
          </div>
          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 75%; left: 92.5%; width: 43px; height: 3%;">
            <input style=" font-weight:600" [(ngModel)]="R_Dp_9" />
          <span style="font-size: 80%;  font-weight:600 " >Pa</span>

          </div>

        
        </div>

        <div class="form component">
          <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
  
          <div style="height: 40px;"></div>
          <div  class="hvac-text">
          <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
          <div style="width:25%; ">
              <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event" />
          </div>
          <span style="margin-left:-10%; font-size: 100%;">%</span>
          </div>
      </div> 
      </div>
    </div>
  </div>
</div>
