<div style="position: relative; height: 100%; width: 100%;">
  <div  class="main_container">
      <div class="content-container">
          <div class="upcontainer">
              <div class="map-container">
              <img src="/assets/predict_ahu/wc chiller3.svg" class="fmap">
             

              <button class="rightbutton" routerLink="/dashboard/chiller1" style="display: flex; justify-content:center; align-items:center; top:50%; right:-8%">
                  <img src="assets/logos/right-arrow.png" alt="" style="width: 50% ">
              </button>

              <!-- Ch.w.in -->
              <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 59%; left: 15.5%; width: 45px; height: 3%;">
                <span style="font-size: 80%; color: #D90606; font-weight:200" >Ch.w.in</span>
              </div> -->
              <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 65%; left: 75%; width: 45px; height: 3%;">
                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" Chw_in_1 | number: '1.2-2'" (ngModelChange)=" Chw_in_1 = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div> -->

                <!-- Ch.w.out -->

                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 36.7%; left: 77.4%; width: 45px; height: 3%;">
                  <span style="font-size: 80%; color: #D90606; font-weight:200" >Ch.w.out</span>
                </div> -->
                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 47%; left: 75.8%; width: 45px; height: 3%;">
                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" Chw_out_1 | number: '1.2-2'" (ngModelChange)=" Chw_out_1 = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div> -->

                 <!-- Cond.w.out -->

<!--
                 <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 61%; left: 13.4%; width: 45px; height: 3%;">
                  <span style="font-size: 80%; color: #D90606; font-weight:200" >Cond.w.out</span>
                </div> -->
                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 53.7%; left:75.8%; width: 45px; height: 3%;">
                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" Condw_in_1| number: '1.2-2'" (ngModelChange)=" Condw_in_1 = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div> -->


                <!-- Cond.w.in -->

                <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 66.9%; left: 77.3%; width: 45px; height: 3%;">
                  <span style="font-size: 80%; color: #D90606; font-weight:200" >Cond.w.in</span>
                </div> -->
                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 39%; left: 94%; width: 45px; height: 3%;">
                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" mass_avg_temp | number: '1.2-2'" (ngModelChange)=" mass_avg_temp = $event" />
                  <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                </div>

                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 42%; left: 94%; width: 45px; height: 3%;">
                  <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" mass_avg_temp | number: '1.2-2'" (ngModelChange)=" mass_avg_temp = $event" />
                  <span style="font-size: 80%; color: #293AC6; font-weight:600" >°C</span>
                </div>

                  <!-- WC.chiller2 power -->

              <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -0.8%; left: 66.7%; width: 45px; height: 3%;">
                <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" ch_power1_input | number: '1.2-2'" (ngModelChange)=" ch_power1_input = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
              </div> -->

              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 3.7%; left: 63%; width: 45px; height: 3%;">
                <input readonly  style="color: #00A578; font-weight:600" [ngModel]=" ch_w_setpoint_out | number: '1.2-2'" (ngModelChange)=" ch_w_setpoint_out = $event" />
                <span style="font-size: 80%; color: #00A578; font-weight:600" >°C</span>
              </div>

                              <!-- WC.set point temp -->

                              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -0.7%; left: 63%; width: 45px; height: 3%;">
                                <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" ch_w_setpoint | number: '1.2-2'" (ngModelChange)=" ch_w_setpoint = $event" />
                                <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                              </div>
         


          </div>
          <!-- <div class="form component">
            <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
    
            <div style="height: 40px;"></div>
            <div  class="hvac-text">
            <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
            <div style="width:25%; ">
                <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                [ngModel]="KPI | number : '1.2-2'"
                (ngModelChange)="KPI = $event" />
            </div>
            <span style="margin-left:-10%; font-size: 100%;">%</span>
            </div>
        </div>  -->
          </div>
      </div>
  </div>

</div>















