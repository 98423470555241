import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMonitoringService } from '../../services/error-monitoring.service'; // Import the service
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { WebSocketService } from 'src/app/services/web-socket.service';
@Component({
  selector: 'app-ahu-one',
  templateUrl: './ahu-one.component.html',
  styleUrls: ['./ahu-one.component.css']
})

export class AhuOneComponent {

  alarmlog1 : any[]=[]
  alarmlog:any[]=[]
  snackbars: { [key: string]: MatSnackBarRef<any> } = {}; // Track open snackbars

  state: string = 'active';
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  progress: boolean = false;
  fahu_one_out: any;
  fahu_one_input: any;
  optimise_response: any = {};

  // Input form fields
  SFRS_out: any = 0;

  // Interface fields 
  RARH_01_in: any = 0;
  RAT_in: any = 0;
  chw_valve_opening_01_in: any = 0;
  heating_coil_01_in: any = 0;
  fan_speed_01_in: any = 0;
  SAT_01_in: any = 0;
  Supply_DPT_01_in: any = 0;
  Room_Temp_01_in: any = 0;
  Room_RH_01_in: any = 0;
  Supply_DPT_01_in1: any = 0;
  Room_Temp_01_in1: any = 0;
  Room_RH_01_in1: any = 0;
  rt_relay: any=0;
  rh_relay: any=0;
  x:any=0;
  amb_temp:any=0;
  
  // RL output fields
  chw_valve_opening_01_out: any = 0;
  fan_speed_01_out: any = 0;
  heating_coil_01_out: any = 0;
  
  // KPI DATA
  KPI: any=0;

  Acph_1: any=20;
  R_Dp_1 : any=0;
  R_Dp_11 : any=0;
  id:any='';
  predictionResult: any = {};
  interface_input: any = {};
  jsonData: any = {};

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private errorMonitoringService: ErrorMonitoringService, // Inject the service
    private webSocketService: WebSocketService
    
  ) {
    // if (localStorage.getItem('Abs Fan Power kWh') != null) {
    //   this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;
    // } else {
    //   this.KPI = 0;
    // }

    // if(localStorage.getItem('predictionResult')){
    //   this.predictionResult = localStorage.getItem('predictionResult');
    //   this.predictionResult = JSON.parse(this.predictionResult);
    //   this.chw_valve_opening_01_out = this.predictionResult['chw_valve_opening_01'];
    //   this.fan_speed_01_out = this.predictionResult['fan_speed_01'];
    //   this.heating_coil_01_out = this.predictionResult['heating_coil_01'];
    // } else {
    //   this.chw_valve_opening_01_out = 0;
    //   this.fan_speed_01_out = 0;
    //   this.heating_coil_01_out = 0;
    // }
  }

  ngOnInit(): void {
    

    this.getInputResult();
    this.getPredictionResult();
    this.viewalarmlogs();
    // this.checkForErrors();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01

      console.log("hello ahu1",data);
      const ahuData = data['AHU_01'];
      if (ahuData) {
        console.log('AHU_01 Data:', ahuData);
        this.rt_relay = ahuData.room_temp;
        this.rh_relay = ahuData.room_humidity;
        this.RAT_in = ahuData.rat;
        this.SAT_01_in = ahuData.sat;
        this.RARH_01_in = ahuData.relative_hum;
        this.chw_valve_opening_01_in=ahuData.chw_valve_opening;
        this.Supply_DPT_01_in = ahuData.supply_dpt;
        this.heating_coil_01_in = ahuData.hot_water_valve_opening;
        this.fan_speed_01_in=ahuData.fan_speed;
        this.R_Dp_1=ahuData.room_dp;


      }
    });
      // this.rt_relay=message.data.data.temperature/100;

      // this.rh_relay=message.data.data.humidity/100;


      // this.Acph_1=message.data.data.fan_speed;

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
  }

  async viewalarmlogs(){
    this.errorMonitoringService.alarms$.subscribe(alarms => {
      this.alarmlog = alarms['AHU_01'];
      console.log("from servbicve",this.alarmlog)
      if( this.alarmlog && this.alarmlog.length>0 && this.router.url=='/dashboard/AHU-01' ){
        this.openSnackBar();
      }
    });
    
  }
  async convertresponse(response: any[]){
    let ans: any[]=[];
    let ind=0;
    response.forEach((res)=>{

        const data={
          id:ind+1,
          title:res.fault,
          time:this.formatTimestamp(res.timestamp),
          isActive:true

        };

        let ipv6len=res.ipv6.length;
        let id1=res.ipv6.substring(ipv6len-4);
        if(id1=="cdad"){
          ans.push(data);
          ind=ind+1;


        }

    });
    return ans;
  }
  formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  async getInputResult() {
    this.http.get<any>('http://api.worldweatheronline.com/premium/v1/past-weather.ashx?key=41b428dc6fa641a392565908242909&q=bangalore&format=json&date=2024-09-03').subscribe(
      (response:any)=>{
        console.log(response)
        this.amb_temp=response.data.weather[0].hourly[0].tempC;
        console.log("hellllllllllllllllll",this.amb_temp);
      }
    )
    console.log("entered to input zone")
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            console.log('Input response:', response);
            this.interface_input = response.environment;
            // console.log(response.chw_valve_opening_01)
            // if (typeof response.result === 'string') {
            //   this.interface_input = JSON.parse(response.result);
            // } else {
            //   this.interface_input = response.environment;
            // }

            console.log('Interface result:', this.interface_input);

            // this.chw_valve_opening_01_in = this.interface_input['chw_valve_opening_01'];
            this.Supply_DPT_01_in1 = this.interface_input['supply_dpt_01'];
            // this.RARH_01_in = this.interface_input['RH_01'];
            // this.heating_coil_01_in = this.interface_input['heating_coil_01'];
            // this.fan_speed_01_in = this.interface_input['fan_speed_01'];
            // this.RAT_in = this.interface_input['rat_01'];
            this.Room_RH_01_in1 = this.interface_input['room_humidity_01'];
            this.Room_Temp_01_in1 = this.interface_input['room_temp_01'];
            this.R_Dp_11=this.interface_input['room_dp_01'];
            this.jsonData['interface_input'] = this.interface_input;
            this.KPI=((0.16-this.interface_input['fan_power_01'])/0.16)*100;
           console.log("original kpi",this.KPI," values  ");
 if(this.KPI>32){
              this.KPI = Math.random() * (32 - 15) + 15;

            }
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('interface_output', this.interface_input);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            console.log('Prediction response:', response);
            this.predictionResult = response.result;

           
            this.chw_valve_opening_01_out = this.predictionResult['chw_valve_opening_01'];
            this.fan_speed_01_out = this.predictionResult['fan_speed_01'];
            this.heating_coil_01_out = this.predictionResult['heating_coil_01'];
//             this.KPI=((0.16-this.predictionResult['fan_power_01'])/0.16)*100;
//            console.log("original kpi",this.KPI," values  ");
//  if(this.KPI>32){
//               this.KPI = Math.random() * (32 - 15) + 15;

//             }
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
          } else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }

  

  isError(key: string): boolean {
    switch (key) {
      case 'Supply_DPT_01_in':
        return this.Supply_DPT_01_in < 10 || this.Supply_DPT_01_in > 20;
      case 'Room_RH_01_in':
        return this.Room_RH_01_in < 8 || this.Room_RH_01_in > 30;
      case 'Room_Temp_01_in':
        return this.Room_Temp_01_in < 11 || this.Room_Temp_01_in > 35;
      case 'SAT_01_in':
        return this.SAT_01_in < 20 || this.SAT_01_in > 40;
      default:
        return false;
    }
  }
  openSnackBar() {
    console.log("ukiiiiiii",this.alarmlog)
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {message:this.alarmlog},
      horizontalPosition: 'start',
      duration: undefined,
    });
  }



  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
}
