import { Component } from '@angular/core';

@Component({
  selector: 'app-savings-form-for-ahus',
  templateUrl: './savings-form-for-ahus.component.html',
  styleUrls: ['./savings-form-for-ahus.component.css']
})
export class SavingsFormForAhusComponent {
predictData() {
throw new Error('Method not implemented.');
}
CHWRT: any;
CHWVP: any;
VFD_freq: any;

}
