<div style="position: relative; height: 100%; width: 100%">

    <div style="
        width: 100%;
        height: 100%;
        min-width: 1200px;
        display: flex;
        flex-direction: column;
        position: absolute; ">
      <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar>
      <div style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          align-items: center;
          gap: 10px;
        " >
        <div style="
            height: 400px;
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          " >
          <!-- FAHU Map  -->
          <div
            style="
              height: 400px;
              width: 1200px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            " >
            <div style="height: 400px; width: 1200px; margin-right:0px">
              <img src="/assets/images/fahu-manar-mall.svg" width="1200px" height="400px" />
              <div style="position: absolute;
              margin-top: -155px;
              margin-left: 340px;
              height: 75px;
              width: 300px;">
                <div style="display: flex; width: 100%; height: 100%; justify-content: center; align-items:center; ">
                  <p style="font-family: 'Inter', sans-serif; font-size: 24px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">Ground Floor</p>
                </div>
              </div>
              <div style="position: absolute;
              margin-top: -342px;
              margin-left: 1055px;
              height: 270px;
              width: 150px;">
                <div style="display: flex; width: 80%; height: 100%; justify-content: center; align-items:center;">
                  <p style="font-family: 'Inter', sans-serif; font-size: 20px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">ZONE 1: FOOD COURT (McDonald's To Burger King)</p>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Image Code -->
          <div  style="height: 246px; width: 169.5px; margin-left: 1032.5px; margin-top: 67.9px;" class="image-container" (mouseover)="hideImage()" (mouseout)="showImage()">
            <img src="/assets/area/ahu-area-image.png" width="165px"  height="239px" alt="Your Image" [class.hidden]="isHidden" />
          </div>
  
          <!-- componets -->
          <div style="height: 350px; width: 1000px; position: absolute">
            <!-- FAN -->
            <div style="
            position:absolute;
                height: 85px;
                width: 85px;
                margin-top: 118px;
                margin-left: 333px;
              " >
              <Fan [state]="state"></Fan>
            </div>
  
            <!-- 1. SFRS, Supply Fan Run Status -->
            <div
            class="input-group"
            style="position:absolute; width: 80px; margin-top: 47px; margin-left: 250px" >
              <img src="/assets/images/switch1.svg" />
              <input
              [ngModel]="SFRS_out | number : '1.2-2'"
              (ngModelChange)="SFRS_out = $event"
            />
            </div>
  

            


            
  
            <!-- 2. CHWVP opening Percentage  -->
            <div
              class="input-group"
              style="position:absolute; width: 80px;
              margin-top: 230px;
              margin-left: 138px;"
            >
              <img src="/assets/images/percentage.png" />
              <input
              [ngModel]="CHWVP_out | number : '1.2-2'"
              (ngModelChange)="CHWVP_out = $event"
            />
            </div>
  
            <!-- 3. Fan Power -->
            <div
              class="input-group"
              style="position:absolute; width: 110px;
              margin-top: 47px;
              margin-left: 445px;"
            >
              <img src="/assets/images/power.svg" />
              <input
              [ngModel]="FAN_POWER_out | number : '1.2-2'"
              (ngModelChange)="FAN_POWER_out = $event"
            />
            <span>kWh</span>
            </div>
  
            <!-- 4. Fan Speed-->
            <div
              class="input-group"
              style="position:absolute; width: 90px;
              margin-top: 47px;
              margin-left: 349px;"
            >
              <img src="/assets/images/fan.svg" />
              <input
              [ngModel]="VFD_freq_out | number : '1.2-2'"
              (ngModelChange)="VFD_freq_out = $event"
            />
            <span>Hz</span>
            </div>
  
                <!-- 5. SDPT_out -->
            <!-- <div
            class="input-group"
            style="width: 94px;
            margin-top: 77px;
            margin-left: 625px;"
            >
                <img src="/assets/images/differential-pressure-transmitter.png" />
                <ng-container *ngIf="SDPT_out !== false; else emptyInput">
                  <input
                    [ngModel]="SDPT_out | number : '1.2-2'"
                    (ngModelChange)="SDPT_out = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input [ngModel]="''" />
                </ng-template>
            <span>Pa</span>
          </div> -->
  
            <!-- 6. SAT_out -->
            <div
              class="input-group"
              style="position:absolute; width: 90px;
              margin-top: 78px;
              margin-left: 770px;"
            >
              <img src="/assets/images/temp.svg" />
              <input
              [ngModel]="SAT_out | number : '1.2-2'"
              (ngModelChange)="SAT_out = $event"
            />
            </div>
  
            <!-- <div
              class="input-group2"
              style="width: 110px;
              margin-top: 5px;
              margin-left: 20px;"
            >
              <img src="/assets/images/CO2.png" />
              <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
                <input
                  [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                  (ngModelChange)="cassandra_data['co2'] = $event"
                />
              </ng-container>
              <ng-template #emptyInput>
                <input [ngModel]="''" />
              </ng-template>
              <span>ppm</span>
            </div> -->
  
            <!-- <div
              class="input-group2"
              style="width: 110px;
              margin-top: -10px;
              margin-left: 210px;"
            >
              <img src="/assets/images/DP.png" />
              <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
                <input
                  [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                  (ngModelChange)="cassandra_data['pfp'] = $event"
                />
              </ng-container>
              <ng-template #emptyInput>
                <input [ngModel]="''" />
              </ng-template>
              <span>mbar</span>
            </div> -->
          </div>
        </div>
      </div>
  
      <div
      style="
        height: auto;
        width: 100%;
        min-width: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 7px;
        align-items: center;
        gap: 10px;
      "
    >
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: row;
          height: auto;
          justify-content: center;
        "
      >
        <div style="width: 600px; height: 100%; margin: 0px 20px 0px 50px">
          <div
            class="form"
            style="
              padding: 20px;
              flex-direction: row;
              gap: 30px;
              align-items: start;
            "
          >
            <div
              style="
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;
              "
            >
              <!-- Chilled Water Inlet Temperature -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >CHW TEMP.</span
                >
  
                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temp.svg" />
                  <input [(ngModel)]="CHWRT" />
                </div>
              </div>
  
              <!-- CHW Valve Chilled Water Valve Openiong Percentage-->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >CHW VALVE</span
                >
  
                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input [(ngModel)]="CHWVP" />
                </div>
              </div>
  
              <!-- FAN SPEED -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >FAN SPEED</span
                >
  
                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input [(ngModel)]="VFD_freq" />
                </div>
              </div>
  
             
            </div>
  
            <div
              style="
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: space-between;
              "
            >
              <div style="display: flex; flex-direction: column; gap: 10px">
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >SUPPLY DPT</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/differential-pressure-transmitter.png" />
                    <input [(ngModel)]="SAT" />
                    <span>Pa</span>
                  </div>
                </div> -->
  
                 <!-- SAT --Supply Air Temperature-->
              <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >SAT</span
              >
  
              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temp.svg" />
                <input [(ngModel)]="SAT" />
              </div>
            </div>
  
  
  
                <!-- Supply Fan Run status -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >SFRS</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/switch1.svg" />
                    <input [(ngModel)]="SFRS" />
                  </div>
                </div>
  
                <!-- Ambient Temperature -->
  
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >AMBIENT TEMP.</span
                  >
  
                  <div class="input-group" style="width: 100px">
                    <img src="/assets/images/temp.svg" />
                    <input [(ngModel)]="AMBIENT_TEMP" />
                  </div>
                </div>
              </div>
  
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  gap: 10px;
                  justify-content: end;
                  margin-top: 10px;
                "
              >
                <button
                  style="
                    width: 50%;
                    background-color: var(--blue-one);
                    border-radius: 20px;
                    border: none;
                    height: auto;
                    padding: 7px;
                    color: white;
                    cursor: pointer;
                  "
                  (click)="predictData()"
                >
                  Predict
                </button>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div> -->
        </div>
  
        <!-- KPI -->
  
        <!-- <div class="form" style="height: 200px; width: 400px">
          <span
            class="span-text-dark"
            style="font-weight: bold; text-align: center"
            >KEY PERFORMANCE INDICATOR</span
          >
  
          <div style="height: 30px"></div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              gap:10px;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 12px; font-weight: 500"
              >FAN POWER SAVING</span
            >
  
            <div class="input-group" style="width: 120px">
              <img src="/assets/images/power.png" />
              <input
                [ngModel]="KPI | number : '1.2-2'"
                (ngModelChange)="KPI = $event"
              />
              <span>%</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    </div>
  </div>
