<div class="scaffold">
  <div style="height: 100%; width: 100%; position: relative; font-family: Mulish">
    <div
      style="
        height: 80%;
        width: 100%;
        background: 
          url('/assets/predict_ahu/strides login1.svg');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 140px;
        "
        class="client-logo"
      >
     
   

        <div style="position: relative;">
          <img src="/assets/strides-images/strides-logo-login.svg" width="350px" style="border-radius: 5px;" />
          <div style="position: absolute; top: 67%; left: 65%; transform: translate(-50%, -50%);">
              <img src="/assets/strides-images/logo animation mulish 1.gif" width="240px" />
          </div>
      </div>
     
      
        <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>
      
      <!-- <app-role-selection *ngIf="showRoleModal" (roleSelected)="onRoleSelected()"></app-role-selection> -->
      <form action="" class="form">
        <input type="text" id="username" name="username" class="form-item" placeholder="Username" [(ngModel)]="email">
        <div class="pass">
          <input [type]="type" id="password" name="password" class="form-item" placeholder="Password" [(ngModel)]="password">
          <span (click)="hideShowPass()" class="fa {{eyeIcon}}"></span>
        </div>
        <button mat-raised-button class="btn" (click)="handleLogin()" style="
        color: white;
        font-family: Mulish;
      ">Login</button>
        <a
            class="hover-text-dark"
            [routerLink]="['/forgot-password']"
            style="margin-top: 15px; color:black; margin-bottom: 0px"
            >Forgot Password?</a
          >
    </form>



      </div>
    </div>
  </div>
</div>
