<div style="position: relative; height: 100%; width: 100%; ">
  <div class="main_container">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="/assets/predict_ahu/AHU 7.svg" />
          <!-- <button class="button-a7" routerLink="/dashboard/AHU-07-02">
            <img src="assets/logos/right-arrow.png" alt="" style="width: 50%">
          </button> -->
          <button class="button-a7" routerLink="/dashboard/AHU-07-02">
            <img src="assets/logos/right-arrow.png" alt="" style="width: 50%">
          </button>

          <!-- Components -->
          <div class="component fan" style="height: 17%; top: 51.4%; left: 47.4%;">
            <Fan [state]="state" ></Fan>
          </div>
          <!-- RARH  -->
          <!-- <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 14.3%; left: 43.5%; width: 43px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="RARH_07_in | number: '1.2-2'" (ngModelChange)="RARH_07_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div> -->
          <!-- RAT  -->
          <!-- <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 16.6%; left: 43.5%; width: 43px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div> -->
          <!-- Cooling Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 91.3%; left: 31.5%; width: 49px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="chw_valve_opening_07_in | number: '1.2-2'" (ngModelChange)="chw_valve_opening_07_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>
           <!-- Cooling Coil Valve  -->
           <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 31.5%; width: 49px; height: 3%;">
            <input readonly readonly  style="color: #00A578; font-weight:600" [ngModel]="chw_valve_opening_07_out | number: '1.2-2'" (ngModelChange)="chw_valve_opening_07_out = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
          </div>
            <!-- Heating COil valve  -->
            <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 91.3%; left: 44%; width: 49px; height: 3%;">
              <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="heating_coil_07_in | number: '1.2-2'" (ngModelChange)="heating_coil_07_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
            </div>
          <!-- Heating Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 44%; width: 49px; height: 3%;">
            <input readonly readonly  style="color: #00A578; font-weight:600" [ngModel]="heating_coil_07_out | number: '1.2-2'" (ngModelChange)="heating_coil_07_out = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
          </div>
           <!-- Fan Speed  -->
           <div class="input-groupR fanspeedc1 component" style="display:flex; justify-content:center; align-items:center; top: 77%; left: 53%; width: 49px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="fan_speed_07_in | number: '1.2-2'" (ngModelChange)="fan_speed_07_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>

          <div class="input-groupR fanspeedc1 component" style="display:flex; justify-content:center; align-items:center; top: 0%; left: 15%; width: 43px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="amb_temp | number: '1.2-2'" (ngModelChange)="amb_temp = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div>
            <!-- Fan Speed  -->
            <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 81.1%; left: 53%; width: 49px; height: 3%;">
              <input readonly readonly  style="color: #00A578; font-weight:600" [ngModel]="fan_speed_07_out | number: '1.2-2'" (ngModelChange)="fan_speed_07_out = $event" />
              <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
            </div>
             <!-- SAT  -->
             <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 25.5%; left: 68%; width: 3.5%; height: 3%;">
              <input readonly readonly  style="color: #D90606; font-weight:600"  />
            </div>
              <!-- Supply DPT  -->
              <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 70.7%; left: 69.9%; width: 49px; height: 3%;">
                <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="Supply_DPT_07_in | number: '1.2-2'" (ngModelChange)="Supply_DPT_07_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Pa</span>
              </div>
              <!-- <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 77%; left: 64.5%; width: 57px; height: 3%;">
                SupplyDPT
              </div> -->
              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 75.1%; left: 70%; width: 49px; height: 3%;">
                <input readonly style=" font-weight:600;color: #293AC6; padding-right: 2px; " [ngModel]="Supply_DPT_07_in1 | number: '1.2-2'" (ngModelChange)="Supply_DPT_07_in1 = $event" />
                <div style="font-size: 80%;color: #293AC6; font-weight:600" >Pa</div>
              </div>

              <!-- <div class="input-groupR  component" style="display:flex; color: #D90606; justify-content:center; align-items:center; top: 60.6%; left: 75.5%; width: 43px; height: 3%;">
                ACPH
              </div> -->
              <div class="input-groupR  component" style="display:flex;color: #D90606; justify-content:center; align-items:center; top: 55.6%; left: 82%; width: 49px; height: 3%;">
                <input readonly style=" color: #D90606;font-weight:600"[ngModel]="Acph_7 | number: '1.2-2'" (ngModelChange)="Acph_7 = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600;  ">
                  No.
                </span>
              </div>
    
              <!-- <div class="input-groupR  component" style="color: #D90606; display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 75%; left: 75.5%; width: 43px; height: 3%;">
                RDP
              </div> -->
              <div class="input-groupR  component" style="color: #D90606; display:flex; justify-content:center; align-items:center; top: 61.4%; left: 82%; width: 30px; height: 3%;">
                <input readonly style="color: #D90606; font-weight:600" [ngModel]="R_Dp_71 | number:'1.2-2'"  (ngModelChange)="R_Dp_71" />
              </div>
              <div class="input-groupR  component" style="color: #D90606; font-size: calc(0.5vw + 0.6vh);    font-weight:600; display:flex; justify-content:center; align-items:center; top: 61.4%; left: 83%; width: 43px; height: 3%; margin-left: 5px;">
                Pa
              </div>
              <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 69%; left: 75.5%; width: 43px; height: 3%;">
                R DP
              </div> -->
              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 65.6%; left: 82%; width: 43px; height: 3%;">
                <input readonly style=" color: #293AC6;font-weight:600" [ngModel]="R_Dp_7 | number: '1.2-2'" (ngModelChange)="R_Dp_7 = $event" />
              <span style="font-size: 80%; color: #293AC6; font-weight:600 " >Pa</span>
    
              </div>

               <!-- ROom Temp -->
               <!-- <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 70.5%; left: 91%; width: 43px; height: 3%;">
                <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="Room_Temp_07_in | number: '1.2-2'" (ngModelChange)="Room_Temp_07_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
              </div> -->
                <!-- Room RH  -->
          <!-- <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 73.5%; left: 91%; width: 43px; height: 3%;">
            <input readonly readonly  style="color: #D90606; font-weight:600" [ngModel]="Room_RH_07_in | number: '1.2-2'" (ngModelChange)="Room_RH_07_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div> -->

        </div>

        <div class="form component">
          <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
  
          <div style="height: 40px;"></div>
          <div  class="hvac-text">
          <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
          <div style="width:25%; ">
              <input readonly style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event" />
          </div>
          <span style="margin-left:-10%; font-size: 100%;">%</span>
          </div>
      </div> 

      </div>
    </div>
  </div>
</div>




























