<div style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="/assets/predict_ahu/AHU2 3.svg" />

          <!-- Components -->
          <div class="component fan" style="height: 14.5%; top: 67.2%; left: 51.7%;">
            <Fan [state]="state" ></Fan>
          </div>
          <!-- RAT  -->
          <div class="input-groupR teampc component" style="display:flex; justify-content:center; align-items:center; top: 14.6%; left: 43.5%; width: 43px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div>
          <!-- RARH  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 17.3%; left: 43.5%; width: 43px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="RARH_02_in | number: '1.2-2'" (ngModelChange)="RARH_02_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>
          
          <!-- Cooling Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 93.8%; left: 34.5%; width: 43px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="chw_valve_opening_02_in | number: '1.2-2'" (ngModelChange)="chw_valve_opening_02_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>
           <!-- Cooling Coil Valve  -->
           <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 96.8%; left: 34.5%; width: 43px; height: 3%;">
            <input style="color: #00A578; font-weight:600" [ngModel]="chw_valve_opening_02_out | number: '1.2-2'" (ngModelChange)="chw_valve_opening_02_out = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
          </div>
            <!-- Heating COil valve  -->
            <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 93.7%; left: 49%; width: 43px; height: 3%;">
              <input style="color: #D90606; font-weight:600" [ngModel]="heating_coil_02_in | number: '1.2-2'" (ngModelChange)="heating_coil_02_in = $event" />
              <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
            </div>
          <!-- Heating Coil Valve  -->
          <div class="input-groupR RHc component" style="display:flex; justify-content:center; align-items:center; top: 96.7%; left: 49%; width: 43px; height: 3%;">
            <input style="color: #00A578; font-weight:600" [ngModel]="heating_coil_02_out | number: '1.2-2'" (ngModelChange)="heating_coil_02_out = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
          </div>
           <!-- Fan Speed  -->
           <div class="input-groupR fanspeedc1 component" style="display:flex; justify-content:center; align-items:center; top: 84.5%; left: 58.5%; width: 43px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="fan_speed_02_in | number: '1.2-2'" (ngModelChange)="fan_speed_02_in = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
          </div>  
            <!-- Fan Speed  -->
            <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 87%; left: 58.5%; width: 43px; height: 3%;">
              <input style="color: #00A578; font-weight:600" [ngModel]="fan_speed_02_out | number: '1.2-2'" (ngModelChange)="fan_speed_02_out = $event" />
              <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
            </div>
             <!-- SAT  -->
             <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 46%; left: 68.8%; width: 3.5%; height: 3%;">
              <input style="color: #D90606; font-weight:600"  />
            </div>
              <!-- Supply DPT  -->
              <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 80.7%; left: 77.9%; width: 43px; height: 3%;">
                <input style="color: #D90606; font-weight:600" [ngModel]="Supply_DPT_02_in | number: '1.2-2'" (ngModelChange)="Supply_DPT_02_in = $event" />
                <span style="font-size: 80%; color: #D90606; font-weight:600" >Pa</span> 
                <!-- in has RED -->
              </div>
              <!-- <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 84%; left: 72.5%; width: 57px; height: 3%;">
                SupplyDPT
              </div> -->
              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 84%; left: 78%; width: 43px; height: 3%;">
                <input style=" font-weight:600;color: #293AC6; padding-right: 2px; " [ngModel]="Supply_DPT_02_in1 | number: '1.2-2'" (ngModelChange)="Supply_DPT_02_in1 = $event" />
                <div style="font-size: 80%;color: #293AC6; font-weight:600" >Pa</div>
              </div>
               <!-- ROom Temp -->
               <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 43.1%; left: 86%; width: 35px; height: 3%;">
                <input style="color: #293AC6; font-weight:600" [ngModel]="Room_Temp_02_in1 | number: '1.2-2'" (ngModelChange)="Room_Temp_02_in1 = $event" />
                <span style="font-size: 80%; color: #293AC6; font-weight:600" >°C</span>
              </div>
              <!-- <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 66%; left: 83.5%; width: 43px; height: 3%;">
                RT
              </div> -->
              <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 66%; left: 86.5%; width: 43px; height: 3%;">
                <input style="color: #293AC6; font-weight:600" [ngModel]="Room_Temp_02_in1 | number: '1.2-2'" (ngModelChange)="Room_Temp_02_in1 = $event" />
                <span style="font-size: 80%; color: #293AC6; font-weight:600" >°C</span>
              </div> -->
                <!-- Room RH  -->
          <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 43.1%; left: 92%; width: 43px; height: 3%;">
            <input style="color: #293AC6; font-weight:600" [ngModel]="Room_RH_02_in1 | number: '1.2-2'" (ngModelChange)="Room_RH_02_in = $event" />
            <span style="font-size: 80%;color: #293AC6;font-weight:600" >%</span>
          </div>
          <!-- <div class="input-groupR  component" style="display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 66%; left: 89.5%; width: 43px; height: 3%;">
            RH
          </div> -->
          <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 66%; left: 92.5%; width: 43px; height: 3%;">
            <input style="font-weight:600" [ngModel]="Room_RH_02_in1 | number: '1.2-2'" (ngModelChange)="Room_RH_02_in1 = $event" />
            <span style="font-size: 80%; font-weight:600" >%</span>
          </div> -->

          <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 31.1%; left: 52.5%; width: 43px; height: 3%;">
            <input style="color: #D90606; font-weight:600" [ngModel]="amb_temp | number: '1.2-2'" (ngModelChange)="amb_temp = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
          </div>
          <!-- ROom Temp -->
          <div class="input-groupR fanspeedc2 component" style="display:flex; justify-content:center; align-items:center; top: 63.4%; left: 86.6%; width: 43px; height: 3%;">
            <input style="color: #293AC6;font-weight:600" [ngModel]="Room_Temp_02_in1 | number: '1.2-2'" (ngModelChange)="Room_Temp_02_in1 = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600" >°C</span>
          </div>
            <!-- Room RH  -->
      <div class="input-groupR fanpowc component" style="display:flex; justify-content:center; align-items:center; top: 63.4%; left: 92.4%; width: 40px; height: 3%;">
        <input style="color: #293AC6; font-weight:600" [ngModel]="Room_RH_02_in1 | number: '1.2-2'" (ngModelChange)="Room_RH_02_in1 = $event" />
        <span style="font-size: 80%; color: #293AC6; font-weight:600" >%</span>
      </div>
      <!-- <div class="input-groupR  component" style="color: #D90606;display:flex; justify-content:center; align-items:center; top: 70.6%; left: 88.5%; width: 43px; height: 3%;">
        ACPH
      </div> -->
      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 69.6%; left: 89.5%; width: 49px; height: 3%;">
        <input style=" color: #D90606; font-weight:600" [ngModel]="Acph_2 | number:'1.2-2'"  (ngModelChange)="Acph_2"/>
        <span style="font-size: calc(0.5vw + 0.6vh);color: #D90606;     font-weight:600;">
          No.
        </span>
      </div>
      <!-- <div class="input-groupR  component" style="color: #D90606; display:flex; font-size: calc(0.5vw + 0.6vh); justify-content:center; align-items:center; top: 78%; left: 88.5%; width: 43px; height: 3%;">
        RDP
      </div> -->
      <div class="input-groupR  component" style="color: #D90606; display:flex; justify-content:center; align-items:center; top: 73.2%; left: 89.5%; width: 30px; height: 3%;">
        <input style="color: #D90606; font-weight:600" [ngModel]="R_Dp_2 | number:'1.2-2'"  (ngModelChange)="R_Dp_2" />
      </div>
      <div class="input-groupR  component" style="color: #D90606; font-size: calc(0.5vw + 0.6vh);    font-weight:600; display:flex; justify-content:center; align-items:center; top: 73.2%; left: 90.3%; width: 43px; height: 3%; margin-left: 5px;">
        Pa
      </div>

<!-- 
      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 75%; left: 88.5%; width: 43px; height: 3%;">
        R DP
      </div> -->
      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 75.8%; left: 89.5%; width: 43px; height: 3%;">
        <input style="color: #293AC6; font-weight:600" [ngModel]="R_Dp_21 | number: '1.2-2'" (ngModelChange)="R_Dp_21 = $event" />
        <span style="font-size: 80%; color: #293AC6; font-weight:600 " >Pa</span>

      </div>
    


      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 54.9%; left: 87%; width: 43px; height: 3%;">
        <input class="no-background-quartz" style="color: #183772;"
          [ngModel]="rt_relay | number : '1.2-2'"
          (ngModelChange)="rt_relay = $event"
        />
      </div>

      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 57.3%; left: 87%; width: 43px; height: 3%;">
        <input class="no-background-quartz" style="color: #183772;"
          [ngModel]="rh_relay | number : '1.2-2'"
          (ngModelChange)="rh_relay = $event"
        />
      </div>

      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 34.9%; left: 87%; width: 43px; height: 3%;">
        <input class="no-background-quartz" style="color: #183772;"
          [ngModel]="rt_relay | number : '1.2-2'"
          (ngModelChange)="rt_relay = $event"
        />
      </div>

      <div class="input-groupR-new overlay-content component" style=" display:flex; justify-content:center; align-items:center; top: 37.3%; left: 87%; width: 43px; height: 3%;">
        <input class="no-background-quartz" style="color: #183772;"
          [ngModel]="rh_relay | number : '1.2-2'"
          (ngModelChange)="rh_relay = $event"
        />
      </div>

          
        </div>
        <div class="form component">
          <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
  
          <div style="height: 40px;"></div>
          <div  class="hvac-text">
          <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
          <div style="width:25%; ">
              <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event" />
          </div>
          <span style="margin-left:-10%; font-size: 100%;">%</span>
          </div>
      </div> 
      </div>
    </div>
  </div>
</div>
