

<div class="container-fluid">
    <!-- Summary Cards Row -->
    <div class="row d-flex justify-content-center" style="display: flex; flex-direction: row; gap: 20px;">
        <!-- Total Energy Saved Card -->
        <div class="col-lg-4 col-md-4 col-sm-12 mb-3" style=" width: 25%;">
            <div class="card" style="min-height: 150px">
                <div class="card-body text-center">
                    <h5 class="card-title">Total Energy Saved</h5>
                    <br>
                    <p class="card-value">78%</p>
                    <button class="btn btn-primary">Optimize</button>
                </div>
            </div>
        </div>
 
        <!-- Annual Cost Saving Card -->
        <div class="col-lg-4 col-md-4 col-sm-12 mb-3" style=" width: 25%;">
            <div class="card" style="min-height: 150px">
                <div class="card-body text-center">
                    <h5 class="card-title">Annual Cost Saving</h5>
                    <br>
                    <p class="card-value">₹85,412</p>
                </div>
            </div>
        </div>
 
        <!-- Annual Energy Saving Card -->
        <div class="col-lg-4 col-md-4 col-sm-12 mb-3" style=" width: 25%;">
            <div class="card" style="min-height: 150px">
                <div class="card-body text-center">
                    <h5 class="card-title">Annual Energy Saving</h5>
                    <br>
                    <p class="card-value">234 Kwh</p>
                </div>
            </div>
        </div>
    </div>
</div>
 
<br>
  
<!-- Table Row -->
<div class="container-fluid">
    <div class="row mt-5">
        <div class="col" style="border: 1px solid #ccc; border-radius: 8px; padding: 13px">
            <input type="text" class="form-control mb-3" placeholder="Search AHU name, area served, etc." style="margin-top: 10px; margin-left: 20px; border-radius: 100px;height: 50px; width: 650px; border: 1px solid; border-color: grey; padding-left: 10px;">
            <button class="btn btn-secondary">Optimize</button>
 
            <table class="table table-bordered" style="width: 100%;">
            <thead class="thead-dark" style="margin: 0; justify-content: space-around; font-size: 50px;">
                <tr>
                <th scope="col">AHU Name</th>
                <th scope="col">Area Served</th>
                <th scope="col">Status</th>
                <th scope="col">Energy Saved</th>
                <th scope="col">Fan Speed Predicted</th>
                <th scope="col">Fan Speed Optimized</th>
                <th scope="col">CWV Predicted</th>
                <th scope="col">CWV Optimized</th>
                </tr>
            </thead>
            <br>
            <tbody style="justify-content: space-around; padding: 35px;">
                <tr *ngFor="let row of tableRows" style="margin: 0 10px; padding: 5px;">
                <td>{{ row.ahuName }}</td>
                <td>{{ row.areaServed }}</td>
                <td><div style="padding: 4px; background-color:rgb(159, 255, 223); border-radius: 10px;">{{ row.status }}</div></td>
                <td>{{ row.energySaved }}</td>
                <td>{{ row.fanSpeedPr }}</td>
                <td>{{ row.fanSpeedOp }}</td>
                <td>{{ row.cwvPr }}</td>
                <td>{{ row.cwvOp }}</td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
</div>