<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                <img src="/assets/strides-images/CT2.svg" class="fmap">
                
                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top:-2%; left: 9.8%; width: 13.5%; height: 3%;">
                  <span style="font-size: 80%; color: #D90606; font-weight:200" >CT Water in.</span>
                </div>
                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -2%; left: 20%; width: 3.5%; height: 3%;">
                    <input style="color: #D90606; font-weight:600" [ngModel]=" CT_water_in | number: '1.2-2'" (ngModelChange)=" CT_water_in = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                  </div>

                  

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom:12%; left: 31%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:200" >CT1 Water out</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 85%; left: 42%; width: 3.5%; height: 3%;">
                    <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_water_out | number: '1.2-2'" (ngModelChange)=" CT2_water_out = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 85%; left: 30.8%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #00A578; font-weight:200" >CT1 FAN SPEED</span>
                  </div>
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 12%; left: 42%; width: 3.5%; height: 3%;">
                    <input style="color:#00A578; font-weight:600" [ngModel]=" CT2_FAN_SPEED_GR | number: '1.2-2'" (ngModelChange)=" CT2_FAN_SPEED_GR = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                  </div>

                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 88%; left:30.8%; width: 13.5%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:200" >CT1 FAN SPEED</span>
                  </div>
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 9%; left: 42%; width: 3.5%; height: 3%;">
                    <input style="color: #D90606; font-weight:600" [ngModel]=" CT2_FAN_SPEED_RED | number: '1.2-2'" (ngModelChange)=" CT2_FAN_SPEED_RED = $event" />
                    <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                  </div>

               
            </div>
            <div class="form component">
              <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
      
              <div style="height: 40px;"></div>
              <div  class="hvac-text">
              <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
              <div style="width:25%; ">
                  <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event" />
              </div>
              <span style="margin-left:-10%; font-size: 100%;">%</span>
              </div>
          </div> 
            </div>
        </div>   
    </div>

</div>