import { Injectable } from '@angular/core'; 
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class RoleserviceService {

  constructor(private http:HttpClient){
    this.getallroles();
  }
  allrolename:any[]=[];
  rolePermissionMap: { [key: string]: { [commandName: string]: string } } = {};  
  setallroles(roledata:any){
    if(this.allrolename.length>0){
      this.allrolename=[];
    }


    roledata.forEach((packet:any)=>{
      if(packet.roleStatus=="active" && packet.roleName!="defaultRole"){
        this.allrolename.push(packet.roleName);
      }
      
    })

  }
  setAllRolePermission(roleDataPacket: any) {
    this.rolePermissionMap = roleDataPacket.reduce((acc: any, role: any) => {
      acc[role.roleName] = role.permissions.reduce((commandAcc: any, command: any) => {
        commandAcc[command.commandName] = command.permissions;
        return commandAcc;
      }, {});
      return acc;
    }, {});
    sessionStorage.setItem('rolePermissionMap', JSON.stringify(this.rolePermissionMap));

  }

  isRoleCommand(commandName: string): boolean {
    const roleName = sessionStorage.getItem('userRole');

    console.log("landed iin checking role permissions",roleName);
    if(roleName){
      if(Object.keys(this.rolePermissionMap).length === 0){
        const savedPermissions = sessionStorage.getItem('rolePermissionMap');
        if(savedPermissions){
          this.rolePermissionMap = JSON.parse(savedPermissions);
        }
        

      }
      const rolePermissions = this.rolePermissionMap[roleName];
      console.log(this.rolePermissionMap);
      console.log("landed iin checking role permissions check 2",rolePermissions);

      if (rolePermissions && rolePermissions[commandName] === 'READ_WRITE') {
        return true;
      }
      return false;
    }
    return false;
    
   
  }
  


 getallroles(){
    this.http.get<any>(`${environment.apiBaseUrl}/roles`).subscribe(
      (response)=>{
        
        this.setallroles(response);
        this.setAllRolePermission(response);
        console.log(response);
      },
      (error)=>{
        console.log(error);
      }
    )
  }
  getallrolenames(){
    return this.allrolename;
  }
}