import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-three-screen',
  templateUrl: './zone-three-screen.component.html',
  styleUrls: ['./zone-three-screen.component.css']
})
export class ZoneThreeScreenComponent {
  type!: number;
  RAT: number;
  KPI: number = 0;
RAT_out: any;
RAT_out_AHU_06: any;
Zone_temp:number=0;
power_ahu_06:number=0;
BASELINE_ZONE_03:number=0;
POWER_ZONE_03:number=0;


constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_06 = Number(localStorage.getItem('RAT_out_AHU_06')) || 0;
  localStorage.setItem('RAT_out_AHU_06', JSON.stringify(this.RAT_out_AHU_06));
  console.log("RAT_AHU_06_output_zone_one", this.RAT_out_AHU_06 );




  this.Zone_temp = this.RAT_out_AHU_06;


    this.power_ahu_06 = Number(localStorage.getItem('Power_AHU_06_out')) || 0;
    localStorage.setItem('power_ahu_06', JSON.stringify('power_ahu_06'));
    console.log("Power_AHU_06_out", this.power_ahu_06);

    this.BASELINE_ZONE_03 = 7.2;
    this.POWER_ZONE_03=this.power_ahu_06;

    
    if(this.POWER_ZONE_03){
      this.KPI = ((this.BASELINE_ZONE_03-this.POWER_ZONE_03)/this.BASELINE_ZONE_03)*100;
    localStorage.setItem('kpi_zone_03', JSON.stringify('KPI'));
    }
    else{
      this.KPI = 0 ;
    }
  

 }
}
