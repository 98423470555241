import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebSocketService } from '../services/web-socket.service';

interface CateringRoom {
  roomName: string;
  rt: number | null;
  rh: number | null;
  rdp: number | null;
  acph: number | null;
}

interface AHU {
  ahuName: string;
  cateringRooms: CateringRoom[];
}

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.css']
})
export class TableDataComponent {
  tableData: AHU[] = [];
  rarh_ahu1:any=0;
  rat_ahu1:any=0;
  rt_ahu1:any=0;
  rh_ahu1:any=0;
  rdp_ahu1:any=0;

  rarh_ahu2:any=0;
  rat_ahu2:any=0;
  rt_ahu2:any=0;
  rh_ahu2:any=0;
  rdp_ahu2:any=0;

  rarh_ahu3:any=0;
  rat_ahu3:any=0;
  rt_ahu3:any=0;
  rh_ahu3:any=0;
  rdp_ahu3:any=0;

  rarh_ahu4:any=0;
  rat_ahu4:any=0;
  rt_ahu4:any=0;
  rh_ahu4:any=0;
  rdp_ahu4:any=0;

  rarh_ahu5:any=0;
  rat_ahu5:any=0;
  rt_ahu5:any=0;
  rh_ahu5:any=0;
  rdp_ahu5:any=0;


  rarh_ahu6:any=0;
  rat_ahu6:any=0;
  rt_ahu6:any=0;
  rh_ahu6:any=0;
  rdp_ahu6:any=0;

  rarh_ahu7:any=0;
  rat_ahu7:any=0;
  rt_ahu7:any=0;
  rh_ahu7:any=0;
  rdp_ahu7:any=0;

  rarh_ahu8:any=0;
  rat_ahu8:any=0;
  rt_ahu8:any=0;
  rh_ahu8:any=0;
  rdp_ahu8:any=0;

  rarh_ahu10:any=0;
  rat_ahu10:any=0;
  rt_ahu10:any=0;
  rh_ahu10:any=0;
  rdp_ahu10:any=0;

  rarh_ahu11:any=0;
  rat_ahu11:any=0;
  rt_ahu11:any=0;
  rh_ahu11:any=0;
  rdp_ahu11:any=0;

  rarh_ahu12:any=0;
  rat_ahu12:any=0;
  rt_ahu12:any=0;
  rh_ahu12:any=0;
  rdp_ahu12:any=0;
  constructor(private http: HttpClient,private webSocketService:WebSocketService) {}

  ngOnInit(): void {
    this.getTableData();
    this.webSocketService.ahuData$.subscribe(
      data=>{
        const ahu1data=data['AHU_01'];
        if(ahu1data){
          this.rarh_ahu1=ahu1data.relative_hum;
          this.rat_ahu1=ahu1data.rat;
          this.rt_ahu1=ahu1data.room_temp;
          this.rh_ahu1=ahu1data.room_humidity;
          this.rdp_ahu1=ahu1data.room_dp;
        }
        const ahu2data=data['AHU_02'];
        if(ahu2data){
          this.rarh_ahu2=ahu2data.relative_hum;
          this.rat_ahu2=ahu2data.rat;
          this.rt_ahu2=ahu2data.room_temp;
          this.rh_ahu2=ahu2data.room_humidity;
          this.rdp_ahu2=ahu2data.room_dp;
        }
        const ahu3data=data['AHU_03'];
        if(ahu3data){
          this.rarh_ahu3=ahu3data.relative_hum;
          this.rat_ahu3=ahu3data.rat;
          this.rt_ahu3=ahu3data.room_temp;
          this.rh_ahu3=ahu3data.room_humidity;
          this.rdp_ahu3=ahu3data.room_dp;
        }
        const ahu4data=data['AHU_04'];
        if(ahu4data){
          this.rarh_ahu4=ahu4data.relative_hum;
          this.rat_ahu4=ahu4data.rat;
          this.rt_ahu4=ahu4data.room_temp;
          this.rh_ahu4=ahu4data.room_humidity;
          this.rdp_ahu4=ahu4data.room_dp;
        }
        const ahu5data=data['AHU_05'];
        if(ahu5data){
          this.rarh_ahu5=ahu5data.relative_hum;
          this.rat_ahu5=ahu5data.rat;
          this.rt_ahu5=ahu5data.room_temp;
          this.rh_ahu5=ahu5data.room_humidity;
          this.rdp_ahu5=ahu5data.room_dp;
        }
        const ahu6data=data['AHU_06'];
        if(ahu6data){
          this.rarh_ahu6=ahu6data.relative_hum;
          this.rat_ahu6=ahu6data.rat;
          this.rt_ahu6=ahu6data.room_temp;
          this.rh_ahu6=ahu6data.room_humidity;
          this.rdp_ahu6=ahu6data.room_dp;
        }
        const ahu7data=data['AHU_07'];
        if(ahu7data){
          this.rarh_ahu7=ahu7data.relative_hum;
          this.rat_ahu7=ahu7data.rat;
          this.rt_ahu7=ahu7data.room_temp;
          this.rh_ahu7=ahu7data.room_humidity;
          this.rdp_ahu7=ahu7data.room_dp;
        }
        const ahu8data=data['AHU_08'];
        if(ahu8data){
          this.rarh_ahu8=ahu8data.relative_hum;
          this.rat_ahu8=ahu8data.rat;
          this.rt_ahu8=ahu8data.room_temp;
          this.rh_ahu8=ahu8data.room_humidity;
          this.rdp_ahu8=ahu8data.room_dp;
        }
        const ahu10data=data['AHU_10'];
        if(ahu10data){
          this.rarh_ahu10=ahu10data.relative_hum;
          this.rat_ahu10=ahu10data.rat;
          this.rt_ahu10=ahu10data.room_temp;
          this.rh_ahu10=ahu10data.room_humidity;
          this.rdp_ahu10=ahu10data.room_dp;
        }
        const ahu11data=data['AHU_11'];
        if(ahu11data){
          this.rarh_ahu11=ahu11data.relative_hum;
          this.rat_ahu11=ahu11data.rat;
          this.rt_ahu11=ahu11data.room_temp;
          this.rh_ahu11=ahu11data.room_humidity;
          this.rdp_ahu11=ahu11data.room_dp;
        }
        const ahu12data=data['AHU_12'];
        if(ahu12data){
          this.rarh_ahu12=ahu12data.relative_hum;
          this.rat_ahu12=ahu12data.rat;
          this.rt_ahu12=ahu12data.room_temp;
          this.rh_ahu12=ahu12data.room_humidity;
          this.rdp_ahu12=ahu12data.room_dp;
        }
      }
    )
  }



  getTableData(): void {
    // Dummy data as per the provided AHU catering to rooms.
    this.tableData = [
      {
        ahuName: "LMB-AHU-01-1000 CFM",
        cateringRooms: [
          { roomName: "Liquid dispensing staging", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-02-1000 CFM",
        cateringRooms: [
          { roomName: "Day store", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "sfg quarantine", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-03-1000 CFM",
        cateringRooms: [
          { roomName: "Suppositories", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-04-1000 CFM",
        cateringRooms: [
          { roomName: "Liquid filing", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-05-1000 CFM",
        cateringRooms: [
          { roomName: "Ointment preparation", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-06-1000 CFM",
        cateringRooms: [
          { roomName: "Ointment fill & seal", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-07-5800 CFM",
        cateringRooms: [
          { roomName: "CHANGE ROOM GENTS", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "CHANGE ROOM LADIES", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "PROD. OFFICE", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "IPQA", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "ENTRANCE CORRIDOR", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "GENTS AIR LOCK", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "LADIES AIR LOCK", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "LADIES HAND AND FOOT WASH", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "GENTS HAND AND FOOT WASH", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "LINEN ROOM", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-08-5800 CFM",
        cateringRooms: [
          { roomName: "Liquid preparation", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-10-4000 CFM",
        cateringRooms: [
          { roomName: "Sachet filling", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-11-4000 CFM",
        cateringRooms: [
          { roomName: "HOLDING ROOM", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "ACESSORIES", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "MATERIAL CORRIDOR", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "MaterialAIR LOCK", rt: null, rh: null, rdp: null, acph: null },
          { roomName: "MGF CORRIDOR", rt: null, rh: null, rdp: null, acph: null }
        ]
      },
      {
        ahuName: "LMB-AHU-12-4000 CFM",
        cateringRooms: [
          { roomName: "EQUIPMENT DRYING", rt: null, rh: null, rdp: null, acph: null }
        ]
      }
    ];
  }
}
