<div style="position: relative; height: 100%; width: 100%">
    <div style="
            width: 100%;
            height: 100%;
            min-width: 1200px;
            display: flex;
            flex-direction: column;
            position: absolute;">
        <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
            <!-- main body -->
            <div style="
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: start;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;
                margin-top:2.5%;
                padding-left: 150px;">
                <div style="
                    height: 620px;
                    width: 1200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;">
                    <!-- map -->
                    <div style="
                        height: 620px;
                        display: flex;
                        width: 1200px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        
                        margin-left:-25%;">
                        <img src="/assets/strides-images/OLT-AHU-01-1000 CFM.svg" width="1000px" height="1000px" />
                    </div>
                    <!-- componets -->
                    <div style="height: 350px; width: 1000px; position: absolute">
                        <!-- FAN -->


                        <!-- 1. RAT -->




                      



                 

                        






                    </div>




                </div>







                                <!-- KPI FORM  -->
        
       
      <!-- <div class="form" style="height: 200px; width: 400px; background:#F0F6FF; margin-top:20px; z-index:1; margin-right:50px; gap:10px;">
        <span
          class="span-text-dark"
          style="font-weight: bold; font-size: 20px; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div style="height: 30px;   "></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            width: 380px;
            justify-content: space-between;
            align-items: center;
            padding:15px;
            z-index:1; background:white;
            border-radius: 10px;
            gap:20px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 16px; font-weight: 500"
            >MAIN ENTRANCE Temperature</span
          >

          <div style="width:70px">
            
            <input style="width:70px; font-size: 20px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
            [ngModel]="Zone_temp | number : '1.1-1'"
            (ngModelChange)="Zone_temp = $event"
            />
            
          </div>
          <span style="margin-left:-10px; font-size: 20px;">ºC</span>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            width: 380px;
            justify-content: space-between;
            align-items: center;
            padding:15px;
            z-index:1; background:white;
            border-radius: 10px;
            gap:20px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 16px; font-weight: 500"
            >MAIN ENTRANCE Energy Saving</span
          >

          <div style="width:70px">
            
            <input style="width:70px; font-size: 20px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
            [ngModel]="KPI | number : '1.1-1'"
            (ngModelChange)="KPI = $event"
            />
            
          </div>
          <span style="margin-left:-5px; font-size: 20px;">%</span>
        </div>

      </div>  -->


      




            </div>
        </div>
    </div>
</div>