// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-summary-page',
//   templateUrl: './summary-page.component.html',
//   styleUrls: ['./summary-page.component.css']
// })

import { Component } from '@angular/core';
 
interface CardData {
  title: string;
  value?: string | number;
  buttonText: string;
  progress?: number; // Optional property for progress bar percentage
}
 
interface TableRow {
  ahuName: string;
  areaServed: string;
  status: string;
  energySaved : string;
  fanSpeedPr : string;
  fanSpeedOp: string;
  cwvPr: string;
  cwvOp: string;
  route: string;
  // Define other properties
}
 
 
@Component({
  selector: 'app-summary',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.css']
})
export class SummaryPageComponent {
 
  cards: CardData[] = [
    { title: 'Total Energy Saved', value: '78%', buttonText: 'Optimize' },
    { title: 'Annual Cost Saving', value: '₹85,412', buttonText: 'View Details' },
    { title: 'Annual Energy Saving', value: '234 Kwh', buttonText: 'View Details' }
  ];
 
  tableRows: TableRow[] = [
    { ahuName: 'AUB', route:'/dashboard/ahu/AUB',areaServed: 'A Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    { ahuName: 'B1L0', route:'/dashboard/ahu/B1L0',areaServed: 'B1 Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    { ahuName: 'B2EGS', route:'/dashboard/ahu/B2EGS',areaServed: 'B2E Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    { ahuName: 'D1L0', route:'/dashboard/ahu/D1L0',areaServed: 'D1 Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    { ahuName: 'EDayE', route:'/dashboard/ahu/EDayE',areaServed: 'E Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    { ahuName: 'Chiller Plant', route:'/dashboard/ahu/f-chiller-plant',areaServed: 'F Block', status: 'ON', energySaved: '40%', fanSpeedPr: '70Hz', fanSpeedOp: '70Hz', cwvPr: '65%', cwvOp : '65%'},
    // ... add more dummy row data
  ];
 
 
 
}