import { Component,ElementRef, HostListener, OnInit } from '@angular/core';
// import { ButtonService } from './services/button-service/button.service';

import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Strides-Pharma';
  currentScreenIndex: number = 0;
  currentPart: string = 'dashboard';
  private timeoutId: any;
  private readonly TIMEOUT_PERIOD = 15 * 60 * 1000; 
  // screens: { [key: string]: string[] } = {
  //   'dashboard': ['dashboard/chiller2', 'dashboard/chiller1', 'dashboard/chiller3'],
  // };
  
   constructor(private router: Router, public authService: AuthService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const currentUrl = event.url;
        const isNotLoginPage = this.router.url !== '/login';
        
        if (currentUrl === '/animated-home-screen' && this.authService.isLoggedIn() && isNotLoginPage) {
          // If user navigates to loading page from a protected page and is logged in, logout and redirect to login
          this.authService.logout();
          this.router.navigate(['/login']);
        }
        if (currentUrl === '/login' && this.authService.isLoggedIn()) {
          // If user navigates to login page and is logged in, logout
          this.authService.logout();
        }
        
      }
    });
  }

  ngOnInit() {
    this.resetTimeout();
  }
  @HostListener('window:mousemove') onUserActivity() {
    this.resetTimeout();
  }

  @HostListener('window:keydown') onKeydown() {
    this.resetTimeout();
  }

  resetTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      sessionStorage.clear();
      this.router.navigate(['/login']);
    }, this.TIMEOUT_PERIOD);
  }

  @HostListener('document:keydown', ['$event'])
  disableSpecialKeys(event: KeyboardEvent): void {
    // Add keys you want to disable
    if (event.ctrlKey || event.altKey || event.key === 'F12' || event.key === 'F1' || event.key === 'F2') {
      event.preventDefault();
    }
  }
  disableRightClick(event: MouseEvent): void {
    event.preventDefault();
  }


  
}
