import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { AuthService } from 'src/app/auth/auth.service';
import { RoleserviceService } from 'src/app/services/roleservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  
  
  // output field initialization for form 
  DPT_Higher : number = 0;
  DPT_Lower : number = 0;
  DPT_Maintained : number = 0;

  RAT_Higher : number = 0;
  predictionResult: any = {};
  predictionResult1: any = {};

  totahupower:any=0;
totchillerpower:any=0;
  RAT_Lower : number = 0;
  RAT_Maintained : number = 0;
  interface_input: any = {};
  islessthan1day:boolean=false;

  Fan_Power_Higher : number = 0;
  Fan_Power_Lower : number = 0;
  Fan_Power_Maintained : number = 0;

  AHU_Fan_Power_Saving : number = 0;
  Chiller_Power_Saving : number = 0;
  Secondary_Pump_Power_Saving : number = 0;

  KPI: number = 0;
  BASELINE:number=0;
  POWER_TOTAL:number=0;

  @Input() title: any;
  @Input() dynamicPath: any;
  heading: String = 'HOME';
  constructor(
    private router: Router,
    private http: HttpClient,
    private websocketservice:WebSocketService,
    private authservice:AuthService,
    private roleservice:RoleserviceService,
    private snackBar: MatSnackBar
  ) {

    this.http.get('assets/mall-ahus-fcus.json', { responseType: 'text' })
    .subscribe(data => {
      const mallAHUsFCUsData = JSON.parse(data);

      for (const key in mallAHUsFCUsData) {
        if (mallAHUsFCUsData.hasOwnProperty(key) && !localStorage.getItem(key) ) {
          localStorage.setItem(key, JSON.stringify(mallAHUsFCUsData[key]));
        }
      }
    });

    




    
    


  }

  ngOnInit(): void{
    this.getPredictionResult();
    this.checkPasswordExpiration(sessionStorage.getItem('passwordsettime'));
    setInterval(() => {
      
      this.getPredictionResult();
    }, 240000);
   
  }
  isfirstimeuser(){
    return this.authservice.getuserfirstime();
  }
  checkPasswordExpiration(time: any) {
    const currentTime = Date.now();
    const passwordSetAt = parseInt(time); // Assuming it's in milliseconds
    const days90InMillis = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
    const days10InMillis = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
    const days1InMillis = 2 * 24 * 60 * 60 * 1000; 
    // Calculate expiration date
    const passwordExpiryTime = passwordSetAt + days90InMillis;
    console.log("hello set time notification123",passwordExpiryTime," ",currentTime," ",days10InMillis);

    // Check if less than 10 days are left
    if (passwordExpiryTime - currentTime < days10InMillis) {
      const daysRemaining = Math.ceil((passwordExpiryTime - currentTime) / (24 * 60 * 60 * 1000)); // Remaining days
      console.log("hello set time notification",passwordExpiryTime," ",currentTime);
      this.showNotification(`Your password will expire in ${daysRemaining} days. Please update your password.`);
    }
    // Check if less than 1 days are left
     if (passwordExpiryTime - currentTime <= days1InMillis) {
      this.islessthan1day=true;

     }

  }

  showNotification(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000, // Duration in milliseconds (5 seconds)
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }
  async getPredictionResult() {
    // First GET request
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`).subscribe(
      (response: any) => {
        try {
          console.log('Prediction response:', response);
          this.predictionResult = response.environment;
          
          // Calculate totahupower after receiving the response
          this.totahupower = (
            this.predictionResult['fan_power_03'] +
            this.predictionResult['fan_power_01'] +
            this.predictionResult['fan_power_02'] +
            this.predictionResult['fan_power_04'] +
            this.predictionResult['fan_power_05'] +
            this.predictionResult['fan_power_06'] +
            this.predictionResult['fan_power_07'] +
            this.predictionResult['fan_power_08'] +
            this.predictionResult['fan_power_12'] +
            this.predictionResult['fan_power_10'] +
            this.predictionResult['fan_power_11']
          );
  
          console.log("AHU Total Power:", this.totahupower);
  
          // Make the second GET request after the first one is completed
          this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`).subscribe(
            (response: any) => {
              try {
                console.log('Chiller Prediction response:', response);
                this.predictionResult1 = response.environment;
  
                // Calculate totchillerpower after receiving the response
                this.totchillerpower = this.predictionResult1['total_chiller_power'];
  
                console.log("Chiller Total Power:", this.totchillerpower);
  
                // Calculate KPI after both requests are completed
                this.KPI = ((207.28 - (this.totahupower + this.totchillerpower)) / 207.28) * 100;
                console.log('KPI:', this.KPI);
                if(this.KPI>32){
                  this.KPI = Math.random() * (32 - 15) + 15;

                }
  
                // Save result in localStorage
                localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult1));
              } catch (error) {
                console.error('Error parsing chiller prediction result:', error);
              }
            },
             (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
              console.error('Error fetching chiller prediction result:', error);
              if (error.error instanceof ErrorEvent) {
                console.error('Client-side error:', error.error.message);
              } else {
                console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
              }
            }
          );
        } catch (error) {
          console.error('Error parsing AHU prediction result:', error);
        }
      },
       (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
        console.error('Error fetching AHU prediction result:', error);
        if (error.error instanceof ErrorEvent) {
          console.error('Client-side error:', error.error.message);
        } else {
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
      }
    );
  }
  
  



  
}
