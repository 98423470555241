import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

import { Observable,BehaviorSubject } from 'rxjs';
import { ErrorMonitoringService } from './error-monitoring.service';


@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: Socket;
  private readonly SERVER_URL = `${environment.apiBaseUrl}`; // Update with your server URL
  private ahuDataSubject: BehaviorSubject<{ [key: string]: any }> = new BehaviorSubject<{ [key: string]: any }>({});
  public ahuData$ = this.ahuDataSubject.asObservable();
  constructor(private errormonitoringservice:ErrorMonitoringService) {
    this.socket = io(this.SERVER_URL);
    this.socket.on('onAlarm', async (data: any) => {
      console.log('Received data from WebSocket:', data.data);  // Debugging log
      const convertedData = await this.errormonitoringservice.convertResponse(data.data); // Convert the response
      console.log(convertedData);
      const equipname=convertedData[0].description;
      console.log('Converted Data:plssssssssss', equipname);  // Debugging log
      this.errormonitoringservice.setAlarms(equipname,convertedData); // Save to ErrorMonitoringService
    });
  

    this.socket.on('onMessage',async (message: any) => {
    

      console.log('Received message for relay from web:', message);
      // Extract identifier to distinguish between AHUs
      const identifier = this.extractEquipmentIdentifier(message.data.identifier);
      const currentData = this.ahuDataSubject.value;
      
      // Update the data for the specific AHU
      currentData[identifier] = message.data;

      // Emit updated data
      console.log("in web socket service working of spread operator",{...currentData})
      this.ahuDataSubject.next({ ...currentData });
    });
  }
  private extractEquipmentIdentifier(identifier: string): string {
    const parts = identifier.split('/');
    return parts[parts.length - 1]; // Get the last part as the identifier
  }
  // Listen to messages from the server
  onMessage(event: string): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, (data: any) => {
        observer.next(data);
      });

      // Clean up the subscription
      return () => {
        this.socket.off(event);
      };
    });
  }



  // Emit messages to the server
  sendMessage(event: string, data: any) {
    this.socket.emit(event, data);
  }

  // Close the socket connection
  close() {
    this.socket.disconnect();
  }

}
