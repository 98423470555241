import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-ten-comp',
  templateUrl: './ahu-ten-comp.component.html',
  styleUrls: ['./ahu-ten-comp.component.css']
})
export class AhuTenCompComponent {

}
