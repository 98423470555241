<div style="position: relative; height: 100%; width: 100%">
  <!-- <Water-mark></Water-mark> -->

  <div style="
        width: 100%;
        height: 100%;
        min-width: 1000px;
        display: flex;
        flex-direction: row;
        position: absolute;
      ">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar>
    <div style="
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          align-items: start;
          gap: 10px;
        ">
      <div style="
            height: 400px;
            width: 900px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          ">
        <div style="
              height: 400px;
              width: 900px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
            ">
          <img src="/assets/images/hex-manar.svg" width="900px" height="400px" />
          <!-- <div style="position: absolute; top: 325px; left: 365px; height: 50px; width: 270px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                <p style="font-family: 'Inter', sans-serif; font-size: 24px; font-weight:520; color:var(--blue-one); text-align: center; line-height:25px; ">AHU ROOM NAME</p>
              </div>
            </div>
            <div style="position: absolute; top: 110px; left: 1010px; height: 100px; width: 150px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                <p style="font-family: 'Inter', sans-serif; font-size: 24px; font-weight:520; color:var(--blue-one); text-align: center; line-height:25px; ">AREA/ FLOOR/ SECTION</p>
              </div>
            </div> -->
        </div>

        <!-- componets -->

        <!-- PUMP 1 -->
        <div style="
                height: 60px;
                width: 60px;
                margin-top: -92px;
                margin-left: 200px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            ">
          <Primary-pump [state]="'active'"></Primary-pump>

          <!-- PUMP 2 -->
        </div>
        <div style="
                height: 60px;
                width: 60px;
                margin-top: 103px;
                margin-left: 200px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            ">
          <Primary-pump [state]="'active'"></Primary-pump>

          <!-- <Fan [state]="'active'"></Fan> -->
        </div>
        <div style="
                height: 60px;
                width: 60px;
                margin-top: 308px;
                margin-left: 200px;
                z-index: 1;
                position: absolute;
                transform: rotate(-90deg);
            ">
          <Primary-pump [state]="'active'"></Primary-pump>

          <!-- <Fan [state]="'active'"></Fan> -->
        </div>
        <!-- Pump 1 ON/OFF  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: -80px; margin-left: 83px">
          <img src="/assets/images/switch1.svg" />
          <input [ngModel]="Pump1_Status" (ngModelChange)="Pump1_Status = $event" />
        </div>

        <!-- Pump 1 VFD  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: -80px; margin-left: 316px">
          <img src="/assets/images/percentage.png" />
          <input [ngModel]="VFD_Sec_Pump_1_out | number : '1.2-2'" (ngModelChange)="VFD_Sec_Pump_1_out = $event" />
        </div>

        <!-- SecPumpPower2 -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: -80px; margin-left: 484px">
          <img src="/assets/images/power.png" />
          <input [ngModel]="Sec_Pump_Power_out_1 | number : '1.2-2'" (ngModelChange)="Sec_Pump_Power_out_1 = $event" />
        </div>


        <!-- Pump 2 ON/OFF  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 114px; margin-left: 83px">
          <img src="/assets/images/switch1.svg" />
          <input [ngModel]="Pump2_Status" (ngModelChange)="Pump2_Status = $event" />
        </div>

        <!-- Pump 2 VFD  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 114px; margin-left: 316px">
          <img src="/assets/images/percentage.png" />
          <input [ngModel]="VFD_Sec_Pump_2_out | number : '1.2-2'" (ngModelChange)="VFD_Sec_Pump_2_out = $event" />
        </div>
        <!-- SecPumpPower2 -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 114px; margin-left: 484px">
          <img src="/assets/images/power.png" />
          <input [ngModel]="Sec_Pump_Power_out_2 | number : '1.2-2'" (ngModelChange)="Sec_Pump_Power_out_2 = $event" />
        </div>

        <!-- Pump 3 ON/OFF  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 320px; margin-left: 83px">
          <img src="/assets/images/switch1.svg" />
          <input [ngModel]="Pump3_Status" (ngModelChange)="Pump3_Status = $event" />
        </div>

        <!-- Pump 3 VFD  -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 320px; margin-left: 316px">
          <img src="/assets/images/percentage.png" />
          <input [ngModel]="VFD_Sec_Pump_3_out | number : '1.2-2'" (ngModelChange)="VFD_Sec_Pump_3_out = $event" />
        </div>
        <!-- SecPumpPower3 -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: 320px; margin-left: 484px">
          <img src="/assets/images/power.png" />
          <input [ngModel]="Sec_Pump_Power_out_3 | number : '1.2-2'" (ngModelChange)="Sec_Pump_Power_out_3 = $event" />
        </div>

        <!-- Diff_Pressure_Sec_Pump_out -->
        <div class="input-group" style="position:absolute; width: 80px; margin-top: -350px; margin-left: 574px">
          <img src="/assets/images/differential-pressure-transmitter.png" />
          <input [ngModel]="Diff_Pressure_Sec_Pump_out | number : '1.2-2'" (ngModelChange)="Diff_Pressure_Sec_Pump_out = $event" />
        </div>


      </div>
    </div>

    <div style="
          height: auto;
          width: 100%;
          
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 7px;
          align-items: center;
          gap: 10px;
        ">
      <div style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: auto;
            justify-content: center;
          ">
        <div style="width: 300px; height: 100%; margin: 0px 20px 0px 50px">
          <div class="form" style="
                padding: 20px;
                flex-direction: column;
                gap: 30px;
                align-items: start;
              ">
            <div style="
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                ">
              <div style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  ">
                <span class="span-text-dark" style="font-size: 15px; font-weight: 500">Pump 1</span>

              </div>
              <!-- ON/OFF Status (SecPumpSta	(0 or 1)	Binary)-->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">ON/OFF STATUS</span>
                <div class="dropdown-box" style="width: 100px">
                  <select [(ngModel)]="onOffStatus1" (change)="onOnOffStatus1Change()" class="status-dropdown">
                    <option value="0" style="color:#fffffb; background:var(--blue-one);" selected disabled>-SELECT-
                    </option>
                    <option value="1">ON</option>
                    <option value="2">OFF</option>
                  </select>
                </div>
              </div>

              <!-- Sec Pump VFD (SecPumpVFD	(30 to 100)	%)-->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">SEC PUMP VFD</span>

                <div
                  [ngClass]="{ 'input-group': onOffStatus1 === '1', 'input-group0': onOffStatus1 === '0' || onOffStatus1 === '2' }"
                  style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input [(ngModel)]="VFD_Sec_Pump_1"
                    [attr.disabled]="onOffStatus1 === '0' || onOffStatus1 === '2' ? true : null" />
                </div>
              </div>




              <!-- PUMP 2  -->
              <div style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  ">
                <span class="span-text-dark" style="font-size: 15px; font-weight: 500">Pump 2</span>

              </div>
              <!-- ON/OFF Status -->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">ON/OFF STATUS</span>
                <div class="dropdown-box" style="width: 100px">
                  <select [(ngModel)]="onOffStatus2" (change)="onOnOffStatus2Change()" class="status-dropdown">
                    <option value="0" disabled style="color:#fffffb">-SELECT-</option>
                    <option value="1">ON</option>
                    <option value="2">OFF</option>
                  </select>
                </div>
              </div>

              <!-- Sec Pump VFD -->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">SEC PUMP VFD</span>

                <div
                  [ngClass]="{ 'input-group': onOffStatus2 === '1', 'input-group0': onOffStatus2 === '0' || onOffStatus2 === '2' }"
                  style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input [(ngModel)]="VFD_Sec_Pump_2"
                    [attr.disabled]="onOffStatus2 === '0' || onOffStatus2 === '2' ? true : null" />
                </div>
              </div>


              <!-- PUMP 3 -->
              <div style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  ">
                <span class="span-text-dark" style="font-size: 15px; font-weight: 500">Pump 3</span>

              </div>
              <!-- ON/OFF Status -->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">ON/OFF STATUS</span>
                <div class="dropdown-box" style="width: 100px">
                  <select [(ngModel)]="onOffStatus3" (change)="onOnOffStatus3Change()" class="status-dropdown">
                    <option value="0" disabled style="color:#fffffb">-SELECT-</option>
                    <option value="1">ON</option>
                    <option value="2">OFF</option>
                  </select>
                </div>
              </div>

              <!-- Sec Pump VFD -->
              <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <span class="span-text-dark" style="font-size: 12px; font-weight: 500">SEC PUMP VFD</span>

                <div
                  [ngClass]="{ 'input-group': onOffStatus3 === '1', 'input-group0': onOffStatus3 === '0' || onOffStatus3 === '2' }"
                  style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input [(ngModel)]="VFD_Sec_Pump_3"
                    [attr.disabled]="onOffStatus3 === '0' || onOffStatus3 === '2' ? true : null" />
                </div>
              </div>



            </div>

            <div style="
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: space-between;
                ">
              <div style="display: flex; flex-direction: column; gap: 10px">



                <!-- CHW ST -->
                <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >CHW ST</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input [(ngModel)]="CHWRT" />
                </div>
              </div> -->


                <!-- CHW RT  -->
                <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >CHW RT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input [(ngModel)]="SAT" />
                    </div>
                  </div> -->

                <!-- CHW ST SET POINT -->
                <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >CHW ST SET POINT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input [(ngModel)]="CHWST" />
                    </div>
                  </div> -->

                <!-- EOLDPSP -->

                <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >EOLDPSP</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/differential-pressure-transmitter.png" />
                      <input [(ngModel)]="CHWVF" />
                      <span>Pa</span>
                    </div>
                  </div> -->
              </div>

              <div style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 10px;
                    justify-content: end;
                    margin-top: 10px;
                  ">
                <button style="
                      width: 50%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    " (click)="predictData()">
                  Predict
                </button>
                <button style="
                      width: 50%;
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    " (click)="optimiseData()" [ngClass]="{
                      'button-disabled': disableOptimise,
                      'button-enabled': !disableOptimise
                    }" [disabled]="disableOptimise">
                  Optimise
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- KPI -->

        <!-- <div class="form" style="height: 200px; width: 400px">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500; margin-right:10px;"
                >FAN POWER SAVING</span
              >
  
              <div class="input-group" style="width: 120px; ">
                <img src="/assets/images/power.png" />
                <input
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event"
                />
                <span>%</span>
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</div>