<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                <img src="/assets/chillers1,2,3/chillers1,2,3/chillers.svg" class="fmap">
                <div class="component" style="width: 6%; height: 11%; top: 24.5%;left: 10.6%;  font-size: 120%;">
                    <button class="hbtn"  routerLink="../chiller3">
                      CHILLER-3
                    </button>
                  </div>
                  <div class="component" style="width: 8%; height: 11%; top: 35%;left: 20.3%;  font-size: 120%;">
                    <button class="hbtn" routerLink="../chiller1">
                      CHILLER-1
                    </button>
                  </div>
                  <div class="component" style="width: 9%; height: 12%; top: 50%;left: 32.6%;  font-size: 120%;">
                    <button class="hbtn" routerLink="../chiller2">
                      CHILLER-2
                    </button>
                  </div>

                  <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 1%; left: 23%; width: 10%; height: 3%;">
                    <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Ch.w set point</span>
                  </div> -->
                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 3.7%; left: 28.7%; width: 45px; height: 3%;">
                      <input readonly style="color: #D90606; font-weight:600" [ngModel]=" Chilled_Water_Set_Point | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point = $event" />
                      <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                    </div>

                    <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -3%; left: 23%; width: 10%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Chiller3 Power</span>
                    </div> -->
                    <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 3.5%; left: 28.7%; width: 45px; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" ch_power1_input | number: '1.2-2'" (ngModelChange)=" ch_power1_input = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                      </div> -->

                      <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -7%; left: 23%; width: 10%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200" >WC.Chiller3 Power</span>
                      </div> -->
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 7%; left: 28.7%; width: 45px; height: 3%;">
                          <input readonly style="color: #00A578; font-weight:600" [ngModel]=" Chilled_Water_Set_Point_out | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point_out = $event" />
                          <span style="font-size: 80%; color: #00A578; font-weight:600" >°C</span>
                        </div>





                    <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 6%; left: 41.4%; width: 10%; height: 3%;">
                      <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Ch.w set point</span>
                    </div> -->
                    <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 9.5%; left: 44.8%; width: 45px; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" Chilled_Water_Set_Point | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                      </div>
<!--
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 2%; left: 41.4%; width: 10%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Chiller2 Power</span>
                      </div> -->
                      <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 8%; left: 44.8%; width: 45px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" ch_power1_input | number: '1.2-2'" (ngModelChange)=" ch_power1_input = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                        </div> -->

                        <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: -2%; left: 41.4%; width: 10%; height: 3%;">
                          <span style="font-size: 80%; color: #00A578; font-weight:200" >WC.Chiller2 Power</span>
                        </div> -->
                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 12.8%; left: 44.8%; width: 45px; height: 3%;">
                            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" Chilled_Water_Set_Point_out | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point_out = $event" />
                            <span style="font-size: 80%; color: #00A578; font-weight:600" >°C</span>
                          </div>




                      <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 25%; left: 66%; width: 10%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Ch.w set point</span>
                      </div> -->
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 26%; left: 65%; width: 45px; height: 3%;">
                          <input readonly style="color: #D90606; font-weight:600" [ngModel]=" Chilled_Water_Set_Point | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:600" >°C</span>
                        </div>

                        <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 21%; left: 66%; width: 10%; height: 3%;">
                          <span style="font-size: 80%; color: #D90606; font-weight:200" >WC.Chiller1 Power</span>
                        </div> -->
                        <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 21%; left: 65%; width: 45px; height: 3%;">
                            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" ch_power1_input | number: '1.2-2'" (ngModelChange)=" ch_power1_input = $event" />
                            <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                          </div> -->

                          <!-- <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 17%; left: 66%; width: 10%; height: 3%;">
                            <span style="font-size: 80%; color: #00A578; font-weight:200" >WC.Chiller1 Power</span>
                          </div> -->
                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; top: 29.5%; left: 65%; width: 45px; height: 3%;">
                              <input readonly style="color: #00A578; font-weight:600" [ngModel]=" Chilled_Water_Set_Point_out | number: '1.2-2'" (ngModelChange)=" Chilled_Water_Set_Point_out = $event" />
                              <span style="font-size: 80%; color: #00A578; font-weight:600" >°C</span>
                            </div>
             
            </div>
            <div class="form component">
              <span class="span-text-dark" style="margin-bottom: 5PX; font-size: 110%;">Key Performance Indicator</span>
      
              <div style="height: 40px;"></div>
              <div  class="hvac-text">
              <span class="span-text-dark" style="font-size: 110%; font-weight: 500">Power Saving</span>
              <div style="width:25%; ">
                  <input style="width:100%; font-size: 16px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event" />
              </div>
              <span style="margin-left:-10%; font-size: 100%;">%</span>
              </div>
          </div> 
            </div>
        </div>
    </div>

</div>
