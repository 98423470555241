import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-admin-log',
  templateUrl: './admin-log.component.html',
  styleUrls: ['./admin-log.component.css']
})
export class AdminLogComponent {
  displayedColumns: string[] = ['date', 'time', 'username', 'detail'];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  fromDate: Date | null = null;
  fromTime: string | null = '09:30';
  toDate: Date | null = null;
  convfromdate:any=null;
  convtodate:any=null;
  toTime: string | null = '09:30';
  anyerror: boolean = true;
  showData: boolean = true;
  response1: any={};
  aftermessage: string = '';
  numberoflogs: boolean = true;
  isloading:boolean=false;
  times = [
    { value: '00:00', name: '00:00' },
    { value: '01:00', name: '01:00' },
    { value: '02:00', name: '02:00' },
    { value: '03:00', name: '03:00' },
    { value: '04:00', name: '04:00' },
    { value: '05:00', name: '05:00' },
    { value: '06:00', name: '06:00' },
    { value: '07:00', name: '07:00' },
    { value: '08:00', name: '08:00' },
    { value: '09:00', name: '09:00' },
    { value: '10:00', name: '10:00' },
    { value: '11:00', name: '11:00' },
    { value: '12:00', name: '12:00' },
    { value: '13:00', name: '13:00' },
    { value: '14:00', name: '14:00' },
    { value: '15:00', name: '15:00' },
    { value: '16:00', name: '16:00' },
    { value: '17:00', name: '17:00' },
    { value: '18:00', name: '18:00' },
    { value: '19:00', name: '19:00' },
    { value: '20:00', name: '20:00' },
    { value: '21:00', name: '21:00' },
    { value: '22:00', name: '22:00' },
    { value: '23:00', name: '23:00' },
  ];
  dateData: string[] = [];
  timeData: string[] = [];
  usernameData: string[] = [];
  detailData: string[] = [];
  actionData: string[]=[];
  groupedData: any = {};
  showDownloadButton = false;

  detailMappings: { [key: string]: string } = {
    "user login": " has logged in.",
    "user logout": " has logged out.",
    "user change Password": " has changed the password.",
    "user new Password updated": " has updated his temporary password.",
    "cex_command_sending": " sent command to control equipment.",
    "user download encrypt pdf": " has downloaded an encrypted PDF file.",
    "user download encrypt xlsx": " has downloaded an encrypted XLSX file.",
    
  };

  detailMappingsAC: { [key: string]: string } = {
    "user userAccountActivateOrDeactivate": " account has been activated or deactivated.",
    "create roles": "role has been created.",
    "update role": "role has been updated.",
    "Delete Role": "role has been deleted.",
    "user forgot password": " has requested to reset the password.",

    "user registration": " has been registered.",
    "user delete": " has been deleted.",
    "username update": " has been updated.",
  }
  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   console.log(this.paginator,"this is in afterview")
  //   this.dataSource.sort = this.sort;
  // }
  

  ngOnInit(): void {
    this.groupDataByDate();
    this.updateDataSource();
    // this.dataSource.paginator = this.paginator;
    // Set the data source after grouping data
  }

  // Function to group the data by date
  // Function to group the data by date and exclude logs with "get" in the detail
  groupDataByDate(): void {
    this.groupedData = this.dateData.reduce((acc: any, date: string, index: number) => {
      const detail = this.detailData[index].toLowerCase();  // Convert to lowercase for case-insensitive matching
      
      if (!detail.includes('get') && !detail.includes('fetchlogs')) {  // Check if "get" is not in the detail
        if (!acc[date]) acc[date] = [];
        // console.log(detail,"not get");
        // console.log(this.detailData[index],"ojas",this.detailMappingsAC["update role"],this.detailMappingsAC[this.detailData[index].trim()],this.detailMappings[this.detailData[index].trim()],"uma");
        const detail1 = `${this.actionData[index]} ${this.detailMappings[this.detailData[index].trim()] || this.detailMappingsAC[this.detailData[index].trim()] || 'Unknown detail'}`;
        acc[date].push({
          time: this.timeData[index],
          username: this.usernameData[index],
          detail: detail1
        });
      }
  
      return acc;
    }, {});
  }

  updateDataSource(): void {
    // Flatten the grouped data into an array of logs
    const flattenedLogs = Object.keys(this.groupedData).flatMap(date => {
      return this.groupedData[date].map((log: any) => ({
        date,
        time: log.time,
        username: log.username,
        detail: log.detail
      }));
    });

    // Update the dataSource with the flattened log data
    this.dataSource = new MatTableDataSource(flattenedLogs);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(private cdr: ChangeDetectorRef, private http: HttpClient,   private router: Router, private snackBar: MatSnackBar,
  ) {
    
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-IN', options);
    
    // Replace the short month name with capitalized first 3 letters (e.g., Nov instead of Nov.)
    const [day, month, year] = formattedDate.split(' ');
    return `${month}-${day}-${year}`;
  }

  formatTime(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    return date.toLocaleTimeString('en-IN', { ...options, timeZone: 'Asia/Kolkata' });
  }
  
  async SendData() {
    this.anyerror = false;
   
    if (!this.fromDate) {
      this.anyerror = true;
      alert('From Date is required.');
      return;
    }
 
    if (!this.fromTime) {
      this.anyerror = true;
      alert('From Time is required.');
      return;
    }
 
    if (!this.toDate) {
      this.anyerror = true;
      alert('To Date is required.');
      return;
    }
 
    if (!this.toTime) {
      this.anyerror = true;
      alert('To Time is required.');
      return;
    }
 
    if (this.fromDate > this.toDate) {
      this.anyerror = true;
      alert('From date should be less than To date');
      return;
    }
    this.isloading=true;
    console.log(this.fromDate, 'check2');
    const fromDatetime =
      this.fromDate && this.fromTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.fromDate);
 
            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
            const newDateString = date.toISOString().split('T')[0];
            const formattedTime = this.convertTo24HourFormat(this.fromTime);
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

         this.convfromdate =
      this.fromDate
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.fromDate);
 
            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
            const newDateString = date.toISOString().split('T')[0];
            return `${newDateString}`;
          })()
        : '';
 
    const toDatetime =
      this.toDate && this.toTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.toDate);
 
            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
 
            // Format the adjusted date as "YYYY-MM-DD"
            const newDateString = date.toISOString().split('T')[0];
 
            // Convert the time to 24-hour format
            const formattedTime = this.convertTo24HourFormat(this.toTime);
 
            // Combine the adjusted date with the formatted time
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

         this.convtodate =
        this.toDate
          ? (() => {
              // Create a Date object from the user input
              const date = new Date(this.toDate);
   
              // Increase the date by 1 day
              date.setDate(date.getDate() + 1);
   
              // Format the adjusted date as "YYYY-MM-DD"
              const newDateString = date.toISOString().split('T')[0];
   
              // Convert the time to 24-hour format
   
              // Combine the adjusted date with the formatted time
              return `${newDateString}`;
            })()
          : '';
 
    console.log('check from', fromDatetime);
    console.log('check to', toDatetime);
    const fromDate = new Date(fromDatetime.replace(' ', 'T'));
    const toDate = new Date(toDatetime.replace(' ', 'T'));
    
    // Subtract 5 hours and 30 minutes (in milliseconds)
    const timeOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    
    // Adjust the dates
    const adjustedFromDate = new Date(fromDate.getTime() - timeOffset);
    const adjustedToDate = new Date(toDate.getTime() - timeOffset);
    
    // Convert back to the original format (YYYY-MM-DD HH:MM:SS)
    const formatDate = (date:any) => {
      const pad = (n:any) => (n < 10 ? '0' + n : n);
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };
    
    const data = {
      from_datetime: formatDate(adjustedFromDate),
      to_datetime: formatDate(adjustedToDate),
    };
    // const data = {
    //   from_datetime: fromDatetime,
    //   to_datetime: toDatetime,
    // };
//  console.log(data),"umaaaaaaaa"
    this.showData = true;
 
    this.http
  .post<any[]>(`${environment.apiBaseUrl}/users/activityLogs`, data)
  .subscribe(
    (response: any[]) => {
      this.isloading = false;
      // console.log('received response', response);
      // console.log(data);
      const uniqueDetailData = [...new Set(response.map((item) => item['detail']))];
      // console.log('Unique detailData:', uniqueDetailData);  // 20 unique details

      // Step 2: Get one packet for each unique detail
      // const onePacketPerDetail = uniqueDetailData.map((uniqueDetail) => {
      //   return response.find((item) => item['detail'] === uniqueDetail);
      // });

      // Step 3: Log the 20 unique packets
      // console.log('One packet per unique detail:', onePacketPerDetail);


      // Check if any 'detail' in the response contains 'cex_command_sending'
      // const cexCommandItems = response.filter(item =>
      //   item.detail.toLowerCase().includes('cex_command_sending')
      // );

      // if (cexCommandItems.length > 0) {
      //   console.log('Elements containing "cex_command_sending":', cexCommandItems);
      // }

      // Continue processing the response data
      this.response1 = {
        dateData: response.map((item) => this.formatDate(new Date(item['timestamp']))),
        timeData: response.map((item) => this.formatTime(new Date(item['timestamp']))),
        detailData: response.map((item) => item['detail']),
        roleData: response.map((item) => item['role']),
        usernameData: response.map((item) => item['username']),
        actionData: response.map((item) => {
          if (this.detailMappings.hasOwnProperty(item['detail'])) {
            return item['username'];  // Return username if detail is in detailMappings
          } else if (item['action_command']) {
            // Check if action_command exists before accessing its properties
            return (
              item['action_command']['username'] ||
              item['action_command']['roleName'] ||
              item['action_command']['name'] ||
              item['action_command']['employeeName'] ||
              item['action_command']['emailId'] ||
              item['action_command']['description'] ||

              'UnKnown'  // Fallback if none of the properties exist
            );
          } else {
            return 'UnKnown';  // Fallback if action_command is undefined
          }
        })
      };
      

      console.log(this.response1,"response11111");
      this.dateData = this.response1.dateData;
      this.timeData = this.response1.timeData;
      this.usernameData = this.response1.usernameData;
      this.detailData = this.response1.detailData;
      this.actionData=this.response1.actionData;

      this.groupDataByDate();
      this.updateDataSource();
      console.log(this.paginator,"this is in send data function")
    this.dataSource.sort = this.sort;
      this.showDownloadButton = true;
    },
    (error) => {
      if (error.status === 401) {
        this.router.navigate(['/login']);
        sessionStorage.removeItem('accessToken');
      }
      this.isloading = false;
      this.numberoflogs = false;
      console.log(this.numberoflogs);
      this.openSnackBar(error.error.message);
      console.error('Error:', error);
    }
  );

  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  convertTo24HourFormat(time: string | null): string | null {
    if (!time) return null;
 
    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':');
    return `${hours}:${minutes}:00`;
  }

  isshowing(){
    if(this.groupedData.length>0){
      return true;
    }
    else{
      return false;
    }
  }
  downloadLogsAsPDF(): void {
    const doc = new jsPDF();
  
    const pageHeight = doc.internal.pageSize.height || 297;  // A4 size page height in mm
    const lineHeight = 6;  // Line height between rows
    const marginTop = 20;  // Top margin
    const marginBottom = 20;  // Bottom margin
    let yPos = marginTop;  // Initial Y position for the first line
  
    // Function to add the watermark on each page
    const addWatermark = () => {
      doc.setFontSize(50);
      doc.setTextColor(150);  // Light grey for the watermark
      doc.text('Powered by ', 35, 150, { angle: 45 });
      doc.setTextColor(255, 0, 0);  // Red color for 'Bert Labs'
      doc.text('Bert Labs', 120, 150, { angle: 45 });
      doc.setTextColor(0, 0, 0);  // Reset text color to black
    };
  
    // Add watermark for the first page
    addWatermark();
  
    // Add centered and bold title for 'Activity Logs'
    doc.setFontSize(22);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 102, 204);  // Blue color for the title
    doc.text('Activity Logs', doc.internal.pageSize.width / 2, yPos, { align: 'center' });
    yPos += 15;
  
    // Date and time range text
    const fromDateTimeText = `From: ${this.fromDate ? this.formatDate(this.fromDate) : 'N/A'} ${this.fromTime || 'N/A'}`;
    const toDateTimeText = `To: ${this.toDate ? this.formatDate(this.toDate) : 'N/A'} ${this.toTime || 'N/A'}`;
    
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0);  // Set text color back to black for date range
    doc.text(fromDateTimeText, 14, yPos);
    yPos += 10;
    doc.text(toDateTimeText, 14, yPos);
    yPos += 15;
  
    // Line style for separating logs
    doc.setDrawColor(150, 150, 150);  // Light grey for line separators
  
    // Loop over the grouped data to add logs to the PDF
    Object.keys(this.groupedData).forEach((date) => {
      if (yPos + lineHeight * 3 > pageHeight - marginBottom) {
        doc.addPage();
        yPos = marginTop;  // Reset the Y position for the new page
        addWatermark();  // Add watermark on the new page
      }
  
      // Add date header
      doc.setFontSize(14);
      doc.setTextColor(0, 102, 204);  // Blue color for date headers
      doc.text(`Date: ${date}`, 14, yPos);
      yPos += 8;
  
      // Set text color back to black for the logs
      doc.setTextColor(0, 0, 0);
  
      this.groupedData[date].forEach((activity: any) => {
        // If the Y position exceeds the page height, add a new page
        if (yPos + lineHeight * 4 > pageHeight - marginBottom) {
          doc.addPage();
          yPos = marginTop;  // Reset the Y position for the new page
          addWatermark();  // Add watermark on the new page
        }
  
        // Add activity logs to the PDF with reduced spacing between them
        doc.setFontSize(12);
        doc.text(`Time: ${activity.time}`, 14, yPos);
        yPos += lineHeight + 2;  // Slightly reduced space between log details
        doc.text(`Username: ${activity.username}`, 14, yPos);
        yPos += lineHeight + 2;  // Slightly reduced space between log details
        doc.text(`Detail: ${activity.detail}`, 14, yPos);
        yPos += lineHeight + 2;
  
        // Draw a line after each log entry
        doc.line(14, yPos, 200, yPos);
        yPos += 6;  // Extra space after each log entry
      });
  
      yPos += 15;  // Extra space between date groups
    });
  
    // Save the generated PDF
    doc.save(`activity_logs_${this.convfromdate}_${this.convtodate}.pdf`);
  }
  
  
  
  

}





// downloadLogsAsPDF(): void {
//   const doc = new jsPDF();

//   // Set the title for the PDF
//   doc.setFontSize(18);
//   doc.text('Activity Logs', 14, 20);
//   doc.setFontSize(12);
  
//   // Starting y-axis position
//   let yPos = 30;
  
//   // Loop over the grouped data to add logs to the PDF
//   Object.keys(this.groupedData).forEach((date) => {
//     doc.setFontSize(14);
//     doc.text(`Date: ${date}`, 14, yPos);
//     yPos += 8;

//     this.groupedData[date].forEach((activity: any) => {
//       doc.setFontSize(12);
//       doc.text(`Time: ${activity.time}`, 14, yPos);
//       yPos += 6;
//       doc.text(`Username: ${activity.username}`, 14, yPos);
//       yPos += 6;
//       doc.text(`Detail: ${activity.detail}`, 14, yPos);
//       yPos += 10;
//     });

//     yPos += 5; // Extra space between date groups
//   });

//   // Save the generated PDF
//   doc.save('activity_logs.pdf');
// }
// this was the previous code, now according to the font and format of this file, please add the from and to as mentioned above