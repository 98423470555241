import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-five-comp',
  templateUrl: './ahu-five-comp.component.html',
  styleUrls: ['./ahu-five-comp.component.css']
})
export class AhuFiveCompComponent {

}
