import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-one-screen',
  templateUrl: './zone-one-screen.component.html',
  styleUrls: ['./zone-one-screen.component.css']
})
export class ZoneOneScreenComponent {
  type!: number;
  RAT: number;
  RAT_out: any;
  KPI: number = 0;

  power_ahu_02:number=0;
  power_ahu_03:number=0;
  power_ahu_04:number=0;
  power_fahu_01:number=0;
  power_fahu_02:number=0;
  POWER_ZONE_01:number=0;
  BASELINE_ZONE_01:number=0;
  Zone_temp: number=0;
  RAT_out_AHU_02: any;
  RAT_out_AHU_03: any;
  RAT_out_AHU_04: any;
  SAT_out_FAHU_01: any;
  SAT_out_FAHU_02: any;
  constructor(private router: Router) {
    this.RAT = 0;
    this.RAT_out_AHU_02 = Number(localStorage.getItem('RAT_out_AHU_02')) || 0;
    localStorage.setItem('RAT_out_AHU_02', JSON.stringify(this.RAT_out_AHU_02));
    console.log("RAT_AHU_02_output_zone_one", this.RAT_out_AHU_02 );

    this.RAT_out_AHU_03 = Number(localStorage.getItem('RAT_out_AHU_03')) || 0;
    localStorage.setItem('RAT_out_AHU_03', JSON.stringify(this.RAT_out_AHU_03));
    console.log("RAT_AHU_03_output_zone_one", this.RAT_out_AHU_03 );

    this.RAT_out_AHU_04 = Number(localStorage.getItem('RAT_out_AHU_04')) || 0;
    localStorage.setItem('RAT_out_AHU_04', JSON.stringify(this.RAT_out_AHU_04));
    console.log("RAT_AHU_04_output_zone_one", this.RAT_out_AHU_04 );

    this.SAT_out_FAHU_01 = Number(localStorage.getItem('SAT_out_FAHU_01')) || 0;
    localStorage.setItem('SAT_out_FAHU_01', JSON.stringify(this.SAT_out_FAHU_01));
    console.log("SAT_output_zone_one", this.SAT_out_FAHU_01 );

    this.SAT_out_FAHU_02 = Number(localStorage.getItem('SAT_out_FAHU_02')) || 0;
    localStorage.setItem('SAT_out_FAHU_02', JSON.stringify(this.SAT_out_FAHU_02));
    console.log("SAT_output_zone_one", this.SAT_out_FAHU_02 );

    this.Zone_temp = ((this.SAT_out_FAHU_01+this.SAT_out_FAHU_02+this.RAT_out_AHU_02+this.RAT_out_AHU_03+this.RAT_out_AHU_04)/5);


    this.power_ahu_02 = Number(localStorage.getItem('Power_AHU_02_out')) || 0;
    localStorage.setItem('power_ahu_02', JSON.stringify('power_ahu_02'));
    console.log("Power_AHU_02_out", this.power_ahu_02);

    this.power_ahu_03 = Number(localStorage.getItem('Power_AHU_03_out')) || 0;
    localStorage.setItem('power_ahu_03', JSON.stringify('power_ahu_03'));
    console.log("Power_AHU_03_out", this.power_ahu_03);

    this.power_ahu_04 = Number(localStorage.getItem('Power_AHU_04_out')) || 0;
    localStorage.setItem('power_ahu_04', JSON.stringify('power_ahu_04'));
    console.log("Power_AHU_04_out", this.power_ahu_04);

    this.power_fahu_01 = Number(localStorage.getItem('Power_FAHU_01_out')) || 0;
    localStorage.setItem('power_fahu_01', JSON.stringify('power_fahu_01'));
    console.log("Power_FAHU_01_out", this.power_fahu_01);

    this.power_fahu_02 = Number(localStorage.getItem('Power_FAHU_02_out')) || 0;
    localStorage.setItem('power_fahu_02', JSON.stringify('power_fahu_02'));
    console.log("Power_FAHU_02_out", this.power_fahu_02);

    this.BASELINE_ZONE_01 = 34.219;
    this.POWER_ZONE_01=(this.power_ahu_02+this.power_ahu_03+this.power_ahu_04+this.power_fahu_01+this.power_fahu_02);

    if(this.POWER_ZONE_01){
      this.KPI = (((this.BASELINE_ZONE_01-this.POWER_ZONE_01)/this.BASELINE_ZONE_01)*100) ;
    localStorage.setItem('kpi_zone_01', JSON.stringify('KPI'));
    }
    else{
      this.KPI = 0 ;
    }
    



   }

  // Function to navigate to "zone-two" when AHU-01 is clicked
  
  
}
