import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-two-comp',
  templateUrl: './ahu-two-comp.component.html',
  styleUrls: ['./ahu-two-comp.component.css']
})
export class AhuTwoCompComponent {

}
