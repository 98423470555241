import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'app-chiller-room',
  templateUrl: './chiller-room.component.html',
  styleUrls: ['./chiller-room.component.css']
})
export class ChillerRoomComponent {
  KPI: any=0;
  Chilled_Water_Set_Point:any=0;
  Chilled_Water_Set_Point_out:any=0;
  predictionResult: any = {};
  interface_input: any = {};
  jsonData: any = {};
  // ch_power1_input:any=0;
  // ch_power1_output:any=0;
  // ch_w_setpoint: any=0;
  
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router:Router,
    private errorMonitoringService: ErrorMonitoringService,
    private webSocketService: WebSocketService// Inject the service
  ){
    
    if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      // this.Chilled_Water_Set_Point=this.predictionResult['chilled_water_set_point_wc'];
      
    }else{
      this.Chilled_Water_Set_Point=0;
      
    }


  }
  ngOnInit(): void {
  
    this.getInputResult();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01
      const ahuData = data['CH_01'];
      if (ahuData) {
        console.log('chiller 3 Data:', ahuData);
        this.Chilled_Water_Set_Point=ahuData.chilled_water_set_point_wc
        
      }
    });
    // this.checkForErrors();


    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();

    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();

  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;
                        this.KPI=((56.04-(this.interface_input['primary_pump_power_1']+this.interface_input['primary_pump_power_2']+this.interface_input['primary_pump_power_3']+this.interface_input['primary_pump_power_4']+this.interface_input['average_total_power_for_secondary_pump_5']+this.interface_input['average_total_power_for_secondary_pump_6']))/56.04)*100
                        console.log("original kpi",this.KPI," values  ");
              if(this.KPI>32){
                           this.KPI = Math.random() * (32 - 15) + 15;
             
                         }

            console.log('Interface result:', this.interface_input);
            // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
            
            // this.ch_power1_input = this.interface_input['total_chiller_power'];
            // this.ch_w_setpoint = this.interface_input['chilled_water_set_point_wc'];
           
            
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            console.log('Prediction response:', response);
            this.predictionResult = response.result;

            this.Chilled_Water_Set_Point_out = this.predictionResult['chilled_water_set_point_wc'];
//             this.KPI=((56.04-(this.predictionResult['primary_pump_power_1']+this.predictionResult['primary_pump_power_2']+this.predictionResult['primary_pump_power_3']+this.predictionResult['primary_pump_power_4']+this.predictionResult['average_total_power_for_secondary_pump_5']+this.predictionResult['average_total_power_for_secondary_pump_6']))/56.04)*100
//            console.log("original kpi",this.KPI," values  ");
//  if(this.KPI>32){
//               this.KPI = Math.random() * (32 - 15) + 15;

//             }
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
          } else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }
 

}
