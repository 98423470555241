<div class="ahu-twelve">
   
    <svg id="ahu-twelve" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 58.209 64.941">
        <g id="Component_184_1" data-name="Component 184 – 1" transform="translate(1.5 0.5)">
          <g id="Component_183_1" data-name="Component 183 – 1">
            <path id="Path_11397" data-name="Path 11397" d="M13091.557-20969.488a3.73,3.73,0,0,0-4.146,3.988c.028,4.348.125,49.359.125,49.359s-.024,3.766,4.31,3.7,44.443-.033,44.443-.033a3.465,3.465,0,0,0,2.994-3.553c0-3.189.12-50.1.12-50.1a3.321,3.321,0,0,0-3.666-3.262C13132.074-20969.359,13091.557-20969.488,13091.557-20969.488Z" transform="translate(-13087.41 20975.385)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
            <text id="AHU12" transform="translate(1 40.377)" fill="#377c54" font-size="12" font-family="Mulish, sans-serif" font-weight="700"><tspan x="3.784" y="12">AHU12</tspan></text>
            <text id="Equipment_drying" data-name="Equipment 
      drying" transform="translate(3 12.377)" fill="#9f9f9f" font-size="9" font-family="Mulish, sans-serif"><tspan x="0.307" y="9">Equipment </tspan><tspan x="7.28" y="20">drying</tspan></text>
            <path id="Path_11400" data-name="Path 11400" d="M13.717-2.709V7.535H0s.562,5.316,3.757,8.441,9.024,4.082,9.024,4.082-5.829.809-9.024,4.172S0,33.5,0,33.5l6.547-.162,7.17-.176V46.245" transform="translate(10.464 13.717) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          </g>
        </g>
      </svg>
      
  </div>
  
  
  
  
  
  
  
  
  
  
  
  