import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fahu-two',
  templateUrl: './fahu-two.component.html',
  styleUrls: ['./fahu-two.component.css']
})

export class FahuTwoComponent {
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  // optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  // disableOptimise: boolean = true;
  // disablePMV: boolean = true;
  fahu_two_out: any;
  fahu_two_input: any;

  // input form fields
  CHWVP: number;
  CHWRT: number; //Chilled water return temp
  SAT: number;
  SFRS: number;
  VFD_freq: number;
  AMBIENT_TEMP: number;
  model_no: number = 30;
  
  SAT_out_FAHU_02: number = 0;


  // output form fields
  FAN_POWER_out: number;
  SAT_out: number;
  CHWVP_out: number;
  SFRS_out: number;
  VFD_freq_out: number;
  // hasdata: boolean = true;

  isHidden = false;
  state: string = 'active';
  hideImage() {
    this.isHidden = true;
  }
  showImage() {
    this.isHidden = false;
  }

  // KPI DATA
  KPI: number;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    if (localStorage.getItem('Abs Fan Power kWh') != null) {
      this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;
    } else {
      this.KPI = 0;
    }


    // router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.type = Number(this.activeroute.snapshot.paramMap.get('type'));
    //   }
    // });

    // input field intialisation
    // 'Off Coil Temp': Number(this.AMBIENT_TEMP),
    // 'SA Temp': Number(this.SAT),
    // 'VFD_freq Freq': Number(this.VFD_freq),
    // 'CHWS Temp': Number(this.SFRS),
    // 'CHWR Temp': Number(this.CHWRT),
    // 'Valve Feedback': Number(this.CHWVP),

    if (localStorage.getItem('fahu_two_input')) {
      this.fahu_two_input = localStorage.getItem('fahu_two_input');
      this.fahu_two_input = JSON.parse(this.fahu_two_input);
      this.CHWVP = Number(this.fahu_two_input['CHWVP_FAHU_02']);
      this.SAT = Number(this.fahu_two_input['sat_FAHU_02']);
      this.VFD_freq = Number(this.fahu_two_input['VFD_freq_FAHU_02']);
      this.AMBIENT_TEMP = Number(this.fahu_two_input['Ambient temp']);
      this.CHWRT = Number(this.fahu_two_input['Chilled water return temp']);
      this.SFRS = Number(this.fahu_two_input['SFRS_FAHU_02']);
      this.model_no = 30;
    } else {
      this.SAT = 19;
      this.CHWVP = 78;
      this.VFD_freq = 67;
      this.AMBIENT_TEMP = 35;
      this.CHWRT = 8;
      this.SFRS = 1;
    }
    // output field initialisation
    if (localStorage.getItem('optimise-response')) {
      this.optimise_response = localStorage.getItem('optimise-response');
      this.optimise_response = JSON.parse(this.optimise_response);
      this.fahu_two_out = localStorage.getItem('fahu_two_out');
      this.fahu_two_out = JSON.parse(this.fahu_two_out);
      this.FAN_POWER_out = this.fahu_two_out['fan_power_FAHU_02']
      this.SAT_out = this.fahu_two_out['sat_FAHU_02'];
      this.CHWVP_out = this.optimise_response['CHWVP_FAHU_02'];
      this.SFRS_out = this.fahu_two_out['SFRS_out'];
      this.VFD_freq_out = this.optimise_response['VFD_freq_FAHU_02'];;
    }else{
      if (localStorage.getItem('fahu_two_out')) {
        this.fahu_two_out = localStorage.getItem('fahu_two_out');
        this.fahu_two_out = JSON.parse(this.fahu_two_out);
        console.log(this.fahu_two_out);
        this.FAN_POWER_out = this.fahu_two_out['fan_power_FAHU_02']
        this.SAT_out = this.fahu_two_out['sat_FAHU_02'];
        this.CHWVP_out = this.fahu_two_out['CHWVP_out'];
        this.SFRS_out = this.fahu_two_out['SFRS_out'];
        this.VFD_freq_out = this.fahu_two_out['VFD_freq_out'];
        console.log("CHWVP-ouput", this.CHWVP_out);
      } else {
        this.FAN_POWER_out = 0;
        this.SAT_out = 0;
        this.CHWVP_out = 0;
        this.SFRS_out = 0;
        this.VFD_freq_out = 0;
      }
    }
    



    

    // this.disableOptimise = !this.hasdata;

    // console.log('hasdata', this.hasdata);
    // console.log('disbale', this.disableOptimise);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.CHWRT < 6 || this.CHWRT > 10) {
      this.openSnackBar('Chilled Water Return Temp. should be in range [6,10]');
      return false;
    } else if (this.CHWVP < 0 || this.CHWVP > 100) {
      this.openSnackBar('CHWVP should be in range [0,100]');
      return false;
    }  else if (this.VFD_freq < 30 || this.VFD_freq > 100) {
      this.openSnackBar('VFD_freq should be in range [30,100]');
      return false;
    } else if (this.SAT < 17 || this.SAT > 20) {
      this.openSnackBar('SAT should be in range [17,20]');
      return false;
    } else if (this.SFRS < 0 || this.SFRS > 1) {
      this.openSnackBar('SFRS can only be 0 or 1');
      return false;
    } else if (this.AMBIENT_TEMP < 10 || this.AMBIENT_TEMP > 77) {
      this.openSnackBar('AMBIENT_TEMP should be in range [10,77]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {

    if (this.validate()) {
      this.progress = true;
      this.predict_input = {
        'CHWVP_FAHU_02': Number(this.CHWVP),
        'Chilled water return temp': Number(this.CHWRT),
        'sat_FAHU_02': Number(this.SAT),
        'SFRS_FAHU_02': Number(this.SFRS),
        'Ambient temp': Number(this.AMBIENT_TEMP),
        'VFD_freq_FAHU_02': Number(this.VFD_freq),
        'model_no.': 30,
      };

    console.log('predict input', this.predict_input);

        // for Zone 1 
        


     

      try {
        const response = await this.http
          .post<any>(
            'http://52.140.60.102:5030/sec_30',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();


        this.predict_response = response;
        this.predict_response['CHWVP'] = Number(this.CHWVP);
        this.predict_response['SFRS'] = Number(this.SFRS);
        this.predict_response['VFD_freq'] = Number(this.VFD_freq);
        console.log('predict output', this.predict_response);
        console.log('predict output', this.predict_response);

        this.SAT_out_FAHU_02 = this.predict_response['sat_FAHU_02'];
        // this.SAT_out_FAHU_02 = this.predict_input['sat_FAHU_02'];
        console.log('sat-fahu-02',this.SAT_out_FAHU_02 );
        localStorage.setItem('SAT_out_FAHU_02', JSON.stringify(this.SAT_out_FAHU_02));


        this.openSnackBar('Predicted Succesfully');

        this.FAN_POWER_out = this.predict_response["fan_power_FAHU_02"];
        this.SAT_out = this.predict_response["sat_FAHU_02"];
        this.CHWVP_out = this.predict_response['CHWVP'];
        this.VFD_freq_out = this.predict_response['VFD_freq'];
        this.SFRS_out = this.predict_response['SFRS'];
        // this.CHWVP_out = this.CHWVP;
        // this.SFRS_out = this.SFRS;
        // this.VFD_freq_out = this.VFD_freq;
        // this.VFD_freq_out = this.VFD_freq;
        
        // this.CHWRT_out = this.CHWRT;
        localStorage.setItem('predict_response', JSON.stringify(response));

        this.fahu_two_out = {
          'fan_power_FAHU_02': this.FAN_POWER_out,
          'sat_FAHU_02': this.SAT_out,
          'CHWVP_out': this.CHWVP_out,
          'SFRS_out': this.SFRS_out,
          'VFD_freq_out': this.VFD_freq_out,
        }

        localStorage.setItem('fahu_two_out', JSON.stringify(this.fahu_two_out));
        localStorage.setItem('fahu_two_input', JSON.stringify(this.predict_input));


        



        this.progress = false;
        // this.disablePMV = false;


        // this.disableOptimise = !this.hasdata;

      } catch (error) {
        console.error('Error occurred while making predict API request:', error);
      }
    }
  }

  // async optimiseData() {

  //   this.optimise_input = {
  //     "PMV D7-L2-01": Number(localStorage.getItem('PMV D7-L2-1')),
  //     "PMV D7-L2-02": Number(localStorage.getItem('PMV D7-L2-2')),
  //     "PMV D7-L2-03": Number(localStorage.getItem('PMV D7-L2-3')),
  //     "PMV D7-L2-04": Number(localStorage.getItem('PMV D7-L2-4')),
  //     "PMV D7-L2-05": Number(localStorage.getItem('PMV D7-L2-5')),
  //     "PMV D7-L2-06": Number(localStorage.getItem('PMV D7-L2-6')),
  //     "PMV D7-L2-07": Number(localStorage.getItem('PMV D7-L2-7')),
  //     "PMV D7-L2-08": Number(localStorage.getItem('PMV D7-L2-8')),
  //     "PMV D7-L2-09": Number(localStorage.getItem('PMV D7-L2-9')),
  //     "PMV D7-L2-10": Number(localStorage.getItem('PMV D7-L2-10')),
  //     "PMV D7-L2-11": Number(localStorage.getItem('PMV D7-L2-11')),
  //     "PMV D7-L2-12": Number(localStorage.getItem('PMV D7-L2-12')),
  //     "PMV D7-L2-13": Number(localStorage.getItem('PMV D7-L2-13')),
  //     "PMV D7-L2-14": Number(localStorage.getItem('PMV D7-L2-14')),
  //     "PMV D7-L2-15": Number(localStorage.getItem('PMV D7-L2-15')),
  //     "PMV D7-L2-16": Number(localStorage.getItem('PMV D7-L2-16')),
  //     "PMV D7-L2-17": Number(localStorage.getItem('PMV D7-L2-17')),
  //     "PMV D7-L2-18": Number(localStorage.getItem('PMV D7-L2-18')),
  //     "PMV D7-L2-19": Number(localStorage.getItem('PMV D7-L2-19')),
  //     "PMV D7-L2-20": Number(localStorage.getItem('PMV D7-L2-20')),
  //     "PMV D7-L2-21": Number(localStorage.getItem('PMV D7-L2-21')),
  //     "PMV D7-L2-22": Number(localStorage.getItem('PMV D7-L2-22')),
  //     "PMV D7-L2-23": Number(localStorage.getItem('PMV D7-L2-23')),
  //     "model_no.": 1
  //   }
  //   console.log('data', this.optimise_input);
  //   this.progress = true;
  //   console.log('optimise api call');

  //   // this.emptyCheck(this.predict_input);
  //   try {
  //     const response = await this.http
  //       .post<any>(
  //         'https://bpsliveatchangiairport.bertlabs.com/power',
  //         this.optimise_input,
  //         this.httpOptions
  //       )
  //       .toPromise();

  //     this.optimise_response = response;
  //     console.log('resp', this.optimise_response);

  //     this.FP_out = this.optimise_response['Abs Fan Power kWh'];
  //     this.VFD_freq_out = this.optimise_response['VFD_freq Freq'];
  //     localStorage.setItem('PMV D7-L2-1', this.optimise_response['PMV D7-L2-01']);
  //     localStorage.setItem('PMV D7-L2-2', this.optimise_response['PMV D7-L2-02']);
  //     localStorage.setItem('PMV D7-L2-3', this.optimise_response['PMV D7-L2-03']);
  //     localStorage.setItem('PMV D7-L2-4', this.optimise_response['PMV D7-L2-04']);
  //     localStorage.setItem('PMV D7-L2-5', this.optimise_response['PMV D7-L2-05']);
  //     localStorage.setItem('PMV D7-L2-6', this.optimise_response['PMV D7-L2-06']);
  //     localStorage.setItem('PMV D7-L2-7', this.optimise_response['PMV D7-L2-07']);
  //     localStorage.setItem('PMV D7-L2-8', this.optimise_response['PMV D7-L2-08']);
  //     localStorage.setItem('PMV D7-L2-9', this.optimise_response['PMV D7-L2-09']);
  //     localStorage.setItem('PMV D7-L2-10', this.optimise_response['PMV D7-L2-10']);
  //     localStorage.setItem('PMV D7-L2-11', this.optimise_response['PMV D7-L2-11']);
  //     localStorage.setItem('PMV D7-L2-12', this.optimise_response['PMV D7-L2-12']);
  //     localStorage.setItem('PMV D7-L2-13', this.optimise_response['PMV D7-L2-13']);
  //     localStorage.setItem('PMV D7-L2-14', this.optimise_response['PMV D7-L2-14']);
  //     localStorage.setItem('PMV D7-L2-15', this.optimise_response['PMV D7-L2-15']);
  //     localStorage.setItem('PMV D7-L2-16', this.optimise_response['PMV D7-L2-16']);
  //     localStorage.setItem('PMV D7-L2-17', this.optimise_response['PMV D7-L2-17']);
  //     localStorage.setItem('PMV D7-L2-18', this.optimise_response['PMV D7-L2-18']);
  //     localStorage.setItem('PMV D7-L2-19', this.optimise_response['PMV D7-L2-19']);
  //     localStorage.setItem('PMV D7-L2-20', this.optimise_response['PMV D7-L2-20']);
  //     localStorage.setItem('PMV D7-L2-21', this.optimise_response['PMV D7-L2-21']);
  //     localStorage.setItem('PMV D7-L2-22', this.optimise_response['PMV D7-L2-22']);
  //     localStorage.setItem('PMV D7-L2-23', this.optimise_response['PMV D7-L2-23']);
  //     localStorage.setItem('Abs Fan Power kWh', this.optimise_response['Abs Fan Power kWh']);

  //     this.openSnackBar('Optimised Succesfully');


  //     this.KPI = ((1.81 - Number(localStorage.getItem('Abs Fan Power kWh'))) * 100) / 1.81;


  //     this.progress = false;
  //   } catch (error) {
  //     console.error('Error occurred while making predict API request:', error);
  //   }
  // }


}