import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Inject,Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';

@Component({
  selector: 'Main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.css'],
    // imports: [MatButtonModule, MatDialogModule],

})
export class MainSideBarComponent implements OnInit {
  
  @Output() titleEmmiter: EventEmitter<string> = new EventEmitter<string>();

  block: string = '';
  user_access:string='';
  routing_paths: string[] = [];
  notificationsCount:any=10;
  showNavbar: boolean = false; //change
  expanded: boolean = false;
  constructor(private authService: AuthService,private router: Router, private activatedRoute: ActivatedRoute, private location: Location,public dialog: MatDialog, private http: HttpClient,  private errorMonitoringService: ErrorMonitoringService
  ) {
    this.user_access = this.authService.getUserRole() ?? '';
    
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkNavbarVisibility(activatedRoute);
      }
 
    });
  }
  isAdmin(): boolean {
    return this.user_access === 'admin';
  }
  // isAcess():boolean{
  //   return this.user_access === 'admin' || this.user_access==='monitor';

  // }
  showLogoutDialog() {

    // this.router.navigate(['/dashboard/logout'])
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
  homeTab(drawer:any){
    if(drawer.open())
      drawer.toggle();
    this.selectedItem=null;
    this.showNavbar=false;
  }
  private checkNavbarVisibility(route: ActivatedRoute): void {
    if (route.firstChild) {
      route.firstChild.url.subscribe((segments) => {
        console.log("segments: ", segments);
        this.block = segments[0].path;
        console.log("block name: ", this.block)

        let selectedItem = this.items.find(item => item.route === ("/dashboard/" + this.block));
        console.log("selected item: ", selectedItem)
        if (selectedItem){
          selectedItem.expanded = true;
        }

        for (let item of this.items) {
          if (item.route != ("/dashboard/" + this.block) && (this.block.includes('zone') || this.block.includes('main')  || this.block.includes('mall') || this.block.includes('hyper-lobby') )){
            item.expanded = false;
          }
        }

        if (segments.length > 0 && segments[0].path === 'home') {
          // this.showNavbar = false;
          for (var item of this.items){
            item.expanded = false;
            if (item.hasOwnProperty('subComponents')){
              for (var subComponent of item.subComponents){
                subComponent.expanded = false;
              }
            }
          }
        } else {
          // this.showNavbar = true;
        }
      });
      this.checkNavbarVisibility(route.firstChild);
    }
  }



  getnoalarms(){
    return this.errorMonitoringService.getNumberOfAlarms();
  }

  isToggled = false;

  selectedItem: any;
  items: any[] = [
    
    {
      title: 'AHU Room',
      isExpandable: true,
      expanded: false,
      route:'/dashboard/home',
      subComponents:[
        {
          title: 'LMB-AHU-01-1000 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-01',
        },
        
        {
          title: 'LMB-AHU-02-1425 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-02',
        },
        
        {
          title: 'LMB-AHU-03-4675 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-03',
        },
        {
          title: 'LMB-AHU-04-4000 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-04',
        },
        {
          title: 'LMB-AHU-05-3675 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-05',
        },
        {
          title: 'LMB-AHU-06-4000 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-06',
        },
        
        {
          title: 'LMB-AHU-07-5800 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-07',
        },
        {
          title: 'LMB-AHU-08-3675 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-08',
        },
        // {
        //   title: 'AHU-09',
        //   isExpandable: true,
        //   expanded: false,
        //   route:'/dashboard/AHU-09',
       
        // },
        {
          title: 'LMB-AHU-10-4000 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-10',
        },
        {
          title: 'LMB-AHU-11-8300 CFM',
          isExpandable: false,
          expanded: false,
          route:'/dashboard/AHU-11',
        },
           
    {
      title: 'LMB-AHU-12 & EU-12-600 CFM',
      isExpandable: false,
      expanded: false,
      route:'/dashboard/AHU-12',
    },
      ],
    },
    {
      title: 'Air Cooled Chiller',
      isExpandable: false,
      expanded: false,
      route:'/dashboard/chiller4',
    
    },



    {
      title: 'Chiller(WC) Room',
      isExpandable: true,
      expanded: false,
      route:'/dashboard/chiller-room',
      subComponents:[
        {title:'Chiller1',route:'chiller1',disabled:false,isExpandable: false,expanded:false},
        {title:'Chiller2',route:'chiller2',disabled:false,isExpandable: false,expanded:false},
        {title:'Chiller3',route:'chiller3',disabled:false,isExpandable: false,expanded:false},
      ],
    },
    {
      title: 'CondenserPump',
      isExpandable: false,
      expanded: false,
      route:'/dashboard/condenser_pumps',
    },
    {
      title: 'PrimaryPump',
      isExpandable: false,
      expanded: false,
      route:'/dashboard/primary_pumps',
    },
    {
      title: 'SecondaryPump',
      isExpandable: false,
      expanded: false,
      route:'/dashboard/secondary_pumps',
    },
    {
      title: 'Cooling Towers',
      isExpandable: true,
      expanded: false,
      route:'/dashboard/cooling-towers',
      subComponents:[
        {title:'Cooling Tower1',route:'cooling-tower1',disabled:false,isExpandable: false,expanded:false},
        {title:'Cooling Tower2',route:'cooling-tower2',disabled:false,isExpandable: false,expanded:false},
      ],
    },
    
    

    // {
    //   title: 'Heat Exchanger Pumps',
    //   isExpandable: true,
    //   expanded: false,
    //   route:'/dashboard/heat-exchanger-1',
    //   subComponents: [   
        
    //     { title: 'Heat Exchanger 2',route:'/dashboard/heat-exchanger-2',disabled:false, isExpandable: false, expanded:  false},
    //     { title: 'Heat Exchanger 3',route:'/dashboard/heat-exchanger-3',disabled:false, isExpandable: false, expanded:  false},
    //   ],
    // },
    
    
    
  ];

  toggleExpanded(item: any) {
    // this.showNavbar=true;
     item.expanded = !item.expanded;
    
  }

  isSelectedDescription(item: any, description: any): boolean {
    return this.selectedItem === item && item.expanded;
  }

  toggleButton(event: any): void {

    this.isToggled = event.source.checked;
  }

  goback() {
    this.location.back();
    this.errorMonitoringService.clearErrorLog();

  }

  handleItemClick(subComponent: any): void {
    console.log('Clicked on:', subComponent);
    
    if ( this.routing_paths.includes(subComponent.route) ){
      // Navigate to the route
      this.router.navigate([subComponent.route]);
    }
  }
  
  updateDisabledStatus() {
    for (const item of this.items) {
      for (const subComponent of item.subComponents) {
        // console.log(subComponent.route);
        // console.log(this.routing_paths.includes(subComponent.route));
        subComponent.disabled = !this.routing_paths.includes(subComponent.route);
      }
    }
  }

  async loadRoutingPaths() {
    try {
      const data = await this.http.get('assets/mall-ahus-fcus.json', { responseType: 'text' }).toPromise();
      const mallAHUsFCUsData = JSON.parse(String(data));

      for (const key in mallAHUsFCUsData) {
        if (mallAHUsFCUsData.hasOwnProperty(key)) {
          this.routing_paths.push('/dashboard/' + key);
        }
      }
      this.routing_paths.push('/dashboard/FAHU-01');
      this.routing_paths.push('/dashboard/FAHU-02');
      this.routing_paths.push('/dashboard/heat-exchanger');
      this.routing_paths.push('/dashboard/cooling-tower');
      this.routing_paths.push('/dashboard/primary-pump');
      this.routing_paths.push('/dashboard/secondary-pump');
      this.routing_paths.push('/dashboard/chiller');
      this.routing_paths.push('/dashboard/condenser-pump');
      this.routing_paths.push('/dashboard/f-chiller-plant');
      
      console.log(this.routing_paths);
    } catch (error) {
      console.error('Error loading routing paths:', error);
    }
  }

  async ngOnInit() {

    await this.loadRoutingPaths();
    this.updateDisabledStatus();
    // this.user_access = this.authService.getUserRole();
    // console.log("uwqdwhdewd",this.user_access)
  }

  handleclick(item:any): boolean{
    this.toggleExpanded(item);
    this.selectedItem=item;
    if(item.subComponents && item.subComponents ){
      return false;
    }
    else{
      return true;
    }
  }
  
}
