<div class="main-container">
    <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>AHU</th>
              <th>Catering Rooms</th>
              <th>RAT</th>
              <th>RT (°C)</th>
              <th>RARH</th>
              <th>RH (%)</th>
              <th>RDP (Pa)</th>
              
              
              <th>ACPH</th>
            </tr>
          </thead>
          <tbody>
            <!-- First AHU -->
            <tr>
                <td>
                    LMB-AHU-01-1000 CFM
                </td>
                <td>
                    Liquid dispensing staging
                </td>
                <td>
                    {{rat_ahu1}}
                </td>
                <td>
                    {{rt_ahu1}}
                </td>
                <td>
                    {{rarh_ahu1}}
                </td>
                <td>
                    {{rh_ahu1}}
                </td>
                <td>
                    {{rdp_ahu1}}
                </td>
             
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 2nd AHU -->
            <tr>
                <td>
                    LMB-AHU-02-1000 CFM
                </td>
                <td>
                    Day store
                </td>
                <td>
                    {{rat_ahu2}}
                </td>
                <td>
                    {{rt_ahu2}}
                </td>
                <td>
                    {{rarh_ahu2}}
                </td>
                <td>
                    {{rh_ahu2}}
                </td>
                <td>
                    {{rdp_ahu2}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <tr>
                <td>
                    
                </td>
                <td>
                    sfg quranmtine
                </td>
                <td>
                    {{rat_ahu2}}
                </td>
                <td>
                    {{rt_ahu2}}
                </td>
                <td>
                    {{rarh_ahu2}}
                </td>
                <td>
                    {{rh_ahu2}}
                </td>
                <td>
                    {{rdp_ahu2}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 3rd AHU -->
            <tr>
                <td>
                    LMB-AHU-03-1000 CFM
                </td>
                <td>
                    Supposistories
                </td>
                <td>
                    {{rat_ahu3}}
                </td>
                <td>
                    {{rt_ahu3}}
                </td>
                <td>
                    {{rarh_ahu3}}
                </td>
                <td>
                    {{rh_ahu3}}
                </td>
                <td>
                    {{rdp_ahu3}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 4th AHU -->
            <tr>
                <td>
                    LMB-AHU-04-1000 CFM
                </td>
                <td>
                    Liquid filing
                </td>
                <td>
                    {{rat_ahu4}}
                </td>
                <td>
                    {{rt_ahu4}}
                </td>
                <td>
                    {{rarh_ahu4}}
                </td>
                <td>
                    {{rh_ahu4}}
                </td>
                <td>
                    {{rdp_ahu4}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 5th AHU -->
            <tr>
                <td>
                    LMB-AHU-05-1000 CFM
                </td>
                <td>
                    Ointment preparation
                </td>
                <td>
                    {{rat_ahu5}}
                </td>
                <td>
                    {{rt_ahu5}}
                </td>
                <td>
                    {{rarh_ahu5}}
                </td>
                <td>
                    {{rh_ahu5}}
                </td>
                <td>
                    {{rdp_ahu5}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 6th AHU -->
            <tr>
                <td>
                    LMB-AHU-06-1000 CFM
                </td>
                <td>
                    Ointment fill and seal
                </td>
                <td>
                    {{rat_ahu6}}
                </td>
                <td>
                    {{rt_ahu6}}
                </td>
                <td>
                    {{rarh_ahu6}}
                </td>
                <td>
                    {{rh_ahu6}}
                </td>
                <td>
                    {{rdp_ahu6}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 7th AHU -->
            <tr>
                <td>
                    LMB-AHU-07-1000 CFM
                </td>
                <td>
                    CHANGE ROOM GENTS
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    CHANGE ROOM LADIES
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    PROD. OFFICE
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    IPQA
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    ENTRANCE CORRIDOR
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    GENTS AIR LOCK
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    LADIES HAND AND FOOT WASH
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    GENTS HAND AND FOOT WASH
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    LINEN ROOM
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    LADIES HAND AND FOOT WASH
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    GENTS HAND AND FOOT WASH
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    LINEN ROOM
                </td>
                <td>
                    {{rat_ahu7}}
                </td>
                <td>
                    {{rt_ahu7}}
                </td>
                <td>
                    {{rarh_ahu7}}
                </td>
                <td>
                    {{rh_ahu7}}
                </td>
                <td>
                    {{rdp_ahu7}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 8th AHU -->
            <tr>
                <td>
                    LMB-AHU-08-1000 CFM
                </td>
                <td>
                    Liquid preparation
                </td>
                <td>
                    {{rat_ahu8}}
                </td>
                <td>
                    {{rt_ahu8}}
                </td>
                <td>
                    {{rarh_ahu8}}
                </td>
                <td>
                    {{rh_ahu8}}
                </td>
                <td>
                    {{rdp_ahu8}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 10th AHU -->
            <tr>
                <td>
                    LMB-AHU-10-1000 CFM
                </td>
                <td>
                    Sachet filling
                </td>
                <td>
                    {{rat_ahu10}}
                </td>
                <td>
                    {{rt_ahu10}}
                </td>
                <td>
                    {{rarh_ahu10}}
                </td>
                <td>
                    {{rh_ahu10}}
                </td>
                <td>
                    {{rdp_ahu10}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 11th AHU -->
            <tr>
                <td>
                    LMB-AHU-11-1000 CFM
                </td>
                <td>
                    HOLDING ROOM
                </td>
                <td>
                    {{rat_ahu11}}
                </td>
                <td>
                    {{rt_ahu11}}
                </td>
                <td>
                    {{rarh_ahu11}}
                </td>
                <td>
                    {{rh_ahu11}}
                </td>
                <td>
                    {{rdp_ahu11}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
                    
                </td>
                <td>
                    ACESSORIES
                </td>
                <td>
                    {{rat_ahu11}}
                </td>
                <td>
                    {{rt_ahu11}}
                </td>
                <td>
                    {{rarh_ahu11}}
                </td>
                <td>
                    {{rh_ahu11}}
                </td>
                <td>
                    {{rdp_ahu11}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
    
                </td>
                <td>
                    MATERIAL CORRIDOR
                </td>
                <td>
                    {{rat_ahu11}}
                </td>
                <td>
                    {{rt_ahu11}}
                </td>
                <td>
                    {{rarh_ahu11}}
                </td>
                <td>
                    {{rh_ahu11}}
                </td>
                <td>
                    {{rdp_ahu11}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
                    
                </td>
                <td>
                    MaterialAIR LOCK
                </td>
                <td>
                    {{rat_ahu11}}
                </td>
                <td>
                    {{rt_ahu11}}
                </td>
                <td>
                    {{rarh_ahu11}}
                </td>
                <td>
                    {{rh_ahu11}}
                </td>
                <td>
                    {{rdp_ahu11}}
                </td>
                <td>
                    25
                </td>  
            </tr>
            <tr>
                <td>
                    
                </td>
                <td>
                    MGF CORRIDOR
                </td>
                <td>
                    {{rat_ahu11}}
                </td>
                <td>
                    {{rt_ahu11}}
                </td>
                <td>
                    {{rarh_ahu11}}
                </td>
                <td>
                    {{rh_ahu11}}
                </td>
                <td>
                    {{rdp_ahu11}}
                </td>
                <td>
                    25
                </td>  
            </tr>
    
            <!-- 12th AHU -->
             <tr>
                <td>
                    LMB-AHU-12-4000 CFM              
                </td>
                <td>
                    EQUIPMENT DRYING
                </td>
                <td>
                    {{rat_ahu12}}
                </td>
                <td>
                    {{rt_ahu12}}
                </td>
                <td>
                    {{rarh_ahu12}}
                </td>
                <td>
                    {{rh_ahu12}}
                </td>
                <td>
                    {{rdp_ahu12}}
                </td>
                <td>
                    25
                </td>  
            </tr>
          </tbody>
        </table>
      </div>
</div>
  

  <!-- // AHU1-Liquid dispensing staging
// AHU2-Day store, sfg quranmtine
// AHU3-Supposistories
// AHU4-Liquid filing
// AHU5- Ointment preparation
// AHU6- Ointment fill & seal
// AHU7- CHANGE ROOM GENTS, CHANGE ROOM LADIES, PROD. OFFICE, IPQA, ENTRANCE CORRIDOR, GENTS AIR LOCK, LADIES AIR LOCK, LADIES HAND AND FOOT WASH, GENTS HAND AND FOOT WASH, LINEN ROOM, LADIES HAND AND FOOT WASH, GENTS HAND AND FOOT WASH, LINEN ROOM
// AHU8- Liquid preparation
// AHU10- Sachet filling
// AHU11- HOLDING ROOM, ACESSORIES, MATERIAL CORRIDOR, MaterialAIR LOCK, MGF CORRIDOR
// AHU12- EQUIPMENT DRYING -->