<div class="ahu-ten">
    
    
      <svg id="ahu-ten" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 160.37 96.014">
        <g id="Component_186_1" data-name="Component 186 – 1" transform="translate(1.503 1.5)">
          <path id="Path_11418" data-name="Path 11418" d="M12439.38-21105.1c0,4.387-.194,78.758-.194,78.758s-.238,5.119,6.384,4.926,147.331-.437,147.331-.437a4.239,4.239,0,0,0,3.651-4.107c.048-3.609-.151-80.211-.151-80.211a3.844,3.844,0,0,0-4.134-3.887l-150.363.184S12439.377-21109.49,12439.38-21105.1Z" transform="translate(-12439.185 21110.063)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
          <text id="Container_Washing" data-name="Container Washing" transform="translate(20.688 8.787)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="2.716" y="12">Container Washing</tspan></text>
          <text id="AHU10" transform="translate(44.688 36.787)" fill="#377c54" font-size="16" font-family="Mulish, sans-serif" font-weight="700"><tspan x="7.712" y="16">AHU10</tspan></text>
          <path id="Path_11422" data-name="Path 11422" d="M-2399.645-21036.285v-12.744h13.292s-.544-5.152-3.641-8.18-8.744-3.959-8.744-3.959,5.648-.783,8.744-4.041,3.641-8.99,3.641-8.99l-13.292.328v-14.4" transform="translate(21137.598 -2305.631) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
        </g>
      </svg>
      
  </div>
  
  
  
  
  
  
  
  
  
  
  
  