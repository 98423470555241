import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-lobby',
  templateUrl: './main-lobby.component.html',
  styleUrls: ['./main-lobby.component.css']
})
export class MainLobbyComponent {
  type!: number;

  KPI:number=0;
RAT_out: any;
RAT_out_AHU_14: any;
RAT_out_AHU_15: any;
RAT_out_AHU_16: any;
RAT_out_AHU_19: any;
RAT_out_AHU_20: any;
Zone_temp:number=0;
power_ahu_14:number=0;
power_ahu_15:number=0;
power_ahu_16:number=0;
power_ahu_19:number=0;
power_ahu_20:number=0;
BASELINE_MAIN_LOBBY:number=0;
POWER_MAIN_LOBBY:number=0;
isFanRunning:boolean=false


constructor(private router: Router) {

  this.isFanRunning=true





 }
}
