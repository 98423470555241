import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-condenser-pumps',
  templateUrl: './condenser-pumps.component.html',
  styleUrls: ['./condenser-pumps.component.css']
})
export class CondenserPumpsComponent {

  errorLog: { message: string, timestamp: Date }[] = [];
  snackbars: { [key: string]: MatSnackBarRef<any> } = {};

  vfd_for_condenser_pump_1_in: any=0;
  vfd_for_condenser_pump_2_in: any=0;
  vfd_for_condenser_pump_3_in: any=0;
  vfd_for_condenser_pump_4_in: any=0;
  vfd_for_condenser_pump_1_out: any=0;
  vfd_for_condenser_pump_2_out: any=0;
  vfd_for_condenser_pump_3_out: any=0;
  vfd_for_condenser_pump_4_out: any=0;

  power_1_in: any=0;
  power_2_in: any=0;
  power_3_in: any=0;
  power_4_in: any=0;
  power_1_out: any=0;
  power_2_out: any=0;
  power_3_out: any=0;
  power_4_out: any=0;

  predictionResult: any = {};
  interface_input: any = {};
  jsonData: any = {};

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router:Router,
    private errorMonitoringService: ErrorMonitoringService// Inject the service
  ){
    if(localStorage.getItem('interface_input')){
      this.interface_input = localStorage.getItem('interface_input');
      this.interface_input = JSON.parse(this.interface_input);
      this.vfd_for_condenser_pump_1_in=this.interface_input['vfd_for_condenser_pump_1'];
      this.vfd_for_condenser_pump_2_in=this.interface_input['vfd_for_condenser_pump_2'];
      this.vfd_for_condenser_pump_3_in=this.interface_input['vfd_for_condenser_pump_3'];
      this.vfd_for_condenser_pump_4_in=this.interface_input['vfd_for_condenser_pump_4'];
    }else{
      this.vfd_for_condenser_pump_1_in=0;
      this.vfd_for_condenser_pump_2_in=0;
      this.vfd_for_condenser_pump_3_in=0;
      this.vfd_for_condenser_pump_4_in=0;
    }
    if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      this.vfd_for_condenser_pump_1_out=this.predictionResult['vfd_for_condenser_pump_1'];
      this.vfd_for_condenser_pump_2_out=this.predictionResult['vfd_for_condenser_pump_2'];
      this.vfd_for_condenser_pump_3_out=this.predictionResult['vfd_for_condenser_pump_3'];
      this.vfd_for_condenser_pump_4_out=this.predictionResult['vfd_for_condenser_pump_4'];
    }else{
      this.vfd_for_condenser_pump_1_out=0;
      this.vfd_for_condenser_pump_2_out=0;
      this.vfd_for_condenser_pump_3_out=0;
      this.vfd_for_condenser_pump_4_out=0;
    }


  }



  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    // this.checkForErrors();

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 240000); // 240000 milliseconds = 4 minutes
    // this.getInputResult();
  }
  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Input response:', response);

            // Check if the response is a string and parse it if necessary
                        this.interface_input = response.environment;


            console.log('Interface result:', this.interface_input);
            
            this.vfd_for_condenser_pump_1_in = this.interface_input['vfd_for_condenser_pump_1'];
            this.vfd_for_condenser_pump_2_in = this.interface_input['vfd_for_condenser_pump_2'];
            this.vfd_for_condenser_pump_3_in = this.interface_input['vfd_for_condenser_pump_3'];
            this.vfd_for_condenser_pump_4_in = this.interface_input['vfd_for_condenser_pump_4'];
            
            this.jsonData['interface_input'] = this.interface_input;
            console.log(this.jsonData);
            localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
            console.log('interface_output', this.interface_input);
          } catch (error) {
            console.error('Error parsing Input result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching Input result:', error);
        }
      );
  }
  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            // Log the response to check its content
            console.log('Prediction response:', response);
  
            // Check if the response is a string and parse it if necessary
                      this.predictionResult = response.result;

            this.vfd_for_condenser_pump_1_out = this.predictionResult['vfd_for_condenser_pump_1'];
            this.vfd_for_condenser_pump_2_out = this.predictionResult['vfd_for_condenser_pump_2'];
            this.vfd_for_condenser_pump_3_out = this.predictionResult['vfd_for_condenser_pump_3'];
            this.vfd_for_condenser_pump_4_out = this.predictionResult['vfd_for_condenser_pump_4'];
            console.log('Prediction result:', this.predictionResult);
            this.jsonData['predictionResult'] = this.predictionResult;
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('predict_output', this.predictionResult);
          } catch (error) {
            console.error('Error parsing prediction result:', error);
          }
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error fetching prediction result:', error);
          if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            console.error('Client-side error:', error.error.message);
          } else {
            // Backend returned an unsuccessful response code
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
  }






}
