import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { WebSocketService } from 'src/app/services/web-socket.service';
@Component({
  selector: 'app-chiller3',
  templateUrl: './chiller3.component.html',
  styleUrls: ['./chiller3.component.css']
})
export class Chiller3Component {
  
      Chw_in_3: any=0;
      Chw_out_3: any=0;
      Condw_in_3: any=0;
      Condw_out_3: any=0;
      alarmlog: any[]=[];
      ch_power1_input:any=0;
      ch_power1_output:any=0;
      ch_w_setpoint: any=0;
      predictionResult: any={};
      ch_w_setpoint_out:any=0;
      mass_avg_temp:any=0;
      KPI: any=0;
      jsonData: any = {};
      interface_input: any = {};
      errorLog: { message: string, timestamp: Date }[] = [];
      snackbars: { [key: string]: MatSnackBarRef<any> } = {};
      constructor(
        private activeroute:ActivatedRoute,
        private router: Router,
      private http: HttpClient,
      private snackBar: MatSnackBar,
      private errorMonitoringService: ErrorMonitoringService,    
      private webSocketService: WebSocketService
      // Inject the service



      ){
        
      }


      ngOnInit(): void {
        this.getInputResult();
        this.viewalarmlogs();
        this.getPredictionResult();
        this.webSocketService.ahuData$.subscribe(data => {
          // Get data for AHU_01
          const ahuData = data['CH_01'];
          if (ahuData) {
            console.log('chiller 3 Data:', ahuData);
            this.ch_w_setpoint=ahuData.chilled_water_set_point_wc
            
          }
        });
        // this.checkForErrors();
    
        setInterval(() => {
          this.getInputResult();
          this.getPredictionResult();

        }, 240000); // 240000 milliseconds = 4 minutes
        // this.getInputResult();
      }
      async viewalarmlogs(){
        this.errorMonitoringService.alarms$.subscribe(alarms => {
          this.alarmlog = alarms['CHILLER_03'];
          console.log("from servbicve",this.alarmlog)
          if(this.alarmlog.length>0 && this.router.url=='/dashboard/chiller3' ){
            this.openSnackBar();
          }
        });
        
      }
    
      async getInputResult() {
        this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
          .subscribe(
            (response: any) => {
              try {
                // Log the response to check its content
                console.log('Input response:', response);
    
                // Check if the response is a string and parse it if necessary
                this.interface_input = response.environment;

    
                console.log('Interface result:', this.interface_input);
                // this.Zone_Temp_C4_L2_02_out = this.interface_input['Zone_Temp_C4-L2-02'];
                
            //     this.Chw_in_3 = this.interface_input['chw_valve_opening_01'];
            //     this.Chw_out_3 = this.interface_input['supply_dpt_01'];
            //     this.Condw_in_3 = this.interface_input['RH_01'];
            //     this.Condw_out_3 = this.interface_input['heating_coil_01'];
            //     this.ch_power1_input = this.interface_input['total_chiller_power'];
            // this.ch_w_setpoint = this.interface_input['chilled_water_set_point_wc'];
           
            this.mass_avg_temp=this.interface_input['mass_averaged_temperature_outlet_for_part_evap_waterside_3']

                
                this.jsonData['interface_input'] = this.interface_input;
                console.log(this.jsonData);
                localStorage.setItem('interface_input', JSON.stringify(this.interface_input));
              } catch (error) {
                console.error('Error parsing Input result:', error);
              }
            },
             (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
              console.error('Error fetching Input result:', error);
            }
          );
      }
      async getPredictionResult() {
        this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
          .subscribe(
            (response: any) => {
              try {
                console.log('Prediction response:', response);
                this.predictionResult = response.result;
    
                this.ch_w_setpoint_out = this.predictionResult['chilled_water_set_point_wc'];

                console.log('Prediction result:', this.predictionResult);
                this.jsonData['predictionResult'] = this.predictionResult;
                console.log(this.jsonData);
                localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
                console.log('predict_output', this.predictionResult);
              } catch (error) {
                console.error('Error parsing prediction result:', error);
              }
            },
             (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
              console.error('Error fetching prediction result:', error);
              if (error.error instanceof ErrorEvent) {
                console.error('Client-side error:', error.error.message);
              } else {
                console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
              }
            }
          );
      }
    
    
    
    
      isError(key: string): boolean {
        switch (key) {
          case 'Chw_in_3':
            return this.Chw_in_3 < 10 || this.Chw_in_3 > 20;
          case 'Chw_out_3':
            return this.Chw_out_3 < 8 || this.Chw_out_3 > 30;
          case 'Condw_in_3':
            return this.Condw_in_3 < 11 || this.Condw_in_3 > 35;
          case 'Condw_out_3':
            return this.Condw_out_3 < 20 || this.Condw_out_3> 40;
          default:
            return false;
        }
      }
    
      openSnackBar() {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: {message:this.alarmlog},
          horizontalPosition: 'start',
          duration: undefined,
        });
      }

  
  
    
}
