import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent {
  userForm: FormGroup;
  // email: string = '';
  // firstname: string = '';
  // lastname: string = '';
  // jobrole: string = '';
  // mobile: string = '';
  // username: string = '';
  // userpassword: string = '';

  constructor(private fb: FormBuilder, private router: Router) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobRole: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      username: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&].{8,}$'),
       
      ]]
    });
  }

  showvalid():boolean{

    if(this.userForm.get('password')?.dirty){
      if(this.userForm.get('password')?.value==''){
        return false;
      }
      else{
        return true;
      }
    }
    return false;
  }

  hasMinLengthError(): boolean {
    const control = this.userForm.get('password');
    // Check if the control is dirty and has an error, and if the password is not empty
    console.log(control);
    console.log(control?.dirty ,"hehe",control?.hasError('minlength'),"kj",control?.value !== "")
    console.log(!!(control?.dirty && control?.hasError('minlength') && control?.value !== ""))
    return !!(control?.dirty && control?.hasError('minlength') );
  }
  
  hasUppercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[A-Z])/.test(control?.value ?? ''));
  }
  
  hasLowercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[a-z])/.test(control?.value ?? ''));
  }
  
  hasNumberError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*\d)/.test(control?.value ?? ''));
  }
  
  hasSpecialCharError(): boolean {
    const control = this.userForm.get('password');
    return !!(control?.dirty && control?.hasError('pattern') && control?.value !== '' && !/(?=.*[@$#^!%*?&])/.test(control?.value ?? ''));
  }
  
  

  onSubmit() {
    if (this.userForm.valid) {
      const data = this.userForm.value;  // Get form values

      console.log('User data:', this.userForm.value);
      console.log(data);
      alert('User added successfully!');
      this.router.navigate(['/view-users']);
    }
  }

  onSaveAndAddAnother() {
    if (this.userForm.valid) {
      console.log('User data:', this.userForm.value);
      alert('User added successfully! Add another user.');
      this.userForm.reset();
    }
  }

  onCancel() {
    this.router.navigate(['dashboard/viewusers']);
  }

  get password() {
    return this.userForm.get('password');
  }
}
