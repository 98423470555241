<div class="main-container">
    <div class="sidebar">
      <div class="menu-items">
        <div class="menu-item" routerLink="../ahucommand">AHU Command</div>
        <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
        <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Sequencing</div>
        <div class="menu-item" routerLink="../ahuscedule-command">AHU Schedule Command</div>
      </div>
    </div>

    <div class="content">
      <div class="command-section">
        <h2>CHILLER PLANT SEQUENCING</h2>
        <div class="command-input-container">
          <input [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
          <button class="show-command-btn" (click)="showCommand()">Show Command</button>
        </div>

        <div *ngIf="showDetails">
          <label>Plant Components</label>
          <select [(ngModel)]="selectedComponent" (change)="onComponentChange()">
            <option value="Chillers">Chillers</option>
            <option value="Cooling Towers">Cooling Towers</option>
            <option value="Condenser Pumps">Condenser Pumps</option>
            <option value="Primary Pumps">Primary Pumps</option>
            <option value="Secondary Pumps">Secondary Pumps</option>
          </select>

          <div *ngIf="selectedComponent=='Chillers'">
            <div *ngFor="let component of components" class="main-radio">

              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input type="radio" name="{{ component.name }}_status"  value="ON" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)"  /> ON
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}_status" value="OFF" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)" /> OFF
                </label>



                <label>
                  <button style="position: relative; top: -1px; left: 42px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'on_off',component.name,'OnOff')">Send</button>
                </label>

                <span [style.color]="getStatusColor(equipstatus[component.name + '_OnOff'])" style="position: relative; top: 1px; left: 42px;">
                  {{ equipstatus[component.name + '_OnOff'] }}
                </span>


              </div>



              <div class="radio-group" *ngIf="component.hasValves">
                <label>{{ component.name }} Evap Valve</label>
                <label>
                  <input type="radio" name="{{ component.name }}_evap_valve" value="OPEN" [ngModel]="getComponentState(component.name, 'EvapValve')"
                  (ngModelChange)="setComponentState(component.name, 'EvapValve', $event)"  /> OPEN
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}_evap_valve" value="CLOSE" [ngModel]="getComponentState(component.name, 'EvapValve')"
                  (ngModelChange)="setComponentState(component.name, 'EvapValve', $event)"  /> CLOSE
                </label>

                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'evap_val_on_off',component.name,'EvapValve')">Send</button>
                </label>

                <span [style.color]="getStatusColor(equipstatus[component.name + '_EvapValve'])">
                  {{ equipstatus[component.name + '_EvapValve'] }}
                </span>
              </div>

              <div class="radio-group" *ngIf="component.hasCondValves">
                <label>{{ component.name }} Cond Valve</label>
                <label>
                  <input type="radio" name="{{ component.name }}_cond_valve" value="OPEN" [ngModel]="getComponentState(component.name, 'CondValve')"
                  (ngModelChange)="setComponentState(component.name, 'CondValve', $event)" /> OPEN
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}_cond_valve" value="CLOSE" [ngModel]="getComponentState(component.name, 'CondValve')"
                  (ngModelChange)="setComponentState(component.name, 'CondValve', $event)" /> CLOSE
                </label>
                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'cond_val_on_off',component.name,'CondValve')">Send</button>
                </label>
                <span [style.color]="getStatusColor(equipstatus[component.name + '_CondValve'])">
                  {{ equipstatus[component.name + '_CondValve'] }}
                </span>
              </div>

              <div class="radio-group" >
                <label>{{ component.name }} CW Set point</label>
                <label>  <input class="radio-group-input" type="number" [ngModel]="getComponentState(component.name, 'cwsp')"
                  (ngModelChange)="setComponentState(component.name, 'cwsp', $event)" >
                </label>
                <label>
                  <button style="position: relative; top: -1px; left: 85px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'cwsp',component.name,'cwsp')">Send</button>
                </label>
              </div>
            </div>

          </div>

           <div *ngIf="selectedComponent=='Cooling Towers'" >
            <div *ngFor="let component of components" class="main-radio">


              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input type="radio" name="{{ component.name }}FanOnOff" value="ON" [ngModel]="getComponentState(component.name, 'FanOnOff')"
                  (ngModelChange)="setComponentState(component.name, 'FanOnOff', $event)" /> ON
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}FanOnOff" value="OFF" [ngModel]="getComponentState(component.name, 'FanOnOff')"
                  (ngModelChange)="setComponentState(component.name, 'FanOnOff', $event)" /> OFF
                </label>
                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'fan_on_off',component.name,'FanOnOff')">Send</button>
                </label>

                <span [style.color]="getStatusColor(equipstatus[component.name + '_FanOnOff'])">
                  {{ equipstatus[component.name + '_FanOnOff'] }}
                </span>




              </div>

              <div class="radio-group" >
                <label>{{ component.name }} Fan Speed</label>
                <label>  <input class="radio-group-input" type="number" [ngModel]="getComponentState(component.name, 'Fanvfd')"
                  (ngModelChange)="setComponentState(component.name, 'Fanvfd', $event)">
                </label>
                <label>
                  <button style="position: relative; top: -1px; left: 73px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'fan_vfd',component.name,'Fanvfd')">Send</button>
                </label>

                <span style="position: relative; top: -1px; left: 73px;" [style.color]="getStatusColor(equipstatus[component.name + '_Fanvfd'])">
                  {{ equipstatus[component.name + '_Fanvfd'] }}
                </span>
              </div>
            </div>


          </div>

          <div  *ngIf="selectedComponent=='Secondary Pumps'">
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="ON" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)" /> ON
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="OFF" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)" /> OFF
                </label>
                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'on_off',component.name,'OnOff')">Send</button>
                </label>
                <span [style.color]="getStatusColor(equipstatus[component.name + '_OnOff'])">
                  {{ equipstatus[component.name + '_OnOff'] }}
                </span>

              </div>

              <div class="radio-group" >
                <label>{{ component.name }} Fan Speed</label>
                <label>  <input class="radio-group-input" type="number" [ngModel]="getComponentState(component.name, 'vfd')"
                  (ngModelChange)="setComponentState(component.name, 'vfd', $event)" >
                </label>
                <label>
                  <button style="position: relative; top: 0px; left: 73px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'vfd',component.name,'vfd')">Send</button>
                </label>
                <span style="position: relative; top: 0px; left: 73px;"  [style.color]="getStatusColor(equipstatus[component.name + '_vfd'])">
                  {{ equipstatus[component.name + '_vfd'] }}
                </span>
              </div>
            </div>


          </div>

          <div *ngIf="selectedComponent=='Primary Pumps'" >
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="ON" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)"/> ON
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="OFF" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)"/> OFF
                </label>
                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'on_off',component.name,'OnOff')">Send</button>
                </label>

                <span [style.color]="getStatusColor(equipstatus[component.name + '_OnOff'])">
                  {{ equipstatus[component.name + '_OnOff'] }}
                </span>

              </div>

              <div class="radio-group" >
                <label>{{ component.name }} Fan Speed</label>
                <label>  <input class="radio-group-input" type="number" [ngModel]="getComponentState(component.name, 'vfd')"
                  (ngModelChange)="setComponentState(component.name, 'vfd', $event)" >
                </label>
                <label>
                  <button style="position: relative; top: 0px; left: 73px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'vfd',component.name,'vfd')">Send</button>
                </label>
                <span style="position: relative; top: 0px; left: 73px;" [style.color]="getStatusColor(equipstatus[component.name + '_vfd'])">
                  {{ equipstatus[component.name + '_vfd'] }}
                </span>
              </div>
          </div>
          </div>

          <div *ngIf="selectedComponent=='Condenser Pumps'" >
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="ON" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)" /> ON
                </label>
                <label>
                  <input type="radio" name="{{ component.name }}onoff" value="OFF" [ngModel]="getComponentState(component.name, 'OnOff')"
                  (ngModelChange)="setComponentState(component.name, 'OnOff', $event)" /> OFF
                </label>
                <label>
                  <button class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'on_off',component.name,'OnOff')">Send</button>
                </label>
                <span [style.color]="getStatusColor(equipstatus[component.name + '_OnOff'])">
                  {{ equipstatus[component.name + '_OnOff'] }}
                </span>
              </div>

              <div class="radio-group" >
                <label>{{ component.name }} Fan Speed</label>
                <label>  <input class="radio-group-input" type="number" [ngModel]="getComponentState(component.name, 'vfd')"
                  (ngModelChange)="setComponentState(component.name, 'vfd', $event)" >
                </label>
                <label>
                  <button style="position: relative; top: 0px; left: 73px;" class="send-btn" (click)="sendCommand(getid(component.name),getname(component.name),'vfd',component.name,'vfd')">Send</button>
                </label>
                <span style="position: relative; top: 0px; left: 73px;" [style.color]="getStatusColor(equipstatus[component.name + '_vfd'])">
                  {{ equipstatus[component.name + '_vfd'] }}
                </span>

              </div>
          </div>


          </div>


          <!-- <div class="button-group">
            <button class="clear-btn" (click)="clearSelection()">Clear</button>
            <button class="send-btn">Send</button>
          </div> -->
        </div>
        <!-- <p class="waiting-text">Waiting for the Command!</p> -->
      </div>
    </div>
  </div>
