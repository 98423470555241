import { Component } from '@angular/core';

@Component({
  selector: 'app-animated-home-screen',
  templateUrl: './animated-home-screen.component.html',
  styleUrls: ['./animated-home-screen.component.css']
})
export class AnimatedHomeScreenComponent {

}
