import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { AhuTemplateComponent } from './ahu-template/ahu-template.component';
import { ZoneOneScreenComponent } from './pages/zone-wise-pages/zone-one-screen/zone-one-screen.component';
import { ZoneTwoScreenComponent } from './pages/zone-wise-pages/zone-two-screen/zone-two-screen.component';
import { ZoneThreeScreenComponent } from './pages/zone-wise-pages/zone-three-screen/zone-three-screen.component';
import { ZoneFourScreenComponent } from './pages/zone-wise-pages/zone-four-screen/zone-four-screen.component';
import { ZoneFiveComponent } from './pages/zone-wise-pages/zone-five/zone-five.component';
import { ZoneSixComponent } from './pages/zone-wise-pages/zone-six/zone-six.component';
import { ZoneSevenComponent } from './pages/zone-wise-pages/zone-seven/zone-seven.component';
import { MallTwoComponent } from './pages/zone-wise-pages/mall-two/mall-two.component';
import { MainLobbyComponent } from './pages/zone-wise-pages/main-lobby/main-lobby.component';
import { MainEntranceComponent } from './pages/zone-wise-pages/main-entrance/main-entrance.component';
import { MallOneComponent } from './pages/zone-wise-pages/mall-one/mall-one.component';
import { HyperLobbyComponent } from './pages/zone-wise-pages/hyper-lobby/hyper-lobby.component';
import { AnimatedHomeScreenComponent } from './components/animated-home-screen/animated-home-screen.component';
import { SummaryPageComponent } from './pages/summary-page/summary-page.component';
import { AhuOneComponent } from './pages/ahu-one/ahu-one.component';
import { AhuTwoComponent } from './pages/ahu-two/ahu-two.component';
import { AhuThreeComponent } from './pages/ahu-three/ahu-three.component';
import { AhuFourComponent } from './pages/ahu-four/ahu-four.component';
import { AhuFiveComponent } from './pages/ahu-five/ahu-five.component';
import { AhuSixComponent } from './pages/ahu-six/ahu-six.component';
import { AhuSevenComponent } from './pages/ahu-seven/ahu-seven.component';
import { AhuEightComponent } from './pages/ahu-eight/ahu-eight.component';
import { AhuNineComponent } from './pages/ahu-nine/ahu-nine.component';
import { AhuTenComponent } from './pages/ahu-ten/ahu-ten.component';
import { AhuElevenComponent } from './pages/ahu-eleven/ahu-eleven.component';
import { AhuSevenScreenTwoComponent } from './pages/ahu-seven-screen-two/ahu-seven-screen-two.component';
import { AhuTwelveComponent } from './pages/ahu-twelve/ahu-twelve.component';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { OltBlockComponent } from './pages/olt-block/olt-block.component';
import { Chiller1Component } from './pages/chiller-plant/chiller1/chiller1.component';
import { Chiller2Component } from './pages/chiller-plant/chiller2/chiller2.component';
import { Chiller3Component } from './pages/chiller-plant/chiller3/chiller3.component';
import { ChillerRoomComponent } from './pages/chiller-plant/chiller-room/chiller-room.component';
import { CoolingTower1Component } from './pages/chiller-plant/cooling-tower1/cooling-tower1.component';
import { CoolingTower2Component } from './pages/chiller-plant/cooling-tower2/cooling-tower2.component';
import { CoolingTowersComponent } from './pages/chiller-plant/cooling-towers/cooling-towers.component';
import { Chiller4Component } from './pages/chiller-plant/chiller4/chiller4.component';
import { CondenserPumpsComponent } from './pages/chiller-plant/condenser-pumps/condenser-pumps.component';
import { PrimaryPumpsComponent } from './pages/chiller-plant/primary-pumps/primary-pumps.component';
import { SecondaryPumpsComponent } from './pages/chiller-plant/secondary-pumps/secondary-pumps.component';
import { ViewAllErrorsComponent } from './pages/view-all-errors/view-all-errors.component';
import { StridesAnalyticsComponent } from './pages/strides-analytics/strides-analytics.component';
import { CommandsComponent } from './commands/commands.component';
import { PidTuningCommandComponent } from './commands/pid-tuning-command/pid-tuning-command.component';
import { ChillerplantCommandComponent } from './commands/chillerplant-command/chillerplant-command.component';
import { AhuscheduleCommandComponent } from './commands/ahuschedule-command/ahuschedule-command.component';
import { roleGuard } from './guards/role.guard';
import { ProfileComponent } from './components/users/profile/profile.component';
import { AdduserComponent } from './components/users/adduser/adduser.component';
import { ViewusersComponent } from './components/users/viewusers/viewusers.component';
import { TableDataComponent } from './table-data/table-data.component';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { AhuCommandComponent } from './commands/ahu-command/ahu-command.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { AdminLogComponent } from './pages/admin-log/admin-log.component';
import { AlarmLogComponent } from './pages/alarm-log/alarm-log.component';


const routes: Routes = [

  {path:'login',component:LoginComponent},
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'forgot-password',component:ForgotPasswordComponent},
  {path:'animated-home-screen', component: AnimatedHomeScreenComponent },
  {path:'change-password', component: ChangepasswordComponent },


  {path:'dashboard',component:DashboardComponent, canActivate: [AuthGuard] ,
     children:[
      // for now--> home- oltBlock
      // change it to--> home-homeComponent and name of olt to homecomponent
      {path:'logout',component:LogoutDialogComponent},
      {path:'home', component: HomeComponent},
      {path:'olt-block', component: HomeComponent},
      {path:"summary",component: SummaryPageComponent},
      {path:"analytics", component: StridesAnalyticsComponent},

      {path:'AHU-01', component: AhuOneComponent,data:{commandName:'ahu'}},
      {path:'AHU-02', component: AhuTwoComponent,data:{commandName:'ahu'}},
      {path:'AHU-03', component: AhuThreeComponent,data:{commandName:'ahu'}},
      {path:'AHU-04', component: AhuFourComponent,data:{commandName:'ahu'}},
      {path:'AHU-05', component: AhuFiveComponent,data:{commandName:'ahu'}},
      {path:'AHU-06', component: AhuSixComponent,data:{commandName:'ahu'}},
      {path:'AHU-07', component: AhuSevenComponent,data:{commandName:'ahu'}},
      {path:'AHU-07-02', component: AhuSevenScreenTwoComponent,data:{commandName:'ahu'}},
      {path:'AHU-08', component: AhuEightComponent,data:{commandName:'ahu'}},
      {path:'AHU-09', component: AhuNineComponent,data:{commandName:'ahu'}},
      {path:'AHU-10', component: AhuTenComponent,data:{commandName:'ahu'}},
      {path:'AHU-11', component: AhuElevenComponent,data:{commandName:'ahu'}},
      {path:'AHU-12', component: AhuTwelveComponent,data:{commandName:'ahu'}},
      {path:'chiller-plant', component: ChillerPlantComponent,data:{commandName:'ahu'}},
      {path:'chiller2', component: Chiller2Component,data:{commandName:'chiller'}},
      {path:'chiller1', component: Chiller1Component,data:{commandName:'chiller'}},
      {path:'chiller3', component: Chiller3Component,data:{commandName:'chiller'}},
      {path:'chiller4', component: Chiller4Component,data:{commandName:'chiller'}},

      {path:'chiller-room', component: ChillerRoomComponent},
      {path:'cooling-tower1',component:CoolingTower1Component,data:{commandName:'chiller'}},
      {path:'cooling-tower2',component:CoolingTower2Component,data:{commandName:'chiller'}},
      {path:'cooling-towers',component:CoolingTowersComponent,data:{commandName:'chiller'}},
      {path:'condenser_pumps',component:CondenserPumpsComponent,data:{commandName:'chiller'}},
      {path:'primary_pumps',component:PrimaryPumpsComponent,data:{commandName:'chiller'}},
      {path:'secondary_pumps',component:SecondaryPumpsComponent,data:{commandName:'chiller'}},
      {path:'view-all-errors',component:ViewAllErrorsComponent},
      {path:'commands',component:CommandsComponent},
      {path:'ahucommand',component:AhuCommandComponent,canActivate:[roleGuard],data:{commandName:'ahucontrol'}},


      {path:'pid-tuning',component:PidTuningCommandComponent,canActivate:[roleGuard],data:{commandName:'pidtuning'}},
      {path:'chillerplant-command',component:ChillerplantCommandComponent,canActivate:[roleGuard],data:{commandName:'chillersequencing'}},
      {path:'ahuscedule-command',component:AhuscheduleCommandComponent,canActivate:[roleGuard],data:{commandName:'ahuschedule'}},
      {path:'manageusers',component:ManageUsersComponent},
      {path:'adduser',component:AdduserComponent},
      {path:'viewusers',component:ViewusersComponent},
      {path:'table-data',component:TableDataComponent},
      {path:'admin-log',component:AdminLogComponent},
      {path:'alarm-log',component:AlarmLogComponent}









     ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
