<div class="ahu-eleven">
    
      
      
      <svg id="ahu-eleven" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1373.987 277.205">
        <g id="Component_185_1" data-name="Component 185 – 1" transform="translate(0.5 0.555)">
          <path id="Path_11407" data-name="Path 11407" d="M11904.271-21105.854c.015,4.521,0,44.717,0,44.717s.051,4.752,3.781,4.744,481.933.2,481.933.2l.036,75.271s-.044,4.455,4.217,4.41,807.175-.3,807.175-.3l-.283,126.713s.095,3.789,3.368,3.8,59.469-.18,59.469-.18a3.415,3.415,0,0,0,3.213-3.5c.036-3.328-.105-126.924-.105-126.924s3.161-.518,3.185-3.555-.2-32.576-.2-32.576a2.983,2.983,0,0,0-2.937-3.088c-.243-.006-12.45,0-12.45,0l.372-85.186s.9-8.6-5.1-9.584c-5.875,0-16.624.244-17.934.244-2.074,0-14.052,0-15.833,0-.79,0-3.339-.371-5,.518a3.641,3.641,0,0,0-1.657,3.035v90.943l-776.812-.162.147-89.471s.405-4.812-4.6-4.748c-4.446.057-502.9.162-502.9.162l-17.437,0S11904.257-21110.375,11904.271-21105.854Z" transform="translate(-11898.271 21121.443)" fill="#f0fbff" stroke="#429163" stroke-width="3"/>
          <text id="Material_Corridor" data-name="Material Corridor" transform="translate(175.194 30.15)" fill="#606060" font-size="12" font-family="Mulish" font-weight="300"><tspan x="50.654" y="12">Material Corridor</tspan></text>
          <path id="Path_11408" data-name="Path 11408" d="M-2389.342-21041.236v-16.617h-10.3s.422-3.994,2.822-6.34,6.778-3.066,6.778-3.066-4.378-.607-6.778-3.133-2.822-6.967-2.822-6.967l10.3.254v-15.053" transform="translate(2539.645 21105.33)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11409" data-name="Path 11409" d="M11.438,0V9.5H0s.468,4.433,3.133,7.039,7.524,3.4,7.524,3.4-4.86.674-7.524,3.479S0,31.153,0,31.153l2.358-.058,3.1-.077,5.978-.147V41.188" transform="translate(229.954 76.471) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11410" data-name="Path 11410" d="M11.438,0V9.5H0s.468,4.433,3.133,7.039,7.524,3.4,7.524,3.4-4.86.674-7.524,3.479S0,31.153,0,31.153l2.358-.058,3.1-.077,5.978-.147V41.188" transform="translate(269.954 76.471) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11411" data-name="Path 11411" d="M-2399.645-21041.236v-16.617h10.3s-.422-3.994-2.822-6.34-6.778-3.066-6.778-3.066,4.378-.607,6.778-3.133,2.822-6.967,2.822-6.967l-10.3.254v-15.053" transform="translate(2399.645 21104.33)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11412" data-name="Path 11412" d="M-2399.645-21041.236v-16.617h10.3s-.422-3.994-2.822-6.34-6.778-3.066-6.778-3.066,4.378-.607,6.778-3.133,2.822-6.967,2.822-6.967l-10.3.254v-15.053" transform="translate(21142.85 -2389.342) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11413" data-name="Path 11413" d="M-2399.645-21041.236v-16.617h10.3s-.422-3.988-2.822-6.34-6.778-3.066-6.778-3.066,4.378-.607,6.778-3.133,2.822-6.967,2.822-6.967l-10.3.254v-15.053" transform="translate(2801.645 21105.33)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <text id="Air_Lock" data-name="Air Lock" transform="translate(433.408 30.15)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="21.864" y="12">Air Lock</tspan></text>
          <path id="Path_11414" data-name="Path 11414" d="M-2399.645-21047.975v-9.879h10.3s-.422-3.994-2.822-6.34-6.778-3.066-6.778-3.066,4.378-.607,6.778-3.133,2.822-6.967,2.822-6.967l-10.3.254v-11.158" transform="translate(21581.104 -2294.342) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <text id="Manufacturing_Corridor" data-name="Manufacturing Corridor" transform="translate(810.233 117.16)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="63.252" y="12">Manufacturing Corridor</tspan></text>
          <text id="AHU11" transform="translate(599.645 114.434)" fill="#377c54" font-size="16" font-family="Mulish, sans-serif" font-weight="700"><tspan x="107.712" y="16">AHU11</tspan></text>
          <text id="Acessories" transform="translate(1324.552 84.15) rotate(-90)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="0" y="12">Acessories</tspan></text>
          <path id="Path_11415" data-name="Path 11415" d="M-2385.927-21040.84v-7.555h-13.717s.562-5.316,3.757-8.441,9.024-4.082,9.024-4.082-5.829-.809-9.024-4.172-3.757-9.273-3.757-9.273l6.547.162,7.17.176v-8.266" transform="translate(-19727.846 2492.248) rotate(90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <path id="Path_11416" data-name="Path 11416" d="M13.717,1.867V7.555H0S.562,12.871,3.757,16s9.024,4.082,9.024,4.082-5.829.809-9.024,4.172S0,33.523,0,33.523l6.547-.162,7.17-.176V64.534" transform="translate(1302.851 157.49) rotate(-90)" fill="none" stroke="#acc1af" stroke-width="1"/>
          <text id="Cleaned_equipment_Holding_room" data-name="Cleaned 
      equipment 
      Holding room" transform="translate(1313.551 268.631) rotate(-90)" fill="#606060" font-size="12" font-family="Mulish, sans-serif" font-weight="300"><tspan x="25.548" y="12">Cleaned </tspan><tspan x="20.094" y="27">equipment </tspan><tspan x="15.81" y="42">Holding room</tspan></text>
        </g>
      </svg>
      
  </div>
  
  
  
  
  
  
  
  
  
  
  
  