import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mall-one',
  templateUrl: './mall-one.component.html',
  styleUrls: ['./mall-one.component.css']
})
export class MallOneComponent {
  type!: number;
  RAT: number;
RAT_out: any;
KPI:number=0;
RAT_out_AHU_07: any;
RAT_out_AHU_09: any;
RAT_out_AHU_21: any;
RAT_out_AHU_22: any;
power_ahu_07: number=0;
power_ahu_09: number=0;
power_ahu_21: number=0;
power_ahu_22: number=0;
Zone_temp: number=0;
BASELINE_MALL_01:number=0;
POWER_MALL_01:number=0;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_07 = Number(localStorage.getItem('RAT_out_AHU_07')) || 0;
  localStorage.setItem('RAT_out_AHU_07', JSON.stringify(this.RAT_out_AHU_07));
  console.log("RAT_AHU_07_output_zone_one", this.RAT_out_AHU_07 );

  this.RAT_out_AHU_09 = Number(localStorage.getItem('RAT_out_AHU_09')) || 0;
  localStorage.setItem('RAT_out_AHU_09', JSON.stringify(this.RAT_out_AHU_09));
  console.log("RAT_AHU_09_output_zone_one", this.RAT_out_AHU_09 );

  this.RAT_out_AHU_21 = Number(localStorage.getItem('RAT_out_AHU_21')) || 0;
  localStorage.setItem('RAT_out_AHU_21', JSON.stringify(this.RAT_out_AHU_21));
  console.log("RAT_AHU_21_output_zone_one", this.RAT_out_AHU_21 );

  this.RAT_out_AHU_22 = Number(localStorage.getItem('RAT_out_AHU_22')) || 0;
  localStorage.setItem('RAT_out_AHU_22', JSON.stringify(this.RAT_out_AHU_22));
  console.log("RAT_AHU_22_output_zone_one", this.RAT_out_AHU_22 );
  
  this.Zone_temp = ((this.RAT_out_AHU_07+this.RAT_out_AHU_09+this.RAT_out_AHU_21+this.RAT_out_AHU_22)/4);


  this.power_ahu_07 = Number(localStorage.getItem('Power_AHU_07_out')) || 0;
  localStorage.setItem('power_ahu_07', JSON.stringify('power_ahu_07'));
  console.log("Power_AHU_07_out", this.power_ahu_07);

  this.power_ahu_09 = Number(localStorage.getItem('Power_AHU_09_out')) || 0;
  localStorage.setItem('power_ahu_09', JSON.stringify('power_ahu_09'));
  console.log("Power_AHU_09_out", this.power_ahu_09);

  this.power_ahu_21 = Number(localStorage.getItem('Power_AHU_21_out')) || 0;
  localStorage.setItem('power_ahu_21', JSON.stringify('power_ahu_21'));
  console.log("Power_AHU_21_out", this.power_ahu_21);

  this.power_ahu_22 = Number(localStorage.getItem('Power_AHU_22_out')) || 0;
  localStorage.setItem('power_ahu_22', JSON.stringify('power_ahu_22'));
  console.log("Power_AHU_22_out", this.power_ahu_22);

  

  this.BASELINE_MALL_01 = 9.59;
  this.POWER_MALL_01=(this.power_ahu_07+this.power_ahu_09+this.power_ahu_21+this.power_ahu_22);

  
  if(this.POWER_MALL_01){
    this.KPI = ((this.BASELINE_MALL_01-this.POWER_MALL_01)/this.BASELINE_MALL_01)*100;
  localStorage.setItem('kpi_mall_02', JSON.stringify('KPI'));
  }
  else{
    this.KPI = 0 ;
  }






 }
}
