
<div class="main_container" style="display: flex;  height: 100%; background-color: #FFFDF2; flex-direction: row;"
><div class="main-container">
  <div *ngIf="isloading">
    <app-loading-spinner *ngIf="isloading"></app-loading-spinner>
  </div>
  <div class="user-list">
    <h2 class="title">Project Members(8)</h2>
    <p class="sub-title">Add / Remove People who can access the Dashboard</p>

    <!-- Loop through users and display them -->
    <div class="user-item" *ngFor="let user of users">
      <div class="user-info">
        <div class="user-avatar"></div>
        <div class="user-details">
          <p class="user-name">{{ user.employeeName }}  <span >({{user.username}})</span></p>
          <p class="user-role">{{ user.role }} | {{ user.emailId }}</p>
        </div>
      </div>
      <mat-slide-toggle 
      [checked]="!user.isUserDeactivated"
      
      (change)="toggleUserStatus(user, $event.checked)" *ngIf="!isAdmin(user)">
      {{ user.isUserDeactivated ? 'Inactive' : 'Active' }} 
      
    </mat-slide-toggle>
      <!-- <button *ngIf="isAdmin(user)" class="btn change-admin-btn">Change Admin</button> -->
      <!-- <button *ngIf="!user.isAdmin" class="btn remove-btn">Remove</button> -->
      <button *ngIf="!isAdmin(user)" class="three-dots" mat-icon-button [matTooltip]="'Edit'" (click)="editUser(user)" >
        <mat-icon>edit</mat-icon> <!-- Horizontal three dots -->
      </button>

      <mat-menu class="matcontainer"  #menu="matMenu">
        <!-- <button class="matbutton" mat-menu-item (click)="deleteUser(user)">
          <span>Delete</span>
        </button> -->
        <button class="matbutton" mat-menu-item (click)="editUser(user)">
          <!-- <mat-icon>edit</mat-icon> -->
          <span>Edit</span>
        </button>
     
      </mat-menu>
    </div>
  </div>
  <!-- <button class="btn add-user-btn" routerLink="../adduser">Add New User</button> -->

</div>

<div class="container">
  <div class="title1">{{isediting? 'Update User':'Add New User'}}</div>
  <form  [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <!-- First Name and Last Name -->
    <div class="form-group nameclass">
      <div>   
        <label>First Name</label>
        <input formControlName="firstName" placeholder="Enter First Name" /></div>
      <div> 
        <label>Last Name</label>
        <input formControlName="lastName" placeholder="Enter Last Name" />
      </div>
    </div>
   
     

    <!-- Job Role -->
    <div class="form-group">
      <label>Job Role</label>
      <select formControlName="jobRole">
        <option *ngFor="let role of allroles" [value]="role">{{ role }}</option>
      </select>
    </div>

    <!-- Email Address -->
    <div class="form-group">
      <label>Email Address</label>
      <input formControlName="email" placeholder="Enter Work Email Address" />
    </div>

    <!-- Mobile Number -->
    <div class="form-group">
      <label>Mobile Number</label>
      <input formControlName="mobile" placeholder="Enter Mobile Number" />
    </div>

    <!-- Username -->
    <div class="form-group">
      <label>User Name/Emp Id</label>
      <input formControlName="username" placeholder="Type Username" [readOnly]="isediting" />
     
    </div>
    <div *ngIf="userForm.controls['username'].touched && hasUsernameError()" class="error-message">
      * Only numeric values are acceptable.
    </div>

    <!-- Password -->
    <div *ngIf="!isediting" class="form-group">
      <label>User Password</label>

        <div class="pass">
        <input
          type="{{ passwordFieldType }}"
          id="password" name="password"
          formControlName="password"
          placeholder="Min 8 Characters"
        />
        <span (click)="hideShowPass()" class="fa {{eyeIcon}}"></span>
        </div>
    </div>

    <!-- Error Messages for Password Validation -->
    <div *ngIf="showvalid()" class="validation-messages">
      <div [ngClass]="{'valid': !hasMinLengthError(), 'invalid': hasMinLengthError()}">
        * Minimum 8 characters
      </div>
      <div [ngClass]="{'valid': !hasUppercaseError(), 'invalid': hasUppercaseError()}">
        * At least 1 uppercase letter
      </div>
      <div [ngClass]="{'valid': !hasLowercaseError(), 'invalid': hasLowercaseError()}">
        * At least 1 lowercase letter
      </div>
      <div [ngClass]="{'valid': !hasNumberError(), 'invalid': hasNumberError()}">
        * At least 1 number
      </div>
      <div [ngClass]="{'valid': !hasSpecialCharError(), 'invalid': hasSpecialCharError()}">
        * At least 1 special character
      </div>
    </div>

    <!-- Buttons -->
    <div class="form-buttons">
      <button type="submit" [disabled]="userForm.invalid && !isediting">Submit</button>
      <button type="button" class="cancelbutton" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>


</div>

  
