<div style="position: relative; height: 100%; width: 100%;">
    <div  class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img src="/assets/strides-images/prim pump.svg" class="fmap">

                      <!-- pri_pump1 -->
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 6%; left: 80.8%; width: 41px;  height: 3%;">
                          <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump1 | number: '1.2-2'" (ngModelChange)=" vfd_pump1 = $event" />
                          <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                        </div>
                        <!-- <div class="input-groupR  component" style="font-size: 80%; color: #D90606;display:flex; justify-content:center; align-items:center; bottom: 5.8%; left: 81.9%; width: 3.5%; height: 3%;">
                         %
                        </div> -->

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 6%; left: 87%;width: 45px;  height: 3%;">
                            <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump1 | number: '1.2-2'" (ngModelChange)=" power_pump1 = $event" />
                            <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>

                          </div>
                          <!-- <div class="input-groupR  component" style="font-size: 80%; color: #D90606;display:flex; justify-content:center; align-items:center; bottom: 6%; left: 88%; width: 3.5%; height: 3%;">
                            kw
                          </div> -->
                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 1.7%; left: 87%; width: 45px;  height: 3%;">
                            <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump1_out | number: '1.2-2'" (ngModelChange)=" power_pump1_out = $event" />
                            <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span>

                          </div>
                          <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 1.7%; left: 88%; width: 3.5%; height: 3%;">
                            kw
                          </div> -->

                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 2%; left: 80.8%;width: 43px;  height: 3%;">
                            <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump1_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump1_rl = $event" />
                            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>

                          </div>
                          <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 2%; left: 81.9%; width: 3.5%; height: 3%;">
                            %
                          </div> -->

                      <!-- pri_pump2 -->

                        <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 46.7%; left: 83%; width: 41px;  height: 3%;">
                            <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump2 | number: '1.2-2'" (ngModelChange)=" vfd_pump2 = $event" />
                            <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                          </div>
                          <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 46.5%; left: 84%; width: 3.5%; height: 3%;">
                            %
                          </div> -->
                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 46.7%; left: 89%; width: 45px;  height: 3%;">
                            <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump2 | number: '1.2-2'" (ngModelChange)=" power_pump2 = $event" />
                            <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                          </div>
                          <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 46.5%; left: 90%; width: 3.5%; height: 3%;">
                            kw
                          </div> -->

                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 42.2%; left: 89%; width: 45px;  height: 3%;">
                            <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump2_out | number: '1.2-2'" (ngModelChange)=" power_pump2_out = $event" />
                            <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span>

                          </div>
                          <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #00A578;  justify-content:center; align-items:center; bottom: 42.2%; left: 90%; width: 3.5%; height: 3%;">
                            kw
                          </div> -->


                          <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 42.3%; left: 83%; width: 41px;  height: 3%;">
                            <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump2_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump2_rl = $event" />
                            <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                          </div>
                          <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 42%; left: 84%; width: 3.5%; height: 3%;">
                            %
                          </div> -->




                      <!-- pri_pump3-->
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 57%; left: 73.1%; width: 41px;  height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump3 | number: '1.2-2'" (ngModelChange)=" vfd_pump3 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span>
                      </div>
                      <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 55.8%; left: 75.5%;">
                        %
                      </div> -->
                     <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 57%; left: 79.1%; width: 45px;  height: 3%;">
                        <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump3 | number: '1.2-2'" (ngModelChange)=" power_pump3 = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>
                      </div>
                      <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 57%; left: 80.1%; width: 3.5%; height: 3%;">
                        kw
                      </div> -->
                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 53%; left: 79.1%;width: 45px;  height: 3%;">
                        <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump3_out | number: '1.2-2'" (ngModelChange)=" power_pump3_out = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span>
                      </div>
                      <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #00A578;  justify-content:center; align-items:center; bottom: 53%; left: 80.1%; width: 3.5%; height: 3%;">
                        kw
                      </div> -->

                      <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 53%; left: 73.1%; width: 41px;  height: 3%;">
                        <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump3_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump3_rl = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>
                      </div>
                      <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 53%; left: 74.1%; width: 3.5%; height: 3%;">
                        %
                      </div> -->



                            <!-- pri_pump4-->


                              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 67.5%; left: 60%; width: 41px;  height: 3%;">
                                <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump4 | number: '1.2-2'" (ngModelChange)=" vfd_pump4 = $event" />
                                <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span></div>
                              <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 66%; left: 62.3%;">
                                %
                              </div> -->
                             <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 67.5%; left: 66.1%; width: 45px;  height: 3%;">
                                <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump4 | number: '1.2-2'" (ngModelChange)=" power_pump4 = $event" />
                                <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span></div>
                              <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 66.2%; left: 68%; ">
                                kw
                              </div> -->
                              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 63.5%; left: 66.1%; width: 45px; height: 3%;">
                                <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump4_out | number: '1.2-2'" (ngModelChange)=" power_pump4_out = $event" />
                                <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span></div>
                              <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #00A578;  justify-content:center; align-items:center; bottom: 62%; left: 68%; ">
                                kw
                              </div> -->

                              <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 63.5%; left: 60%;width: 41px;  height: 3%;">
                                <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump4_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump4_rl = $event" />
                                <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span></div>
                              <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 63.5%; left: 61%; width: 3.5%; height: 3%;">
                                %
                              </div> -->
                           <!-- pri_pump6-->

                                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 55.7%; left: 28%; width: 41px;  height: 3%;">
                                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump6 | number: '1.2-2'" (ngModelChange)=" vfd_pump6 = $event" />
                                  <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span> </div>
                                <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 54.5%; left: 30.5%;">
                                  %
                                </div> -->
                               <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 55.7%; left: 33.9%; width: 45px;  height: 3%;">
                                  <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump6 | number: '1.2-2'" (ngModelChange)=" power_pump6 = $event" />
                                  <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span> </div>
                                <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 54.3%; left: 36.2%; ">
                                  kw
                                </div> -->

                                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 51.7%; left: 33.9%; width: 45px;  height: 3%;">
                                  <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump6_out | number: '1.2-2'" (ngModelChange)=" power_pump6_out = $event" />
                                  <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span></div>
                                <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #00A578;  justify-content:center; align-items:center; bottom: 50.3%; left: 36.2%; ">
                                  kw
                                </div> -->

                                <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 51.5%; left: 28%; width: 41px;  height: 3%;">
                                  <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump6_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump6_rl = $event" />
                                  <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span> </div>
                                <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 51.3%; left: 29.2%; width: 3.5%; height: 3%;">
                                  %
                                </div> -->



                               <!-- pri_pump6-->



                                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 5.7%; left: 12.8%; width: 41px;  height: 3%;">
                                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" vfd_pump5 | number: '1.2-2'" (ngModelChange)=" vfd_pump5 = $event" />
                                    <span style="font-size: 80%; color: #D90606; font-weight:600" >%</span> </div>
                                  <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 4.6%; left: 15.2%;">
                                    %
                                  </div> -->
                                 <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 5.7%; left: 18.7%; width: 45px;  height: 3%;">
                                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" power_pump5 | number: '1.2-2'" (ngModelChange)=" power_pump5 = $event" />
                                    <span style="font-size: 80%; color: #D90606; font-weight:600" >kw</span>  </div>
                                  <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 4.6%; left: 21%; ">
                                    kw
                                  </div> -->

                                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 1.7%; left: 18.7%;width: 45px;  height: 3%;">
                                    <input readonly  style="color: #293AC6; font-weight:600" [ngModel]=" power_pump5_out | number: '1.2-2'" (ngModelChange)=" power_pump5_out = $event" />
                                    <span style="font-size: 80%; color: #293AC6; font-weight:600" >kw</span>  </div>
                                  <!-- <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #00A578;  justify-content:center; align-items:center; bottom: 0.1%; left: 21%; ">
                                    kw
                                  </div> -->

                                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 2%; left: 12.8%; width: 41px;  height: 3%;">
                                    <input readonly  style="color:#00A578; font-weight:600" [ngModel]=" vfd_pump5_rl | number: '1.2-2'" (ngModelChange)=" vfd_pump5_rl = $event" />
                                    <span style="font-size: 80%; color: #00A578; font-weight:600" >%</span>  </div>
                                  <!-- <div class="input-groupR  component" style="font-size: 80%; color: #00A578;display:flex; justify-content:center; align-items:center; bottom: 2%; left: 13.8%; width: 3.5%; height: 3%;">
                                    %
                                  </div> -->

                                  <!-- ch.w.temp -->
                                  <div class="input-groupR  component" style="display:flex; justify-content:center; align-items:center; bottom: 71.7%; left: 45.7%;width: 41px;  height: 3%;">
                                    <input readonly  style="color: #D90606; font-weight:600" [ngModel]=" Ch_w_temp | number: '1.2-2'" (ngModelChange)=" Ch_w_temp = $event" />
                                  </div>
                                  <div class="input-groupR  component" style="display:flex;font-size: 80%; color: #D90606;  justify-content:center; align-items:center; bottom: 70.3%; left: 47.7%; ">
                                    °C
                                  </div>

















                </div>
            </div>
        </div>
    </div>
</div>







<!--
                    <div class="component" style="width: 5%; height: 18%; top: 55%;left: 25.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            15KW
                        </button>
                      </div>


                    <div class="component" style="width: 5%; height: 18%; top: 78%;left: 13.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11KW
                        </button>
                      </div>
                      <div class="component" style="width: 5%; height: 18%; top: 40%;left: 60%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11KW
                        </button>
                      </div>
                      <div class="component" style="width: 5%; height: 18%; top: 50%;left: 65.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11KW
                        </button>
                      </div>
                      <div class="component" style="width: 5%; height: 18%; top: 62%;left: 71.5%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11KW
                        </button>
                      </div>

                      <div class="component" style="width: 5%; height: 18%; top: 81%;left: 80.2%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #429163;
                            border: none;
                            height: 40%;
                            width: 100%;
                            font-size: 100%;
                            color: white;

                            z-index: 999;
                            " >
                            11KW
                        </button>
                      </div> -->
