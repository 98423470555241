import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-four-comp',
  templateUrl: './ahu-four-comp.component.html',
  styleUrls: ['./ahu-four-comp.component.css']
})
export class AhuFourCompComponent {

}
