<div style="position: relative; height: 100%; width: 100%">
  <!-- <Water-mark></Water-mark> -->

 <!-- Type 1 ---- AHUs for Manar Mall -->
 <div *ngIf="type==1">
  <div style="
      width: 100%;
      height: 100%;
      min-width: 1100px;
      display: flex;
      flex-direction: column;
      position: absolute; ">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar>
    <div style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        align-items: center;
        gap: 10px;
      " >
      <div style="
          height: 450px;
          width: 1200px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        " >
        <!-- AHU Map  -->
        <div
          style="
            height: 450px;
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          " >
          <div style="height: 450px; width: 1200px; margin-right:0px">
            <img src="/assets/images/ahu-manar-mall.svg" width="1200px" height="450px" />
            <div style="position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -111px;
            margin-left: 400px;
            height: 75px;
            width: 325px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: center; align-items:center; ">
                <p style="    font-family:Mulish; font-size: 20px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">{{ name }}</p>
              </div>
            </div>
            <div style="position: absolute;
            display: flex;
            margin-top: -402px;
            align-items: center;
            justify-content: center;
            margin-left: 990px;
            height: 250px;
            width: 170px;">
              <div style="display: flex; width: 80%; height: 100%; justify-content: center; align-items:center;">
                <p style="    font-family:Mulish; font-size: 20px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">{{ area }}</p>
              </div>
            </div>
          </div>
          
          
          
          
          


        </div>

        <!-- Image Code -->
        <div  style="height: 255px; width: 180px; margin-left: 986.5px; margin-top: 52px;" class="image-container" (mouseover)="hideImage()" (mouseout)="showImage()">
          <img [src]="'/assets/area/' + imageFileName" width="180px"  height="255px" alt="Your Image" [class.hidden]="isHidden" />
        </div>

        <!-- componets -->
        <div style="height: 450px; width: 1200px; position: absolute">
          <!-- FAN -->
          <div style="
          position:absolute;
              height: 85px;
              width: 85px;
              margin-top: 209px;
              margin-left: 486px;
            " >
            <Fan [state]="stateAHU"></Fan>
          </div>

          <!-- 1.  Switch-->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px; margin-top: 136px; margin-left: 379px" >
            <img src="/assets/images/switch1.svg" />
            <ng-container *ngIf="SFRS_out !== false; else emptyInput">
              <input
                [ngModel]="SFRS_out | number : '1.2-2'"
                (ngModelChange)="SFRS_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>


          <!--2. RAT_out -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px; margin-top: 17px; margin-left: 644px" >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="RAT_out !== false; else emptyInput">
              <input
                [ngModel]="RAT_out | number : '1.2-2'"
                (ngModelChange)="RAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

            <!--3. Ambient Temp      -->
            <div
            [className]="capsule_group"
            style="position:absolute; width: 80px; margin-top: 95px; margin-left: 105px" >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="AMBIENT_TEMP_out !== false; else emptyInput">
              <input
                [ngModel]="AMBIENT_TEMP_out | number : '1.2-2'"
                (ngModelChange)="AMBIENT_TEMP_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

            <!-- 4. CHWST -->
            <div
            [className]="capsule_group"
            style="position:absolute; width: 90px; margin-top: 374px; margin-left: 197px" >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="CHWST_out !== false; else emptyInput">
              <input
                [ngModel]="CHWST_out | number : '1.2-2'"
                (ngModelChange)="CHWST_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          <!-- 5. CHWVP  -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 80px;
            margin-top: 335px;
            margin-left: 269px;"
          >
            <img src="/assets/images/percentage.png" />
            <ng-container *ngIf="CHWVP_out !== false; else emptyInput">
              <input
                [ngModel]="CHWVP_out | number : '1.2-2'"
                (ngModelChange)="CHWVP_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          <!-- 6. Fan Power -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 100px;
            margin-top: 136px;
            margin-left: 604px;"
          >
            <img src="/assets/images/power.png" />
            <ng-container *ngIf="FAN_Power_out !== false; else emptyInput">
              <input
                [ngModel]="FAN_Power_out | number : '1.2-2'"
                (ngModelChange)="FAN_Power_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>Wh</span>
          </div>

          <!-- 7. Fan Speed-->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 95px;
            margin-top: 136px;
            margin-left: 488px;"
          >
            <img src="/assets/images/fan-speed.png" />
            <ng-container *ngIf="VFD_Freq_out !== false; else emptyInput">
              <input
                [ngModel]="VFD_Freq_out | number : '1.2-2'"
                (ngModelChange)="VFD_Freq_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

              

          <!-- 8. SAT_out -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px;
            margin-top: 175px;
            margin-left: 855px;"
          >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="SAT_out !== false; else emptyInput">
              <input
                [ngModel]="SAT_out | number : '1.2-2'"
                (ngModelChange)="SAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: 5px;
            margin-left: 20px;"
          >
            <img src="/assets/images/CO2.png" />
            <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['co2'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>ppm</span>
          </div> -->

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: -10px;
            margin-left: 210px;"
          >
            <img src="/assets/images/DP.png" />
            <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['pfp'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>mbar</span>
          </div> -->
        </div>
      </div>
    </div>

    <div
    style="
      height: auto;
      width: 100%;
      min-width: 1000px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 7px;
      align-items: center;
      gap: 10px;
    "
  >
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: center;
      "
    >
      <div style="width: 600px; height: 100%; margin: 0px 20px 0px 50px">
        <div
          class="form"
          style="
            padding: 20px;
            flex-direction: row;
            gap: 30px;
            align-items: start;
          "
        >
          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
            "
          >
            <!-- SFRS	Supply Fan Run Status(21 - 24)-->
            
            <!-- <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
              <span class="span-text-dark" style="font-size: 12px; font-weight: 500">SFRS</span>                
              <div class="dropdown-box" style="width: 100px">
                  <select (change)="updateOnOffStatus($event)" class="status-dropdown">
                      <option value="0" style="color:#fffffb; background:var(--blue-one);" selected disabled >-SELECT-</option>
                      <option value="1" >ON</option>
                      <option value="2">OFF</option>
                  </select>
              </div>
          </div> -->
                <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SFRS</span
                >
              
                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/switch1.svg" />
                  <input [(ngModel)]="SFRS" />
                </div>
              </div>
          
            



            <!-- CHW Valve CHWVP	(0 - 100)-->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CHW VALVE</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="CHWVP" />
              </div>
            </div>

            <!-- FAN SPEED Fan Speed	(30 to 100)-->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >FAN SPEED</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="VFD_freq" />
              </div>
            </div>


            <!-- chilled water supply temperature	(6 to 10)-->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CHWST</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temp.svg" />
                <input [(ngModel)]="CHWST" />
              </div>
            </div>

           
          </div>

          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 10px">
              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SUPPLY DPT</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <input [(ngModel)]="SAT" />
                  <span>Pa</span>
                </div>
              </div> -->

               <!-- SAT	(17 - 20)-->
            <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 12px; font-weight: 500"
              >SAT</span
            >

            <div class="input-group" style="width: 100px">
              <img src="/assets/images/temp.svg" />
              <input [(ngModel)]="SAT" />
            </div>
          </div>



              <!--RAT RAT	(21 - 24) -->
              <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >RAT</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temp.svg" />
                <input [(ngModel)]="RAT" />
              </div>
            </div>

              <!-- Ambient Temperature	(10 to 55)-->

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >AMBIENT TEMP.</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temp.svg" />
                  <input [(ngModel)]="AMBIENT_TEMP" />
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 10px;
                justify-content: end;
                margin-top: 10px;
              "
            >
              <button
                style="
                  width: 50%;
                  background-color: var(--blue-one);
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                "
                (click)="predictData()"
              >
                Predict
              </button>
              <!-- <button
                style="
                  width: 50%;
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                "
                (click)="predictData()"
                [ngClass]="{
                  'button-disabled': disablePredict,
                  'button-enabled': !disablePredict
                }"
                [disabled]="disablePredict"
              >
                Predict
              </button> -->
            </div>
          </div>
        </div>
        <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div>
      </div>

      <!-- KPI -->

      <!-- <div class="form" style="height: 200px; width: 400px">
        <span
          class="span-text-dark"
          style="font-weight: bold; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div style="height: 30px"></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap:10px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >FAN POWER SAVING</span
          >

          <div class="input-group" style="width: 120px">
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event"
            />
            <span>%</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  </div>
</div>

<!-- Type 2 ---- FCUs for Manar Mall -->
<div *ngIf="type==2">
  <div style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute; ">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar>
    <div style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        align-items: center;
        gap: 10px;
      " >
      <div style="
          height: 400px;
          width: 1100px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        " >
        <!-- AHU Map  -->
        <div
          style="
            height: 400px;
            width: 1100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          " >
          <div style="height: 400px; width: 1100px; margin-right:140px">
            <img src="/assets/images/fcu-manar-mall.svg" width="1200px" height="400px" />
            <div style="position: absolute; top: 305px; left: 250px; height: 75px; width: 350px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: center; align-items:center; ">
                <p style="    font-family:Mulish;
 font-size: 24px; font-weight:520; text-align: center; line-height:28px; ">{{ name }}</p>
              </div>
            </div>
            <div style="position: absolute; top: 15px; left: 945px; height: 270px; width: 170px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: center; align-items:center;">
                <p style="    font-family:Mulish;
 font-size: 24px; font-weight:520; text-align: center; line-height:28px; ">{{ area }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- componets -->
        <div style="height: 350px; width: 1000px; position: absolute">
          <!-- FAN -->
          <div style="
              height: 85px;
              width: 85px;
              margin-top: 170px;
              margin-left: 280px;
            " >
            <Fan [state]="state"></Fan>
          </div>

          <!-- 1. RAT -->
          <!-- <div
            [className]="capsule_group"
            style="width: 80px; margin-top: -188px; margin-left: 100px" >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="RAT_out !== false; else emptyInput">
              <input
                [ngModel]="RAT_out | number : '1.2-2'"
                (ngModelChange)="RAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div> -->

          

          <!-- 2. CHWVP  -->
          <!-- <div
            [className]="capsule_group"
            style="width: 70px;
            margin-top: 188px;
            margin-left: 242px;"
          >
            <img src="/assets/images/percentage.png" />
            <ng-container *ngIf="CHWVP_out !== false; else emptyInput">
              <input
                [ngModel]="CHWVP_out | number : '1.2-2'"
                (ngModelChange)="CHWVP_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div> -->

          <!-- 3. Fan Power -->
          <!-- <div
            [className]="capsule_group"
            style="width: 110px;
            margin-top: -217px;
            margin-left: 380px;"
          >
            <img src="/assets/images/power.png" />
            <ng-container *ngIf="FAN_Power_out !== false; else emptyInput">
              <input
                [ngModel]="FAN_Power_out | number : '1.2-2'"
                (ngModelChange)="FAN_Power_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>Wh</span>
          </div> -->

          <!-- 4. Fan Speed-->
          <!-- <div
            [className]="capsule_group"
            style="width: 85px;
            margin-top: -30px;
            margin-left: 492px;"
          >
            <img src="/assets/images/fan-speed.png" />
            <ng-container *ngIf="VFD_Freq_out !== false; else emptyInput">
              <input
                [ngModel]="VFD_Freq_out | number : '1.2-2'"
                (ngModelChange)="VFD_Freq_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div> -->

              <!-- 5. SDPT_out -->
          <!-- <div
          [className]="capsule_group"
          style="width: 94px;
          margin-top: 77px;
          margin-left: 625px;"
          >
              <img src="/assets/images/differential-pressure-transmitter.png" />
              <ng-container *ngIf="SDPT_out !== false; else emptyInput">
                <input
                  [ngModel]="SDPT_out | number : '1.2-2'"
                  (ngModelChange)="SDPT_out = $event"
                />
              </ng-container>
              <ng-template #emptyInput>
                <input [ngModel]="''" />
              </ng-template>
          <span>Pa</span>
        </div> -->

          <!-- 6. SAT_out -->
          <!-- <div
            [className]="capsule_group"
            style="width: 90px;
            margin-top: -90px;
            margin-left: 710px;"
          >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="SAT_out !== false; else emptyInput">
              <input
                [ngModel]="SAT_out | number : '1.2-2'"
                (ngModelChange)="SAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div> -->

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: 5px;
            margin-left: 20px;"
          >
            <img src="/assets/images/CO2.png" />
            <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['co2'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>ppm</span>
          </div> -->

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: -10px;
            margin-left: 210px;"
          >
            <img src="/assets/images/DP.png" />
            <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['pfp'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>mbar</span>
          </div> -->
        </div>
      </div>
    </div>

    <div
    style="
      height: auto;
      width: 100%;
      min-width: 1000px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 7px;
      align-items: center;
      gap: 10px;
    "
  >
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: center;
      "
    >
      <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
        <div
          class="form"
          style="
            padding: 20px;
            flex-direction: row;
            gap: 30px;
            align-items: start;
          "
        >
          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
            "
          >
            <!-- RAT -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >RAT</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temp.svg" />
                <input [(ngModel)]="RAT" />
              </div>
            </div>

            <!-- CHW Valve -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CHW VALVE</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="CHWVP" />
              </div>
            </div>

            <!-- FAN SPEED -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >FAN SPEED</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="VFD_freq" />
              </div>
            </div>

           
          </div>

          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 10px">
              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SUPPLY DPT</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <input [(ngModel)]="SAT" />
                  <span>Pa</span>
                </div>
              </div> -->

               <!-- SAT -->
            <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 12px; font-weight: 500"
              >SAT</span
            >

            <div class="input-group" style="width: 100px">
              <img src="/assets/images/temp.svg" />
              <input [(ngModel)]="SAT" />
            </div>
          </div>



              <!-- chws temp. -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >A</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temp.svg" />
                  <input [(ngModel)]="SFRS" />
                </div>
              </div>

              <!-- CHW VALVE -->

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >AMBIENT TEMP.</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temp.svg" />
                  <input [(ngModel)]="AMBIENT_TEMP" />
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 10px;
                justify-content: end;
                margin-top: 10px;
              "
            >
              <button
                style="
                  width: 50%;
                  background-color: var(--blue-one);
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                  
                "
                (click)="predictData()"
              >
                Predict
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div>
      </div>

      <!-- KPI -->

      <div class="form" style="height: 200px; width: 250px">
        <span
          class="span-text-dark"
          style="font-weight: bold; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div style="height: 30px"></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >Power Saving</span
          >

          <div class="input-group" style="width: 120px">
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event"
            />
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>


<!-- Type 3 ------ FAHUs for Manar Mall  -->
<div *ngIf="type==3">
  <div style="
      width: 100%;
      height: 100%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: absolute; ">
    <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar>
    <div style="
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        align-items: center;
        gap: 10px;
      " >
      <div style="
          height: 400px;
          width: 1200px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        " >
        <!-- FAHU Map  -->
        <div
          style="
            height: 400px;
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
          " >
          <div style="height: 400px; width: 1200px; margin-right:0px">
            <img src="/assets/images/fahu-manar-mall.svg" width="1200px" height="400px" />
            <div style="position: absolute;
            margin-top: -155px;
            margin-left: 340px;
            height: 75px;
            width: 300px;">
              <div style="display: flex; width: 100%; height: 100%; justify-content: center; align-items:center; ">
                <p style="    font-family:Mulish;
 font-size: 24px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">{{ name }}</p>
              </div>
            </div>
            <div style="position: absolute;
            margin-top: -342px;
            margin-left: 1055px;
            height: 270px;
            width: 150px;">
              <div style="display: flex; width: 80%; height: 100%; justify-content: center; align-items:center;">
                <p style="    font-family:Mulish;
 sans-serif; font-size: 20px; color:#0B3876; font-weight:520; text-align: center; line-height:28px; ">{{ area }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Image Code -->
        <div  style="height: 246px; width: 169.5px; margin-left: 1032.5px; margin-top: 67.9px;" class="image-container" (mouseover)="hideImage()" (mouseout)="showImage()">
          <img [src]="'/assets/area/' + imageFileName" width="165px"  height="239px" alt="Your Image" [class.hidden]="isHidden" />
        </div>

        <!-- componets -->
        <div style="height: 350px; width: 1000px; position: absolute">
          <!-- FAN -->
          <div style="
          position:absolute;
              height: 85px;
              width: 85px;
              margin-top: 118px;
              margin-left: 333px;
            " >
            <Fan [state]="state"></Fan>
          </div>

          <!-- 1. SFRS, Supply Fan Run Status -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px; margin-top: 47px; margin-left: 255px" >
            <img src="/assets/images/switch1.svg" />
            <ng-container *ngIf="RAT_out !== false; else emptyInput">
              <input
                [ngModel]="RAT_out | number : '1.2-2'"
                (ngModelChange)="RAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          

          <!-- 2. CHWVP opening Percentage  -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 80px;
            margin-top: 230px;
            margin-left: 138px;"
          >
            <img src="/assets/images/percentage.png" />
            <ng-container *ngIf="CHWVP_out !== false; else emptyInput">
              <input
                [ngModel]="CHWVP_out | number : '1.2-2'"
                (ngModelChange)="CHWVP_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          <!-- 3. Fan Power -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 110px;
            margin-top: 47px;
            margin-left: 445px;"
          >
            <img src="/assets/images/power.svg" />
            <ng-container *ngIf="FAN_Power_out !== false; else emptyInput">
              <input
                [ngModel]="FAN_Power_out | number : '1.2-2'"
                (ngModelChange)="FAN_Power_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>Wh</span>
          </div>

          <!-- 4. Fan Speed-->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px;
            margin-top: 47px;
            margin-left: 349px;"
          >
            <img src="/assets/images/fan.svg" />
            <ng-container *ngIf="VFD_Freq_out !== false; else emptyInput">
              <input
                [ngModel]="VFD_Freq_out | number : '1.2-2'"
                (ngModelChange)="VFD_Freq_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>%</span>
          </div>

              <!-- 5. SDPT_out -->
          <!-- <div
          [className]="capsule_group"
          style="width: 94px;
          margin-top: 77px;
          margin-left: 625px;"
          >
              <img src="/assets/images/differential-pressure-transmitter.png" />
              <ng-container *ngIf="SDPT_out !== false; else emptyInput">
                <input
                  [ngModel]="SDPT_out | number : '1.2-2'"
                  (ngModelChange)="SDPT_out = $event"
                />
              </ng-container>
              <ng-template #emptyInput>
                <input [ngModel]="''" />
              </ng-template>
          <span>Pa</span>
        </div> -->

          <!-- 6. SAT_out -->
          <div
            [className]="capsule_group"
            style="position:absolute; width: 90px;
            margin-top: 78px;
            margin-left: 770px;"
          >
            <img src="/assets/images/temp.svg" />
            <ng-container *ngIf="SAT_out !== false; else emptyInput">
              <input
                [ngModel]="SAT_out | number : '1.2-2'"
                (ngModelChange)="SAT_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
          </div>

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: 5px;
            margin-left: 20px;"
          >
            <img src="/assets/images/CO2.png" />
            <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['co2'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>ppm</span>
          </div> -->

          <!-- <div
            class="input-group2"
            style="width: 110px;
            margin-top: -10px;
            margin-left: 210px;"
          >
            <img src="/assets/images/DP.png" />
            <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
              <input
                [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                (ngModelChange)="cassandra_data['pfp'] = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input [ngModel]="''" />
            </ng-template>
            <span>mbar</span>
          </div> -->
        </div>
      </div>
    </div>

    <div
    style="
      height: auto;
      width: 100%;
      min-width: 1000px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 7px;
      align-items: center;
      gap: 10px;
    "
  >
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: center;
      "
    >
      <div style="width: 600px; height: 100%; margin: 0px 20px 0px 50px">
        <div
          class="form"
          style="
            padding: 20px;
            flex-direction: row;
            gap: 30px;
            align-items: start;
          "
        >
          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
            "
          >
            <!-- Chilled Water Inlet Temperature -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CWIT</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temp.svg" />
                <input [(ngModel)]="RAT" />
              </div>
            </div>

            <!-- CHW Valve Chilled Water Valve Openiong Percentage-->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CHW VALVE</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="CHWVP" />
              </div>
            </div>

            <!-- FAN SPEED -->
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >FAN SPEED</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input [(ngModel)]="VFD_freq" />
              </div>
            </div>

           
          </div>

          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 10px">
              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SUPPLY DPT</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <input [(ngModel)]="SAT" />
                  <span>Pa</span>
                </div>
              </div> -->

               <!-- SAT --Supply Air Temperature-->
            <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span
              class="span-text-dark"
              style="font-size: 12px; font-weight: 500"
              >SAT</span
            >

            <div class="input-group" style="width: 100px">
              <img src="/assets/images/temp.svg" />
              <input [(ngModel)]="SAT" />
            </div>
          </div>



              <!-- Supply Fan Run status -->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SFRS</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/switch1.svg" />
                  <input [(ngModel)]="SFRS" />
                </div>
              </div>

              <!-- Ambient Temperature -->

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >AMBIENT TEMP.</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/temp.svg" />
                  <input [(ngModel)]="AMBIENT_TEMP" />
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 10px;
                justify-content: end;
                margin-top: 10px;
              "
            >
              <button
                style="
                  width: 50%;
                  background-color: var(--blue-one);
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                "
                (click)="predictData()"
              >
                Predict
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div>
      </div>

      <!-- KPI -->

      <div class="form" style="height: 200px; width: 400px">
        <span
          class="span-text-dark"
          style="font-weight: bold; text-align: center"
          >KEY PERFORMANCE INDICATOR</span
        >

        <div style="height: 30px"></div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap:10px;
          "
        >
          <span
            class="span-text-dark"
            style="font-size: 12px; font-weight: 500"
            >FAN POWER SAVING</span
          >

          <div class="input-group" style="width: 120px">
            <img src="/assets/images/power.png" />
            <input
              [ngModel]="KPI | number : '1.2-2'"
              (ngModelChange)="KPI = $event"
            />
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

  


</div>
