import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ahuschedule-command',
  templateUrl: './ahuschedule-command.component.html',
  styleUrls: ['./ahuschedule-command.component.css']
})
export class AhuscheduleCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  errorMessage: string = '';
  selectedBlock: string = 'OLT'; // Default block
  selectedAHU: string = '';
  floors:string[]=['GF']
  selectedfloor:string='';
  responseMessage: string = '';
  schedule_id:number=0;
  isLoading: boolean = false;
  starthour:number=0;
  startmin:number=0;
  endhour:number=0;

  endmin:number=0;
  schedulecontrol:boolean=true;


  ahus : string[]=['AHU_01','AHU_02','AHU_03','AHU_04','AHU_05','AHU_06','AHU_07','AHU_08','AHU_10','AHU_11','AHU_12']
constructor(private http:HttpClient){

}
  showCommand() {
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Wrong command';
      this.showDetails = false;
    }
  }

  sendCommand(){
 
    this.isLoading = true;
  
      const data = {
        client: 'strides',
          site: 'krsg',
          action:3,
          id1: this.selectedBlock,
          id2: this.selectedfloor,
          id3: this.selectedAHU,
          user: "bert",
          body:{
            scheduleId:this.schedule_id,
        startHr: this.starthour,
        startMin: this.startmin,
        stopHr: this.endhour,
        stopMin:this.endmin,
        schControl:0
          }
      };
      console.log(data);
  
      this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
        (response)=>{
          this.isLoading=false;
            this.responseMessage='Command sent successfully!!';
            console.log("schedule data",data)
            console.log('post response',response);
        },
        (error)=>{
          
          this.isLoading=false;
          this.responseMessage='Failed to send command.please try again!';
          console.log('post response',error);
        }
      )
    
    }
}
