<div class="scaffold">
    <Nav-bar ></Nav-bar>

    <div style="height: 100%; display: flex; flex-direction: row;overflow-x: hidden;overflow-y:hidden;">
      <div style="height: 100%;">
        <Main-side-bar ></Main-side-bar>
      </div>

      <!-- <a [routerLink]="['ahu', 'account-office-ahu']">account office</a>
      <a [routerLink]="['ahu', 'training-room-ahu']">training room</a>
      <a [routerLink]="['ahu', 'reservation-office-ahu']">reservation office</a> -->

      <div style="min-height: 100%; width: 100%;overflow-y: scroll; overflow-x: scroll;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
