import { Component } from '@angular/core';

@Component({
  selector: 'Ahu-one-comp',
  templateUrl: './ahu-one-comp.component.html',
  styleUrls: ['./ahu-one-comp.component.css']
})
export class AhuOneCompComponent {

}
