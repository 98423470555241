import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RoleserviceService } from 'src/app/services/roleservice.service';
//this is for managing roles ,not user(mistake done by ojas)
//thanks for the credit uma     -ojas sultanpure

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})

export class ManageUsersComponent   {

  ngOnInit():void{
    this.getallroles();
  }
  // Role name and permissions array
constructor(private http:HttpClient,private authservice:AuthService,private roleservice:RoleserviceService,    private snackBar: MatSnackBar,
){

}
   roleDataPacket = [
    {
      roleName: 'admin',
      description: 'Administrator with full access',
      permissions: [
        { commandName: 'ahu', permissions: 'READ_WRITE' },
      
       
        { commandName: 'ahucontrol', permissions: 'READ_WRITE' },
        { commandName: 'pidtuning', permissions: 'READ_WRITE' },
        { commandName: 'ahuschedule', permissions: 'READ_WRITE' },
        { commandName: 'chillersequencing', permissions: 'READ_WRITE' },
        { commandName: 'chiller', permissions: 'READ_WRITE' }
      ],
      roleStatus: 'active'
    },
    {
      roleName: 'engineer',
      description: 'Engineer with specific access',
      permissions: [
        { commandName: 'ahu', permissions: 'READ' },
       
        { commandName: 'ahucontrol', permissions: 'READ' },
        { commandName: 'pidtuning', permissions: 'READ' },
        { commandName: 'ahuschedule', permissions: 'READ' },
        { commandName: 'chillersequencing', permissions: 'READ' },
        { commandName: 'chiller', permissions: 'READ' }
      ],
      roleStatus: 'active'
    },
    {
      roleName: 'monitor',
      description: 'Monitor with read-only access',
      permissions: [
        { commandName: 'ahu', permissions: 'READ' },
    
        { commandName: 'ahucontrol', permissions: 'READ' },
        { commandName: 'pidtuning', permissions: 'READ' },
        { commandName: 'ahuschedule', permissions: 'READ' },
        { commandName: 'chillersequencing', permissions: 'READ' },
        { commandName: 'chiller', permissions: 'READ' }
      ],
      roleStatus: 'inactive'
    }
  ];

  isMenuOpen: boolean[] = [];
  // Form values
  roleName: string = '';
  description: string = '';
  roleStatus: string = '';
  rolePermissions: { [key: string]: any } = {
    ahu:undefined,
    coolingtower:undefined,

    ahucontrol:undefined,
    
    ahuschedule:undefined,
    chillersequencing:undefined,
    chiller:undefined,

  };

  // Save role and permissions

  
  ismodifying:boolean=false;
  // Save role and permissions

  submitRole(){
    const permissions: any[] = [];
    console.log("submit",this.rolePermissions);
    const keys=Object.keys(this.rolePermissions);
    keys.forEach(key=>{
      if(this.rolePermissions[key]!=undefined){
        if(this.rolePermissions[key]==1){
          permissions.push({commandName:key,readWritePermission:'READ_WRITE'})
        }
        else{
          permissions.push({commandName:key,readWritePermission:'READ'})

        }
      }
    })
   
    if(this.ismodifying){
      const data={
        // name:this.roleName,
        description:this.description,
        roleStatus:this.roleStatus,
        permissions:permissions
      }
      this.updateRole(data);
    }
    else{
      const data={
        name:this.roleName,
        description:this.description,
        roleStatus:this.roleStatus,
        permissions:permissions
  
  
  
      }
      this.saveRole(data);
    }
  }
  updateRole(data:any){
    console.log(data,"update role")
    this.http.put<any>(`${environment.apiBaseUrl}/roles/${this.roleName}`,data).subscribe(
      (response)=>{
        console.log(response);
        this.openSnackBar(response.message)
        this.getallroles();
      },
    (error)=>{
      console.log(error);
    }
    )

    this.cancelRole();

   
  }
  saveRole(data:any) {

    this.http.post<any>(`${environment.apiBaseUrl}/roles`,data).subscribe(
      (response)=>{
        console.log(response)
        this.openSnackBar(`Successfully added new role with name ${data.roleName}`)

        this.getallroles();
      },
    (error)=>{
      console.log(error);
    }
    )
    this.cancelRole()
    console.log(data);


   
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }

  getallroles(){
    console.log("getting roles")
    this.http.get<any>(`${environment.apiBaseUrl}/roles`).subscribe(
      (response)=>{
        this.roleDataPacket=response
        this.roleservice.setallroles(this.roleDataPacket);
        console.log(response,"all roles");
      },
      (error)=>{
        console.log(error);
      }
    )
  }
isroleadmin(role:any){
  if(role.roleName=='Admin' || role.roleName=='admin' ||role.roleName=='defaultRole' ||role.roleName=='DefaultRole' ){
    return true;
  }
  else{
    return false;
  }
}
  editRole(role: any) {
    console.log('Editing role:', role.roleName);
    this.ismodifying=true;
    console.log(role);
    this.roleName=role.roleName;
    this.description=role.description;
    this.roleStatus=role.roleStatus;
    role.permissions.forEach((per:any)=>{
      if(per.permissions=='READ_WRITE'){
        this.rolePermissions[per.commandName]=1;
      }
      else{
        this.rolePermissions[per.commandName]=0;

      }
    })
   

    // Add your logic here to edit the role, e.g., opening a modal with role details
  }
  
  deleteRole(role: any) {
    console.log('Deleting role:', role.roleName);
    console.log(role);
    this.http.delete<any>(`${environment.apiBaseUrl}/roles/${role.roleName}`).subscribe(
      (response)=>{
        console.log(response);
        this.getallroles();
        this.openSnackBar(`Successfully deleted  ${role.roleName}`)



      },
      (error)=>{

      }
    )
    // Add your logic to handle role deletion
  }
  
  getPermissionsDisplay(permissions: any[]): string {
    console.log(permissions)
    return permissions
      .map(permission => `${permission.commandName} ${permission.permissions.toLowerCase()}`)
      .join(', ');
  }
  
  

  // Cancel and reset form
  cancelRole() {
    this.roleName = '';
    this.description = '';
    this.roleStatus = '';
    this.ismodifying=false;
    this.resetPermissions();
  }

  // Reset permissions after saving or canceling
  resetPermissions() {
    for (const key in this.rolePermissions) {
      this.rolePermissions[key] = false;
    }
  }
  onPermissionChange(key: string, value: number): void {
    // When changing, ensure only one checkbox can be selected at a time
    if (this.rolePermissions[key] !== value) {
      this.rolePermissions[key] = value;
    } else {
      // If clicking the already selected checkbox, you can deselect it if needed
      this.rolePermissions[key] = null; // Or handle it differently
    }
  }
  // Determine if "Read Only" should be disabled
 

  // Helper method to apply CSS class for disabled checkboxes




  // Function to toggle the meatballs menu for a specific role
  toggleMenu(index: number) {
    this.isMenuOpen[index] = !this.isMenuOpen[index];
  }


}
