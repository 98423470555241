<div style="position: relative; height: 100%; width: 100%">
    <div style="
            width: 100%;
            height: 100%;
            min-width: 1200px;
            display: flex;
            flex-direction: column;
            position: absolute;">
        <div style="display: flex; flex-direction: row; height: 100%; width: 100%">
            <!-- main body -->
            <div style="
                height: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: start;
                gap: 10px;
                justify-content: center;
                overflow-y: scroll;
                margin-top:2%;
                ">
                <div style="
                    height: 620px;
                    width: 1200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;">
                    <!-- map -->
                    <div style="
                        height: 620px;
                        display: flex;
                        width: 1200px;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        
                        margin-left:-15%;">
                        <img src="/assets/manar-zones/zone-eleven-map-details.svg" width="1200px" height="620px" />
                    </div>
                    <!-- componets -->
                    <div style="height: 350px; width: 1000px; position: absolute">
                        <!-- FAN -->


                        




                        <div
                            style=" display: flex; flex-direction: row;  align-items: center; gap:10px; margin-top:322px; margin-left:670px;  ">
                            <div class="ahu-one-hover" routerLink="/dashboard/heat-exchanger">
                                <span style="font-size: 15px; color:#0B3876; ">HEX-Pumps</span>
                            </div>
                            <!-- <div class="input-group" style="width: 90px;">
                                <img src="/assets/images/temperature-celcius.png" />
                                <input [(ngModel)]="RAT" />
                            </div> -->
                            
                        </div>






                    </div>




                </div>




                                <!-- KPI FORM  -->
        
                                <div class="form disabled-form" style="height: 200px; width: 380px; background:#F0F6FF; margin-top:20px; z-index:1; margin-right:50px; gap:10px;">
                                  <span
                                    class="span-text-dark"
                                    style="font-weight: bold; font-size: 20px; color: gray; text-align: center"
                                    >KEY PERFORMANCE INDICATOR</span
                                  >
                          
                                  <div style="height: 30px;   "></div>
                                  <div
                                    style="
                                      display: flex;
                                      flex-direction: row;
                                      width: 350px;
                                      justify-content: space-between;
                                      align-items: center;
                                      padding:15px;
                                      z-index:1; background:white;
                                      border-radius: 10px;
                                      gap:20px;
                                    "
                                  >
                                    <span
                                      class="span-text-dark"
                                      style="font-size: 16px;color: gray; font-weight: 500"
                                      >ZONE 11 Temperature</span
                                    >
                          
                                    <div style="width:70px">
                                      
                                      <input style="width:70px; font-size: 20px; color: gray; text-align:center; border-radius:8px; border: 2px solid gray;  "
                                      [ngModel]="Zone_temp | number : '1.1-1'"
                                      (ngModelChange)="Zone_temp = $event"
                                      />
                                      
                                    </div>
                                    <span style="margin-left:-60px; font-size: 20px;">ºC</span>
                                  </div>
                          
                                  <div
                                    style="
                                      display: flex;
                                      flex-direction: row;
                                      width: 350px;
                                      justify-content: space-between;
                                      align-items: center;
                                      padding:15px;
                                      z-index:1; background:white;
                                      border-radius: 10px;
                                      gap:20px;
                                    "
                                  >
                                    <span
                                      class="span-text-dark"
                                      style="font-size: 16px; color: gray; font-weight: 500"
                                      >ZONE 11 Energy Saving</span
                                    >
                          
                                    <div style="width:70px">
                                      
                                      <input style="width:70px; font-size: 20px; color: gray;  text-align:center; border-radius:8px; border: 2px solid gray;  "
                                      [ngModel]="KPI | number : '1.1-1'"
                                      (ngModelChange)="KPI = $event"
                                      />
                                      
                                    </div>
                                    <span style="margin-left:-50px; font-size: 20px;">%</span>
                                  </div>
                          
                                </div> 
                          
                          
                          






            </div>
        </div>
    </div>
</div>