import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-two-screen',
  templateUrl: './zone-two-screen.component.html',
  styleUrls: ['./zone-two-screen.component.css']
})
export class ZoneTwoScreenComponent {
  type!: number;
  RAT: number;
  KPI: number =0 ;
  RAT_out: any;
  RAT_out_AHU_05: any;
  Zone_temp:number=0;
  power_ahu_05:number=0;
  BASELINE_ZONE_02:number=0;
  POWER_ZONE_02:number=0;

  constructor(private router: Router) {
    this.RAT = 0;
    this.RAT_out_AHU_05 = Number(localStorage.getItem('RAT_out_AHU_05')) || 0;
    localStorage.setItem('RAT_out_AHU_05', JSON.stringify(this.RAT_out_AHU_05));
    console.log("RAT_AHU_05_output_zone_one", this.RAT_out_AHU_05 );

    
    this.Zone_temp = this.RAT_out_AHU_05;


    this.power_ahu_05 = Number(localStorage.getItem('Power_AHU_05_out')) || 0;
    localStorage.setItem('power_ahu_05', JSON.stringify('power_ahu_05'));
    console.log("Power_AHU_05_out", this.power_ahu_05);

    this.BASELINE_ZONE_02 = 6.29;
    this.POWER_ZONE_02=this.power_ahu_05;

    
    if(this.POWER_ZONE_02){
      this.KPI = ((this.BASELINE_ZONE_02-this.POWER_ZONE_02)/this.BASELINE_ZONE_02)*100;
    localStorage.setItem('kpi_zone_02', JSON.stringify('KPI'));
    }
    else{
      this.KPI = 0 ;
    }


   }
}
