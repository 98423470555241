import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-entrance',
  templateUrl: './main-entrance.component.html',
  styleUrls: ['./main-entrance.component.css']
})
export class MainEntranceComponent {
  type!: number;
  RAT: number;
RAT_out: any;
KPI: number=0;
RAT_out_AHU_13: any;
RAT_out_AHU_17: any;
RAT_out_AHU_18: any;
Zone_temp:number=0;
power_ahu_13:number=0;
power_ahu_17:number=0;
power_ahu_18:number=0;
BASELINE_MAIN_ENTRANCE:number=0;
POWER_MAIN_ENTRANCE: number=0;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_13 = Number(localStorage.getItem('RAT_out_AHU_13')) || 0;
  localStorage.setItem('RAT_out_AHU_13', JSON.stringify(this.RAT_out_AHU_13));
  console.log("RAT_AHU_13_output_zone_one", this.RAT_out_AHU_13 );

  this.RAT_out_AHU_17 = Number(localStorage.getItem('RAT_out_AHU_17')) || 0;
  localStorage.setItem('RAT_out_AHU_17', JSON.stringify(this.RAT_out_AHU_17));
  console.log("RAT_AHU_17_output_zone_one", this.RAT_out_AHU_17 );

  this.RAT_out_AHU_18 = Number(localStorage.getItem('RAT_out_AHU_18')) || 0;
  localStorage.setItem('RAT_out_AHU_18', JSON.stringify(this.RAT_out_AHU_18));
  console.log("RAT_AHU_18_output_zone_one", this.RAT_out_AHU_18 );



  this.Zone_temp = ((this.RAT_out_AHU_13+this.RAT_out_AHU_17+this.RAT_out_AHU_18)/3);


  this.power_ahu_13 = Number(localStorage.getItem('Power_AHU_13_out')) || 0;
  localStorage.setItem('power_ahu_13', JSON.stringify('power_ahu_13'));
  console.log("Power_AHU_13_out", this.power_ahu_13);

  this.power_ahu_17 = Number(localStorage.getItem('Power_AHU_17_out')) || 0;
  localStorage.setItem('power_ahu_17', JSON.stringify('power_ahu_17'));
  console.log("Power_AHU_17_out", this.power_ahu_17);

  this.power_ahu_18 = Number(localStorage.getItem('Power_AHU_18_out')) || 0;
  localStorage.setItem('power_ahu_18', JSON.stringify('power_ahu_18'));
  console.log("Power_AHU_18_out", this.power_ahu_18);

  this.BASELINE_MAIN_ENTRANCE = 17.25;
  this.POWER_MAIN_ENTRANCE=(this.power_ahu_13+this.power_ahu_17+this.power_ahu_18);

  
  if(this.POWER_MAIN_ENTRANCE){
    this.KPI = ((this.BASELINE_MAIN_ENTRANCE-this.POWER_MAIN_ENTRANCE)/this.BASELINE_MAIN_ENTRANCE)*100;
  localStorage.setItem('kpi_main_entrance', JSON.stringify('KPI'));

  }
  else{
    this.KPI = 0 ;
  }









 }
}
