import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mall-two',
  templateUrl: './mall-two.component.html',
  styleUrls: ['./mall-two.component.css']
})
export class MallTwoComponent {
  type!: number;
  RAT: number;
  KPI:number=0;
RAT_out: any;
RAT_out_AHU_01: number=0;
RAT_out_AHU_10: number=0;
RAT_out_AHU_12: number=0;
RAT_out_AHU_28: number=0;
power_ahu_01: number=0;
power_ahu_10: number=0;
power_ahu_12: number=0;
power_ahu_28: number=0;
Zone_temp: number=0;
BASELINE_MALL_02:number=0;
POWER_MALL_02:number=0;

constructor(private router: Router) {
  this.RAT = 0;
  this.RAT_out_AHU_01 = Number(localStorage.getItem('RAT_out_AHU_01')) || 0;
  localStorage.setItem('RAT_out_AHU_01', JSON.stringify(this.RAT_out_AHU_01));
  console.log("RAT_AHU_01_output_zone_one", this.RAT_out_AHU_01 );

  this.RAT_out_AHU_10 = Number(localStorage.getItem('RAT_out_AHU_10')) || 0;
  localStorage.setItem('RAT_out_AHU_10', JSON.stringify(this.RAT_out_AHU_10));
  console.log("RAT_AHU_10_output_zone_one", this.RAT_out_AHU_10 );

  this.RAT_out_AHU_12 = Number(localStorage.getItem('RAT_out_AHU_12')) || 0;
  localStorage.setItem('RAT_out_AHU_12', JSON.stringify(this.RAT_out_AHU_12));
  console.log("RAT_AHU_12_output_zone_one", this.RAT_out_AHU_12 );

  this.RAT_out_AHU_28 = Number(localStorage.getItem('RAT_out_AHU_28')) || 0;
  localStorage.setItem('RAT_out_AHU_28', JSON.stringify(this.RAT_out_AHU_28));
  console.log("RAT_AHU_28_output_zone_one", this.RAT_out_AHU_28 );
  

  this.Zone_temp = ((this.RAT_out_AHU_01+this.RAT_out_AHU_10+this.RAT_out_AHU_12+this.RAT_out_AHU_28)/4);


  this.power_ahu_01 = Number(localStorage.getItem('Power_AHU_01_out')) || 0;
  localStorage.setItem('power_ahu_01', JSON.stringify('power_ahu_01'));
  console.log("Power_AHU_01_out", this.power_ahu_01);

  this.power_ahu_10 = Number(localStorage.getItem('Power_AHU_10_out')) || 0;
  localStorage.setItem('power_ahu_10', JSON.stringify('power_ahu_10'));
  console.log("Power_AHU_10_out", this.power_ahu_10);

  this.power_ahu_12 = Number(localStorage.getItem('Power_AHU_12_out')) || 0;
  localStorage.setItem('power_ahu_12', JSON.stringify('power_ahu_12'));
  console.log("Power_AHU_12_out", this.power_ahu_12);

  this.power_ahu_28 = Number(localStorage.getItem('Power_AHU_28_out')) || 0;
  localStorage.setItem('power_ahu_28', JSON.stringify('power_ahu_28'));
  console.log("Power_AHU_28_out", this.power_ahu_28);

  

  this.BASELINE_MALL_02 = 12.21;
  this.POWER_MALL_02=(this.power_ahu_01+this.power_ahu_10+this.power_ahu_12+this.power_ahu_28);

  
  if(this.POWER_MALL_02){
    this.KPI = ((this.BASELINE_MALL_02-this.POWER_MALL_02)/this.BASELINE_MALL_02)*100;
  localStorage.setItem('kpi_mall_02', JSON.stringify('KPI'));
  }
  else{
    this.KPI = 0 ;
  }






 }
}
