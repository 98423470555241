

   <div class="scaffold">
    <div class="background-image"></div>

    <div class="animation-container">
      <div class="animation-element">
        <img class="manar-logo" src="/assets/strides-images/strides-logo-loading.svg"  alt="Manar Mall Logo">
        <img class="manar-logo-powered-by" src="/assets/logos/powered_by.gif" alt="Powered By GIF" width="220px"
          style=" border-radius: 5px; z-index: 1; position: absolute;
          margin-top: 110px; margin-left:75px; ">
      </div>

      <!-- <div class="animation-element">
        <span class="span-text-light powered-by">Powered by:</span>
      </div> -->

      <div class="animation-element">
        <!-- <div class="bert-labs-logo">
          <img src="/assets/logos/bertlabs-logo.png" alt="Bert Labs Logo" width="100%">
        </div> -->
      </div>
    </div>
  </div>